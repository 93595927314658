import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import PrincipaleComponentAgence from "./PrincipalComponentAgence";
import moment from "moment";
import AuthContextAgence from "./Base";
import MesRequetesPage from "./MesRequetesPage";
import ReponduRequete from "./ReponduRequete";
import Swal from "sweetalert2";
import BaseUrl from "../../BaseUrl";
import PostePro from "./compo/poste";

// const siteUrl = 'http://127.0.0.1:8000'; 
const baseUrl = BaseUrl();
function ProfilDashboard(){
    function separateNumber(number) {
        // Vérifier si le nombre est un entier positif
        if (Number.isInteger(number) && number >= 0) {
          // Convertir le nombre en chaîne de caractères
          var numberString = number.toString();
      
          // Séparer les chiffres en centaines
          var separatedNumber = '';
          for (var i = numberString.length - 1; i >= 0; i--) {
            separatedNumber = numberString.charAt(i) + separatedNumber;
            if (i > 0 && (numberString.length - i) % 3 === 0) {
              separatedNumber = ',' + separatedNumber;
            }
          }
      
          return separatedNumber;
        } else {
          return 'Veuillez fournir un entier positif.';
        }
    }
    const agence = AuthContextAgence();
    const navigate = useNavigate();
    const [mesPostes,setMesPostes] = useState([]);
    const [voirPLus,setVoirPlus] = useState(0)
    const [voirTel,setVoirTel] = useState(0);
    const [totalPostes,setTotalPostes] = useState(0);
    const [visibleItems, setVisibleItems] = useState(15);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [loading, setLoading] = useState(false);
    const [loadingItems,setLoadingItems] = useState(false);
    const [totalRequetes,setTotalRequetes] = useState(0);
    const [totalReponses,setTotalReponses] = useState(0);
    const [nameComponent,setNameComponent] = useState('requetes');
    const [myAbonne,setMyAbonne] = useState([]);
    //const [person,setPerson] = useState(agence?.user);
    const [chooseAbonne,setChooseAbonne] = useState('');
    const [loadingAbonnement,setLoadingAbonnement] = useState(false);


    //console.log(person)
    useEffect(()=>{
     
        loadItems();
        try {
            if (agence && agence.user && agence.user.id) {
              axios.get(baseUrl.base+'/postes/?pro=' + agence.user.id).then((resp) => {
                setTotalPostes(resp.data.length);
              });

              axios.get(baseUrl.base+'/mes-requetes-agence/?pro='+agence.id).then((res)=>{
                setTotalRequetes(res.data.length)
              });

              axios.get(baseUrl.base+'/my-abonnes/?agence='+agence.id).then((resp)=>{
                if(resp.data.bool == false){
                    setMyAbonne([]);
                }else{
                    setMyAbonne(resp.data[0]);
                }
                
              });

            }
          } catch (error) {
            console.log(error);
          }
     
    },[agence,visibleItems]);

    const loadItems = () => {
    
        setLoading(true);
       
        try {
        
            if(agence && agence.user && agence.user.id){
              axios.get(baseUrl.base+'/postes/?agence='+agence.user?.id+'&page='+visibleItems).then((resp) => {
                if(resp.data.length > 0){
                    setMesPostes(resp.data);
                }else{
                  setHasMoreItems(false);
                }
    
                setLoading(false);
                
              });
            }
            
          } catch (error) { 
            console.log(error); 
          }
        
      };
    
      const handleShowMore = () => {
        setVisibleItems(prevVisibleItems => prevVisibleItems + 3);  
      };
    
      const showMoreButton = hasMoreItems && (
        <>
            <button onClick={handleShowMore} role="button" class="btn btn-sm btn-loader btn-primary-soft" >
              <span class="load-text">  Voir plus </span>
              <div class="load-icon">
                <div class="spinner-grow spinner-grow-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </button>
        </>
       /*  <button onClick={handleShowMore}>Voir plus</button> */  
      );

    const funcVoirPLus =(poste)=>{
        const _fromData = new FormData();
        _fromData.append('poste',poste);
        _fromData.append('user',agence.user.id);
        try {
            axios.post(baseUrl.base+'/interet-voir-plus-click/',_fromData).then((resp)=>{
                if(resp.data.bool){}else{}
            })
        } catch (error) {
            
        }

        setVoirPlus(poste)
           
        
    }

    const funcVoirTel = (poste)=>{
        const _fromData = new FormData();
        _fromData.append('poste',poste);
        _fromData.append('user',agence.user.id);
        try {
            axios.post(baseUrl.base+'/interet-voir-tel-click/',_fromData).then((resp)=>{
                if(resp.data.bool){}else{}
            })
        } catch (error) {
            
        }

        setVoirTel(poste)
    }

    const chooseAbonneBtn=(abonne)=>{
        if(abonne == chooseAbonne){
            setChooseAbonne('');
        }else{
          setChooseAbonne(abonne);
        }
    }

    const showModal=()=>{
        window.$(`#modalAbonnement`).modal('show');
    }

    const NewAbonnement=()=>{
        const _fromData = new FormData();

        _fromData.append('forfait',chooseAbonne);
        _fromData.append('agence',agence.id);

        if(chooseAbonne !==""){
            try {
                axios.post(baseUrl.base+'/save-my-abonnement/',_fromData).then((resp)=>{
                    if(resp.data.bool){
                        Swal.fire({
                            title: 'FELICITATION',
                            text: 'Votre abonnement a été pris en compte avec succès !',
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonColor: '#3085d6',
                            confirmButtonText: 'OK'
                          }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload();
                            }
                          }); 
                    }
                });
            } catch (error) {
                console.log(error);
            }
        }
  
    }

    
    


    return (
        <>
          <PrincipaleComponentAgence>
     
                    <div class="container">
                        <div class="row g-4">
                       
                        <div class="col-lg-2"></div>
                        
                        <div class="col-lg-8 vstack gap-4">
                        {agence.length !=0 && agence.image == null && 
                            <div class="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                <strong>Pour plus de visibilité de votre page Veuillez ajouter un logo et completer vos infos.</strong>
                                <Link to="/ag/settings/" class="btn btn-xs btn-success mt-2 mt-md-0 ms-md-4">Changer</Link>
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                        }

                    

                        {/* {!myAbonne?.illimit && myAbonne?.reponseNbre < 10   && 
                            <div class="alert alert-info alert-dismissible fade show mb-0" role="alert">
                                <strong>Vous avez moins de 10 réponses. Réabonnez-vous maintenant .</strong>
                                 <button class="btn btn-xs btn-success mt-2 mt-md-0 ms-md-4" onClick={()=>showModal()}>Abonnez-vous maintenant !</button> 
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div>
                        } */}

                       
                       
                            
                            <div class="card">
                        
                            <div class="h-200px rounded-top banner-profil" ></div>
                                
                                <div class="card-body py-0">
                                    <div class="d-sm-flex align-items-start text-center text-sm-start">
                                        <div>
                                        
                                        <div class="avatar avatar-xxl mt-n5 mb-3">
                                            {agence?.image !== null &&
                                                <img class="avatar-img rounded-circle border border-white border-3" src={agence?.image} alt="" />
                                            }

                                            {agence?.image == null &&
                                                <img class="avatar-img rounded-circle border border-white border-3" src="/assets/images/avatar/placeholder.jpg" alt="" />
                                            }
                                        
                                        </div>
                                        </div>
                                        <div class="ms-sm-4 mt-sm-3">
                                        
                                        <h1 class="mb-0 h5">{agence?.raison_sociale } <i class="bi bi-patch-check-fill text-success small"></i></h1>
                                        <p className="text-info  m-0"><b>Likes <i class="fa-solid fa-thumbs-up"></i> : {agence.likeCount}</b></p> 
                                        {/* {!myAbonne?.illimit &&<p className="text-danger border mt-0">Droit de reponse : <b><i class="fa-solid fa-bullhorn"></i> {myAbonne?.reponseNbre}</b></p>}
                                        {myAbonne?.illimit &&<p className="text-danger border mt-0">Vous disposez de Reponses <b>Illimitée</b> </p>} */}
                                        
                                        </div>
                                        
                                        <div class="d-flex mt-3 justify-content-center ms-sm-auto">
                                        <Link class="btn btn-primary me-2" to='/ag/creer-nouveau-posts/'> Ajouter une annonce </Link>
                                        <div class="dropdown">
                                            
                                        {/*   <button class="icon-md btn btn-light" type="button" id="profileAction2" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i class="bi bi-three-dots"></i>
                                            </button>
                                            
                                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="profileAction2">
                                            <li><a class="dropdown-item" href="#"> <i class="bi bi-bookmark fa-fw pe-2"></i>Share profile in a message</a></li>
                                            <li><a class="dropdown-item" href="#"> <i class="bi bi-file-earmark-pdf fa-fw pe-2"></i>Save your profile to PDF</a></li>
                                            <li><a class="dropdown-item" href="#"> <i class="bi bi-lock fa-fw pe-2"></i>Lock profile</a></li>
                                            <li><hr class="dropdown-divider" /></li>
                                            <li><a class="dropdown-item" href="#"> <i class="bi bi-gear fa-fw pe-2"></i>Profile settings</a></li>
                                            </ul> */}
                                        </div>
                                        </div>
                                    </div>
                                
                                    <ul class="list-inline mb-0 text-center text-sm-start mt-3 mt-sm-0">
                                        <li class="list-inline-item"><i class="bi bi-briefcase me-1"></i> {agence.user?.nom} {agence.user?.prenom}</li>
                                        <li class="list-inline-item"><i class="bi bi-geo-alt me-1"></i> {agence.adresse}</li>
                                        <li class="list-inline-item"><i class="bi bi-phone me-1"></i> {agence.user?.tel}</li>
                                    </ul> 
                                </div>
                                
                                <div class="card-footer mt-3 pt-2 pb-0">
                                
                                <ul class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
                                    <li class="nav-item cursor-pointer"> <a class={nameComponent == 'requetes' ? "nav-link active" : "nav-link" } onClick={()=>setNameComponent('requetes')}> Recherches{/* <span class="badge bg-danger bg-opacity-10 text-danger small"> {totalRequetes > 0 ?  "+"+totalRequetes : 0}</span> */} </a> </li>
                                    <li class="nav-item cursor-pointer"> <a class={nameComponent == 'publication' ? "nav-link active" : "nav-link" } onClick={()=>setNameComponent('publication')}>Annonces <span class="badge bg-primary bg-opacity-10 text-primary small"> {totalPostes}</span>  </a> </li>
                                    
                                    {/* <li class="nav-item"> <a class={nameComponent == 'reponses' ? "nav-link active" : "nav-link" } onClick={()=>setNameComponent('reponses')}> Mes recommandations <span class="badge bg-success bg-opacity-10 text-success small"> {totalReponses}</span></a> </li> */}
                                {/*   <li class="nav-item"> <a class="nav-link" href="my-profile-videos.html"> Videos</a> </li>
                                    <li class="nav-item"> <a class="nav-link" href="my-profile-events.html"> Events</a> </li>
                                    <li class="nav-item"> <a class="nav-link" href="my-profile-activity.html"> Activity</a> </li> */}
                                </ul>
                                </div>
                            </div>
                            
                            {nameComponent == 'publication' &&
                                <>
                                    {loading && <div className="text-center">Chargement...</div>}
                                    {mesPostes.length == 0 && !loading && <div className="text-center">Aucune Annonces</div>}
                           

                                    {mesPostes && 
                                        mesPostes?.map((poste,index)=>
                                       <PostePro poste={poste}/>
                                        )
                                    }
                                
                                    {showMoreButton}
                                </>
                            }

                            {nameComponent == 'requetes' && 
                                <>
                                    <MesRequetesPage agence={agence} />
                                </>
                            }

                            {nameComponent == 'reponses' && 
                                <>
                                    <ReponduRequete agence={agence} />
                                </>
                            }
                            
                          
                        </div>
                        
                        <div class="col-lg-2">


                        </div>
                        

                        </div> 
                    </div>

          </PrincipaleComponentAgence>

            
            <div class="modal fade" id="modalCreateFeed" tabindex="-1" aria-labelledby="modalLabelCreateFeed" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabelCreateFeed">Create post</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                

                
                <div class="modal-body">
                    
                    <div class="d-flex mb-3">
                    
                    <div class="avatar avatar-xs me-2">
                        <img class="avatar-img rounded-circle" src="/assets/images/avatar/03.jpg" alt="" />
                    </div>
                    
                    <form class="w-100">
                        <textarea class="form-control pe-4 fs-3 lh-1 border-0" rows="4" placeholder="Share your thoughts..." autofocus></textarea>
                    </form>
                    </div>
                    
                    <div class="hstack gap-2">
                    <a class="icon-md bg-success bg-opacity-10 text-success rounded-circle" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Photo"> <i class="bi bi-image-fill"></i> </a>
                    <a class="icon-md bg-info bg-opacity-10 text-info rounded-circle" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Video"> <i class="bi bi-camera-reels-fill"></i> </a>
                    <a class="icon-md bg-danger bg-opacity-10 text-danger rounded-circle" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Events"> <i class="bi bi-calendar2-event-fill"></i> </a>
                    <a class="icon-md bg-warning bg-opacity-10 text-warning rounded-circle" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Feeling/Activity"> <i class="bi bi-emoji-smile-fill"></i> </a>
                    <a class="icon-md bg-light text-secondary rounded-circle" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Check in"> <i class="bi bi-geo-alt-fill"></i> </a>
                    <a class="icon-md bg-primary bg-opacity-10 text-primary rounded-circle" href="#" data-bs-toggle="tooltip" data-bs-placement="top" title="Tag people on top"> <i class="bi bi-tag-fill"></i> </a>
                    </div>
                    
                </div>
                
                <div class="modal-footer row justify-content-between">
                    
                    <div class="col-lg-3">
                    <select class="form-select js-choice" data-position="top" data-search-enabled="false">
                        <option value="PB">Public</option>
                        <option value="PV">Friends</option>
                        <option value="PV">Only me</option>
                        <option value="PV">Custom</option>
                    </select>
                    </div>
                    
                    <div class="col-lg-8 text-sm-end">
                    <button type="button" class="btn btn-danger-soft me-2"> <i class="bi bi-camera-video-fill pe-1"></i> Live video</button>
                    <button type="button" class="btn btn-success-soft">Post</button>
                    </div>
                </div>
                

                </div>
            </div>
            </div>

            <div class="modal fade" id="feedActionPhoto" tabindex="-1" aria-labelledby="feedActionPhotoLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                
                <div class="modal-header">
                    <h5 class="modal-title" id="feedActionPhotoLabel">Add post photo</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                
                    <div class="modal-body">
                    
                    <div class="d-flex mb-3">
                    
                    <div class="avatar avatar-xs me-2">
                        <img class="avatar-img rounded-circle" src="/assets/images/avatar/03.jpg" alt="" />
                    </div>
                    
                    <form class="w-100">
                        <textarea class="form-control pe-4 fs-3 lh-1 border-0" rows="2" placeholder="Share your thoughts..."></textarea>
                    </form>
                    </div>

                    
                    <div>
                    <label class="form-label">Upload attachment</label>
                    <div class="dropzone dropzone-default card shadow-none" data-dropzone='{"maxFiles":2}'>
                        <div class="dz-message">
                        <i class="bi bi-images display-3"></i>
                        <p>Drag here or click to upload photo.</p>
                        </div>
                    </div>
                    </div>
                    

                    </div>
                    
                    <div class="modal-footer ">
                    
                        <button type="button" class="btn btn-danger-soft me-2" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-success-soft">Post</button>
                    </div>
                
                </div>
            </div>
            </div>

            <div class="modal fade" id="feedActionVideo" tabindex="-1" aria-labelledby="feedActionVideoLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                
                <div class="modal-header">
                <h5 class="modal-title" id="feedActionVideoLabel">Add post video</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
            
                <div class="modal-body">
                
                <div class="d-flex mb-3">
                    
                    <div class="avatar avatar-xs me-2">
                    <img class="avatar-img rounded-circle" src="/assets/images/avatar/03.jpg" alt="" />
                    </div>
                    
                    <form class="w-100">
                    <textarea class="form-control pe-4 fs-3 lh-1 border-0" rows="2" placeholder="Share your thoughts..."></textarea>
                    </form>
                </div>

                
                <div>
                    <label class="form-label">Upload attachment</label>
                    <div class="dropzone dropzone-default card shadow-none" data-dropzone='{"maxFiles":2}'>
                    <div class="dz-message">
                        <i class="bi bi-camera-reels display-3"></i>
                            <p>Drag here or click to upload video.</p>
                    </div>
                    </div>
                </div>
                

                </div>
                
                <div class="modal-footer">
                    
                    <button type="button" class="btn btn-danger-soft me-2"><i class="bi bi-camera-video-fill pe-1"></i> Live video</button>
                    <button type="button" class="btn btn-success-soft">Post</button>
                </div>
                
                </div>
            </div>
            </div>

            <div class="modal fade" id="modalCreateEvents" tabindex="-1" aria-labelledby="modalLabelCreateAlbum" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                
                <div class="modal-header">
                    <h5 class="modal-title" id="modalLabelCreateAlbum">Create event</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                
                <div class="modal-body">
                    
                    <form class="row g-4">
                    
                    <div class="col-12">
                        <label class="form-label">Title</label>
                        <input type="email" class="form-control" placeholder="Event name here" />
                    </div>
                    
                    <div class="col-12">
                        <label class="form-label">Description</label>
                        <textarea class="form-control" rows="2" placeholder="Ex: topics, schedule, etc."></textarea>
                    </div>
                    
                    <div class="col-sm-4">
                        <label class="form-label">Date</label>
                        <input type="text" class="form-control flatpickr" placeholder="Select date" />
                    </div>
                    
                    <div class="col-sm-4">
                        <label class="form-label">Time</label>
                        <input type="text" class="form-control flatpickr" data-enableTime="true" data-noCalendar="true" placeholder="Select time" />
                    </div>
                    
                    <div class="col-sm-4">
                        <label class="form-label">Duration</label>
                        <input type="email" class="form-control" placeholder="1hr 23m" />
                    </div>
                    
                    <div class="col-12">
                        <label class="form-label">Location</label>
                        <input type="email" class="form-control" placeholder="Logansport, IN 46947" />
                    </div>
                    
                    <div class="col-12">
                        <label class="form-label">Add guests</label>
                        <input type="email" class="form-control" placeholder="Guest email" />
                    </div>
                    
                    <div class="col-12 mt-3">
                        <ul class="avatar-group list-unstyled align-items-center mb-0">
                        <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="/assets/images/avatar/01.jpg" alt="avatar"/>
                        </li>
                        <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="/assets/images/avatar/02.jpg" alt="avatar"/>
                        </li>
                        <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="/assets/images/avatar/03.jpg" alt="avatar"/>
                        </li>
                        <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="/assets/images/avatar/04.jpg" alt="avatar"/>
                        </li>
                        <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="/assets/images/avatar/05.jpg" alt="avatar"/>
                        </li>
                        <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="/assets/images/avatar/06.jpg" alt="avatar"/>
                        </li>
                        <li class="avatar avatar-xs">
                            <img class="avatar-img rounded-circle" src="/assets/images/avatar/07.jpg" alt="avatar" />
                        </li>
                        <li class="ms-3">
                            <small> +50 </small>
                        </li>
                        </ul>
                    </div>
                
                    <div>
                        <label class="form-label">Upload attachment</label>
                        <div class="dropzone dropzone-default card shadow-none" data-dropzone='{"maxFiles":2}'>
                        <div class="dz-message">
                            <i class="bi bi-file-earmark-text display-3"></i>
                            <p>Drop presentation and document here or click to upload.</p>
                        </div>
                        </div>
                    </div>
                    
                    </form>
                    
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-danger-soft me-2" data-bs-dismiss="modal"> Cancel</button>
                    <button type="button" class="btn btn-success-soft">Create now</button>
                </div>
                </div>
            </div>
            </div>


            {/* Abonnement modal */}

            <div class="modal fade" id="modalAbonnement" tabindex="-1" aria-labelledby="modalLabelCreateAlbum" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered">
                    <div class="modal-content">
                    
                        <div class="modal-header">
                            <span class="modal-title" id="modalLabelCreateAlbum"><strong>Reabonnement</strong></span>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    
                        <div class="modal-body">
                        
                            <div class="col-lg-12 mx-auto mt-3">
                                <div class="vstack gap-4">
                                
                                <div class="card card-body bg-warning">
                                    <div class="d-md-flex justify-content-between align-items-start">
                                    
                                    <div class="mb-2 mb-md-0">
                                        <h5 class="mb-1"> <a href="#!">Abonnement Ekeu (5.000 Fcfa)</a></h5>
                                        <p class="small mb-0"><strong>Profiter de 800 réponses pendant 6 mois </strong></p>
                                    </div>
                                    
                                    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                    <button class={chooseAbonne == 'FORFAIT EKEU' ? "btn btn-sm btn-danger border" : "btn btn-sm btn-white border" }  for="Interested2" onClick={()=>chooseAbonneBtn('FORFAIT EKEU')}><i className="fa-solid fa-thumbs-up me-1"></i> Abonner</button>
                                    </div>
                                    </div>
                                </div>  
                                
                                <div class="card card-body bg-warning">
                                    <div class="d-md-flex justify-content-between align-items-start">
                                    
                                    <div class="mb-2 mb-md-0">
                                        <h5 class="mb-1"> <a href="#!">Abonnement Lite (10.000 Fcfa)</a></h5>
                                        <p class="small mb-0"> <strong> Profiter de 1500 réponses pendant 6 mois</strong> </p>
                                    </div>
                                    
                                    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                    
                                    <button class={chooseAbonne == 'FORFAIT LITE' ? "btn btn-sm btn-danger border" : "btn btn-sm btn-white border" }   for="Interested2" onClick={()=>chooseAbonneBtn('FORFAIT LITE')}><i className="fa-solid fa-thumbs-up me-1"></i> Abonner</button>
                                    </div>
                                    </div>
                                </div>  
                                
                                <div class="card card-body bg-warning">
                                    <div class="d-md-flex justify-content-between align-items-start">
                                
                                    <div class="mb-3 mb-md-0">
                                        <h5 class="mb-1"> <a href="#!">Abonnement Pro (30.000 Fcfa)</a></h5>
                                        <p class="small mb-0"><strong>Profiter des réponses illimitées et alerte SMS pendant 1 ans </strong></p>
                                    </div>
                                
                                    <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                    
                                        <button class={chooseAbonne == 'FORFAIT PRO' ? "btn btn-sm btn-danger border" : "btn btn-sm btn-white border" }   for="Interested2" onClick={()=>chooseAbonneBtn('FORFAIT PRO')}><i className="fa-solid fa-thumbs-up me-1"></i> Abonner</button>
                                    </div>
                                    </div>
                                </div>  
                                
                                
                                </div>
                            </div>
                        
                            
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-danger-soft me-2" data-bs-dismiss="modal"> Fermer</button>
                            {chooseAbonne == "" && !loadingAbonnement && <button type="button" class="btn btn-success" disabled>Valider</button>}
                            {chooseAbonne != "" && !loadingAbonnement &&  <button type="button" class="btn btn-success" onClick={()=>NewAbonnement()}>Valider</button>}

                            {chooseAbonne != "" && loadingAbonnement && <button type="button" class="btn btn-success" disabled>Chargement...</button>}
                           
                    
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default ProfilDashboard;