import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import BaseUrl from "../../BaseUrl";


const baseUrl = BaseUrl();
function HeaderPage({client,logout}){

    const [searchValue,setSearchValue] = useState('');
    const [postes,setPostes] = useState([]);
    const navigate = useNavigate();

    const [notifications,setNotifications] = useState([]);
    const [notifReadLength,setNotifReadLength] = useState(0);
    const [loadingNotif,setLoadingNotif] = useState(false);
    const [NotReadLength,setNotReadLength] = useState(0);

 
    useEffect(()=>{
        if(client && client.id){ 
            setLoadingNotif(true);
            try {
                axios.get(baseUrl.base+'/notif-list/?client='+client.id).then((resp)=>{
                    setNotifications(resp.data);
                    setLoadingNotif(false);
                });

                axios.get(baseUrl.base+'/read-notif-users/'+client.id+'/').then((res)=>{
                    if(res.data.bool){
                        setNotifReadLength(res.data.nbres);
                    }
                })
                
                axios.get(baseUrl.base+'/unread-status-notif-users/'+client.id+'/').then((res)=>{
                    if(res.data.bool){
                        setNotReadLength(res.data.nbres);
                    }
                }) 
            } catch (error) {
                console.log(error);
            }
            //verifier chaque 2 min si il n'y a pas une nouvelle notification.
             const intervalId = setInterval(() => {
                axios.get(baseUrl.base+'/notif-list/?client='+client.id).then((resp)=>{
                    setNotifications(resp.data);
                });

                axios.get(baseUrl.base+'/unread-status-notif-users/'+client.id+'/').then((res)=>{
                    if(res.data.bool){
                        setNotReadLength(res.data.nbres);
                    }
                });
              }, 2 * 60 * 1000); 
          
              return () => {
                clearInterval(intervalId);
              }; 
        }
    },[client,postes]);


    const searchFunction=(event)=>{
        setSearchValue(event.target.value);
        try {
            axios.get(baseUrl.base+'/postes/?q='+event.target.value).then((resp)=>{
                setPostes(resp.data);
            });
        } catch (error) {
            console.log(error);
        }

    }

     const ReadingNotification=(subject,notifId)=>{

         try {

                        
            axios.get(baseUrl.base+'/notif-list/?user='+client.id+'&notifId='+notifId).then((resp)=>{
                setNotifications(resp.data);
                setLoadingNotif(false);
            });
            axios.get(baseUrl.base+'/unread-status-notif-users/'+client.id+'/').then((res)=>{
                if(res.data.bool){
                    setNotReadLength(res.data.nbres);
                }
            });
             navigate('/all-notifications-client/?client='+client.id);
        
                
           
        } catch (error) {
            console.log(error);
        } 
       
        
        
    }

    const NotifCallandRead=()=>{
        /* try {
            axios.get(baseUrl.base+'/unread-notif/'+agence.id+'/').then((resp)=>{
                if(resp.data.bool){
                    setNotifReadLength(resp.data.nbres);
                }
            })
        } catch (error) {
            console.log(error);
        } */
    }
 

   
    return (
        <header class="navbar-light fixed-top header-static bg-mode">


        <nav class="navbar navbar-expand-lg">
            <div class="container">
            
                <Link class="navbar-brand" to='/cl/'>
                <img class="light-mode-item navbar-brand-item" src="/assets/logo-winin.png" alt="logo" />
                    {/* <img class="light-mode-item navbar-brand-item" src="/assets/images/logo.svg" alt="logo" />
                    <img class="dark-mode-item navbar-brand-item" src="/assets/images/logo.svg" alt="logo" /> */}
                </Link>
            


                <button class="navbar-toggler ms-auto icon-md btn btn-light p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-animation">
                    <span></span>
                    <span></span>
                    <span></span>
                    </span>
                </button>

                
                <div class="collapse navbar-collapse" id="navbarCollapse">


            <div class="nav mt-3 mt-lg-0 flex-nowrap align-items-center px-4 px-lg-0">
            <div class="nav-item w-100">
            <div class="input-group ms-2" type="button">
           
              <span class="input-group-prepend d-lg-none" id="searchMenu" data-bs-toggle="dropdown" aria-expanded="false"
                data-bs-auto-close="outside"
              >
                <div
                  class="input-group-text bg-gray border-0 rounded-circle mb-2"
                  style={{"min-height": "40px"}}
                >
                  <i class="fas fa-search "></i>
                </div>
              </span>
            
              <span class="input-group-prepend d-none d-lg-block" id="searchMenu"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                data-bs-auto-close="outside"
              >
                <div
                  class="input-group-text bg-gray border-0 rounded-pill"
                  style={{"min-height":"40px","min-width":"230px"}}
                >
                  <i class="fas fa-search me-2 text-muted"></i>
                  <p class="m-0 fs-7 ">Recherche...</p>
                </div>
              </span>
            
              <ul class="dropdown-menu overflow-auto border-0 shadow p-3" aria-labelledby="searchMenu" style={{"width": "22em", "max-height": "600px"}}>
               
                <li>
                  <input type="text" class="rounded-pill border-0 bg-gray dropdown-item " onChange={searchFunction} placeholder="Ecrire ici ..." autofocus />
                </li>

                {postes && 
                    postes?.map((poste,index)=> 
                    <li class="my-4">
                        <div class="alert fade show dropdown-item p-1 m-0 d-flex align-items-center justify-content-between" role="alert">
                        <div class="d-flex align-items-center overflow-hidden">
                            <p class="m-0 text-truncate" onClick={()=>navigate(`/search/top?q=${poste.slug}`,{state:{search:searchValue}})}>{poste.libelle} {poste.adresse_is_coloc}</p>
                        </div>
                        <button type="button" class="btn-close p-0 m-0" data-bs-dismiss="alert"aria-label="Close"></button>
                        </div>
                  </li>
                    )
                }
                {postes == 0 && 
                <li class="my-4">
                    <h6 className=" m-0text-primary">Aucun résultat</h6>
                </li>
                }
                
               
                
                
              </ul>
            </div>
               {/*  <form class="rounded position-relative">
                <input class="form-control ps-5 bg-light" type="search" placeholder="Search..." aria-label="Search" />
                <button class="btn bg-transparent px-2 py-0 position-absolute top-50 start-0 translate-middle-y" type="submit"><i class="bi bi-search fs-5"> </i></button>
                </form> */}
            </div>
            </div>
            

              
                </div>
            
                <ul class="nav flex-nowrap align-items-center ms-sm-3 list-unstyled">
                    
                <li class="nav-item dropdown ms-2">
                                <a class="nav-link icon-md btn btn-light p-0" onClick={()=>NotifCallandRead()} id="notifDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                       {/* {notifReadLength > 0 && <span class="badge-notif animation-blink position-absolute"></span>}  */}
                       <span class="badge-notif animation-blink position-absolute " style={{"width":"11px","height":"11px","background":"none","marginRight":"10px"}}><p className="badge bg-danger  rounded " style={{padding:"1px"}}><b style={{fontSize:"10px"}}>{NotReadLength>99 ? "+99" : NotReadLength}</b></p></span>
                            <i class="bi bi-bell-fill fs-6"> </i>
                        </a>
                        <div class="dropdown-menu dropdown-animation dropdown-menu-end dropdown-menu-size-md p-0 shadow-lg border-0" aria-labelledby="notifDropdown" style={{right:"-56px"}}>
                            <div class="card">
                            <div class="card-header d-flex justify-content-between align-items-center">
                                <h6 class="m-0">Notifications <span class="badge bg-danger bg-opacity-10 text-danger ms-2">{NotReadLength} Nouveaux</span></h6>
                                <Link class="small" to={`/all-notifications-client/?client=${client.id}`}>Voir tout</Link>
                            </div>
                            <div class="card-body p-0">
                                <ul class="list-group list-group-flush list-unstyled p-2" style={{"width": "23em", "max-height": "600px", "overflow-y": "auto"}}>
                                {loadingNotif && <span className="text-success">En cours de chargement...</span>}
                                {notifications &&
                                    notifications.map((notif,index)=>
                                    <li>
                                       
                                             <button onClick={()=>ReadingNotification(notif.notif_subject,notif.id)} class={notif.read_status==false ? "list-group-item list-group-item-action badge-unread rounded d-flex border-0 p-3 mb-1" : "list-group-item list-group-item-action rounded d-flex border-0 p-3 mb-1" }>

                                             <div class="ms-sm-3 d-flex">
                                                 <p class="small mb-2"><b>{notif.titre}</b> {notif.body}.<br/><span className="text-info">{moment(notif.created_at).fromNow()}</span></p>
                                                 {/* <p class="small ms-3"></p> */}
                                             </div>
                                             </button>
                                        
                                   
                                    </li>
                                    )
                                }
                               
    
                                </ul>
                            </div>
                           {/*  <div class="card-footer text-center">
                                <a href="#" class="btn btn-sm btn-primary-soft">See all incoming activity</a>
                            </div> */}
                            </div>
                        </div>
                    </li>
            

            <li class="nav-item ms-2 dropdown">
                        <a class="nav-link btn icon-md p-0" href="#" id="profileDropdown" role="button" data-bs-auto-close="outside" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false">
                           <img class="avatar-img rounded-circle" src="/assets/images/avatar/placeholder.jpg" alt="" />
                        </a>
            <ul class="dropdown-menu dropdown-animation dropdown-menu-end pt-3 small me-md-n3" aria-labelledby="profileDropdown">
                
                <li class="px-3">
                <div class="d-flex align-items-center position-relative">
                    
                    <div class="avatar me-3">
                         <img class="avatar-img rounded-circle" src="/assets/images/avatar/placeholder.jpg" alt="" />
                    </div>
                    <div>
                    <a class="h6 stretched-link" href="#">{client?.nom}</a>
                   {/*  <p class="small m-0">Web Developer</p> */}
                    </div>
                </div>
                <a class="dropdown-item btn btn-primary-soft btn-sm my-2 text-center" href="#">Bienvenue</a>
                </li>
                
                <li><Link class="dropdown-item" to="/cl/settings/"><i class="bi bi-gear fa-fw me-2"></i>Paramètres</Link></li>
               {/*  <li> 
                <a class="dropdown-item" href="https://support.webestica.com/" target="_blank">
                    <i class="fa-fw bi bi-life-preserver me-2"></i>Support
                </a> 
                </li> */}
                <li> 
               {/*  <a class="dropdown-item" href="docs/index.html" target="_blank">
                    <i class="fa-fw bi bi-card-text me-2"></i>Aides
                </a>  */}
                </li>
                <li class="dropdown-divider"></li>
                <li><button class="dropdown-item bg-danger-soft-hover" onClick={logout}><i class="bi bi-power fa-fw me-2"></i>Se Deconnecter</button></li>
                <li> <hr class="dropdown-divider" /></li>
                
                           
                        
            </ul>
                    </li>
            
            
                </ul>
            
            </div>
        </nav>

    </header>
    )
}

export default HeaderPage;