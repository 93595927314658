import { useEffect, useState } from "react";
import AuthContextAgence from "../Base";
import axios from "axios";
import moment from "moment";
import BaseUrl from "../../../BaseUrl";


const baseUrl = BaseUrl();
function AbonnementList(){
    const agence = AuthContextAgence();
    const [abonnements,setAbonnements] = useState([]);

    useEffect(()=>{
        if(agence && agence.id){
            try {
                axios.get(baseUrl.base+'/my-abonnes/?pro='+agence.id).then((resp)=>{
                    setAbonnements(resp.data);
                });
            } catch (error) {
                console.log(error);
            }
        }
    },[agence]);


    return (

         
            <div className="card">
                                    
                <div className="card-header border-0 pb-0">
                <h5 className="card-title">Mes Abonnements</h5>
                {/* <p className="mb-0">See information about your account, download an archive of your data, or learn about your account deactivation options</p> */}
                </div>
                
                <div className="card-body table-responsive-sm">
                
                    <table className="table table-bordered table-striped" >
                            <thead class="thead-dark bg-transparent" >
                                    <tr>
                                        <th /* style={{"max-width":"10px"}} */ scope="col">Date d'abonnement</th>
                                        <th /* style={{"max-width":"40px"}}  */scope="col">Catégorie</th>
                                        <th /* style={{"max-width":"30px"}}  */scope="col">Prix (Fcfa)</th>
                                        <th /* style={{"max-width":"10px"}} */ scope="col">Durée</th>
                                        <th /* style={{"max-width":"10px"}} */ scope="col">Reponses</th>
                                        <th /* style={{"max-width":"10px"}} */ scope="col">Paiement</th>
                                        <th /* style={{"max-width":"10px"}} */ scope="col">Etat</th>
                                    </tr>
                            </thead>
                            <tbody>
                                {abonnements && 
                                    abonnements.map((abonnement,index)=>
                                    <tr>
                                        <td>{moment(abonnement.date_debut).format("dddd,Do MMMM  YYYY")}</td>
                                        <td><b>{abonnement.libelle}</b></td>
                                        <td><b>{abonnement.montant}</b></td>
                                        <td>
                                            {abonnement.libelle == "FORFAIT EKEU" | abonnement.libelle == "FORFAIT LITE" ? <b className="text-success">6 Mois</b> : ""}
                                            {abonnement.libelle == "FORFAIT PRO"  ? <b className="text-success">1 ans</b> : ""}
                                        </td>
                                        <td>
                                            {!abonnement.illimit && <b>{abonnement.reponseNbre}</b>}
                                            {abonnement.illimit && <b className="text-warning">Illimitée</b>}
                                        </td>
                                        <td>
                                            {abonnement.statusPaie && <b className="badge bg-success">Oui</b>}
                                            {!abonnement.statusPaie && <b className="badge bg-danger">Non</b>}
                                        </td>
                                        <td>
                                            {abonnement.etat && <b className="badge bg-success">active</b>}
                                            {!abonnement.etat && <b className="badge bg-danger">Non</b>}
                                        </td>
                                    </tr>
                                    )
                                }
                                
                            </tbody>
                    </table>
    
                </div>
    
                    
                
            </div>


        
    
    )
}

export default AbonnementList;