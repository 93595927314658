
function PostColoc(){
    return (
        <>
            <div class="card">
                        
                        <div class="card-header border-0 pb-0">
                            <h1 class="h4 card-title mb-0">Faire une annonce colocataire</h1>
                        </div>
                        
                        <div class="card-body">
                            <form class="row g-3">
                            
                            <div class="col-12">
                                <label class="form-label">Titre de l'annonce</label>
                                <input type="text" class="form-control"  placeholder="Je recherche un colocataire." disabled readonly/>
                                
                            </div>
                            
                            <div class="col-sm-6 col-lg-4">
                                <label class="form-label">Montant du loyer</label>
                                <input type="text" class="form-control" placeholder="Ex: 60000" />
                            </div>
                            
                            <div class="col-sm-6 col-lg-4">
                                <label class="form-label">Contact</label>
                                <input type="text" class="form-control" placeholder="Ex: 0171808441" />
                            </div>
                            
                            <div class="col-sm-6 col-lg-4">
                                <label class="form-label">Type de service</label>
                                <select class="form-select js-choice" data-search-enabled="true">
                                <option value="PV">Location</option>
                                <option value="PB">Vente</option>
                                </select>
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <label class="form-label">Commune</label>
                                <select class="form-select js-choice" data-search-enabled="true">
                                <option value="PV">Abobo</option>
                                <option value="PB">Yopougon</option>
                                </select>
                            </div>
                            
                            <div class="col-sm-6">
                                <label class="form-label">Lieu</label>
                                <input type="text" class="form-control" placeholder="Ex: Abobo marché près de la mairie" />
                            </div>

                            <div class="col-sm-4 col-lg-4">
                                <label class="form-label">Catégorie</label>
                                <select class="form-select js-choice" data-search-enabled="true">
                                <option value="PV">Domicile</option>
                                <option value="PB">Résidence Meublée</option>
                                </select>
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <label class="form-label">Nombre de pièce</label>
                                <select class="form-select js-choice" data-search-enabled="true">
                                <option value="PV">01 pièce</option>
                                <option value="PB">02 pièces</option>
                                <option value="PB">03 pièces</option>
                                </select>
                            </div>
                            
                            <div class="col-sm-6 col-lg-4">
                                <label class="form-label">Caution (Facultatif)</label>
                                <input type="number" class="form-control" value="0" />
                            </div>
                            
                            <div class="col-12">
                                <label class="form-label">Description</label>
                                <textarea class="form-control" rows="3" ></textarea>
                               
                            </div>
                            
                            
                            <div class="col-12 text-end">
                                <button type="submit" class="btn btn-primary mb-0">Create a page</button>
                            </div>
                            </form>
                        </div>
                        
                    </div>
        </>
    )
}

export default PostColoc;