import axios from "axios";
import { useEffect, useState } from "react";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Validate from "../../../Validation";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import BaseUrl from "../../../BaseUrl";

const baseUrl = BaseUrl();

function UpdateComponentPost({poste,agence}){
    function generateUniqueID() {
        const timestamp = new Date().getTime().toString(); 
        const randomNum = Math.random().toString(36).substr(2, 9); 
      
        return timestamp + randomNum; 
      }

      const navigate = useNavigate();
      const [errors,setErrorM] = useState({});
      const [communes,setCommunes] = useState([]);
      const [pieces,setPieces] = useState([]);
      const [categories,setCategories]= useState([]);
      const [services,setServices] = useState([]);
      const [selectedCommune, setSelectedCommune] = useState(null);
      const [image, setImage] = useState([]);
      const [imageDetails,setImageDetail] = useState([]);

      const [marques,setMarques] = useState([]);
      const [models,setModels] = useState([]);
      const [annees,setAnnes] = useState([]);
      const [etats,setEtatEngins] = useState([]);
      const [boites,setBoites] = useState([]);
      const [carburants,setCarburants] = useState([]);
      const [chassis,setChassis] = useState([]);
      const [couleurs,setCouleurs] = useState([]);
      const [transmissions,setTransmissions] = useState([]);
      const [categoryEngins,setCategoryEngins]= useState([]);

      const [marqueComputers,setMarquesComputers] = useState([]);
      const [marquePhones,setMarquesPhones] = useState([]);
      const [loading,setLoading] = useState(false);
      const [valueDescription,setValueDescription] = useState('');
      const [postData,setPostData] = useState({
        'libelle':'',
        'montant':'',
        'description':'',
        'contact':agence? agence.user?.tel : '',
        'service':'',

        //immobilier
        'piece':'',
        'category':'',
        'caution':'0',
        'quatier':'',
        'commune':'',

        //colocation
        'adresse_is_coloc':'',

        //engins
        'vitesse':'',
        'cylindre':'',
        'couleur':'',
        'transmission':'',
        'boite':'',
        'model':'',
        'carburant':'',
        'chassis':'',
        'kilometrage':'',
        'etat':'',
        'categorie':'',
        'marque':'',
        'annee':'',
        'poids':'',

        //computers
        'marquec':'',
        'modelc':'',
        'couleurc':'',
        'poidsc':'',
        'ecranc':'',
        'processeurc':'',
        'systemeOsc':'',
        'clavierc':'',
        'usbc':'',
        'disquec':'',
        'memoirec':'',
        'autonomiec':'',
        'typeOsc':'',
        'carteGraphiquec':'',
        'clavierEclairec':'0',
        'etatc':'',
        'is_hdmic':'0',

        //phones
        'marquep':'',
        'modelp':'',
        'couleurp':'',
        'poidsp':'',
        'ecranp':'',
        'processeurp':'',
        'systemeOsp':'',
        'resolutionp':'',
        'appareilPhotop':'',
        'versionp':'',
        'reseaup':'',
        'disquep':'',
        'memoirep':'',
        'connectivitep':'',
        'batteriep':'',
        'is_empruntep':'0',
        'etatp':''
    });

 

    useEffect(()=>{
        try {
            if(poste && poste.id){
            axios.get(baseUrl.base+'/poste/'+poste.id+'/').then((resp)=>{
                setValueDescription(resp.data?.description)
                setPostData({
                    'libelle':resp.data?.libelle,
                    'montant':resp.data?.montant,
                    'description':resp.data?.description,
                    'contact':resp.data?.contact,
                    'service':resp.data.service?.id,

                    //immobilier
                    'piece':resp.data.immobilier?.piece?.id,
                    'category':`${resp.data.immobilier?.category?.id}`,
                    'caution':resp.data.immobilier?.caution,
                    'quatier':resp.data.immobilier?.quatier,
                    'commune':resp.data.immobilier?.commune?.id,

                    //colocation
                    'adresse_is_coloc':resp.data.adresse_is_coloc,

                    //engins
                    'vitesse':resp.data.engins?.vitesse,
                    'cylindre':resp.data.engins?.cylindre,
                    'couleur':resp.data.engins?.couleur?.id,
                    'transmission':resp.data.engins?.transmission?.id,
                    'boite':resp.data.engins?.boite?.id,
                    'model':resp.data.engins?.model,
                    'carburant':resp.data.engins?.carburant?.id,
                    'chassis':resp.data.engins?.chassis?.id,
                    'kilometrage':resp.data.engins?.kilometrage,
                    'etat':resp.data.engins?.etat?.id,
                    'categorie':resp.data.engins?.category?.id,
                    'marque':resp.data.engins?.marque?.id,
                    'annee':resp.data.engins?.annee?.id,
                    'poids':resp.data.engins?.poids,

                    //computers
                    'marquec':resp.data.computer?.marque?.id,
                    'modelc':resp.data.computer?.model,
                    'couleurc':resp.data.computer?.couleur?.id,
                    'poidsc':resp.data.computer?.poids,
                    'ecranc':resp.data.computer?.ecran,
                    'processeurc':resp.data.computer?.processeur,
                    'systemeOsc':resp.data.computer?.systemeOs,
                    'clavierc':resp.data.computer?.clavier,
                    'usbc':resp.data.computer?.usb,
                    'disquec':resp.data.computer?.disque,
                    'memoirec':resp.data.computer?.memoire,
                    'autonomiec':resp.data.computer?.autonomie,
                    'typeOsc':resp.data.computer?.typeOs,
                    'carteGraphiquec':resp.data.computer?.carteGraphique,
                    'clavierEclairec':resp.data.computer?.clavierEclaire,
                    'etatc':resp.data.computer?.etat,
                    'is_hdmic':resp.data.computer?.is_hdmi,

                    //phones
                    'marquep':resp.data.phone?.marque?.id,
                    'modelp':resp.data.phone?.model,
                    'couleurp':resp.data.phone?.couleur?.id,
                    'poidsp':resp.data.phone?.poids,
                    'ecranp':resp.data.phone?.ecran,
                    'processeurp':resp.data.phone?.processeur,
                    'systemeOsp':resp.data.phone?.systemeOs,
                    'resolutionp':resp.data.phone?.resolution,
                    'appareilPhotop':resp.data.phone?.appareilPhoto,
                    'versionp':resp.data.phone?.version,
                    'reseaup':resp.data.phone?.reseau,
                    'disquep':resp.data.phone?.disque,
                    'memoirep':resp.data.phone?.memoire,
                    'connectivitep':resp.data.phone?.connectivite,
                    'batteriep':resp.data.phone?.batterie,
                    'is_empruntep':resp.data.phone?.is_emprunte,
                    'etatp':resp.data.phone?.etat


                });

                setSelectedCommune(resp.data.immobilier?.commune?.id);
            })

                      try {
            if(poste && poste.id){
            axios.get(baseUrl.base+'/images-poste/?poste='+poste.id).then((resp)=>{
              setImageDetail(resp.data);
            })
        }
        } catch (error) {
            console.log(error);
        }
        
        try {
           if(agence && agence.user && agence.user.countrie && agence.user.countrie){
            axios.get(baseUrl.base+'/communes/?countrie='+agence.user?.countrie+'').then((resp)=>{
                setCommunes(resp.data);
            })
           }
        
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/pieces/').then((resp)=>{
                setPieces(resp.data);
            })
        } catch (error) {
            console.log(error)
        }

        try {
            axios.get(baseUrl.base+'/category/?tag=01').then((resp)=>{
                setCategories(resp.data);
            })
        } catch (error) {
            console.log(error)
        }

        try {
            axios.get(baseUrl.base+'/services/').then((resp)=>{
                setServices(resp.data);
            })
        } catch (error) {
            console.log(error)
        }

        try {
            axios.get(baseUrl.base+'/marques/?category=01').then((resp)=>{
                setMarques(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/marques/?category=03').then((resp)=>{
                setMarquesComputers(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/marques/?category=04').then((resp)=>{
                setMarquesPhones(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/annee-engins/').then((resp)=>{
                setAnnes(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/etat-engins/').then((resp)=>{
                setEtatEngins(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/couleurs/').then((resp)=>{
                setCouleurs(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/transmissions/').then((resp)=>{
                setTransmissions(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/type-de-boites/').then((resp)=>{
                setBoites(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/carburants/').then((resp)=>{
                setCarburants(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/chassis/').then((resp)=>{
                setChassis(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/category/?tag=02').then((resp)=>{
                setCategoryEngins(resp.data);
            })
        } catch (error) {
            console.log(error)
        }

       
        }
          } catch (error) {
            console.log(error);
          }


       
    },[agence,poste]);
  
 
    const onSelectFile = (event) => {
        const _fromData = new FormData();
        _fromData.append('poste',poste.id);
        
        Array.from(event.target.files).forEach((item, index) => {
            const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
            const originalExtension = item.name.split('.').pop();
            const newFileName = `${currentTimeInSeconds}_${index}_${postData.montant}_${generateUniqueID()}.${originalExtension}`;
            const photo = new File([item], newFileName, { type: item.type });
            _fromData.append('images', photo);
           
        });

        try {
            axios.post(baseUrl.base+'/poste-image-after-update/',_fromData,{
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }).then((resp)=>{
                
                axios.get(baseUrl.base+'/images-poste/?poste='+poste.id).then((response)=>{
                    setImageDetail(response.data);
                });

                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Image enregister avec succes !',
                    showConfirmButton: false,
                    timer: 3000,
                    toast:true,
                    position:'top-right',
                    timerProgressBar:true
                  });
            
            });
        } catch (error) {
            console.log(error);
        }
        
    };

    const deleteHandler=(image)=> {
        
        try {
            axios.delete(baseUrl.base+'/image-delete/'+image).then((resp)=>{
                axios.get(baseUrl.base+'/images-poste/?poste='+poste.id).then((response)=>{
                    setImageDetail(response.data);
                });

                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Image supprimée avec succes !',
                    showConfirmButton: false,
                    timer: 3000,
                    toast:true,
                    position:'top-right',
                    timerProgressBar:true
                  });
            })
        } catch (error) {
            console.log(error);
        }
      }

      const handleChange=(event)=>{
        setPostData({
            ...postData,
            [event.target.name]:event.target.value
        });

      }



      const submitForm=()=>{
        setErrorM(Validate(postData));
        const _fromData = new FormData();
        _fromData.append('libelle',postData.libelle);
        _fromData.append('montant',postData.montant.toString().replace(/\s/g, ''));
        _fromData.append('description',valueDescription);
        _fromData.append('contact',postData.contact);

        // if(poste.is_coloc == true){
        //     if(postData.libelle !== '' && postData.montant !=='' && postData.adresse_is_coloc !==''&& postData.description && postData.contact !=""){
        //         _fromData.append('adresse_is_coloc',postData.adresse_is_coloc);
        //         setLoading(true);
        //         try {
        //             axios.post(baseUrl.base+'/poste-update/'+poste.id+'/',_fromData,{
        //                 headers: {
        //                     'content-type': 'multipart/form-data'
        //                 }
        //             }).then((resp)=>{
        //                 if(resp.data.bool == true){
        //                    window.location.reload();
        //                 }else{
        //                     Swal.fire({
        //                         position: 'top-end',
        //                         icon: 'success',
        //                         title: 'Modification effectuée avec succes !',
        //                         showConfirmButton: false,
        //                         timer: 2000,
        //                         toast:true,
        //                         position:'top-right',
        //                         timerProgressBar:true
        //                       });
        //                   setLoading(false);
                            
        //                 }
                        
        //             })
        //         } catch (error) {
        //             console.log(error);
        //         } 
        //     }
          
        // }
        if(poste.immobilier !== null){

            if(["1","2","5","6","9"].includes(postData.category) && postData.piece === undefined)
            {
                return;
            }

            if(postData.libelle !== '' && postData.montant !=='' && postData.service !=='' && postData.category !=='' && postData.quatier !==''  && postData.caution !==''  && postData.description){
                
                _fromData.append('commune',postData.commune);
                _fromData.append('quatier',postData.quatier);
                _fromData.append('category',postData.category);
                _fromData.append('service',postData.service);
                _fromData.append('caution',postData.caution);
                _fromData.append('piece',postData.piece !== undefined ? postData.piece : "");
                console.log(_fromData);
                setLoading(true);
                 try {
                    axios.post(baseUrl.base+'/poste-update/'+poste.id+'/',_fromData,{
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    }).then((resp)=>{
                        if(resp.data.bool == true){
                           window.location.reload();
                        }else{
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'Modification effectuée avec succes !',
                                showConfirmButton: false,
                                timer: 2000,
                                toast:true,
                                position:'top-right',
                                timerProgressBar:true
                              });
                            setLoading(false);
                            
                        }
                        
                    })
                } catch (error) {
                    console.log(error);
                } 
            }
        }

        // if(poste.engins !== null){
        //     _fromData.append('model',postData.model);
        //     _fromData.append('marque',postData.marque);
        //     _fromData.append('annee',postData.annee);
        //     _fromData.append('etat',postData.etat);
        //     _fromData.append('category',postData.categorie);
        //     _fromData.append('service',postData.service);
        //     _fromData.append('vitesse',postData.vitesse);
        //     _fromData.append('cylindre',postData.cylindre);
        //     _fromData.append('couleur',postData.couleur);
        //     _fromData.append('transmission',postData.transmission);
        //     _fromData.append('boite',postData.boite);
        //     _fromData.append('carburant',postData.carburant);
        //     _fromData.append('chassis',postData.chassis);
        //     _fromData.append('kilometrage',postData.kilometrage);
        //     _fromData.append('poids',postData.poids);

        //     setLoading(true);
        //      try {
        //         axios.post(baseUrl.base+'/poste-update/'+poste.id+'/',_fromData,{
        //             headers: {
        //                 'content-type': 'multipart/form-data'
        //             }
        //         }).then((resp)=>{
        //             if(resp.data.bool == true){
        //                window.location.reload();
        //             }else{
        //                 Swal.fire({
        //                     position: 'top-end',
        //                     icon: 'success',
        //                     title: 'Modification effectuée avec succes !',
        //                     showConfirmButton: false,
        //                     timer: 2000,
        //                     toast:true,
        //                     position:'top-right',
        //                     timerProgressBar:true
        //                   });
        //                 setLoading(false);
                        
        //             }
                    
        //         })
        //     } catch (error) {
        //         console.log(error);
        //     }  
        // }

        //  if(poste.phone !== null){
        //     _fromData.append('model',postData.modelp);
        //     _fromData.append('marque',postData.marquep);
        //     _fromData.append('couleur',postData.couleurp);
        //     _fromData.append('poids',postData.poidsp);
        //     _fromData.append('ecran',postData.ecranp);
        //     _fromData.append('processeur',postData.processeurp);
        //     _fromData.append('systemeOs',postData.systemeOsp);
        //     _fromData.append('version',postData.versionp);
        //     _fromData.append('reseau',postData.reseaup);
        //     _fromData.append('is_emprunte',postData.is_empruntep);
        //     _fromData.append('etat',postData.etatp);
        //     _fromData.append('resolution',postData.resolutionp);
        //     _fromData.append('batterie',postData.batteriep);
        //     _fromData.append('appareilPhoto',postData.appareilPhotop);

        //     _fromData.append('disque',postData.disquep);
        //     _fromData.append('memoire',postData.memoirep);

        //     setLoading(true);
        //         try {
        //             axios.post(baseUrl.base+'/poste-update/'+poste.id+'/',_fromData,{
        //                 headers: {
        //                     'content-type': 'multipart/form-data'
        //                 }
        //             }).then((resp)=>{
        //                 if(resp.data.bool == true){
        //                    window.location.reload();
        //                 }else{
        //                     Swal.fire({
        //                         position: 'top-end',
        //                         icon: 'success',
        //                         title: 'Modification effectuée avec succes !',
        //                         showConfirmButton: false,
        //                         timer: 2000,
        //                         toast:true,
        //                         position:'top-right',
        //                         timerProgressBar:true
        //                       });
        //                     setLoading(false);
                            
        //                 }
                        
        //             })
        //         } catch (error) {
        //             console.log(error);
        //         }  
            
        // } 

        // if(poste.computer !==null){
        //     _fromData.append('model',postData.modelc);
        //     _fromData.append('marque',postData.marquec);
        //     _fromData.append('couleur',postData.couleurc);
        //     _fromData.append('poids',postData.poidsc);
        //     _fromData.append('ecran',postData.ecranc);
        //     _fromData.append('processeur',postData.processeurc);
        //     _fromData.append('systemeOs',postData.systemeOsc);
        //     _fromData.append('disque',postData.disquec);
        //     _fromData.append('memoire',postData.memoirec);
        //     _fromData.append('autonomie',postData.autonomiec);
        //     _fromData.append('typeOs',postData.typeOsc);
        //     _fromData.append('carteGraphique',postData.carteGraphiquec);
        //     _fromData.append('etat',postData.etatc);
        //     _fromData.append('is_hdmi',postData.is_hdmic);
        //     _fromData.append('clavierEclaire',postData.clavierEclairec);
        //     _fromData.append('clavier',postData.clavierc);
        //     _fromData.append('usb',postData.usbc);

        //     setLoading(true);
        //       try {
        //             axios.post(baseUrl.base+'/poste-update/'+poste.id+'/',_fromData,{
        //                 headers: {
        //                     'content-type': 'multipart/form-data'
        //                 }
        //             }).then((resp)=>{
        //                 if(resp.data.bool == true){
        //                    window.location.reload();
        //                 }else{
        //                     Swal.fire({
        //                         position: 'top-end',
        //                         icon: 'success',
        //                         title: 'Modification effectuée avec succes !',
        //                         showConfirmButton: false,
        //                         timer: 2000,
        //                         toast:true,
        //                         position:'top-right',
        //                         timerProgressBar:true
        //                       });
        //                    setLoading(false);
                            
        //                 }
                        
        //             })
        //         } catch (error) {
        //             console.log(error);
        //         } 
        // }



      
        
       

    }



    const modules = {
        toolbar: [
          [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
          [{size: []}],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, 
           {'indent': '-1'}, {'indent': '+1'}],
          ['link'],
          ['clean']
        ],
      };
      
      const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link'
      ];

 
    
    return (
        <>
        <div class="card">
                    
                    <div class="card-header border-0 pb-0">
                        <h3 class="h4 card-title mb-0">{poste.libelle}</h3>
                    </div>
                    
                    <div class="card-body">
                        {poste.immobilier && 
                             <div class="row g-3">
                        
                             <div class="col-12">
                                 <label class="form-label">Titre de l'annonce <span className="text-danger">*</span></label>
                                 <input type="text" class="form-control" placeholder="Ex: Studio en location Abobo Marché" name="libelle" onChange={handleChange} value={postData.libelle} />
                                 {errors.libelle && <span className="text-danger">{errors.libelle}</span> }
                             </div>
                             
                             <div class="col-sm-6 col-lg-4">
                                 <label class="form-label">Montant <span className="text-danger">*</span></label>
                                 <input type="number" class="form-control" placeholder="Ex: 60000" name="montant" onChange={handleChange} value={postData.montant} />
                                 {errors.montant && <span className="text-danger">{errors.montant}</span> }
                             </div>
                             
                             <div class="col-sm-6 col-lg-4">
                                 <label class="form-label">Contact </label>
                                 <input type="text" class="form-control" placeholder="Ex: 0171808441" name="contact" onChange={handleChange} value={postData.contact} />
                             </div>
                             
                             <div class="col-sm-6 col-lg-4">
                                 <label class="form-label">Type de service <span className="text-danger">*</span></label>
                                 <select class="form-select js-choice" name="service" onChange={handleChange} value={postData.service}>
                                     <option value="" selected disabled>Choisir un service ...</option>
                                     {services?.map((service,index)=>
                                         <option value={service.id}>{service.libelle}</option>
                                     )}
                                 
                                 </select>
                                 {errors.service && <span className="text-danger">{errors.service}</span> }
                             </div>
 
                             <div class="col-sm-6 col-lg-6">
                                 <label class="form-label">Commune <span className="text-danger">*</span></label>
                                 <select class="form-select js-choice" name="commune" onChange={handleChange} value={postData.commune}>
                                     <option value="" selected disabled>Choisir un service ...</option>
                                     {communes?.map((commune,index)=>
                                         <option value={commune.id} key={index}>{commune.libelle}</option>
                                     )}
                                 
                                 </select>
                                 {selectedCommune ==null && <span className="text-danger">ce champs est obligatoire</span> }
                             </div>
                             
                             <div class="col-sm-6">
                                 <label class="form-label">Lieu <span className="text-danger">*</span></label>
                                 <input type="text" class="form-control" placeholder="Ex: Abobo marché près de la mairie" name="quatier" onChange={handleChange} value={postData.quatier} />
                                 {errors.quatier && <span className="text-danger">{errors.quatier}</span> }
                             </div>
 
                             <div class="col-sm-4 col-lg-4">
                                 <label class="form-label">Catégorie <span className="text-danger">*</span></label>
                                 <select class="form-select js-choice" name="category" onChange={handleChange} value={postData.category}>
                                 <option value="" selected disabled>Choisir ...</option>
                                     {categories?.map((categorie,index)=>
                                         <option value={categorie.id}>{categorie.libelle}</option>
                                     )}
                                 </select>
                                 {errors.category && <span className="text-danger">{errors.category}</span> }
                             </div>
                            {["1","2","5","6","9"].includes(postData.category) &&
                                <>
                                    <div class="col-sm-4 col-lg-4">
                                        <label class="form-label">Nombre de pièce <span className="text-danger">*</span></label>
                                        <select class="form-select js-choice" name="piece" onChange={handleChange}  value={postData.piece}>
                                            <option value="" selected disabled>Choisir ...</option>
                                            {pieces?.map((piece,index)=>
                                                <option  key={index} value={piece.id}>{piece.libelle}</option>
                                            )}
                                        </select>
                                        {errors.piece && <span className="text-danger">{errors.piece}</span> }
                                    </div>
                                    
                                    <div class="col-lg-4">
                                        <label class="form-label">Caution <span className="text-danger">*</span></label> 
                                        <input type="number" class="form-control" value={postData.caution} name="caution" onChange={handleChange} />
                                        {/* {errors.caution && <span className="text-danger">{errors.caution}</span> } */}
                                    </div>
                                </>
                            }
                             
                             
                             <div class="col-12">
                                 <label class="form-label">Decrire la maison <span className="text-danger">*</span></label>
                                 <ReactQuill theme="snow"  value={valueDescription} onChange={setValueDescription}  modules={modules} formats={formats}/>
                                 {errors.description && <span className="text-danger">{errors.description}</span> }
                             </div>
 
                             <div class="col-12 text-end">
                                {!loading && <button  class="btn btn-primary mb-0" onClick={submitForm}>Modifier</button>} 
                                {loading && <button  class="btn btn-primary mb-0" disabled>Modifier</button>}
                             </div>
                             
                             
                             <hr/>
 
                             
                             <div class="col-12 ">
                                 <h5 class="card-title mb-0 col-6">Images Associées ({imageDetails? imageDetails.length : 0}) </h5>{imageDetails?.length > 6 ?<span className="text-danger col-6">Vous pouvez ajouter que 6 images</span> : ''}
                             </div>
                                 <div class="container">
                                
                                 <div class="card mt-3">
                                     <div class="card-body">
 
                                         <div class="row mb-3">
                                             <div class="col-sm-10">
                                             <div class="tab-pane fade show active" id="tab-1">
                                                 <div class="row g-3">
 
                                                 
                                                     <div class="col-6 col-lg-3">
                                                         <div class=" h-100 pb-3 rounded text-center d-flex align-items-center justify-content-center position-relative">
                                                             <label class="btn stretched-link label-img" >
                                                             <input className="input-none"
                                                                 type="file"
                                                                 onChange={onSelectFile}
                                                                 multiple
                                                                 accept="image/png , image/jpeg, image/webp"
                                                                 />
                                                                 <i class="fa-solid fa-camera-retro fs-1"></i>
                                                                 <h6>Ajouter Photo</h6>
                                                             </label>
                                                         </div>
                                                     </div>
                                                     
                         
                                                 {imageDetails &&
                                                     imageDetails.map((img, index) => {
                                                         return (
                                                      <div class="col-6 col-lg-3 position-relative" key={index}>
                                                         <div class="position-absolute bottom-0 end-0">
                                                         
                                                             <div class="dropdown mb-2 me-3">
                                                                 <button class="btn icon-sm bg-danger text-white rounded-circle" id="photoActionEdit3" onClick={() => deleteHandler(img.id)}>
                                                                     <i class="bi bi-trash-fill"></i>
                                                                 </button>
                                                             </div>
                                                         </div>
                                                        
                                                             <img class="rounded img-fluid" src={img.libelle}  alt="" />
                                                         
                                                     </div> 
 
                                                     );
                                                      })}
                                                     
                                                 </div>
                                             </div>
                                             </div>
                                         </div>
 
                                     </div>
                                 </div>
 
                                 </div>
                             
                            
                             </div>
                        }

                        {poste.is_coloc && 
                              <div class="row g-3">
                        
                              <div class="col-12">
                                  <label class="form-label">Titre de l'annonce</label>
                                  <input type="text" class="form-control"  placeholder="Je recherche un colocataire." name="libelle" onChange={handleChange} value={postData.libelle}  disabled readonly/>
                                  {errors.libelle && <span className="text-danger">{errors.libelle}</span> }
                                  
                              </div>
                              
                              <div class="col-sm-6 col-lg-6">
                                  <label class="form-label">Montant du loyer</label>
                                  <input type="text" class="form-control" placeholder="Ex: 60000" name="montant" onChange={handleChange} value={postData.montant} />
                                  {errors.montant && <span className="text-danger">{errors.montant}</span> }
                              </div>
                              
                              <div class="col-sm-6 col-lg-6">
                                  <label class="form-label">Contact</label>
                                  <input type="text" class="form-control" placeholder="Ex: 0171808441" name="contact" onChange={handleChange} value={postData.contact} />
                                  {errors.contact && <span className="text-danger">{errors.contact}</span> }
                              </div>
                              
                              <div class="col-sm-12">
                                  <label class="form-label">Préciser la commune, le quatier...</label>
                                  <input type="text" class="form-control" placeholder="Ex: Abobo marché près de la mairie" name="adresse_is_coloc" onChange={handleChange} value={postData.adresse_is_coloc} />
                                  {errors.adresse_is_coloc && <span className="text-danger">{errors.adresse_is_coloc}</span> }
                              </div>
  
                              <div class="col-12">
                                  <label class="form-label">Description</label>
                                  <textarea class="form-control" rows="3" name="description" onChange={handleChange} value={postData.description}></textarea>
                                  {errors.description && <span className="text-danger">{errors.description}</span> }
                              </div>
                              
                              
                              <div class="col-12 text-end">
                                {!loading && <button  class="btn btn-primary mb-0" onClick={submitForm}>Modifier</button>} 
                                {loading && <button  class="btn btn-primary mb-0" disabled>Modifier</button>}
                             </div>
                              </div>
                        }

                        {poste.engins && 
                             <div class="row g-3">
                        
                             <div class="col-12">
                                 <label class="form-label">Titre de l'annonce <span className="text-danger">*</span></label>
                                 <input type="text" class="form-control" placeholder="Ex: Studio en location Abobo Marché" name="libelle" onChange={handleChange} value={postData.libelle} />
                                 {errors.libelle && <span className="text-danger">{errors.libelle}</span> }
                             </div>
                             
                             <div class="col-sm-6 col-lg-6">
                            <label class="form-label">Montant</label>
                            <input type="text" class="form-control" placeholder="Ex: 60000" name="montant" onChange={handleChange} value={postData.montant} />
                                {errors.montant && <span className="text-danger">{errors.montant}</span>}
                            </div>
                            
                            <div class="col-sm-6 col-lg-6">
                                <label class="form-label">Contact</label>
                                <input type="text" class="form-control" placeholder="Ex: 0171808441" name="contact" onChange={handleChange} value={postData.contact}/>
                                {errors.contact && <span className="text-danger">{errors.contact}</span>}
                            </div>
                             
                            <div class="col-sm-6 col-lg-4">
                            <label class="form-label">Type de service</label>
                            <select class="form-select js-choice" name="service" onChange={handleChange} value={postData.service}>
                                <option value="" selected disabled>Choisir un service ...</option>
                                {services?.map((service,index)=>
                                    <option value={service.id}>{service.libelle}</option>
                                )}
                            
                            </select>
                            {errors.service && <span className="text-danger">{errors.service}</span> }
                        </div>

                        <div class="col-sm-6 col-lg-4">
                            <label class="form-label">Catégorie</label>
                            <select class="form-select js-choice" name="categorie" onChange={handleChange} value={postData.categorie}>
                            <option value="" selected disabled>Choisir ...</option>
                                {categoryEngins?.map((categorie,index)=>
                                    <option value={categorie.id}>{categorie.libelle}</option>
                                )}
                            </select>
                            {errors.category && <span className="text-danger">{errors.category}</span> }
                        </div>

                        <div class="col-sm-6 col-lg-4">
                            <label class="form-label">Etat </label>
                            <select class="form-select js-choice" name="etat" onChange={handleChange} value={postData.etat}>
                            <option value="" selected disabled>Choisir ...</option>
                                {etats?.map((etat,index)=>
                                    <option value={etat.id}>{etat.libelle}</option>
                                )}
                            </select>
                            {errors.etat && <span className="text-danger">{errors.etat}</span> }
                        </div>
                             
                        <div class="col-sm-6 col-lg-4">
                            <label class="form-label">Marques</label>
                            <select class="form-select js-choice" name="marque" onChange={handleChange} value={postData.marque}>
                                <option value="" selected disabled>Choisir un service ...</option>
                                {marques?.map((marque,index)=>
                                    <option value={marque.id}>{marque.libelle}</option>
                                )}
                            
                            </select>
                            {errors.marque && <span className="text-danger">{errors.marque}</span> }
                        </div>

                        <div class="col-sm-6 col-lg-4">
                            <label class="form-label">Models</label>
                            <input type="text" class="form-control"  name="model" onChange={handleChange} value={postData.model}/>
                            {errors.model && <span className="text-danger">{errors.model}</span>}
                        </div>

                        <div class="col-sm-6 col-lg-4">
                            <label class="form-label">Année </label>
                            <select class="form-select js-choice" name="annee" onChange={handleChange} value={postData.annee}>
                            <option value="" selected disabled>Choisir ...</option>
                                {annees?.map((annee,index)=>
                                    <option value={annee.id}>{annee.libelle}</option>
                                )}
                            </select>
                            {errors.annee && <span className="text-danger">{errors.annee}</span> }
                        </div>
                             
                        <div class="col-sm-6 col-lg-3">
                            <label class="form-label">Couleur </label>
                            <select class="form-select js-choice" name="couleur" onChange={handleChange} value={postData.couleur}>
                            <option value="" selected disabled>Choisir ...</option>
                                {couleurs?.map((couleur,index)=>
                                    <option value={couleur.id}>{couleur.libelle}</option>
                                )}
                            </select>
                            {errors.couleur && <span className="text-danger">{errors.couleur}</span> }
                          
                        </div>

                        <div class="col-sm-6 col-lg-3">
                            <label class="form-label">Boîtes </label>
                            <select class="form-select js-choice" name="boite" onChange={handleChange} value={postData.boite}>
                            <option value="" selected disabled>Choisir ...</option>
                                {boites?.map((boite,index)=>
                                    <option value={boite.id}>{boite.libelle}</option>
                                )}
                            </select>
                            {errors.boite && <span className="text-danger">{errors.boite}</span> }
                          
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <label class="form-label">Transmission </label>
                            <select class="form-select js-choice" name="transmission" onChange={handleChange} value={postData.transmission}>
                            <option value="" selected disabled>Choisir ...</option>
                                {transmissions?.map((transmission,index)=>
                                    <option value={transmission.id}>{transmission.libelle}</option>
                                )}
                            </select>
                            {errors.transmission && <span className="text-danger">{errors.transmission}</span> }
                        </div>

                        <div class="col-sm-6 col-lg-3">
                            <label class="form-label">Chassis </label>
                            <select class="form-select js-choice" name="chassis" onChange={handleChange} value={postData.chassis}>
                            <option value="" selected disabled>Choisir ...</option>
                                {chassis?.map((chassi,index)=>
                                    <option value={chassi.id}>{chassi.libelle}</option>
                                )}
                            </select>
                            {errors.chassis && <span className="text-danger">{errors.chassis}</span> }
                          
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <label class="form-label">Nombre de Vitesses</label>
                            <input type="number" class="form-control" placeholder="" name="vitesse" onChange={handleChange} value={postData.vitesse} />
                            
                        </div>
                        <div class="col-sm-6 col-lg-3">
                            <label class="form-label">Nombre de Cylindres</label>
                            <input type="number" class="form-control" placeholder="" name="cylindre" onChange={handleChange} value={postData.cylindre} />
                            
                        </div>

                        <div class="col-sm-6 col-lg-3">
                            <label class="form-label">kilométrage(km)</label>
                            <input type="number" class="form-control" placeholder="" name="kilometrage" onChange={handleChange} value={postData.kilometrage} />
                            
                        </div>

                        <div class="col-sm-6 col-lg-3">
                            <label class="form-label">Carburant </label>
                            <select class="form-select js-choice" name="carburant" onChange={handleChange} value={postData.carburant}>
                            <option value="" selected disabled>Choisir ...</option>
                                {carburants?.map((carburant,index)=>
                                    <option value={carburant.id}>{carburant.libelle}</option>
                                )}
                            </select>
                            {errors.carburant && <span className="text-danger">{errors.carburant}</span> }
                          
                        </div>

                        <div class="col-sm-6 col-lg-3">
                            <label class="form-label">Poids(kg)</label>
                            <input type="number" class="form-control" placeholder="" name="poids" onChange={handleChange} value={postData.poids} />
                            
                        </div>
                             
                             <div class="col-12">
                                 <label class="form-label">Description <span className="text-danger">*</span></label>
                                 <textarea class="form-control" rows="3" value={postData.description} name="description" onChange={handleChange} ></textarea>
                                 {errors.description && <span className="text-danger">{errors.description}</span> }
                             </div>
 
                             <div class="col-12 text-end">
                                {!loading && <button  class="btn btn-primary mb-0" onClick={submitForm}>Modifier</button>} 
                                {loading && <button  class="btn btn-primary mb-0" disabled>Modifier</button>}
                             </div>
                             
                             
                             <hr/>
 
                             
                             <div class="col-12 ">
                                 <h5 class="card-title mb-0 col-6">Images Associées ({imageDetails? imageDetails.length : 0}) </h5>{imageDetails?.length > 6 ?<span className="text-danger col-6">Vous pouvez ajouter que 6 images</span> : ''}
                             </div>
                                 <div class="container">
                                
                                 <div class="card mt-3">
                                     <div class="card-body">
 
                                         <div class="row mb-3">
                                             <div class="col-sm-10">
                                             <div class="tab-pane fade show active" id="tab-1">
                                                 <div class="row g-3">
 
                                                 
                                                     <div class="col-6 col-lg-3">
                                                         <div class=" h-100 pb-3 rounded text-center d-flex align-items-center justify-content-center position-relative">
                                                             <label class="btn stretched-link label-img" >
                                                             <input className="input-none"
                                                                 type="file"
                                                                 onChange={onSelectFile}
                                                                 multiple
                                                                 accept="image/png , image/jpeg, image/webp"
                                                                 />
                                                                 <i class="fa-solid fa-camera-retro fs-1"></i>
                                                                 <h6>Ajouter Photo</h6>
                                                             </label>
                                                         </div>
                                                     </div>
                                                     
                         
                                                 {imageDetails &&
                                                     imageDetails.map((img, index) => {
                                                         return (
                                                      <div class="col-6 col-lg-3 position-relative" key={index}>
                                                         <div class="position-absolute bottom-0 end-0">
                                                         
                                                             <div class="dropdown mb-2 me-3">
                                                                 <button class="btn icon-sm bg-danger text-white rounded-circle" id="photoActionEdit3" onClick={() => deleteHandler(img.id)}>
                                                                     <i class="bi bi-trash-fill"></i>
                                                                 </button>
                                                             </div>
                                                         </div>
                                                        
                                                             <img class="rounded img-fluid" src={img.libelle}  alt="" />
                                                         
                                                     </div> 
 
                                                     );
                                                      })}
                                                     
                                                 </div>
                                             </div>
                                             </div>
                                         </div>
 
                                     </div>
                                 </div>
 
                                 </div>
                             
                            
                             </div>
                        }

                        {poste.phone &&
                              <div class="row g-3">
                        
                              <div class="col-12">
                                  <label class="form-label">Titre de l'annonce</label>
                                  <input type="text" class="form-control"  name="libelle" onChange={handleChange} value={postData.libelle} />
                                  {errors.libelle && <span className="text-danger">{errors.libelle}</span>}
                              </div>
                              
                              <div class="col-sm-6 col-lg-6">
                                  <label class="form-label">Montant</label>
                                  <input type="text" class="form-control" placeholder="Ex: 60000" name="montant" onChange={handleChange} value={postData.montant} />
                                  {errors.montant && <span className="text-danger">{errors.montant}</span>}
                              </div>
                              
                              <div class="col-sm-6 col-lg-6">
                                  <label class="form-label">Contact</label>
                                  <input type="text" class="form-control" placeholder="Ex: 0171808441" name="contact" onChange={handleChange} value={postData.contact}/>
                                  {errors.contact && <span className="text-danger">{errors.contact}</span>}
                              </div>
                              
                              <div class="col-sm-6 col-lg-4">
                                  <label class="form-label">Etat</label>
                                  <select class="form-select js-choice" name="etatp" onChange={handleChange} value={postData.etatp}>
                                      <option value="" selected disabled>Choisir ...</option>
                                      <option value="Neuf" >Nouveau</option>
                                      <option value="Seconde Main" >Seconde Main</option>
                                     
                                  
                                  </select>
                                  {errors.etat && <span className="text-danger">{errors.etat}</span>}
                              </div>
  
  
                              <div class="col-sm-6 col-lg-4">
                                  <label class="form-label">Marques</label>
                                  <select class="form-select js-choice" name="marquep" onChange={handleChange} value={postData.marquep}>
                                  <option value="" selected disabled>Choisir ...</option>
                                      {marqueComputers.map((marque,index)=>
                                          <option value={marque.id}>{marque.libelle}</option>
                                      )}
                                  </select>
                              </div>
                              <div class="col-sm-6 col-lg-4">
                                  <label class="form-label">Models</label>
                                  <input type="text" class="form-control"  name="modelp" onChange={handleChange} value={postData.modelp}/>
                                  {errors.model && <span className="text-danger">{errors.model}</span>}
                              </div>
  
                              <div class="col-sm-6 col-lg-3">
                                  <label class="form-label">Couleur </label>
                                  <select class="form-select js-choice" name="couleurp" onChange={handleChange} value={postData.couleurp}>
                                  <option value="" selected disabled>Choisir ...</option>
                                      {couleurs?.map((couleur,index)=>
                                          <option value={couleur.id}>{couleur.libelle}</option>
                                      )}
                                  </select>
                                  {errors.couleur && <span className="text-danger">{errors.couleur}</span> }
                                
                              </div>
  
              
                              <div class="col-sm-6 col-lg-3">
                                  <label class="form-label">Mémoire RAM (Go)</label>
                                  <input type="number" class="form-control" placeholder="" name="memoirep" onChange={handleChange}  value={postData.memoirep}/>
                                  {errors.memoire && <span className="text-danger">{errors.memoire}</span>}
                              </div>
                              <div class="col-sm-6 col-lg-3">
                                  <label class="form-label">Capacité du disque (Go)</label>
                                  <input type="number" class="form-control" placeholder="" name="disquep" onChange={handleChange} value={postData.disquep} />
                                  {errors.disque && <span className="text-danger">{errors.disque}</span>}
                              </div>
  
                              <div class="col-sm-6 col-lg-3">
                                  <label class="form-label">Système d'exploitation </label>
                                  <select class="form-select js-choice" name="systemeOsp" onChange={handleChange} value={postData.systemeOsp}>
                                  <option value="" selected disabled>Choisir ...</option>
                                  <option value="Pas de système" >Aucun</option>
                                  <option value="Android" >Android</option>
                                  <option value="iOS" >iOS</option>
                                  <option value="Windows 10 Mobile" >Windows 10 Mobile</option>
                                  <option value="BlackBerry OS" >BlackBerry OS</option>
                                  <option value="KaiOS">KaiOS</option>
                                    
                                  </select>
                                  {errors.systemeOs && <span className="text-danger">{errors.systemeOs}</span>}
                                  
                              </div> 
  
                              
  
                              <div class="col-sm-6 col-lg-3">
                                  <label class="form-label">Version du système</label>
                                  <input type="number" class="form-control" placeholder="" name="versionp" onChange={handleChange}  value={postData.versionp}/>
                                  
                              </div>
  
                          
  
                              <div class="col-sm-6 col-lg-3">
                                  <label class="form-label">Ecran (Pouces)</label>
                                  <input type="number" class="form-control" placeholder="" name="ecranp" onChange={handleChange}  value={postData.ecranp}/>
                                  {errors.ecran && <span className="text-danger">{errors.ecran}</span>}
                              </div>
  
                              <div class="col-sm-6 col-lg-3">
                                  <label class="form-label">Resolution</label>
                                  <input type="text" class="form-control" placeholder="" name="resolutionp" onChange={handleChange} value={postData.resolutionp} />
  
                              </div>
  
                              <div class="col-sm-6 col-lg-3">
                                  <label class="form-label">Camera</label>
                                  <input type="text" class="form-control" placeholder="" name="appareilPhotop" onChange={handleChange} value={postData.appareilPhotop} />
                                  {errors.appareilPhoto && <span className="text-danger">{errors.appareilPhoto}</span>}
                              </div>
  
                              <div class="col-sm-6 col-lg-3">
                                  <label class="form-label">Réseaux</label>
                                  <select class="form-select js-choice" name="reseaup" onChange={handleChange} value={postData.reseaup}>
                                  <option value="" selected disabled>Choisir ...</option>
                                  <option value="2G/3G" >2G/3G</option>
                                  <option value="3G/4G" >3G/4G</option>
                                  <option value="4G/5G" >4G/5G</option>
                                      
                                  </select>
                                  {errors.reseau && <span className="text-danger">{errors.reseau}</span>}
                              </div> 
  
                              <div class="col-sm-6 col-lg-3">
                                  <label class="form-label">Avec emprunte</label>
                                  <select class="form-select js-choice" name="is_empruntep" onChange={handleChange} value={postData.is_empruntep}>
                                  <option value="" selected disabled>Choisir ...</option>
                                  <option value="true" >OUI</option>
                                  <option value="false" >NON</option>
                                      
                                  </select>
                                
                              </div> 
  
                             {/*  <div class="col-sm-6 col-lg-3">
                                  <label class="form-label">HDMI</label>
                                  <select class="form-select js-choice" name="is_hdmi" onChange={handleChange} >
                                  <option value="" selected disabled>Choisir ...</option>
                                  <option value="1" >OUI</option>
                                  <option value="0" >NON</option>
                                      
                                  </select>
                                
                              </div>  */}
                              <div class="col-sm-6 col-lg-3">
                                  <label class="form-label">Poids (kg)</label>
                                  <input type="number" class="form-control" placeholder="" name="poidsp" onChange={handleChange} value={postData.poidsp}/>
                                  
                              </div>
  
                              <div class="col-sm-6 col-lg-3">
                                  <label class="form-label">Processeur </label>
                                  <input type="text" class="form-control" placeholder="" name="processeurp" onChange={handleChange} value={postData.processeurp}/>
                                 {/*  {errors.processeur && <span className="text-danger">{errors.processeur}</span> } */}
                              </div>
  
                              <div class="col-sm-6 col-lg-3">
                                  <label class="form-label">Batterie(mAh)</label>
                                  <input type="number" class="form-control" placeholder="" name="batteriep" onChange={handleChange} value={postData.batteriep}/>
                                 {/*  {errors.processeur && <span className="text-danger">{errors.processeur}</span> } */}
                              </div>
                                   
  
                              
                 
                              
                              <div class="col-12">
                                  <label class="form-label">Description</label>
                                  <textarea class="form-control" rows="3" name="description" onChange={handleChange} value={postData.description}></textarea>
                                  {errors.description && <span className="text-danger">{errors.description}</span> }
                                 
                              </div>
                              
                              <div class="col-12 text-end">
                                {!loading && <button  class="btn btn-primary mb-0" onClick={submitForm}>Modifier</button>} 
                                {loading && <button  class="btn btn-primary mb-0" disabled>Modifier</button>}
                             </div>
                             
  
                              
                              <hr/>
 
                             
                            <div class="col-12 ">
                                <h5 class="card-title mb-0 col-6">Images Associées ({imageDetails? imageDetails.length : 0}) </h5>{imageDetails?.length > 6 ?<span className="text-danger col-6">Vous pouvez ajouter que 6 images</span> : ''}
                            </div>
                                <div class="container">
                                
                                <div class="card mt-3">
                                    <div class="card-body">

                                        <div class="row mb-3">
                                            <div class="col-sm-10">
                                            <div class="tab-pane fade show active" id="tab-1">
                                                <div class="row g-3">

                                                
                                                    <div class="col-6 col-lg-3">
                                                        <div class=" h-100 pb-3 rounded text-center d-flex align-items-center justify-content-center position-relative">
                                                            <label class="btn stretched-link label-img" >
                                                            <input className="input-none"
                                                                type="file"
                                                                onChange={onSelectFile}
                                                                multiple
                                                                accept="image/png , image/jpeg, image/webp"
                                                                />
                                                                <i class="fa-solid fa-camera-retro fs-1"></i>
                                                                <h6>Ajouter Photo</h6>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    
                        
                                                {imageDetails &&
                                                    imageDetails.map((img, index) => {
                                                        return (
                                                    <div class="col-6 col-lg-3 position-relative" key={index}>
                                                        <div class="position-absolute bottom-0 end-0">
                                                        
                                                            <div class="dropdown mb-2 me-3">
                                                                <button class="btn icon-sm bg-danger text-white rounded-circle" id="photoActionEdit3" onClick={() => deleteHandler(img.id)}>
                                                                    <i class="bi bi-trash-fill"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        
                                                            <img class="rounded img-fluid" src={img.libelle}  alt="" />
                                                        
                                                    </div> 

                                                    );
                                                    })}
                                                    
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                </div>
                              </div>
                        }

                        {poste.computer && 
                             <div class="row g-3">
                        
                             <div class="col-12">
                                 <label class="form-label">Titre de l'annonce</label>
                                 <input type="text" class="form-control"  name="libelle" onChange={handleChange} value={postData.libelle} />
                                 {errors.libelle && <span className="text-danger">{errors.libelle}</span>}
                             </div>
                             
                             <div class="col-sm-6 col-lg-6">
                                 <label class="form-label">Montant</label>
                                 <input type="text" class="form-control" placeholder="Ex: 60000" name="montant" onChange={handleChange} value={postData.montant} />
                                 {errors.montant && <span className="text-danger">{errors.montant}</span>}
                             </div>
                             
                             <div class="col-sm-6 col-lg-6">
                                 <label class="form-label">Contact</label>
                                 <input type="text" class="form-control" placeholder="Ex: 0171808441" name="contact" onChange={handleChange} value={postData.contact}/>
                                 {errors.contact && <span className="text-danger">{errors.contact}</span>}
                             </div>
                             
                             <div class="col-sm-6 col-lg-4">
                                 <label class="form-label">Etat</label>
                                 <select class="form-select js-choice" name="etatc" onChange={handleChange} value={postData.etatc}>
                                     <option value="" selected disabled>Choisir ...</option>
                                     <option value="Neuf" >Nouveau</option>
                                     <option value="Neuf avec sac" >Nouveau avec sac</option>
                                     <option value="Quazi-neuf" >Quasi-neuf</option>
                                     <option value="Quazi-neuf avec sac" >Quasi-neuf avec sac</option>
                                     <option value="Seconde Main" >Seconde Main</option>
                                    
                                 
                                 </select>
                                 {errors.etat && <span className="text-danger">{errors.etat}</span>}
                             </div>
 
 
                             <div class="col-sm-6 col-lg-4">
                                 <label class="form-label">Marques</label>
                                 <select class="form-select js-choice" name="marquec" onChange={handleChange} value={postData.marquec}>
                                 <option value="" selected disabled>Choisir ...</option>
                                     {marqueComputers.map((marque,index)=>
                                         <option value={marque.id}>{marque.libelle}</option>
                                     )}
                                 </select>
                             </div>
                             <div class="col-sm-6 col-lg-4">
                                 <label class="form-label">Models</label>
                                 <input type="text" class="form-control"  name="modelc" onChange={handleChange} value={postData.modelc}/>
                                 {errors.model && <span className="text-danger">{errors.model}</span>}
                             </div>
 
                             <div class="col-sm-6 col-lg-3">
                                 <label class="form-label">Couleur </label>
                                 <select class="form-select js-choice" name="couleur" onChange={handleChange} value={postData.couleurc}>
                                 <option value="" selected disabled>Choisir ...</option>
                                     {couleurs?.map((couleur,index)=>
                                         <option value={couleur.id}>{couleur.libelle}</option>
                                     )}
                                 </select>
                                 {errors.couleur && <span className="text-danger">{errors.couleur}</span> }
                               
                             </div>
 
             
                             <div class="col-sm-6 col-lg-3">
                                 <label class="form-label">Mémoire RAM (Go)</label>
                                 <input type="number" class="form-control" placeholder="" name="memoirec" onChange={handleChange}  value={postData.memoirec}/>
                                 {errors.memoire && <span className="text-danger">{errors.memoire}</span>}
                             </div>
                             <div class="col-sm-6 col-lg-3">
                                 <label class="form-label">Capacité du disque (Go)</label>
                                 <input type="number" class="form-control" placeholder="" name="disquec" onChange={handleChange} value={postData.disquec}/>
                                 {errors.disque && <span className="text-danger">{errors.disque}</span>}
                             </div>
 
                             <div class="col-sm-6 col-lg-3">
                                 <label class="form-label">Système d'exploitation </label>
                                 <select class="form-select js-choice" name="systemeOsc" onChange={handleChange} value={postData.systemeOsc}>
                                 <option value="" selected disabled>Choisir ...</option>
                                 <option value="Pas de système" >Aucun</option>
                                 <option value="Windows" >Windows</option>
                                 <option value="macOS" >macOS</option>
                                 <option value="Linux" >Linux</option>
                                 <option value="Chrome OS" >Chrome OS </option>
                                 <option value="IBM z/OS" >IBM z/OS</option>
                                 <option value="Haiku" >Haiku</option>
                                   
                                 </select>
                                 {errors.systemeOs && <span className="text-danger">{errors.systemeOs}</span>}
                                 
                             </div> 
 
                             
 
                             <div class="col-sm-6 col-lg-3">
                                 <label class="form-label">Carte graphique</label>
                                 <input type="text" class="form-control" placeholder="" name="carteGraphiquec" onChange={handleChange}  value={postData.carteGraphiquec}/>
                                 
                             </div>
 
                              <div class="col-sm-6 col-lg-3">
                                 <label class="form-label">Clavier </label>
                                 <select class="form-select js-choice" name="clavierc" onChange={handleChange} value={postData.clavierc}>
                                 <option value="" selected disabled>Choisir ...</option>
                                 <option value="Clavier QWERTY" >Clavier QWERTY</option>
                                 <option value="Clavier AZERTY" >Clavier AZERTY</option>
                                 <option value="Clavier QWERTZ" >Clavier QWERTZ</option>
                                 <option value="Clavier Dvorak" >Clavier Dvorak</option>
                                 <option value="Clavier mécanique" >Clavier mécanique</option>
                                 <option value="Clavier à membrane" >Clavier à membrane</option>
                                     
                                 </select>
                                 {errors.clavier && <span className="text-danger">{errors.clavier}</span>}
                               
                             </div> 
 
                             <div class="col-sm-6 col-lg-3">
                                 <label class="form-label">Ecran (Pouces)</label>
                                 <input type="number" class="form-control" placeholder="" name="ecranc" onChange={handleChange}  value={postData.ecranc}/>
                                 {errors.ecran && <span className="text-danger">{errors.ecran}</span>}
                             </div>
 
                             <div class="col-sm-6 col-lg-3">
                                 <label class="form-label">Nombre port USB</label>
                                 <input type="number" class="form-control" placeholder="" name="usbc" onChange={handleChange}  value={postData.usbc}/>
                                 {errors.usb && <span className="text-danger">{errors.usb}</span>}
                             </div>
 
                             <div class="col-sm-6 col-lg-3">
                                 <label class="form-label">Autonomie (Heure)</label>
                                 <input type="number" class="form-control" placeholder="" name="autonomiec" onChange={handleChange} value={postData.autonomiec}/>
                                 {errors.autonomie && <span className="text-danger">{errors.autonomie}</span>}
                             </div>
 
                             <div class="col-sm-6 col-lg-3">
                                 <label class="form-label">Type de système Os </label>
                                 <select class="form-select js-choice" name="typeOsc" onChange={handleChange} value={postData.typeOsc}>
                                 <option value="" selected disabled>Choisir ...</option>
                                 <option value="64 bits" >64 bits</option>
                                 <option value="32 bits" >32 bits</option>
                                     
                                 </select>
                                 {errors.typeOs && <span className="text-danger">{errors.typeOs}</span>}
                             </div> 
 
                             <div class="col-sm-6 col-lg-3">
                                 <label class="form-label">Clavier Retro-eclairé</label>
                                 <select class="form-select js-choice" name="clavierEclairec" onChange={handleChange} value={postData.clavierEclairec}>
                                 <option value="" selected disabled>Choisir ...</option>
                                 <option value="true" >OUI</option>
                                 <option value="false" >NON</option>
                                     
                                 </select>
                               
                             </div> 
 
                             <div class="col-sm-6 col-lg-3">
                                 <label class="form-label">HDMI</label>
                                 <select class="form-select js-choice" name="is_hdmic" onChange={handleChange} value={postData.is_hdmic}>
                                 <option value="" selected disabled>Choisir ...</option>
                                 <option value="true" >OUI</option>
                                 <option value="false" >NON</option>
                                     
                                 </select>
                               
                             </div> 
                             <div class="col-sm-6 col-lg-3">
                                 <label class="form-label">Poids (kg)</label>
                                 <input type="number" class="form-control" placeholder="" name="poidsc" onChange={handleChange} value={postData.poidsc}/>
                                 
                             </div>
 
                             <div class="col-sm-6 col-lg-6">
                                 <label class="form-label">Processeur </label>
                                 <input type="text" class="form-control" placeholder="" name="processeurc" onChange={handleChange} value={postData.processeurc}/>
                                 {errors.processeur && <span className="text-danger">{errors.processeur}</span> }
                             </div>
                                  
 
                             
                
                             
                             <div class="col-12">
                                 <label class="form-label">Description</label>
                                 <textarea class="form-control" rows="3" name="description" onChange={handleChange} value={postData.description}></textarea>
                                 {errors.description && <span className="text-danger">{errors.description}</span> }
                                
                             </div>
                             
                             
                             <div class="col-12 text-end">
                                {!loading && <button  class="btn btn-primary mb-0" onClick={submitForm}>Modifier</button>} 
                                {loading && <button  class="btn btn-primary mb-0" disabled>Modifier</button>}
                             </div>
                             
  
                              
                              <hr/>
 
                             
                            <div class="col-12 ">
                                <h5 class="card-title mb-0 col-6">Images Associées ({imageDetails? imageDetails.length : 0}) </h5>{imageDetails?.length > 6 ?<span className="text-danger col-6">Vous pouvez ajouter que 6 images</span> : ''}
                            </div>
                                <div class="container">
                                
                                <div class="card mt-3">
                                    <div class="card-body">

                                        <div class="row mb-3">
                                            <div class="col-sm-10">
                                            <div class="tab-pane fade show active" id="tab-1">
                                                <div class="row g-3">

                                                
                                                    <div class="col-6 col-lg-3">
                                                        <div class=" h-100 pb-3 rounded text-center d-flex align-items-center justify-content-center position-relative">
                                                            <label class="btn stretched-link label-img" >
                                                            <input className="input-none"
                                                                type="file"
                                                                onChange={onSelectFile}
                                                                multiple
                                                                accept="image/png , image/jpeg, image/webp"
                                                                />
                                                                <i class="fa-solid fa-camera-retro fs-1"></i>
                                                                <h6>Ajouter Photo</h6>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    
                        
                                                {imageDetails &&
                                                    imageDetails.map((img, index) => {
                                                        return (
                                                    <div class="col-6 col-lg-3 position-relative" key={index}>
                                                        <div class="position-absolute bottom-0 end-0">
                                                        
                                                            <div class="dropdown mb-2 me-3">
                                                                <button class="btn icon-sm bg-danger text-white rounded-circle" id="photoActionEdit3" onClick={() => deleteHandler(img.id)}>
                                                                    <i class="bi bi-trash-fill"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        
                                                            <img class="rounded img-fluid" src={img.libelle}  alt="" />
                                                        
                                                    </div> 

                                                    );
                                                    })}
                                                    
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                </div>
                              
 
                             
                        
                             
               
                             
                             
                             </div>
                        }
                       
                    </div>
                    
                </div>
    </>
    )
}

export default UpdateComponentPost;