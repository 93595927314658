import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import BaseUrl from "../../../BaseUrl";
import Select from "react-select";


const baseUrl = BaseUrl(); 

function MyRequestComponent({user})
{
    const navigate = useNavigate();
    	    //requêtes HALL
		const [queryCategorieComponentName,setQueryCategorieComponentName] = useState('');
		const [loadBegginRq,setloadBegginRq] = useState(false);
		const [categoryRqs,setCategoryRqs] = useState([]);
		const [communeRqs,setCommuneRqs] = useState([]);
		const [pieceRqs,setPieceRqs] = useState([]);
		const [marqueVoitureRqs,setmarqueVoitureRqs] = useState([]);
		const [marqueOrdinateurRqs,setmarqueOrdinateurRqs] = useState([]);
		const [marquePhoneRqs,setmarquePhoneRqs] = useState([]);
		const [boiteRqs,setBoiteRqs] = useState([]);
		const [chassisRqs,setChassisRqs] = useState([]);
		const [etatVoitureRqs,setEtatVoitureRqs] = useState([]);
		const [carburantRqs,setCarburantRqs] = useState([]);
	
		const [selectOptionCat,setSelectOptionCat] = useState(null);
		const [selectOptionCommune,setSelectOptionCommune] = useState(null);
		const [selectOptionPiece,setSelectOptionPiece] = useState(null);
		const [selectOptionMarqueV,setSelectOptionMarqueV] = useState(null);
		const [selectOptionMarquePc,setSelectOptionMarquePc] = useState(null);
		const [selectOptionMarquePhone,setSelectOptionMarquePhone] = useState(null);
		const [selectOptionBoite,setSelectOptionBoite] = useState(null);
		const [selectOptionChassis,setSelectOptionChassis] = useState(null);
		const [selectOptionEtatV,setSelectOptionEtatV] = useState(null);
		const [selectOptionCarburant,setSelectOptionCarburant] = useState(null);
	
		const [loadingQuery,setLoadingQuery] = useState(false);
	
		const [chooseForfait,setChooseForfait] = useState('ekeu');
		const [requeteData,setRequeteData] = useState({
			'objet':'',
			'service':'',
			'bg_min':'',
			'bg_max':'',
			'description':'',
			'is_pro':'1',
			'quantite':'1',
			'zoneVise':'',
			'rom_min':'',
			'rom_max':'',
			'ram_min':'',
			'ram_max':'',
			'etatOthers':''
		})

    useEffect(()=>{
        setloadBegginRq(true);
        try {
            axios.get(baseUrl.base+'/category/?all').then((resp)=>{
                setCategoryRqs(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/communes/?countrie='+user.countrie?.id).then((resp)=>{
                setCommuneRqs(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/pieces/').then((resp)=>{
                setPieceRqs(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/marques/?category=01').then((resp)=>{
                setmarqueVoitureRqs(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/marques/?category=03').then((resp)=>{
                setmarqueOrdinateurRqs(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/marques/?category=04').then((resp)=>{
                setmarquePhoneRqs(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/type-de-boites/').then((resp)=>{
                setBoiteRqs(resp.data);
            })
        } catch (error) {
            
        }

        try {
            axios.get(baseUrl.base+'/chassis/').then((resp)=>{
                setChassisRqs(resp.data);
            })
        } catch (error) {
            console.log(error)
        }

        try {
            axios.get(baseUrl.base+'/etat-engins/').then((resp)=>{
                setEtatVoitureRqs(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/carburants/').then((resp)=>{
                setCarburantRqs(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        setloadBegginRq(false);
    },[user]);




    const optionCats = categoryRqs?.map((option)=>({
        value:`${option.id}`,
        label:`${option.libelle}`
    }));

    const optionCommunes = communeRqs?.map((option)=>({
        value:`${option.id}`,
        label:`${option.libelle}`
    }));

    const optionPieces = pieceRqs?.map((option)=>({
        value:`${option.id}`,
        label:`${option.libelle}`
    }));

    const optionMarqueVoitures = marqueVoitureRqs?.map((option)=>({
        value:`${option.id}`,
        label:`${option.libelle}`
    }));

    const optionPc = marqueOrdinateurRqs?.map((option)=>({
        value:`${option.id}`,
        label:`${option.libelle}`
    }));

    const optionPhone = marquePhoneRqs?.map((option)=>({
        value:`${option.id}`,
        label:`${option.libelle}`
    }));

    const optionBoites = boiteRqs?.map((option)=>({
        value:`${option.id}`,
        label:`${option.libelle}`
    }));

    const optionChassis = chassisRqs?.map((option)=>({
        value:`${option.id}`,
        label:`${option.libelle}`
    }));

    const optionEtatVs = etatVoitureRqs?.map((option)=>({
        value:`${option.id}`,
        label:`${option.libelle}`
    }));

    const optionCarburants = carburantRqs?.map((option)=>({
        value:`${option.id}`,
        label:`${option.libelle}`
    }));

    const handleChangeOptionCats=(selectOption)=>{
        setSelectOptionCat(selectOption.value);
        setErrorCat('');
        
    }

    const handleChangeOptionCommune=(selectOption)=>{
        setSelectOptionCommune(selectOption.value);
        setErrorCommune('');
    }

    const handleChangeOptionPiece=(selectOption)=>{
        setSelectOptionPiece(selectOption.value);
    }

    const handleChangeOptionMarqueV=(selectOption)=>{
        setSelectOptionMarqueV(selectOption.value);
        setErrorMarque('');
    }

    const handleChangeOptionPc=(selectOption)=>{
        setSelectOptionMarquePc(selectOption.value);
        setErrorMarque('');
    }

    const handleChangeOptionPhone=(selectOption)=>{
        setSelectOptionMarquePhone(selectOption.value);
        setErrorMarque('');
    }

    const handleChangeOptionBoite=(selectOption)=>{
        setSelectOptionBoite(selectOption.value);
    }

    const handleChangeOptionChassis=(selectOption)=>{
        setSelectOptionChassis(selectOption.value);
    }

    const handleChangeOptionEtatV=(selectOption)=>{
        setSelectOptionEtatV(selectOption.value);
        setErrorEtatv('');
    }

    const handleChangeOptionCarburant=(selectOption)=>{
        setSelectOptionCarburant(selectOption.value);
    }

    //console.log(selectOptionCat);
    useEffect(()=>{
        if(selectOptionCat){
            sectionChangeCategorieRequete();
        }
    },[selectOptionCat]);

    const sectionChangeCategorieRequete=()=>{
        if(selectOptionCat == 6 || selectOptionCat == 1 || selectOptionCat == 5 || selectOptionCat == 2 || selectOptionCat == 9 || selectOptionCat == 10){
            setQueryCategorieComponentName('immobilier');
        }

        if(selectOptionCat == 3){
            setQueryCategorieComponentName('voiture');
            setSelectOptionMarqueV(null);
        }

        if(selectOptionCat == 7){
            setQueryCategorieComponentName('computer');
            setSelectOptionMarquePc(null);
            setRequeteData({
                'bg_max':requeteData.bg_max,
                'bg_min':requeteData.bg_min,
                'objet':requeteData.objet,
                'description':requeteData.description,
                'is_pro':requeteData.is_pro,
                'quantite':requeteData.quantite,
                'service':requeteData.service,
                'zoneVise':'',
                'rom_min':'',
                'rom_max':'',
                'ram_min':'',
                'ram_max':'',
                'etatOthers':''
            });
        }

        if(selectOptionCat == 8){
            setQueryCategorieComponentName('phone');
            setSelectOptionMarquePhone(null);
            setRequeteData({
                'bg_max':requeteData.bg_max,
                'bg_min':requeteData.bg_min,
                'objet':requeteData.objet,
                'description':requeteData.description,
                'is_pro':requeteData.is_pro,
                'quantite':requeteData.quantite,
                'service':requeteData.service,
                'zoneVise':'',
                'rom_min':'',
                'rom_max':'',
                'ram_min':'',
                'ram_max':'',
                'etatOthers':''
            });
            
        }

        if(selectOptionCat == 4){
            setQueryCategorieComponentName('moto');
        }
    }

    const chooseForfaitBtn=(forfait)=>{
        if(forfait == chooseForfait){
            setChooseForfait('');
        }else{
            setChooseForfait(forfait);
        }
    }

    const handleChangeRequete=(event)=>{
        setRequeteData({
            ...requeteData,
            [event.target.name]:event.target.value
        });
        
    }

    const isNotNullRequete=()=>{
        if(requeteData.objet){setErrorObj('');}
        if(isNaN(requeteData.bg_min)){setErrorBgMin('');}
        if(isNaN(requeteData.bg_max)){setErrorBgMax('');}
        if(requeteData.description){setErrorDesc('');}
        if(requeteData.service){setErrorService('');}
        if(requeteData.etatOthers){setErrorEtatOthers('');}
        if(selectOptionCommune){setErrorCommune('')}
        if(selectOptionPiece){setErrorPiece('')}
        if(requeteData.zoneVise){setErrorZone('')}
    }

    //erreur variable requête
    const [errorCat,setErrorCat] = useState('');
    const [errorObj,setErrorObj] = useState('');
    const [errorBgMin,setErrorBgMin] = useState('');
    const [errorBgMax,setErrorBgMax] = useState('');
    const [errorDesc,setErrorDesc] = useState('');
    const [errorService,setErrorService] = useState('');
    const [errorForfait,setErrorForfait] = useState('');
    const [errorCommune,setErrorCommune] = useState('');
    const [errorEtatv,setErrorEtatv] = useState('');
    const [errorMarque,setErrorMarque] = useState('');
    const [errorEtatOthers,setErrorEtatOthers] = useState('');
    const [errorPiece,setErrorPiece] = useState('');
    const [errorZone,setErrorZone] = useState('');

    useEffect(()=>{
        if(requeteData){isNotNullRequete();}
    },[requeteData])
    

    const submitFormQuery=()=>{
        setErrorCat('');
        setErrorObj('');
        setErrorBgMin('');setErrorBgMax('');setErrorDesc('');setErrorService('');setErrorForfait('');setErrorCommune('');setErrorEtatv('');
        setErrorMarque('');setErrorEtatOthers('');

       


        if(requeteData.objet == ''){setErrorObj('Objet est obligatoire')};
        if(isNaN(requeteData.bg_min)){setErrorBgMin('Veuillez saisir un entier')};
        if(isNaN(requeteData.bg_max)){setErrorBgMax('Veuillez saisir un entier')};

        if(requeteData.bg_min == ""){setErrorBgMin('Le budget minimum est obligatoire')};
        if(requeteData.bg_max == ""){setErrorBgMax('Le budget maximum est obligatoire')};


        if(requeteData.description == ''){setErrorDesc('Decrivez votre besoin')};
        if(requeteData.service == ''){setErrorService('Service est obligatoire')};
        if(selectOptionCat == null){setErrorCat('catégorie obligatoire')};
        if(selectOptionCommune == null){setErrorCommune('Choisissez une commune')}
        if(selectOptionPiece == null){setErrorPiece('Veuillez choisir une pièce')}
        if(requeteData.zoneVise  == ""){setErrorZone('Veuillez choisir la zone visée')}

        



        if(selectOptionCat !== null && requeteData.service && !isNaN(requeteData.bg_max) && !isNaN(requeteData.bg_min) && requeteData.objet && selectOptionCommune !== null  && requeteData.zoneVise !== ""){
            setLoadingQuery(true);
        
            // if(selectOptionCat == 6 || selectOptionCat == 1 || selectOptionCat == 5 || selectOptionCat == 2 || selectOptionCat == 9 && selectOptionCat == 10 /* Immobilier */){
             // }   

                if(["1","2","5","6","9"].includes(selectOptionCat) && selectOptionPiece == null)
                {
                    setLoadingQuery(false);
                    return;
                }
                
                const _fromData = new FormData();

                _fromData.append('commune',selectOptionCommune);
                _fromData.append('piece',selectOptionPiece);
                _fromData.append('zoneVise',requeteData.zoneVise);
                _fromData.append('objet',requeteData.objet);
                _fromData.append('bg_min',requeteData.bg_min.toString().replace(/\./g, ''));
                _fromData.append('bg_max',requeteData.bg_max.toString().replace(/\./g, ''));
                _fromData.append('description',requeteData.description);
                _fromData.append('service',requeteData.service);
                _fromData.append('is_pro',requeteData.is_pro);
                _fromData.append('user',user.id);
                _fromData.append('forfait',chooseForfait);
                _fromData.append('categorie',selectOptionCat);
            
           

            console.log(_fromData);

            // if(selectOptionCat == 3/* Voiture */){
            //     if(selectOptionEtatV == null){setErrorEtatv('Etat est obligatoire')}
            //     if(selectOptionMarqueV == null){setErrorMarque('Choisissez une marque')}

            //     if(selectOptionEtatV !== null && selectOptionMarqueV !== null){

                    
            //         _fromData.append('etatEngins',selectOptionEtatV);
            //         _fromData.append('marque',selectOptionMarqueV);
            //         _fromData.append('quantite',requeteData.quantite);
            //         _fromData.append('boite',selectOptionBoite);
            //         _fromData.append('carburant',selectOptionCarburant);
            //         _fromData.append('chassis',selectOptionChassis);
            //     }
            // }

            // if(selectOptionCat == 7/* PC */){
            //     if(selectOptionMarquePc == null){setErrorMarque('Choisissez une marque')}
            //     if(requeteData.etatOthers == ''){setErrorEtatOthers("L'état est obligatoire")}

            //     if(selectOptionMarquePc !==null && requeteData.etatOthers !==''){

                    
            //         _fromData.append('marque',selectOptionMarquePc);
            //         _fromData.append('etatOthers',requeteData.etatOthers);
            //         _fromData.append('ram_min',requeteData.ram_min);
            //         _fromData.append('ram_max',requeteData.ram_max);
            //         _fromData.append('rom_min',requeteData.rom_min);
            //         _fromData.append('rom_max',requeteData.rom_max);
            //         _fromData.append('quantite',requeteData.quantite);
            //     }
                
            // }

            // if(selectOptionCat == 8/* Phone */){
            //     if(selectOptionMarquePhone == null){setErrorMarque('Choisissez une marque')}
            //     if(requeteData.etatOthers == ''){setErrorEtatOthers("L'état est obligatoire")}

            //     if(selectOptionMarquePhone !==null && requeteData.etatOthers !==''){

                    
            //         _fromData.append('marque',selectOptionMarquePhone);
            //         _fromData.append('etatOthers',requeteData.etatOthers);
            //         _fromData.append('ram_min',requeteData.ram_min);
            //         _fromData.append('ram_max',requeteData.ram_max);
            //         _fromData.append('rom_min',requeteData.rom_min);
            //         _fromData.append('rom_max',requeteData.rom_max);
            //         _fromData.append('quantite',requeteData.quantite);
            //     }
                
            // }

 
             if(chooseForfait){
            
 
                try {
                    axios.post(baseUrl.base+'/save-requetes/',_fromData).then((resp)=>{
                        setLoadingQuery(false);

                        if(resp.data.bool){
                            
                            window.$("#modalCreateRequetes").modal('hide');
                             Swal.fire({
                                title: 'FELICITATION',
                                text: 'Requête envoyée avec success',
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonColor: '#3085d6',
                                confirmButtonText: 'OK'
                              }).then((result) => {
                                if (result.isConfirmed) {
                                    // window.location.reload();
                                }
                              }); 
                        }else{
                            Swal.fire({
                                title: 'Erreur',
                                text: 'Un problème est subvenu !',
                                icon: 'error',
                                showCancelButton: false,
                                confirmButtonColor: 'red',
                                confirmButtonText: 'OK'
                              })
                        }
                    })
                } catch (error) {
                    console.log(error);
                }

            }else{
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: "Vous n'avez pas choisi de forfait",
                    showConfirmButton: false,
                    timer: 3000,
                    toast:true,
                    position:'top-right',
                    timerProgressBar:true
                  });
            } 


           // window.$('#modalChargementRequete').modal('hide');
        }

 
       

    }

    return (
        <>
       
        <div className="modal fade" id="modalCreateRequetes" data-bs-backdrop="static" data-bs-keyboard="true" tabindex="-1" role="dialog" aria-labelledby="modalLabelCreateRequetes" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                    <h5 className="modal-title" id="modalLabelCreateAlbum">Faire une requête</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row g-4">
                            <div className="col-sm-4">
                                <label className="form-label">Catégorie<span className="text-danger">*</span></label>
                                <Select options={optionCats} onChange={handleChangeOptionCats}/>
                                {errorCat && <span className="text-danger">{errorCat}</span>}
                            </div>

                            <div className="col-sm-8">
                                <label className="form-label">Titre<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" placeholder="Ex: 2 chambres salon yopougon maroc" name="objet" onChange={handleChangeRequete}/>
                                {errorObj && <span className="text-danger">{errorObj}</span>}
                            </div>
                            <div className="col-sm-4">
                                <label className="form-label">Service<span className="text-danger">*</span></label>
                                <select className="form-select" name="service" onChange={handleChangeRequete}>
                                    <option value="" disabled selected>Achat ou location ...</option>
                                    <option value="ACHAT">Achat</option>
                                    <option value="LOCATION">Location</option>
                                </select>
                                {errorService && <span className="text-danger">{errorService}</span>}
                            </div>
                            <div className="col-sm-4">
                                <label className="form-label">Budget Minimum (Fcfa)<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name="bg_min" onChange={handleChangeRequete}/>
                                {errorBgMin && <span className="text-danger">{errorBgMin}</span>}
                            </div>
                            <div className="col-sm-4">
                                <label className="form-label">Budget Maximum (Fcfa)<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name="bg_max" onChange={handleChangeRequete}/>
                                {errorBgMax && <span className="text-danger">{errorBgMax}</span>}
                            </div>

                            {/* Immobiliers */}
                            {queryCategorieComponentName == 'immobilier' && 
                            <>
                            <div className="col-sm-4">
                                <label className="form-label">Commune<span className="text-danger">*</span></label>
                                <Select options={optionCommunes} onChange={handleChangeOptionCommune}/>
                                {errorCommune && <span className="text-danger">{errorCommune}</span>}
                            </div>
                                {["1","2","5","6","9"].includes(selectOptionCat) &&
                                    <div className="col-sm-4">
                                        <label className="form-label">Nombre de pièce</label>
                                        <Select options={optionPieces} onChange={handleChangeOptionPiece}/>
                                        {errorPiece && <span className="text-danger">{errorPiece}</span>}
                                    </div>
                                }                            
                            <div className="col-sm-4">
                                <label className="form-label">Zone visée {/*<span className="text-muted">(Facultatif)</span>*/}</label> 
                                <input type="text" className="form-control" name="zoneVise" onChange={handleChangeRequete}/>
                                {errorZone && <span className="text-danger">{errorZone}</span>}
                            </div>
                        </>
                        }

                        {/* Voitures */}
                        {queryCategorieComponentName == 'voiture' &&
                        <>
                            <div className="col-sm-4">
                                <label className="form-label">Etat<span className="text-danger">*</span></label>
                                <Select options={optionEtatVs} onChange={handleChangeOptionEtatV}/>
                                {errorEtatv && <span className="text-danger">{errorEtatv}</span>}
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Marque<span className="text-danger">*</span></label>
                                <Select options={optionMarqueVoitures} onChange={handleChangeOptionMarqueV}/>
                                {errorMarque && <span className="text-danger">{errorMarque}</span>}
                            </div>
                            <div className="col-sm-2">
                                <label className="form-label">Quantité</label>
                                <input type="number" className="form-control" name="quantite" onChange={handleChangeRequete} value={requeteData.quantite}/>
                            </div>
                            <div className="col-sm-4">
                                <label className="form-label">Boite de transmission</label>
                                <Select options={optionBoites} onChange={handleChangeOptionBoite}/>
                            </div>
                            <div className="col-sm-4">
                                <label className="form-label">Carburant</label>
                                <Select options={optionCarburants} onChange={handleChangeOptionCarburant}/>
                            </div>
                            <div className="col-sm-4">
                                <label className="form-label">Chassis</label>
                                <Select options={optionChassis} onChange={handleChangeOptionChassis}/>
                            </div>
                        </>
                        }

                        {/* Motos */}
                        {queryCategorieComponentName == 'moto' &&
                        <>
                        </>
                        }

                        {/* Ordinateurs */}
                        {queryCategorieComponentName == 'computer' &&
                        <>
                            <div className="col-sm-6">
                                <label className="form-label">Marque<span className="text-danger">*</span></label>
                                <Select options={optionPc} onChange={handleChangeOptionPc}/>
                                {errorMarque && <span className="text-danger">{errorMarque}</span>}
                            </div>
                            <div className="col-sm-4">
                                <label className="form-label">Etat<span className="text-danger">*</span></label>
                                <select className="form-select" name="etatOthers" onChange={handleChangeRequete}>
                                    <option value="" disabled selected>...</option>
                                    <option value="Neuf">Nouveau</option>
                                    <option value="Quasi-neuf">Quasi-neuf</option>
                                    <option value="Seconde main">Seconde main</option>
                                </select>
                                {errorEtatOthers && <span className="text-danger">{errorEtatOthers}</span>}
                            </div>

                            <div className="col-sm-2">
                                <label className="form-label">Quantité</label>
                                <input type="number" className="form-control" name="quantite" onChange={handleChangeRequete} value={requeteData.quantite}/>
                            </div>

                            <div className="col-sm-3">
                                <label className="form-label">Stockage Min (Go)</label>
                                <input type="number" className="form-control" name="rom_min" onChange={handleChangeRequete}/>
                            </div>

                            <div className="col-sm-3">
                                <label className="form-label">Stockage max (Go)</label>
                                <input type="number" className="form-control" name="rom_max" onChange={handleChangeRequete}/>
                            </div>

                            <div className="col-sm-3">
                                <label className="form-label">Ram Min (Go)</label>
                                <input type="number" className="form-control" name="ram_min" onChange={handleChangeRequete}/>
                            </div>

                            <div className="col-sm-3">
                                <label className="form-label">Ram max (Go)</label>
                                <input type="number" className="form-control" name="ram_max" onChange={handleChangeRequete}/>
                            </div>
                        </>
                        }

                        {/* Téléphone */}
                        {queryCategorieComponentName == 'phone' &&
                        <>
                            <div className="col-sm-6">
                                <label className="form-label">Marque<span className="text-danger">*</span></label>
                                <Select options={optionPhone} onChange={handleChangeOptionPhone}/>
                                {errorMarque && <span className="text-danger">{errorMarque}</span>}
                            </div>
                            <div className="col-sm-4">
                                <label className="form-label">Etat<span className="text-danger">*</span></label>
                                <select className="form-select" name="etatOthers" onChange={handleChangeRequete}>
                                    <option value="" disabled selected>...</option>
                                    <option value="Neuf">Nouveau</option>
                                    <option value="Quasi-neuf">Quasi-neuf</option>
                                    <option value="Seconde main">Seconde main</option>
                                </select>
                                {errorEtatOthers && <span className="text-danger">{errorEtatOthers}</span>}
                            </div>
                            <div className="col-sm-2">
                                <label className="form-label">Quantité</label>
                                <input type="number" className="form-control" name="quantite" onChange={handleChangeRequete} value={requeteData.quantite}/>
                            </div>

                            <div className="col-sm-3">
                                <label className="form-label">Stockage Min (Go)</label>
                                <input type="number" className="form-control" name="rom_min" onChange={handleChangeRequete}/>
                            </div>

                            <div className="col-sm-3">
                                <label className="form-label">Stockage max (Go)</label>
                                <input type="number" className="form-control" name="rom_max" onChange={handleChangeRequete}/>
                            </div>

                            <div className="col-sm-3">
                                <label className="form-label">Ram Min (Go)</label>
                                <input type="number" className="form-control" name="ram_min" onChange={handleChangeRequete}/>
                            </div>

                            <div className="col-sm-3">
                                <label className="form-label">Ram max (Go)</label>
                                <input type="number" className="form-control" name="ram_max" onChange={handleChangeRequete}/>
                            </div>
                        </>
                        }

                        {/* <div className="col-sm-12">
                        <label className="form-label">Description <span className="text-danger">*</span></label>
                        <textarea className="form-control" rows="2" name="description" onChange={handleChangeRequete}></textarea>
                        {errorDesc && <span className="text-danger">{errorDesc}</span>}
                        </div> */}

                        <div className="col-sm-12 mt-2 pt-0">
                            <div className="form-check">
                                {requeteData.is_pro == '0' && <input className="form-check-input" type="checkbox" name="is_pro" onChange={handleChangeRequete} id="flexCheckDefault" value="1" />}
                                {requeteData.is_pro == '1' && <input className="form-check-input" type="checkbox" name="is_pro" onChange={handleChangeRequete} id="flexCheckDefault" value="0" checked/>}
                                <label className="form-check-label" for="flexCheckDefault">
                                    <strong>Envoyer votre requête aux professionnels uniquement</strong><i className="fas fa-question-circle" title="Envoyer votre requête aux professionnels uniquement"></i>
                                </label>
                            </div>
                        </div>
                    </div>
                    {/* <hr/>
                    <div className="row">
                    <div className="col-sm-4">
                    <div className="card shadow-none text-center h-100">
                    <div className="card-body p-2 pb-0">
                    <div className="avatar avatar-stor avatar-xl bg-success">
                    <a href="#!"><i className="bi bi-emoji-wink " style={{"font-size":"50px","color":"yellow"}}></i></a>
                    </div>
                    <h6 className="card-title mb-1 mt-3"> <a href="#!"> Forfait Ekeu (1000F) </a></h6>
                    <p className="mb-0 small lh-sm">12 reponses par SMS</p>
                    </div>
                    <div className="card-footer p-2 border-0">
                    <div className="w-100">
                    <button class={chooseForfait == 'ekeu' ? "btn btn-sm btn-success " : "btn btn-sm btn-default border "} for="Interested2" onClick={()=>chooseForfaitBtn('ekeu')}><i className="fa-solid fa-thumbs-up me-1"></i> Choisir</button>
                    </div>
                    </div>
                    </div>
                    </div>

                    <div className="col-sm-4">
                    <div className="card shadow-none text-center h-100">
                    <div className="card-body p-2 pb-0">
                    <div className="avatar avatar-stor avatar-xl bg-primary">
                    <a href="#!"><i className="bi bi-emoji-smile" style={{"font-size":"50px","color":"yellow"}}></i></a>
                    </div>
                    <h6 className="card-title mb-1 mt-3"> <a href="#!"> Forfait Lite (1500F) </a></h6>
                    <p className="mb-0 small lh-sm">15 reponses par SMS</p>
                    </div>
                    <div className="card-footer p-2 border-0">
                    <div className="w-100">
                    <button class={chooseForfait == 'lite' ? "btn btn-sm btn-primary " : "btn btn-sm btn-default border "} for="Interested2" onClick={()=>chooseForfaitBtn('lite')}><i className="fa-solid fa-thumbs-up me-1"></i> Choisir</button>
                    </div>
                    </div>
                    </div>
                    </div>

                    <div className="col-sm-4">
                    <div className="card shadow-none text-center h-100">
                    <div className="card-body p-2 pb-0">
                    <div className="avatar avatar-stor avatar-xl bg-danger">
                    <a href="#!"><i className="bi bi-emoji-heart-eyes" style={{"font-size":"50px","color":"yellow"}}></i></a>
                    </div>
                    <h6 className="card-title mb-1 mt-3"> <a href="#!"> Forfait Gros (3000F)</a></h6>
                    <p className="mb-0 small lh-sm">25 reponses par SMS</p>
                    </div>
                    <div className="card-footer p-2 border-0">
                    <div className="w-100">
                    <button class={chooseForfait == 'gros' ? "btn btn-sm btn-danger " : "btn btn-sm btn-default border "} for="Interested2" onClick={()=>chooseForfaitBtn('gros')}><i className="fa-solid fa-thumbs-up me-1"></i> Choisir</button>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div> */}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger-soft me-2" data-bs-dismiss="modal"> Fermer</button>
                        <button type="button" className={!loadingQuery ?"btn btn-success":"btn btn-success disabled"} onClick={submitFormQuery}>{!loadingQuery ? "Enregister" : "Envoie en cours..."}</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default MyRequestComponent;