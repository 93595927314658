import moment from "moment";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";

import ImageGallery from "react-image-gallery";
// import stylesheet if you're not already using CSS @import
import "react-image-gallery/styles/css/image-gallery.css";
import BaseUrl from "../../../BaseUrl";
import AuthContextAgence from "../Base";
import "../../../utils/poste.css"


// const siteUrl = 'http://127.0.0.1:8000';
const baseUrl = BaseUrl(); 
function PostePro({poste}){

    const agence = AuthContextAgence();
    const [voirPLus,setVoirPlus] = useState(0);
    const [voirTel,setVoirTel] = useState(0);


    useEffect(()=>{
      
    },[poste])

    const images = JSON.parse(poste.images_postes).map((img) => ({
        original: `${baseUrl.public}/media/${img?.fields?.libelle}`,
        thumbnail : `${baseUrl.public}/media/${img?.fields?.libelle}`,
    }));


    function separateNumber(number) {
        // Vérifier si le nombre est un entier positif
        if (Number.isInteger(number) && number >= 0) {
          // Convertir le nombre en chaîne de caractères
          var numberString = number.toString();
      
          // Séparer les chiffres en centaines
          var separatedNumber = '';
          for (var i = numberString.length - 1; i >= 0; i--) {
            separatedNumber = numberString.charAt(i) + separatedNumber;
            if (i > 0 && (numberString.length - i) % 3 === 0) {
              separatedNumber = ',' + separatedNumber;
            }
          }
      
          return separatedNumber;
        } else {
          return 'Veuillez fournir un entier positif.';
        }
    }

    const funcVoirPLus =(poste)=>{
        const _fromData = new FormData();
        _fromData.append('poste',poste);
        _fromData.append('user',agence.user.id);
        try {
            axios.post(baseUrl.base+'/interet-voir-plus-click/',_fromData).then((resp)=>{
                if(resp.data.bool){}else{}
            })
        } catch (error) {
            
        }

        setVoirPlus(poste)
           
        
    }

    const funcVoirTel = (poste)=>{
        const _fromData = new FormData();
        _fromData.append('poste',poste);
        _fromData.append('user',agence.user.id);
        try {
            axios.post(baseUrl.base+'/interet-voir-tel-click/',_fromData).then((resp)=>{
                if(resp.data.bool){}else{}
            })
        } catch (error) {
            
        }

        setVoirTel(poste)
    }





    return (
        <div className={poste.is_active? "card" : "card disable"}>
                                    
        <div className="card-header border-0 pb-0">
        <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
            
            <div className="avatar avatar-story me-2">
                {JSON.parse(poste.agence_with_annonce)[0]?.fields?.image == null && <a href="#!"> <img className="avatar-img rounded-circle" src="/assets/images/avatar/placeholder.jpg" alt="" /> </a>}
                {JSON.parse(poste.agence_with_annonce)[0]?.fields?.image != null && <Link to="#"> <img className="avatar-img rounded-circle" src={`${baseUrl.public}/media/${JSON.parse(poste.agence_with_annonce)[0]?.fields?.image}`} alt="" /> </Link>}
            </div>
            
            <div>
                <div className="nav nav-divider">
            {JSON.parse(poste.agence_with_annonce)[0] && <h6 className="nav-item card-title mb-0"> <Link to="#"> {JSON.parse(poste.agence_with_annonce)[0]?.fields?.raison_sociale} <i className="bi bi-patch-check-fill text-success small" title="Pro"></i></Link></h6>} 
            {!JSON.parse(poste.agence_with_annonce)[0] && <h6 className="nav-item card-title mb-0"> <a href="#!"> {poste.user?.nom} {poste.user?.prenom}</a></h6>} 
                <span className="nav-item small text-secondary"> {moment(poste.created_at).fromNow()}</span>
                </div>
                {/* {JSON.parse(poste.agence_with_annonce)[0] && <p className="mb-0 small d-none d-sm-inline-block">{JSON.parse(poste.agence_with_annonce)[0]?.fields?.info_complementaire}</p> } */}
            </div>
            </div>

            
        
            
        </div>
        </div>
    
        <div className="card-body">
        <strong>{poste.libelle} {poste?.adresse_is_coloc}<span className="badge bg-danger bg-opacity-10 text-danger mx-2 fw-bold float-end">{separateNumber(poste.montant)} Fcfa</span> {poste.id != voirPLus && <button className="btn text-primary mx-3" onClick={()=>funcVoirPLus(poste.id)}>Voir plus..</button>}  </strong>
        {poste.id == voirPLus &&
            <>
                {poste.immobilier &&
                    <ul className="list-unstyled mt-0 mb-0">
                    <li className="mb-0"> Code: <strong> {poste.code} </strong> </li>
                    <li className="mb-0"> Commune: <strong> {poste.immobilier?.commune?.libelle} </strong> </li>
                    <li className="mb-0"> Adresse: <strong> {poste.immobilier?.quatier} </strong> </li>
                    <li className="mb-0"> Montant : <strong className="text-danger"> {separateNumber(poste.montant)} Fcfa</strong> </li>
                    <li> Contact: 
                        <button className="btn btn-success btn-sm mx-2 p-0" onClick={()=>funcVoirTel(poste.id)}>
                            <i className="bi bi-telephone pe-1"></i>
                            {poste.id != voirTel ? <b>Afficher le contact</b> : <b>{poste.contact}</b>}
                        </button> 
                    </li>
                    {poste.immobilier?.category?.id !== 10 &&
                        <>
                                <li className="mb-0"> Pièce(s): <strong> {poste.immobilier?.piece?.libelle} </strong> </li>
                                {poste.service?.id == 1 && poste.immobilier?.caution !==0 &&  <li className="mb-0"> Caution: <strong> {poste.immobilier?.caution} </strong> </li>}
                        </>
                    }

                
                    <li className="mb-0"> Service: <strong> {poste.service?.libelle} </strong> </li>
                    <li className="mb-3"> Catégorie: <strong> {poste.immobilier?.category?.libelle} </strong> </li>
                    <li className="mb-0"> 
                    <b className="text-danger">
                        <i class="fa-solid fa-angles-right"></i> Détails de l'annonce</b>
                        <p className="mt-1 border rounded px-2 mt-2">
                            <div dangerouslySetInnerHTML={{ __html: poste.description }} />
                        </p>  
                    </li>
                    <li className="mb-0"> <button className="btn text-primary " onClick={()=>setVoirPlus(0)}>moins..</button>  </li>
                
                </ul>
                }

                {poste.is_coloc &&
                    <ul className="list-unstyled mt-0 mb-0">
                        <li className="mb-0"> Code: <strong> {poste.code} </strong> </li>
                        <li> Contact: 
                            <button className="btn btn-success btn-sm mx-2 p-0" onClick={()=>funcVoirTel(poste.id)}>
                                <i className="bi bi-telephone pe-1"></i>
                                {poste.id != voirTel ? <b>Afficher le contact</b> : <b>{poste.contact}</b>}
                            </button> 
                        </li>
                        <li className="mb-0"> Adresse du domicile: <strong> {poste?.adresse_is_coloc} </strong> </li>
                        <li className="mb-0"> <p className="mt-1 border rounded p-2"><b>{poste.description}</b></p>  </li>
                        <li className="mb-0"> <button className="btn text-primary " onClick={()=>setVoirPlus(0)}>moins..</button>  </li>
                    </ul>
                }

                {poste.engins &&
                    <ul className="list-unstyled mt-0 mb-0">
                        <li className="mb-0"> Code: <strong> {poste.code} </strong> </li>
                        <li> Contact: 
                            <button className="btn btn-success btn-sm mx-2 p-0" onClick={()=>funcVoirTel(poste.id)}>
                                <i className="bi bi-telephone pe-1"></i>
                                {poste.id != voirTel ? <b>Afficher le contact</b> : <b>{poste.contact}</b>}
                            </button> 
                        </li>
                        <li className="mb-0"> Marque: <strong> {poste.engins?.marque?.libelle} </strong> </li>
                        <li className="mb-0"> Model et année: <strong> {poste.engins?.model}, {poste.engins?.annee?.libelle} </strong> </li>
                        <li className="mb-0"> Service: <strong> {poste.service?.libelle} </strong> </li>
                        {poste.service?.id == 2 && <li className="mb-0"> Etat: <strong> {poste.engins?.etat?.libelle} </strong> </li>}
                        <li className="mb-0"> Couleur: <strong> {poste.engins?.couleur?.libelle} </strong> </li>
                        <li className="mb-0"> Boîte: <strong> {poste.engins?.boite?.libelle} </strong> -- Transmission :<strong> {poste.engins?.transmission?.libelle} </strong> -- Carburant : <strong> {poste.engins?.carburant?.libelle} </strong>  </li>
                        <li className="mb-0"> Chassis: <strong> {poste.engins?.chassis?.libelle} </strong> </li>

                        <li className="mb-0"> <p className="mt-1 border rounded p-2"><div dangerouslySetInnerHTML={{ __html: poste.description }} /></p>  </li>
                        <li className="mb-0"> <button className="btn text-primary " onClick={()=>setVoirPlus(0)}>moins..</button>  </li>
                    </ul>
                }

                {poste.computer &&
                    <ul className="list-unstyled mt-0 mb-0">
                        <li className="mb-0"> Code: <strong> {poste.code} </strong> </li>
                        <li> Contact: 
                            <button className="btn btn-success btn-sm mx-2 p-0" onClick={()=>funcVoirTel(poste.id)}>
                                <i className="bi bi-telephone pe-1"></i>
                                {poste.id != voirTel ? <b>Afficher le contact</b> : <b>{poste.contact}</b>}
                            </button> 
                        </li>
                        <li className="mb-0"> Marque: <strong> {poste.computer?.marque?.libelle} </strong> </li>
                        <li className="mb-0"> Model: <strong> {poste.computer?.model} </strong> </li>
                        <li className="mb-0"> Etat: <strong> {poste.computer?.etat} </strong> </li>
                        <li className="mb-0"> Couleur: <strong> {poste.computer?.couleur?.libelle} </strong> </li>
                        <li className="mb-0"> Capacité Stockage: <strong> {poste.computer?.disque}Go</strong> -- RAM : <strong> {poste.computer?.memoire}Go</strong> </li>
                        <li className="mb-0"> Processeur: <strong> {poste.computer?.processeur} </strong> -- Carte Graphique: <strong> {poste.computer?.carteGraphique ? poste.computer?.carteGraphique : "N/A"} </strong>  -- Type système : <strong> {poste.computer?.typeOs}</strong> </li>
                        <li className="mb-0"> Ecran: <strong> {poste.computer?.ecran}" </strong> </li>
                        <li className="mb-0"> Système d'exploitation: <strong> {poste.computer?.systemeOs} </strong> </li>
                        <li className="mb-0"> Autonomie: <strong> {poste.computer?.autonomie} Heures </strong> </li>
                        <li className="mb-0"> Port USB: <strong> {poste.computer?.usb} </strong> -- Clavier : <strong> {poste.computer?.clavier} </strong> -- Clavier Retro-eclairé : <strong> {poste.computer?.clavierEclaire ? "OUI" : "NON"} </strong> -- HDMI: <strong>{poste.computer?.is_hdmi? "OUI" :"NON"}</strong></li>

                        <li className="mb-0"> <p className="mt-1 border rounded p-2"><div dangerouslySetInnerHTML={{ __html: poste.description }} /></p>  </li>
                        <li className="mb-0"> <button className="btn text-primary " onClick={()=>setVoirPlus(0)}>moins..</button>  </li>
                    </ul>
                }

                {poste.phone && 
                    <ul className="list-unstyled mt-0 mb-0">
                    <li className="mb-0"> Code: <strong> {poste.code} </strong> </li>
                    <li> Contact: 
                        <button className="btn btn-success btn-sm mx-2 p-0" onClick={()=>funcVoirTel(poste.id)}>
                            <i className="bi bi-telephone pe-1"></i>
                            {poste.id != voirTel ? <b>Afficher le contact</b> : <b>{poste.contact}</b>}
                        </button> 
                    </li>
                    <li className="mb-0"> Marque: <strong> {poste.phone?.marque?.libelle} </strong> </li>
                    <li className="mb-0"> Model: <strong> {poste.phone?.model} </strong> </li>
                    <li className="mb-0"> Etat: <strong> {poste.phone?.etat} </strong> </li>
                    <li className="mb-0"> Couleur: <strong> {poste.phone?.couleur?.libelle} </strong> </li>
                    <li className="mb-0"> Capacité Stockage: <strong> {poste.phone?.disque}Go</strong> -- RAM : <strong> {poste.phone?.memoire}Go</strong> </li>
                    <li className="mb-0"> Processeur: <strong> {poste.phone?.processeur} </strong> -- Réseau: <strong> {poste.phone?.reseau ? poste.phone?.reseau : "N/A"} </strong>  -- Caméra : <strong> {poste.phone?.appareilPhoto}</strong> </li>
                    <li className="mb-0"> Ecran: <strong> {poste.phone?.ecran}" </strong> -- Resolution :<strong>{poste.phone?.resolution}</strong> </li>
                    <li className="mb-0"> Système d'exploitation: <strong> {poste.phone?.systemeOs} {poste.phone?.version} </strong> </li>
                    <li className="mb-0"> Batterie: <strong> {poste.phone?.batterie} mAh </strong> </li>
                    <li className="mb-0"> Emprunte Digitale: <strong>{poste.phone?.is_emprunte? "OUI" :"NON"}</strong></li>

                    <li className="mb-0"> <p className="mt-1 border rounded p-2"><div dangerouslySetInnerHTML={{ __html: poste.description }} /></p>  </li>
                    <li className="mb-0"> <button className="btn text-primary " onClick={()=>setVoirPlus(0)}>moins..</button>  </li>
                </ul>
                }
            </>
            
        }
    
            
        {!poste.is_coloc &&
            <>
        {JSON.parse(poste.images_postes) &&
    
                            <ImageGallery items={images} 
                             renderItem={(item) => (
                                <div className="image-gallery-image-wrapper">
                                    <img src={item.original} className="image-gallery-image" alt="" />
                                </div>
                            )}
                            />
         
        }
            </>
        }
            
        
    


    <ul className="nav nav-pills nav-pills-light nav-fill nav-stack small border-top border-bottom py-3 mb-3">
        <li className="nav-item">
            <a className="nav-link mb-0 active" href="#!"> <i className="bi bi-telephone pe-1"></i> ({poste.interet_click_voir_tel})</a>
        </li>
    
        
        <li className="nav-item">
            <a className="nav-link mb-0" href="#!"> <i className="bi bi-eye-fill pe-1 text-danger"></i>Vues({poste.interet_click_voir_plus})</a>
        </li>
    </ul>
        

        
        </div>

        </div>
    )
}

export default PostePro;