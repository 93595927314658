import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import BaseUrl from "../../../BaseUrl";

const baseUrl = BaseUrl();
function NewPostes(){
    const [postes,setPostes] = useState([]);
    const navigate = useNavigate();

    useEffect(()=>{
        try {
            axios.get(baseUrl.base+'/postes/?recent').then((resp)=>{
                setPostes(resp.data);
            })
        } catch (error) {
            console.log(error);
        }
    },[]);


    return (
        <div class="col-sm-6 col-lg-12">
            <div class="card">
                
                <div class="card-header pb-0 border-0">
                    <h5 class="card-title mb-0">Annonces Recentes</h5>
                </div>
                
                
                <div class="card-body">
                    
                    {postes?.map((poste,index)=>
                        <div class="card mb-2 p-1">
                        <p class="mb-0"><Link to={`/search/top?q=${poste.slug}`} >{poste.libelle}</Link></p>
                        <small className="text-danger">{moment(poste.created_at).fromNow()}</small>
                    </div>
                    )}
                    
                    
                </div>
                
            </div>
        </div>
    )
}

export default NewPostes;