import AuthContext from "./BaseU";
import PrincipaleComponent from "./PrincipalComponent";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import "../../../App.css";
import Select from "react-select";
import Swal from "sweetalert2";
import NavUser from "./compo/navUser";
import BestEntreprise from "./compo/bestEntreprise";
import NewPostes from "./compo/newPosts";
import Poste from "./compo/poste";
import BaseUrl from "../../BaseUrl";

// const siteUrl = 'http://127.0.0.1:8000';
const baseUrl = BaseUrl(); 
function ProfilUsers(){
    const client = AuthContext();
    const navigate = useNavigate();
    const [mesPostes,setMesPostes] = useState([]);
    const [page, setPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [filterValue,setFiltreValue] = useState(''); 
    const [loadFilter,setLoadFilter] = useState(false);
    const [communes,setCommunes] = useState([]);


    function separateNumber(number) {
        // Vérifier si le nombre est un entier positif
        if (Number.isInteger(number) && number >= 0) {
          // Convertir le nombre en chaîne de caractères
          var numberString = number.toString();
      
          // Séparer les chiffres en centaines
          var separatedNumber = '';
          for (var i = numberString.length - 1; i >= 0; i--) {
            separatedNumber = numberString.charAt(i) + separatedNumber;
            if (i > 0 && (numberString.length - i) % 3 === 0) {
              separatedNumber = ',' + separatedNumber;
            }
          }
      
          return separatedNumber;
        } else {
          return 'Veuillez fournir un entier positif.';
        }
    }

    useEffect(()=>{
        // loadItems();   
        loadItemsrecent();
        try {
          axios.get(baseUrl.base+'/communes/?countrie='+client.countrie?.id).then((resp)=>{
              setCommunes(resp.data);
          })
      } catch (error) {
          console.log(error);
      }

    },[client]);

    const filtreLoadItems=(event)=>{
        const value = event.target.value;
     
     
        if(event.target.value !== ""){
          setLoading(true);
          setLoadFilter(true);
          setFiltreValue(event.target.value);
          try {
              const next = 15
              const url = `${baseUrl.base}/postes/?next=${next}&filter=${event.target.value}`;
              axios.get(url)
              .then(response => {
                  const newItems = response.data;
                /*   setMesPostes(prevItems => [...prevItems, ...newItems]);
                  setPage(prevPage => prevPage + 1); */
                  setMesPostes(newItems);
                  setLoading(false);
                  setLoadFilter(false);
          })
          } catch (error) {
              
          }
        }else{
          setFiltreValue("")
          loadItems();
        }

    }

const loadItems = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    if (filterValue ==""){
        const url = `${baseUrl.base}/postes/?result=${page}`; // Ajustez l'URL pour correspondre à votre point d'extrémité d'API Django
        axios.get(url)
        .then(response => {
            const newItems = response.data;
            setMesPostes(prevItems => [...prevItems, ...newItems]);
            setPage(prevPage => prevPage + 1);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
      });
    }else{
        const url = `${baseUrl.base}/postes/?next=${page}&filter=${filterValue}`; // Ajustez l'URL pour correspondre à votre point d'extrémité d'API Django
        axios.get(url)
        .then(response => {
            const newItems = response.data;
            setMesPostes(prevItems => [...prevItems, ...newItems]);
            setPage(prevPage => prevPage + 1);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
      });
    }
    
  };

  const loadItemsrecent = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    if (filterValue ==""){
        const url = `${baseUrl.base}/postes/?recent`; // Ajustez l'URL pour correspondre à votre point d'extrémité d'API Django
        axios.get(url)
        .then(response => {
            const newItems = response.data;
            setMesPostes(newItems);
            // setPage(prevPage => prevPage + 1);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
      });
    }else{
        const url = `${baseUrl.base}/postes/?next=${page}&filter=${filterValue}`; // Ajustez l'URL pour correspondre à votre point d'extrémité d'API Django
        axios.get(url)
        .then(response => {
            const newItems = response.data;
            setMesPostes(prevItems => [...prevItems, ...newItems]);
            setPage(prevPage => prevPage + 1);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
      });
    }
    
  };


  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
      loadItems();
    }
  };

   useEffect(() => {
    window.addEventListener('scroll', handleScroll());
    return () => {
      window.removeEventListener('scroll', handleScroll());
    };
  }, [handleScroll()]);



    


  const handleShowMore = () => {
    loadItems();
  };
    

  const showMoreButton =  (
    <>
        <button onClick={handleShowMore} type="button" class="btn btn-sm btn-loader btn-primary" >
          <span class="load-text">  Voir plus </span>
          <div class="load-icon">
            <div class="spinner-grow spinner-grow-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </button>
    </>
   /*  <button onClick={handleShowMore}>Voir plus</button> */  
  );
 
    return (
        <PrincipaleComponent>
              <div class="container">

                <div class="row g-4">

                  {/* Navbar de l'utilisateurs */}
                        <NavUser/>      
                  {/* end Navbar de l'utilisateur */}
                  
                        <div class="col-md-8 col-lg-6 vstack gap-4">

                                <div className="card bg-info"> 
                                    <div className="card-body py-0 ">
                                    <div className="roww-50 ">
                                    <span>
                                    <select className="form-select col-sm-6 p-1 m-2" onChange={filtreLoadItems}>
                                    <option value="" selected disabled>Toutes les communes </option>
                                    {communes?.map((commune,index)=>
                                          <option value={commune.id}>{commune.libelle}</option>
                                    )}
                             

                                    </select>
                                    </span>
                                    </div>

                                    </div> 
                                </div> 
                                {loadFilter &&
                                    <span className="p-0 m-0 bg-warning text-center">En cours de chargement...</span>
                                }

                                {mesPostes && 
                                mesPostes?.map((poste,index)=>
                                    <Poste poste={poste} index={index}/>
                                )
                                }

                                {loading && <div className="text-center">Chargement...</div>}
                                {mesPostes.length > 0 && !loading && showMoreButton}
                                {mesPostes.length == 0 && !loading &&
                                <div class="tab-pane fade show" id="group-tab-6">
                                    <div class="card card-body">
                                        <div class="my-sm-5 py-sm-5 text-center">
                                            <i class="display-1 text-muted bi bi-calendar-plus"> </i>
                                            <h4 class="mt-2 mb-3 text-body">Aucune Annonces</h4>
                                            <button class="btn btn-primary-soft btn-sm" onClick={()=>window.location.reload()}> Actualiser !</button>

                                        </div>
                                    </div>
                                </div> 
                                }

                        </div>

                        <div class="col-lg-3 d-none d-sm-inline-block">
                    <div class="row g-4">
                      
                      {/* Best entreprise */}
                          <BestEntreprise />
                      {/* End best entreprise */}
                      

                      {/* New postes */}
                          <NewPostes />
                      {/* End new postes */}
                      
                    </div>
                  </div>
            
                </div>

              </div> 

             
        </PrincipaleComponent>
    )
}

export default ProfilUsers;