import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import BaseUrl from "../../BaseUrl";
import { jwtDecode } from "jwt-decode";

const baseUrl = BaseUrl();
function AuthContext(){
    const [isAuthToken, setIsAuthToken] = useState(localStorage.getItem('_token_ucl'));
    const [client,setClients] = useState([]);
    const navigate = useNavigate();
    const token = localStorage.getItem('_token_ucl') !== null ? localStorage.getItem('_token_ucl') : null;
    const exp = token !== null ? jwtDecode(token).exp : null;
    const isExpired =exp !==null ? new Date() > new Date(exp * 1000) : true;

    useEffect(()=>{
        if(isExpired){
            localStorage.removeItem('_token_ucl');
            navigate('/login');
        }
        if(isAuthToken !== null){
            try {
                axios.get(baseUrl.base+'/users-clients/?token='+isAuthToken,{
                    headers:{
                        'Content-Type':'application/json'
                    },
                    credentials:'include'
                }).then((resp)=>{
                    if(resp.data.failed == true){
                        setIsAuthToken(null);
                        setClients(null);
                        localStorage.removeItem('_token_ucl');
                        navigate('/login');
                    }
                    setClients(resp.data[0]);
                })
            } catch (error) {
                console.log(error);
            }
        }

    },[isAuthToken,isExpired]);

    return client;
}

export default AuthContext;