import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import HeaderPage from "./HeaderPage";
import BaseUrl from "../../BaseUrl";

const baseUrl = BaseUrl();


function PrincipaleComponent({children}){
    const [isAuthToken, setIsAuthToken] = useState(localStorage.getItem('_token_ucl'));
    const [client,setClients] = useState([]);
    const navigate = useNavigate();

    useEffect(()=>{
        if(isAuthToken == null){
            localStorage.removeItem('_token_ucl');
            navigate('/login'); 
        }
        if(isAuthToken !== null){
            try {
                axios.get(baseUrl.base+'/users-clients/?token='+isAuthToken,{
                    headers:{
                        'Content-Type':'application/json'
                    },
                    credentials:'include'
                }).then((resp)=>{
                    if(resp.data.failed){
                        setIsAuthToken(null);
                        setClients(null);
                        localStorage.removeItem('_token_ucl');
                        navigate('/login');
                    }
                    setClients(resp.data[0]);
                })
            } catch (error) {
                console.log(error);
            }
        }

    },[isAuthToken]);

    const logout = ()=>{
        setIsAuthToken(null);
        setClients(null);
        localStorage.removeItem('_token_ucl');
        navigate('/login');
    }

   return ( 
            <>
                <HeaderPage client={client} logout={logout}/>
                <main>
                 
                    {children}
                    
                </main>
            </>
    )
}

export default PrincipaleComponent;