import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PrincipaleComponentAgence from "./PrincipalComponentAgence";
import Swal from "sweetalert2";
import axios from "axios";
import AuthContextAgence from "./Base";
import AbonnementList from "./compo/AbonnementList";
import BaseUrl from "../../BaseUrl";
import Validate from "../../Validation";

const baseUrl = BaseUrl();
function SettingsPro(){
    function generateUniqueID() {
        const timestamp = new Date().getTime().toString(); 
        const randomNum = Math.random().toString(36).substr(2, 9); 
      
        return timestamp + randomNum; 
    }

    const agence = AuthContextAgence();
    const location = useLocation();
    const [countries,setCountries] = useState([]);
    const [image, setImage] = useState([]);
    const [errorPass,setErrorPass] = useState('');
    const [errorPassEg,setErrorPassEg] = useState('');
    const navigate = useNavigate();
    const [errors,setErrorM] = useState({});

    const [agenceData,setAgenceData] = useState({
        'raison_sociale':'',
        'info_complementaire':'',
        'num_registre':'',
        'adresse':'',
        'email':'',
        'contact':'',
        'countrie':'',
        'tel':'',
        'emailUser':'',
        'siteInternet':'',
        'reseauxSociaux':''
    });

    const [changePass,setChangePass] = useState({
        'cfmPass':'',
        'newPass':'',
        'oldPass':''
    })

    useEffect(()=>{
        setAgenceData({
            'raison_sociale':agence.raison_sociale,
            'info_complementaire':agence.info_complementaire,
            'num_registre':agence.num_registre,
            'countrie':agence.countrie?.id,
            'adresse':agence.adresse,
            'email':agence.email,
            'contact':agence.contact,
            'tel':agence.user?.tel,
            'emailUser':agence.user?.email,
            'siteInternet' :agence.siteInternet,
            'reseauxSociaux':agence.reseauxSociaux
        });

        try {
            axios.get(baseUrl.base+'/countrie/').then((resp)=>{
                setCountries(resp.data)
            })
        } catch (error) {
            console.log(error);
        }
    },[agence]);

    const handleChange=(event)=>{
        setAgenceData({
            ...agenceData,
            [event.target.name]:event.target.value
        })
    }

    const onSelectFile = (event) => {
        setImage([]);
        setImage((image)=>image.concat(event.target.files[0]));
    };

    function deleteHandler(index) {
        const updatedImage = [...image];
        updatedImage.splice(index, 1);
        setImage(updatedImage);
      }

      const handleChangePass = (event)=>{
        setChangePass({
          ...changePass,
          [event.target.name]:event.target.value
        })
      }

    const deleteLogoFunct=()=>{
        Swal.fire({
            title: 'Attention !',
            text: 'Voulez-vous vraiment supprimer le logo ?',
            icon: 'info',
            showCancelButton: true,
            cancelButtonText:'NON',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OUI'
          }).then((result) => {
            if (result.isConfirmed) {
                const _fromData = new FormData();
                _fromData.append('agence_id',agence.id);
                try {
                    axios.post(baseUrl.base+'/delete-logo-agence/',_fromData).then((resp)=>{
                        if(resp.data.bool){window.location.reload();}
                    })
                } catch (error) {
                    console.log(error)
                }
                
            }
          });
        
    }

    const submitForm=()=>{
        setErrorM(Validate(agenceData));
        if(agenceData.email !=="" && (/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/).test(agenceData.email) && agenceData.emailUser !=="" && (/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/).test(agenceData.emailUser) && agenceData.tel.length == 10 && agenceData.tel !=="" && !isNaN(agenceData.tel)  && agenceData.contact.length == 10 && agenceData.contact !=="" && !isNaN(agenceData.contact) ){
        const _fromData = new FormData();
        _fromData.append('raison_sociale',agenceData.raison_sociale);
        _fromData.append('info_complementaire',agenceData.info_complementaire);
        _fromData.append('num_registre',agenceData.num_registre);
        _fromData.append('countrie',agenceData.countrie);
        _fromData.append('contact',agenceData.contact);
        _fromData.append('adresse',agenceData.adresse);
        _fromData.append('email',agenceData.email);
        _fromData.append('tel',agenceData.tel);
        _fromData.append('emailUser',agenceData.emailUser);
        _fromData.append('agence_id',agence.id);
        _fromData.append('user_id',agence.user?.id);
        _fromData.append('siteInternet',agenceData.siteInternet);
        _fromData.append('reseauxSociaux',agenceData.reseauxSociaux);

        Array.from(image).forEach((item, index) => {
            const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
            const originalExtension = item.name.split('.').pop();
            const newFileName = `${currentTimeInSeconds}_${index}_${agence.code}_${generateUniqueID()}.${originalExtension}`;
            const photo = new File([item], newFileName, { type: item.type });
            _fromData.append('images', photo);
        });

        //console.log(_fromData);

         try {
            axios.post(baseUrl.base+'/update-agence/',_fromData,{
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }).then((resp)=>{
                if(resp.data.bool){
                    window.location.reload();
                }else{
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: 'Désolé ! Un problème est subvenu.',
                        showConfirmButton: false,
                        timer: 3000,
                        toast:true,
                        position:'top-right',
                        timerProgressBar:true
                      });
                }
            })
        } catch (error) {
            console.log(error)
        } 

    }
    }

    const submitChangePass=()=>{
        setErrorPassEg('');
        setErrorPass('');
        if(changePass.oldPass !== '' && changePass.newPass !== '' && changePass.cfmPass !== ''){
          
          const _formData = new FormData();
          _formData.append('oldPass',changePass.oldPass);
          _formData.append('newPass',changePass.newPass);
          _formData.append('user_id',agence.user?.id);
  
          if(changePass.newPass == changePass.cfmPass){
            try {
              axios.post(baseUrl.base+'/change-pass-users/',_formData).then((resp)=>{
                 if(resp.data.bool == true){
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: resp.data.msg,
                      showConfirmButton: false,
                      timer: 3000,
                      toast:true,
                      position:'top-right',
                      timerProgressBar:true
                    });
  
                    setChangePass({
                        'cfmPass':'',
                        'newPass':'',
                        'oldPass':''
                    })
                 }else{
                  setErrorPass(resp.data.msg);
                 }
              })
            } catch (error) {
              console.log(error);
            }
          }else{
            setErrorPassEg('Le nouveau mot de passe ne correspond pas');
          }
          
        }
  
      }


    return (
        <>
            <PrincipaleComponentAgence>
            <div className="container">
                    <div className="row g-4">
                        <div className="col-lg-3">
                                <div className="d-flex align-items-center mb-4 d-lg-none">
                                        <button className="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
                                            <span className="btn btn-primary"><i className="fa-solid fa-sliders-h"></i></span>
                                        <span className="h6 mb-0 fw-bold d-lg-none ms-2">Menu</span>
                                    </button>
                                </div>

                                <nav className="navbar navbar-light navbar-expand-lg mx-0">
                                    <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar">
                                    
                                        <div className="offcanvas-header">
                                            <button type="button" className="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                        </div>

                                    
                                        <div className="offcanvas-body p-0">
                                        
                                        <div className="card w-100">
                                        
                                            <div className="card-body">

                                        
                                            <ul className="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                                                <li class="nav-item cursor-pointer">
                                                    <a class="nav-link" onClick={()=>{navigate('/ag/home/');window.location.reload()}} > <img class="me-2 h-20px fa-fw" src="/assets/images/icon/indicator-icon.svg" alt="" /><span>Accueil </span></a>
                                                </li>
                                                <li className="nav-item" data-bs-dismiss="offcanvas">
                                                    <a className="nav-link d-flex mb-0 active" href="#nav-setting-tab-1" data-bs-toggle="tab"> <img className="me-2 h-20px fa-fw" src="/assets/images/icon/person-outline-filled.svg" alt="" /><span>Mon Compte </span></a>
                                                </li>
                                                {/* <li className="nav-item" data-bs-dismiss="offcanvas">
                                                    <a className="nav-link d-flex mb-0" href="#nav-setting-tab-2" data-bs-toggle="tab"> <img className="me-2 h-20px fa-fw" src="/assets/images/icon/notification-outlined-filled.svg" alt="" /><span>Notification </span></a>
                                                </li> */}
                                                {/* <li className="nav-item" data-bs-dismiss="offcanvas">
                                                    <a className="nav-link d-flex mb-0" href="#nav-setting-tab-3" data-bs-toggle="tab"> <img className="me-2 h-20px fa-fw" src="/assets/images/icon/shield-outline-filled.svg" alt="" /><span>Abonnements </span></a>
                                                </li> */}
                                            {/*   <li className="nav-item" data-bs-dismiss="offcanvas">
                                                    <a className="nav-link d-flex mb-0" href="#nav-setting-tab-4" data-bs-toggle="tab"> <img className="me-2 h-20px fa-fw" src="/assets/images/icon/handshake-outline-filled.svg" alt="" /><span>Paiement </span></a>
                                                </li>
                                                <li className="nav-item" data-bs-dismiss="offcanvas">
                                                    <a className="nav-link d-flex mb-0" href="#nav-setting-tab-5" data-bs-toggle="tab"> <img className="me-2 h-20px fa-fw" src="/assets/images/icon/chat-alt-outline-filled.svg" alt="" /><span>Messages </span></a>
                                                </li>
                                                <li className="nav-item" data-bs-dismiss="offcanvas">
                                                    <a className="nav-link d-flex mb-0" href="#nav-setting-tab-6" data-bs-toggle="tab"> <img className="me-2 h-20px fa-fw" src="/assets/images/icon/trash-var-outline-filled.svg" alt="" /><span>Fermer </span></a>
                                                </li> */}
                                            </ul>
                                        

                                        </div>
                                    
                                      {/*   <div className="card-footer text-center py-2">
                                            <button className="btn btn-link btn-sm" onClick={()=>navigate('/ag/home/')}>Accueil </button>
                                        </div> */}
                                        </div>
                                    
                                        </div>
                                    
                                     {/*    <ul className="nav small mt-4 justify-content-center lh-1">
                                        <li className="nav-item">
                                            <a className="nav-link" href="my-profile-about.html">About</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="settings.html">Settings</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" target="_blank" href="https://support.webestica.com/login">Support </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" target="_blank" href="docs/index.html">Docs </a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="help.html">Help</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className="nav-link" href="privacy-and-terms.html">Privacy & terms</a>
                                        </li>
                                        </ul>
                                
                                        <p className="small text-center mt-1">©2023 <a className="text-body" target="_blank" href="https://www.webestica.com/"> Webestica </a></p>
                                     */}
                                    </div>
                                </nav>

                        </div>

                        <div className="col-lg-6 vstack gap-4">
   
                        <div className="tab-content py-0 mb-0">

                        
                            <div className="tab-pane show active fade" id="nav-setting-tab-1">
                            
                            <div className="card mb-4">
                                
                                
                                <div className="card-header border-0 pb-0">
                                    <h1 className="h5 card-title">Personne Morale</h1>
                                    <p className="mb-0">Ces informations concernent votre entreprise ou compte professionnel.</p>
                                </div>
                            
                                <div className="card-body">
                        
                                <div className="row g-3">
                                
                                    <div className="col-sm-6 col-lg-12">
                                        <label className="form-label">Raison sociale</label>
                                        <input type="text" className="form-control" placeholder="" name="raison_sociale" onChange={handleChange} value={agenceData.raison_sociale}/>
                                    </div>
                                
                                    <div className="col-sm-6 col-lg-12">
                                    <label className="form-label">Information complementaire</label>
                                     <textarea className="form-control" name="info_complementaire" onChange={handleChange} value={agenceData.info_complementaire}></textarea>
                                    </div>
                                
                                    <div className="col-sm-6 col-lg-6">
                                    <label className="form-label">Registre de commerce</label>
                                    <input type="text" className="form-control" placeholder="" name="num_registre" onChange={handleChange} value={agenceData.num_registre}/>
                                    </div>
                                
                                    <div className="col-sm-6">
                                    <label className="form-label">Adresse</label>
                                    <input type="text" className="form-control" placeholder="" name="adresse" onChange={handleChange} value={agenceData.adresse} />
                                    </div>
                                
                                    <div className="col-sm-6">
                                    <label className="form-label">Téléphone de l'entreprise</label>
                                    <input type="text" className="form-control" placeholder="" name="contact" onChange={handleChange} value={agenceData.contact !== "null" ? agenceData.contact : ""} />
                                    {/* 
                                    <a className="btn btn-sm btn-dashed rounded mt-2" href="#!"> <i className="bi bi-plus-circle-dotted me-1"></i>Add new phone number</a> */}
                                   {errors.contact && <span className="text-danger">{errors.contact}</span> }
                                    </div>

                                    <div className="col-sm-6">
                                    <label className="form-label">Adresse e-mail de l'entreprise</label>
                                    <input type="text" className="form-control" placeholder="" name="email" onChange={handleChange} value={agenceData.email !== 'null' ? agenceData.email : ""} />
                                    {errors.email && <span className="text-danger">{errors.email}</span> }
                                    </div>

                                    <div className="col-sm-6">
                                    <label className="form-label">Téléphone personne de contact</label>
                                    <input type="text" className="form-control" placeholder="" name="tel" onChange={handleChange} value={agenceData.tel} />
                                    {errors.tel && <span className="text-danger">{errors.tel}</span> }
                                    {/* 
                                    <a className="btn btn-sm btn-dashed rounded mt-2" href="#!"> <i className="bi bi-plus-circle-dotted me-1"></i>Add new phone number</a> */}
                                    </div>

                                    <div className="col-sm-6">
                                    <label className="form-label">Adresse e-mail personne de contact</label>
                                    <input type="text" className="form-control" placeholder="" name="emailUser" onChange={handleChange} value={agenceData.emailUser !== 'null' ? agenceData.emailUser : ""} />
                                    {errors.emailUser && <span className="text-danger">{errors.emailUser}</span> }
                                    </div>

                                    <div className="col-sm-6">
                                    <label className="form-label">Lien site internet</label>
                                    <input type="text" className="form-control" placeholder="" name="siteInternet" onChange={handleChange} value={agenceData.siteInternet} />
                                    {/* 
                                    <a className="btn btn-sm btn-dashed rounded mt-2" href="#!"> <i className="bi bi-plus-circle-dotted me-1"></i>Add new phone number</a> */}
                                    </div>

                                    <div className="col-sm-6">
                                    <label className="form-label">Lien Facebook</label>
                                    <input type="text" className="form-control" placeholder="" name="reseauxSociaux" onChange={handleChange} value={agenceData.reseauxSociaux} />
                                    
                                    </div>
                                
                                    {/* <div className="col-12">
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value="" id="allowChecked" checked />
                                        <label className="form-check-label" for="allowChecked">
                                        Allow anyone to add you to their team
                                        </label>
                                    </div>
                                    </div> */}
                                
                                   
                                
                                    
                                    
                                    <div className="col-12">
                                    <label className="form-label">Siège</label>
                                        <select className="form-control" name="countrie" onChange={handleChange} value={agenceData.countrie}>
                                                <option selected disabled>Select un pays ..</option>
                                            {countries &&
                                                countries?.map((countrie,index)=>
                                                    <option value={countrie.id}>{countrie.libelle}</option>
                                            )}
                                            
                                        </select>
                                    </div>

                                    <div className="mb-3">
                                            <label className="form-label">Votre Logo</label>
                                           
                                            <div className="d-flex align-items-center">
                                            <div className="avatar-uploader me-3">
                                             
                                          
                                                <div className="avatar-edit">
                                                <input type='file' id="avatarUpload" onChange={onSelectFile} accept="image/png , image/jpeg, image/webp" />
                                                <label for="avatarUpload"></label>
                                                </div>
                                             
                                                
                                               {agence.image == null && image.length ==0 &&
                                                 <div className="avatar avatar-xl position-relative">
                                                 <img id="avatar-preview" className="avatar-img rounded-circle border border-white border-3 shadow" src="/assets/images/avatar/placeholder.jpg" alt="" />
                                                 </div>
                                               }
                                               {image &&
                                                    image.map((img,index)=>
                                                    <div className="avatar avatar-xl position-relative">
                                                        <img id="avatar-preview" className="avatar-img rounded-circle border border-white border-3 shadow" src={img ? URL.createObjectURL(img): null} alt="" />
                                                    </div>
                                                    )
                                               }

                                            {agence.image != null && image.length == 0 &&
                                                 <div className="avatar avatar-xl position-relative">
                                                 <img id="avatar-preview" className="avatar-img rounded-circle border border-white border-3 shadow" src={agence.image} alt="" />
                                                 </div>
                                               }
                                               
                                            </div>
                                               
                                               {agence.image != null && image.length == 0 &&
                                                <div className="avatar-remove">
                                                    <button type="button" id="avatar-reset-img" className="btn btn-danger" onClick={deleteLogoFunct}>Supprimer</button>
                                                </div>
                                               }

                                            {image.length > 0 &&
                                                <div className="avatar-remove">
                                                    <button type="button" id="avatar-reset-img" className="btn btn-light" onClick={deleteHandler}>Supprimer</button>
                                                </div>
                                               }
                                           

                                            </div>
                                           
                                    </div>

                                    <div className="col-12 text-end">
                                    <button type="submit" className="btn btn-sm btn-primary mb-0" onClick={submitForm}>Modifier</button>
                                    </div>
                                </div>
                                
                                </div>
                        
                            </div>
                            
                            <div className="card">
                            
                                <div className="card-header border-0 pb-0">
                                <h5 className="card-title">Modifier votre mot de passe</h5>
                                <p className="mb-0">Vous pouvez changer votre mot de passe et le personnaliser.</p>
                                </div>
                            
                                <div className="card-body">
                                
                                <div className="row g-3">
                                
                                    <div className="col-12">
                                    <label className="form-label">Ancien mot de passe</label>
                                    <input type="password" className="form-control" name="oldPass" onChange={handleChangePass} value={changePass.oldPass}/>
                                        {errorPass !=='' && <span className="text-danger">{errorPass}</span>}
                                    </div>
                                
                                    <div className="col-12">
                                    <label className="form-label">Nouveau mot de passe</label>
                                    
                                    <div className="input-group">
                                        <input className="form-control fakepassword" type="password" id="psw-input" name="newPass"  onChange={handleChangePass} value={changePass.newPass} />
                                      
                                    </div>
                            
                                  
                                    </div>
                                
                                    <div className="col-12">
                                    <label className="form-label">Confirmer votre mot de passe</label>
                                    <input type="password" className="form-control" placeholder="" name="cfmPass" onChange={handleChangePass} value={changePass.cfmPass}/>
                                    {errorPassEg !=='' &&  <span className="text-danger">{errorPassEg}</span>}
                                    </div>
                                
                                    <div className="col-12 text-end">
                                    <button type="submit" className="btn btn-primary mb-0" onClick={submitChangePass}>Modifier</button>
                                    </div>
                                </div>
                                
                                </div>
                            </div>
                            
                            </div>
                            
                            <div className="tab-pane fade" id="nav-setting-tab-2">
                            
                            <div className="card">
                            
                                <div className="card-header border-0 pb-0">
                                <h5 className="card-title">Notification</h5>
                                <p className="mb-0">Tried law yet style child. The bore of true of no be deal. Frequently sufficient to be unaffected. The furnished she concluded depending procuring concealed. </p>
                                </div>
                                
                                <div className="card-body pb-0">
                                
                                <ul className="list-group list-group-flush">
                                
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                                    <div className="me-2">
                                        <h6 className="mb-0">Likes and Comments</h6>
                                        <p className="small mb-0">Joy say painful removed reached end.</p>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked" checked />
                                    </div>
                                    </li>
                                
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                                    <div className="me-2">
                                        <h6 className="mb-0">Reply to My comments</h6>
                                        <p className="small mb-0">Ask a quick six seven offer see among.</p>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked2" checked />
                                    </div>
                                    </li>
                                
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                                    <div className="me-2">
                                        <h6 className="mb-0">Subscriptions</h6>
                                        <p className="small mb-0">Preference any astonished unreserved Mrs.</p>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked3" />
                                    </div>
                                    </li>
                                
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                                    <div className="me-2">
                                        <h6 className="mb-0">Birthdays</h6>
                                        <p className="small mb-0">Contented he gentleman agreeable do be</p>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked4" />
                                    </div>
                                    </li>
                                
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                                    <div className="me-2">
                                        <h6 className="mb-0">Events</h6>
                                        <p className="small mb-0">Fulfilled direction use continually.</p>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked5" checked />
                                    </div>
                                    </li>
                                    
                                    <li className="list-group-item px-0 py-3">
                                    
                                    <div className="accordion accordion-flush border-0" id="emailNotifications">
                                        
                                        <div className="accordion-item bg-transparent">
                                        <h2 className="accordion-header" id="flush-headingOne">
                                            <a href="#!" className="accordion-button mb-0 p-0 collapsed bg-transparent shadow-none" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                            <span>
                                                <span className="mb-0 h6 d-block">Email notifications</span>
                                                <small className="small mb-0 text-secondary">As hastened oh produced prospect. </small>
                                            </span>
                                            </a>
                                        </h2>
                                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#emailNotifications">
                                            <div className="accordion-body p-0 pt-3">
                                            
                                                <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="NotiSwitchCheckChecked6" checked/>
                                                <label className="form-check-label" for="NotiSwitchCheckChecked6">
                                                    Product emails
                                                </label>
                                                </div>
                                            
                                                <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="NotiSwitchCheckChecke7"/>
                                                <label className="form-check-label" for="NotiSwitchCheckChecke7">
                                                    Feedback emails
                                                </label>
                                                </div>
                                                <hr/>
                                            <div className="mt-3">
                                                <h6>Email frequency</h6>
                                                
                                                <div className="form-check">
                                                <input className="form-check-input" type="radio" name="NotiRadio" id="NotiRadio1"/>
                                                <label className="form-check-label" for="NotiRadio1">
                                                    Daily
                                                </label>
                                                </div>
                                        
                                                <div className="form-check">
                                                <input className="form-check-input" type="radio" name="NotiRadio" id="NotiRadio2" checked />
                                                <label className="form-check-label" for="NotiRadio2">
                                                    Weekly
                                                </label>
                                                
                                                <div className="form-check">
                                                <input className="form-check-input" type="radio" name="NotiRadio" id="NotiRadio3" />
                                                <label className="form-check-label" for="NotiRadio3">
                                                    Periodically
                                                </label>
                                                </div>
                                                
                                                <div className="form-check">
                                                <input className="form-check-input" type="radio" name="NotiRadio" id="NotiRadio4" checked/>
                                                <label className="form-check-label" for="NotiRadio4">
                                                    Off
                                                </label>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    </li>
                                
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                                    <div className="me-2">
                                        <h6 className="mb-0">Push notifications</h6>
                                        <p className="small mb-0">Rendered six say his striking confined. </p>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked8" checked />
                                    </div>
                                    </li>
                                
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                                    <div className="me-2">
                                        <h6 className="mb-0">Weekly account summary <span className="badge bg-primary smaller"> Pro only</span> </h6>
                                        <p className="small mb-0">Rendered six say his striking confined. </p>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked9" disabled />
                                    </div>
                                    </li>
                                </ul>
                                
                                
                                </div>
                            
                            <div className="card-footer pt-0 text-end border-0">
                                <button type="submit" className="btn btn-sm btn-primary mb-0">Save changes</button>
                            </div>
                            </div>
                            
                            </div>
                            
                            <div className="tab-pane fade" id="nav-setting-tab-3">
                            
                                {/* Abonnements listes */}
                                <AbonnementList />
                            </div>
                        
                            <div className="tab-pane fade" id="nav-setting-tab-4">
                            
                            <div className="card">
                            
                                <div className="card-header border-0 pb-0">
                                <h5 className="card-title">Who can connect with you?</h5>
                                <p className="mb-0">He moonlights difficult engrossed it, sportsmen. Interested has all Devonshire difficulty gay assistance joy. Unaffected at ye of compliment alteration to.</p>
                                </div>
                            
                                <div className="card-body">
                                
                                <div className="accordion" id="communications">
                                
                                    <div className="accordion-item bg-transparent">
                                    <h2 className="accordion-header" id="communicationOne">
                                        <button className="accordion-button mb-0 h6" type="button" data-bs-toggle="collapse" data-bs-target="#communicationcollapseOne" aria-expanded="true" aria-controls="communicationcollapseOne">
                                        Connection request
                                        </button>
                                    </h2>
                                
                                    <div id="communicationcollapseOne" className="accordion-collapse collapse show" aria-labelledby="communicationOne" data-bs-parent="#communications">
                                        <div className="accordion-body">
                                        
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="ComRadio" id="ComRadio5" />
                                            <label className="form-check-label" for="ComRadio5">
                                            Everyone on social (recommended)
                                            </label>
                                        </div>
                                        
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="ComRadio" id="ComRadio2" checked/>
                                            <label className="form-check-label" for="ComRadio2">
                                            Only people who know your email address
                                            </label>
                                        </div>
                                    
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="ComRadio" id="ComRadio3" />
                                            <label className="form-check-label" for="ComRadio3">
                                            Only people who appear in your mutual connection list
                                            </label>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                
                                    <div className="accordion-item bg-transparent">
                                    <h2 className="accordion-header" id="communicationTwo">
                                        <button className="accordion-button mb-0 h6 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#communicationcollapseTwo" aria-expanded="false" aria-controls="communicationcollapseTwo">
                                        Who can message you
                                        </button>
                                    </h2>
                                    
                                    <div id="communicationcollapseTwo" className="accordion-collapse collapse" aria-labelledby="communicationTwo" data-bs-parent="#communications">
                                        <div className="accordion-body">
                                        <ul className="list-group list-group-flush">
                                        
                                            <li className="list-group-item d-sm-flex justify-content-between align-items-center px-0 py-1 border-0">
                                            <div className="me-2">
                                                <p className="mb-0">Enable message request notifications</p>
                                            </div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="comSwitchCheckChecked" />
                                            </div>
                                            </li>
                                        
                                            <li className="list-group-item d-sm-flex justify-content-between align-items-center px-0 py-1 border-0">
                                            <div className="me-2">
                                                <p className="mb-0">Allow connections to add you on group </p>
                                            </div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="comSwitchCheckChecked2" checked />
                                            </div>
                                            </li>
                                            
                                            <li className="list-group-item d-sm-flex justify-content-between align-items-center px-0 py-1 border-0">
                                            <div className="me-2">
                                                <p className="mb-0">Allow Sponsored Messages </p>
                                                <p className="small">Your personal information is safe with our marketing partners unless you respond to their Sponsored Messages </p>
                                            </div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="comSwitchCheckChecked3" checked />
                                            </div>
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    
                                    <div className="accordion-item bg-transparent">
                                    <h2 className="accordion-header" id="communicationThree">
                                        <button className="accordion-button mb-0 h6 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#communicationcollapseThree" aria-expanded="false" aria-controls="communicationcollapseThree">
                                        How people can find you
                                        </button>
                                    </h2>
                                    
                                    <div id="communicationcollapseThree" className="accordion-collapse collapse" aria-labelledby="communicationThree" data-bs-parent="#communications">
                                        <div className="accordion-body">
                                        <ul className="list-group list-group-flush">
                                        
                                            <li className="list-group-item d-sm-flex justify-content-between align-items-center px-0 py-1 border-0">
                                            <div className="me-2">
                                                <p className="mb-0">Allow search engines to show your profile?</p>
                                            </div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="comSwitchCheckChecked4" checked />
                                            </div>
                                            </li>
                                        
                                            <li className="list-group-item d-sm-flex justify-content-between align-items-center px-0 py-1 border-0">
                                            <div className="me-2">
                                                <p className="mb-0">Allow people to search by your email address? </p>
                                            </div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="comSwitchCheckChecked5" />
                                            </div>
                                            </li>
                                        
                                            <li className="list-group-item d-sm-flex justify-content-between align-items-center px-0 py-1 border-0">
                                            <div className="me-2">
                                                <p className="mb-0">Allow Sponsored Messages </p>
                                                <p className="small">Your personal information is safe with our marketing partners unless you respond to their Sponsored Messages </p>
                                            </div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="comSwitchCheckChecked6" checked />
                                            </div>
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                
                                </div>
                            
                            <div className="card-footer pt-0 text-end border-0">
                                <button type="submit" className="btn btn-sm btn-primary mb-0">Save changes</button>
                            </div>
                            </div>
                            
                            </div>
                        
                            <div className="tab-pane fade" id="nav-setting-tab-5">
                            
                            <div className="card mb-4">
                            
                                <div className="card-header border-0 pb-0">
                                <h5 className="card-title">Messaging privacy settings</h5>
                                <p className="mb-0">As young ye hopes no he place means. Partiality diminution gay yet entreaties admiration. In mention perhaps attempt pointed suppose. Unknown ye chamber of warrant of Norland arrived. </p>
                                </div>
                        
                                <div className="card-body">
                                
                                <ul className="list-group list-group-flush">
                                    
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                                    <div className="me-2">
                                        <h6 className="mb-0">Enable message request notifications</h6>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="msgSwitchCheckChecked" checked />
                                    </div>
                                    </li>
                                    
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                                    <div className="me-2">
                                        <h6 className="mb-0">Invitations from your network</h6>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="msgSwitchCheckChecked2" checked/>
                                    </div>
                                    </li>
                                    
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                                    <div className="me-2">
                                        <h6 className="mb-0">Allow connections to add you on group</h6>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="msgSwitchCheckChecked3"/>
                                    </div>
                                    </li>
                                    
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                                    <div className="me-2">
                                        <h6 className="mb-0">Reply to comments</h6>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="msgSwitchCheckChecked4"/>
                                    </div>
                                    </li>
                                    
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                                    <div className="me-2">
                                        <h6 className="mb-0">Messages from activity on my page or channel</h6>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="msgSwitchCheckChecked5" checked/>
                                    </div>
                                    </li>
                                    
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                                    <div className="me-2">
                                        <h6 className="mb-0">Personalise tips for my page</h6>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="msgSwitchCheckChecked6" checked/>
                                    </div>
                                    </li>
                                </ul>
                                
                            </div>
                            
                            <div className="card-footer pt-0 text-end border-0">
                                <button type="submit" className="btn btn-sm btn-primary mb-0">Save changes</button>
                            </div>
                            </div>
                            
                            <div className="card">
                            
                                <div className="card-header border-0 pb-0">
                                <h5 className="card-title">Messaging experience</h5>
                                <p className="mb-0">Arrived off she elderly beloved him affixed noisier yet. </p>
                                </div>
                            
                                <div className="card-body">
                                
                                <ul className="list-group list-group-flush">
                                    
                                    <li className="list-group-item d-sm-flex justify-content-between align-items-center px-0">
                                    <div className="me-2">
                                        <h6 className="mb-0">Read receipts and typing indicators</h6>
                                    </div>
                                    <button className="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i className="bi bi-pencil-square"></i> Change</button>
                                    </li>
                                    
                                    <li className="list-group-item d-sm-flex justify-content-between align-items-center px-0">
                                    <div className="me-2">
                                        <h6 className="mb-0">Message suggestions</h6>
                                    </div>
                                    <button className="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i className="bi bi-pencil-square"></i> Change</button>
                                    </li>
                                    
                                    <li className="list-group-item d-sm-flex justify-content-between align-items-center px-0">
                                    <div className="me-2">
                                        <h6 className="mb-0">Message nudges</h6>
                                    </div>
                                    <button className="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i className="bi bi-pencil-square"></i> Change</button>
                                    </li>
                                </ul>
                                
                                </div>
                            
                            <div className="card-footer pt-0 text-end border-0">
                                <button type="submit" className="btn btn-sm btn-primary mb-0">Save changes</button>
                            </div>
                            </div>
                            
                            </div>
                            
                            <div className="tab-pane fade" id="nav-setting-tab-6">
                            
                            <div className="card">
                            
                                <div className="card-header border-0 pb-0">
                                <h5 className="card-title">Delete account</h5>
                                <p className="mb-0">He moonlights difficult engrossed it, sportsmen. Interested has all Devonshire difficulty gay assistance joy. Unaffected at ye of compliment alteration to.</p>
                                </div>
                        
                                <div className="card-body">
                                
                                <h6>Before you go...</h6>
                                <ul>
                                    <li>Take a backup of your data <a href="#">Here</a> </li>
                                    <li>If you delete your account, you will lose your all data.</li>
                                </ul>
                                <div className="form-check form-check-md my-4">
                                    <input className="form-check-input" type="checkbox" value="" id="deleteaccountCheck" />
                                    <label className="form-check-label" for="deleteaccountCheck">Yes, I'd like to delete my account</label>
                                </div>
                                <a href="#" className="btn btn-success-soft btn-sm mb-2 mb-sm-0">Keep my account</a>
                                <a href="#" className="btn btn-danger btn-sm mb-0">Delete my account</a>
                                
                                </div>
                            
                            </div>
                            
                            </div>
                        

                        </div>
                        
                        </div>
                    </div>
            </div>
            </PrincipaleComponentAgence>

            <div className="modal fade" id="modalLoginActivity" tabindex="-1" aria-labelledby="modalLabelLoginActivity" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabelLoginActivity">Where You're Logged in </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <ul className="list-group list-group-flush">
                        
                        <li className="list-group-item d-flex justify-content-between align-items-center px-0 pb-3">
                            <div className="me-2">
                            <h6 className="mb-0">London, UK</h6>
                            <ul className="nav nav-divider small">
                                <li className="nav-item">Active now </li>
                                <li className="nav-item">This Apple iMac </li>
                            </ul>
                            </div>
                            <button className="btn btn-sm btn-primary-soft"> Logout </button>
                        </li>
                        
                        <li className="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                            <div className="me-2">
                            <h6 className="mb-0">California, USA</h6>
                            <ul className="nav nav-divider small">
                                <li className="nav-item">Active now </li>
                                <li className="nav-item">This Apple iMac </li>
                            </ul>
                            </div>
                            <button className="btn btn-sm btn-primary-soft"> Logout </button>
                        </li>
                        
                        <li className="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                            <div className="me-2">
                            <h6 className="mb-0">New york, USA</h6>
                            <ul className="nav nav-divider small">
                                <li className="nav-item">Active now </li>
                                <li className="nav-item">This Windows </li>
                            </ul>
                            </div>
                            <button className="btn btn-sm btn-primary-soft"> Logout </button>
                        </li>
                        
                        <li className="list-group-item d-flex justify-content-between align-items-center px-0 pt-3">
                            <div className="me-2">
                            <h6 className="mb-0">Mumbai, India</h6>
                            <ul className="nav nav-divider small">
                                <li className="nav-item">Active now </li>
                                <li className="nav-item">This Windows </li>
                            </ul>
                            </div>
                            <button className="btn btn-sm btn-primary-soft"> Logout </button>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SettingsPro;