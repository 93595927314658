import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthContext from "./BaseU";
import PrincipaleComponent from "./PrincipalComponent";
import moment from "moment";
import NavUser from "./compo/navUser";
import Poste from "./compo/poste";
import BaseUrl from "../../BaseUrl";

const siteUrl = 'http://127.0.0.1:8000';
const baseUrl = BaseUrl();
function SearchPage(){

    function separateNumber(number) {
        // Vérifier si le nombre est un entier positif
        if (Number.isInteger(number) && number >= 0) {
          // Convertir le nombre en chaîne de caractères
          var numberString = number.toString();
      
          // Séparer les chiffres en centaines
          var separatedNumber = '';
          for (var i = numberString.length - 1; i >= 0; i--) {
            separatedNumber = numberString.charAt(i) + separatedNumber;
            if (i > 0 && (numberString.length - i) % 3 === 0) {
              separatedNumber = ',' + separatedNumber;
            }
          }
      
          return separatedNumber;
        } else {
          return 'Veuillez fournir un entier positif.';
        }
    }

    const client = AuthContext(); 
    const params = new URLSearchParams(window.location.search);
    const valeurQ = params.get('q');
    const location = useLocation();
    const searchRecommande = location.state ? location.state.search : ""

    const [postes,setSearchPoste] = useState([]);
    const [searchOthers,setSearchOthers] = useState([]);

    const [page, setPage] = useState(15);
    const [loading, setLoading] = useState(false);

   
 


    useEffect(()=>{
        if(valeurQ){
            try {
                axios.get(baseUrl.base+'/postes/?title='+valeurQ).then((resp)=>{
                    setSearchPoste(resp.data);
                })
            } catch (error) {
                console.log(error);
            }
        }

        loadItems();
    },[valeurQ,searchRecommande,client]);


     
    const loadItems = () => {
        if (loading) {
          return;
        }
    
        setLoading(true);
        const url = `${baseUrl.base}/postes/?nbre=${page}&recherche=${searchRecommande}`; // Ajustez l'URL pour correspondre à votre point d'extrémité d'API Django
        axios.get(url)
          .then(response => {
            const newItems = response.data;
            setSearchOthers(newItems);
            /* setPage(prevPage => prevPage + 1); */
            setLoading(false);
          })
          .catch(error => {
            console.log(error);
            setLoading(false);
          });
      };
    
     /*  const handleScroll = () => {
        if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
          loadItems();
        }
      }; */
    
    /*        useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []); */





    return (
        <PrincipaleComponent>
        <div class="container">
                 <div class="row g-4">
                
                <NavUser />
                 
                 <div class="col-md-8 col-lg-6 vstack gap-4">
             
                     
                     <div class="card">
                 
                    {/*  <div class="h-200px rounded-top banner-profil" ></div> */}
                         
                         <div class="card-body py-0">
                         <div class="d-sm-flex align-items-start text-center text-sm-start">
                            
                             <div class="ms-sm-4 mt-sm-3">
                             
                             <h6 class="mb-3">Recherche sur : <span className="bg-warning">{valeurQ}</span>...</h6>
                           
                             </div>
                             
                            
                         </div>
                         
                         </div>

                       
                         
                       
                     </div>

                      {postes && 
                        postes?.map((poste,index)=>
                                <Poste poste={poste}/>
                            )
                    //     <div className="card">
                    
                    //     <div class="card-header border-0 pb-0">
                    //     <div class="d-flex align-items-center justify-content-between">
                    //         <div class="d-flex align-items-center">
                            
                    //         <div class="avatar avatar-story me-2">
                    //             {poste.agence_with_annonce  &&
                    //                 JSON.parse(poste.agence_with_annonce)[0]?.fields?.image != null && <Link to={`/pro/${JSON.parse(poste.agence_with_annonce)[0]?.fields?.slug}`}> <img class="avatar-img rounded-circle" src={`${baseUrl.public}/media/${JSON.parse(poste.agence_with_annonce)[0]?.fields?.image}`} alt="" /> </Link>
                    //             }
                    //             {poste.agence_with_annonce == "[]"  &&
                    //                 <a href="#!"> <img class="avatar-img rounded-circle" src="/assets/images/avatar/placeholder.jpg" alt="" /> </a>
                    //             }
                    //         </div>
                            
                    //         <div>
                    //             <div class="nav nav-divider">
                    //             {poste.agence_with_annonce  &&
                    //                 JSON.parse(poste.agence_with_annonce)[0] && <h6 class="nav-item card-title mb-0"> <Link to={`/pro/${JSON.parse(poste.agence_with_annonce)[0]?.fields?.slug}`}> {JSON.parse(poste.agence_with_annonce)[0]?.fields?.raison_sociale} <i class="bi bi-patch-check-fill text-success small" title="Pro"></i></Link></h6>
                    //             } 
                    //             {poste.agence_with_annonce =="[]"  && <h6 class="nav-item card-title mb-0"> <a href="#!"> {poste.user?.nom} {poste.user?.prenom}</a></h6>} 
                    //             <span class="nav-item small text-secondary"> {moment(poste.created_at).fromNow()}</span>
                    //             </div>
                    //             {poste.agence_with_annonce  && <p class="mb-0 small d-none d-sm-inline-block">{JSON.parse(poste.agence_with_annonce)[0]?.fields?.info_complementaire}</p> }
                    //         </div>
                    //         </div>

                    
                    //         {favorites.indexOf(poste.id) === -1 ?

                    //             <button class="btn btn-default py-1 px-2 border" onClick={()=>markFavorite(poste.id)}>
                    //             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                    //                 <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                    //                 </svg>
                    //             </button>

                    //             : 
                    //             <button class="btn btn-success py-1 px-2 border" onClick={()=>UnMarkFavorite(poste.id)}>
                    //             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                    //                 <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                    //                 </svg>
                    //             </button> 
                    //             }
                            

                            
                            
                        
                            
                    //     </div>
                    //     </div>
                    
                    //     <div class="card-body">
                    //     <strong>{poste.libelle} {poste?.adresse_is_coloc}<span class="badge bg-danger bg-opacity-10 text-danger mx-2">{separateNumber(poste.montant)} Fcfa</span> {poste.id != voirPLus && <button className="btn text-primary mx-3" onClick={()=>funcVoirPLus(poste.id)}>Voir plus..</button>}  </strong>
                    //     {poste.id == voirPLus &&
                    //         <>
                    //             {poste.immobilier &&
                    //                 <ul class="list-unstyled mt-0 mb-0">
                    //                 <li class="mb-0"> Code: <strong> {poste.code} </strong> </li>
                    //                 <li class="mb-0">  Adresse: <strong> {poste.immobilier?.commune?.libelle} {poste.immobilier?.quatier} </strong> </li>
                    //                 <li>  Contact: <button className="btn btn-success btn-sm mx-2 p-0" onClick={()=>funcVoirTel(poste.id)}><i class="bi bi-telephone pe-1"></i>{poste.id != voirTel ? "Afficher le contact" : poste.contact}</button> </li>
                    //                 <li class="mb-0"> Pièce(s): <strong> {poste.immobilier?.piece?.libelle} </strong> </li>
                    //                 {poste.service?.id == 1 &&  <li class="mb-0"> Caution: <strong> {poste.immobilier?.caution} </strong> </li>}
                                
                    //                 <li class="mb-0"> Service: <strong> {poste.service?.libelle} </strong> </li>
                    //                 <li class="mb-0"> Catégorie: <strong> {poste.immobilier?.category?.libelle} </strong> </li>
                    //                 <li class="mb-0"> <p className="mt-1 border rounded p-2"><b>{poste.description}</b></p>  </li>
                    //                 <li class="mb-0"> <button className="btn text-primary " onClick={()=>setVoirPlus(0)}>moins..</button>  </li>
                                
                    //             </ul>
                    //             }

                    //             {poste.is_coloc &&
                    //                 <ul class="list-unstyled mt-0 mb-0">
                    //                     <li class="mb-0"> Code: <strong> {poste.code} </strong> </li>
                    //                     <li>  Contact: <button className="btn btn-success btn-sm mx-2 p-0" onClick={()=>funcVoirTel(poste.id)}><i class="bi bi-telephone pe-1"></i>{poste.id != voirTel ? "Afficher le contact" : poste.contact}</button> </li>
                    //                     <li class="mb-0"> Adresse du domicile: <strong> {poste?.adresse_is_coloc} </strong> </li>
                    //                     <li class="mb-0"> <p className="mt-1 border rounded p-2"><b>{poste.description}</b></p>  </li>
                    //                     <li class="mb-0"> <button className="btn text-primary " onClick={()=>setVoirPlus(0)}>moins..</button>  </li>
                    //                 </ul>
                    //             }

                    //             {poste.engins &&
                    //                 <ul class="list-unstyled mt-0 mb-0">
                    //                     <li class="mb-0"> Code: <strong> {poste.code} </strong> </li>
                    //                     <li>  Contact: <button className="btn btn-success btn-sm mx-2 p-0" onClick={()=>funcVoirTel(poste.id)}><i class="bi bi-telephone pe-1"></i>{poste.id != voirTel ? "Afficher le contact" : poste.contact}</button> </li>
                    //                     <li class="mb-0"> Marque: <strong> {poste.engins?.marque?.libelle} </strong> </li>
                    //                     <li class="mb-0"> Model et année: <strong> {poste.engins?.model}, {poste.engins?.annee?.libelle} </strong> </li>
                    //                     <li class="mb-0"> Service: <strong> {poste.service?.libelle} </strong> </li>
                    //                     {poste.service?.id == 2 && <li class="mb-0"> Etat: <strong> {poste.engins?.etat?.libelle} </strong> </li>}
                    //                     <li class="mb-0"> Couleur: <strong> {poste.engins?.couleur?.libelle} </strong> </li>
                    //                     <li class="mb-0"> Boîte: <strong> {poste.engins?.boite?.libelle} </strong> -- Transmission :<strong> {poste.engins?.transmission?.libelle} </strong> -- Carburant : <strong> {poste.engins?.carburant?.libelle} </strong>  </li>
                    //                     <li class="mb-0"> Chassis: <strong> {poste.engins?.chassis?.libelle} </strong> </li>

                    //                     <li class="mb-0"> <p className="mt-1 border rounded p-2"><b>{poste.description}</b></p>  </li>
                    //                     <li class="mb-0"> <button className="btn text-primary " onClick={()=>setVoirPlus(0)}>moins..</button>  </li>
                    //                 </ul>
                    //             }

                    //             {poste.computer &&
                    //                 <ul class="list-unstyled mt-0 mb-0">
                    //                     <li class="mb-0"> Code: <strong> {poste.code} </strong> </li>
                    //                     <li>  Contact: <button className="btn btn-success btn-sm mx-2 p-0" onClick={()=>funcVoirTel(poste.id)}><i class="bi bi-telephone pe-1"></i>{poste.id != voirTel ? "Afficher le contact" : poste.contact}</button> </li>
                    //                     <li class="mb-0"> Marque: <strong> {poste.computer?.marque?.libelle} </strong> </li>
                    //                     <li class="mb-0"> Model: <strong> {poste.computer?.model} </strong> </li>
                    //                     <li class="mb-0"> Etat: <strong> {poste.computer?.etat} </strong> </li>
                    //                     <li class="mb-0"> Couleur: <strong> {poste.computer?.couleur?.libelle} </strong> </li>
                    //                     <li class="mb-0"> Capacité Stockage: <strong> {poste.computer?.disque}Go</strong> -- RAM : <strong> {poste.computer?.memoire}Go</strong> </li>
                    //                     <li class="mb-0"> Processeur: <strong> {poste.computer?.processeur} </strong> -- Carte Graphique: <strong> {poste.computer?.carteGraphique ? poste.computer?.carteGraphique : "N/A"} </strong>  -- Type système : <strong> {poste.computer?.typeOs}</strong> </li>
                    //                     <li class="mb-0"> Ecran: <strong> {poste.computer?.ecran}" </strong> </li>
                    //                     <li class="mb-0"> Système d'exploitation: <strong> {poste.computer?.systemeOs} </strong> </li>
                    //                     <li class="mb-0"> Autonomie: <strong> {poste.computer?.autonomie} Heures </strong> </li>
                    //                     <li class="mb-0"> Port USB: <strong> {poste.computer?.usb} </strong> -- Clavier : <strong> {poste.computer?.clavier} </strong> -- Clavier Retro-eclairé : <strong> {poste.computer?.clavierEclaire ? "OUI" : "NON"} </strong> -- HDMI: <strong>{poste.computer?.is_hdmi? "OUI" :"NON"}</strong></li>

                    //                     <li class="mb-0"> <p className="mt-1 border rounded p-2"><b>{poste.description}</b></p>  </li>
                    //                     <li class="mb-0"> <button className="btn text-primary " onClick={()=>setVoirPlus(0)}>moins..</button>  </li>
                    //                 </ul>
                    //             }

                    //             {poste.phone && 
                    //                 <ul class="list-unstyled mt-0 mb-0">
                    //                     <li class="mb-0"> Code: <strong> {poste.code} </strong> </li>
                    //                     <li>  Contact: <button className="btn btn-success btn-sm mx-2 p-0" onClick={()=>funcVoirTel(poste.id)}><i class="bi bi-telephone pe-1"></i>{poste.id != voirTel ? "Afficher le contact" : poste.contact}</button> </li>
                    //                     <li class="mb-0"> Marque: <strong> {poste.phone?.marque?.libelle} </strong> </li>
                    //                     <li class="mb-0"> Model: <strong> {poste.phone?.model} </strong> </li>
                    //                     <li class="mb-0"> Etat: <strong> {poste.phone?.etat} </strong> </li>
                    //                     <li class="mb-0"> Couleur: <strong> {poste.phone?.couleur?.libelle} </strong> </li>
                    //                     <li class="mb-0"> Capacité Stockage: <strong> {poste.phone?.disque}Go</strong> -- RAM : <strong> {poste.phone?.memoire}Go</strong> </li>
                    //                     <li class="mb-0"> Processeur: <strong> {poste.phone?.processeur} </strong> -- Réseau: <strong> {poste.phone?.reseau ? poste.phone?.reseau : "N/A"} </strong>  -- Caméra : <strong> {poste.phone?.appareilPhoto}</strong> </li>
                    //                     <li class="mb-0"> Ecran: <strong> {poste.phone?.ecran}" </strong> -- Resolution :<strong>{poste.phone?.resolution}</strong> </li>
                    //                     <li class="mb-0"> Système d'exploitation: <strong> {poste.phone?.systemeOs} {poste.phone?.version} </strong> </li>
                    //                     <li class="mb-0"> Batterie: <strong> {poste.phone?.batterie} mAh </strong> </li>
                    //                     <li class="mb-0"> Emprunte Digitale: <strong>{poste.phone?.is_emprunte? "OUI" :"NON"}</strong></li>

                    //                     <li class="mb-0"> <p className="mt-1 border rounded p-2"><b>{poste.description}</b></p>  </li>
                    //                     <li class="mb-0"> <button className="btn text-primary " onClick={()=>setVoirPlus(0)}>moins..</button>  </li>
                    //                 </ul>
                    //             }
                    //         </>
                            
                    //     }
                    
                            
                    //     {poste.images_postes &&
                    //         <>
                    //             {JSON.parse(poste.images_postes) &&
                    //         <section className="section">
                    //             <div className="row">
                    //                 <div className="col-lg-2"></div>
                    //                 <div className="col-lg-8">
                    //                 <div id={`carouselExample${poste.id}`} class="carousel slide carousel-image">
                        
                            
                    //                                 <div class="carousel-inner">
                    //                                 {JSON.parse(poste.images_postes).map((img)=>
                                            
                    //                                     <div class="carousel-item active">
                    //                                     <img src={`${baseUrl.public}/media/${img.fields.libelle}`}   class="d-block w-100" alt={poste.slug} />
                    //                                     </div>
                    //                                     )}
                    //                                 </div>
                    //                                 {!poste.is_coloc &&
                    //                                     <>
                    //                                         <button class="carousel-control-prev" type="button" data-bs-target={`#carouselExample${poste.id}`} data-bs-slide="prev">
                    //                                             <span class="carousel-control-prev-icon bg-dark" aria-hidden="true"></span>
                    //                                             <span class="visually-hidden">Previous</span>
                    //                                         </button>
                    //                                         <button class="carousel-control-next" type="button" data-bs-target={`#carouselExample${poste.id}`} data-bs-slide="next">
                    //                                             <span class="carousel-control-next-icon bg-dark" aria-hidden="true"></span>
                    //                                             <span class="visually-hidden">Next</span>
                    //                                         </button>
                    //                                     </>
                    //                                 }
                                                
                                                
                                        
                                        
                    //                     </div>
                    //                 </div>
                    //                 <div className="col-lg-2"></div>
                    //             </div>
                    //         </section>
                    //     }
                    //         </>
                    //     }
                            
                        
                    
                
                
                    // <ul class="nav nav-pills nav-pills-light nav-fill nav-stack small border-top border-bottom py-3 mb-3">
                    //     <li class="nav-item">
                    //         <a class="nav-link mb-0 active" href="#!"> <i class="bi bi-telephone pe-1"></i> ({poste.interet_click_voir_tel})</a>
                    //     </li>
                    
                        
                    //     <li class="nav-item">
                    //         <a class="nav-link mb-0" href="#!"> <i class="bi bi-eye-fill pe-1 text-danger"></i>Vues({poste.interet_click_voir_plus})</a>
                    //     </li>
                    // </ul>
                        
                
                        
                    //     </div>
                        
                    //     </div>
                                
                        }

                        {searchRecommande != "" &&
                            <>
                                <div class="card pb-2">       
                                <div class="card-body py-0">
                                    <div class="d-sm-flex align-items-start text-center text-sm-start">
                                    
                                        <div class="ms-sm-4 mt-sm-3">
                                        
                                        <h6 class="mb-3"> {loading &&  
                                            <>
                                                <div class="spinner-border spinner-border-sm" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div class="spinner-grow spinner-grow-sm" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div>
                                            </>
                                            } 
                                  Recherches Associées : <span className="bg-warning">{searchRecommande}</span>...</h6>
                                        </div>
                                        
                                    </div>
                                    
                                    </div>

                         </div> 
                            
                            {searchOthers.length > 0 && 
                                searchOthers.map((poste,index)=>
                                    <Poste poste={poste}  />
                                )
                            }

                            </>
                        } 
                        
                    
                    
           
                 
                 </div>
                 
                 <div class="col-lg-3"></div>
                 

                 </div> 
             </div>
        </PrincipaleComponent>
    )
}

export default SearchPage;