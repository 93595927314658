import { useEffect, useState } from "react";
import PrincipaleComponent from "./PrincipalComponent";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "./BaseU";
import axios from "axios";
import moment from "moment";
import BaseUrl from "../../BaseUrl";
import Poste from "./compo/poste";
import NavUser from "./compo/navUser";

// const siteUrl = 'http://127.0.0.1:8000';
const baseUrl = BaseUrl();
function PageProDetail(){
    function separateNumber(number) {
        // Vérifier si le nombre est un entier positif
        if (Number.isInteger(number) && number >= 0) {
          // Convertir le nombre en chaîne de caractères
          var numberString = number.toString();
      
          // Séparer les chiffres en centaines
          var separatedNumber = '';
          for (var i = numberString.length - 1; i >= 0; i--) {
            separatedNumber = numberString.charAt(i) + separatedNumber;
            if (i > 0 && (numberString.length - i) % 3 === 0) {
              separatedNumber = ',' + separatedNumber;
            }
          }
      
          return separatedNumber;
        } else {
          return 'Veuillez fournir un entier positif.';
        }
      }

    const client = AuthContext();
    const {slug} = useParams(); 
    const [agence,setAgence] = useState([]);
    const [postes,setPosteAgences] = useState([]);
    const [voirPLus,setVoirPlus] = useState(0)
    const [voirTel,setVoirTel] = useState(0);
    const [tabName,setTabName] = useState('publication');
    const [statusLike,setStatusLike] = useState('unlike');
    const [signal,setSignal] = useState(false);
    const [favorites,setFavorites] = useState([]);
    const [activiteAgences,setActiviteAgences]= useState([]);
    const [signalData,setSignalData] = useState({
        'titre':'', 
        'motif':''
    });
    const [loadingItems,setLoadingItems] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
        try {
            axios.get(baseUrl.base+'/pro-agences/?slug='+slug).then((resp)=>{ 
    
                    setAgence(resp.data[0]);
  
            })
        } catch (error) {
            console.log(error);
        }



       

       if(client && client.id){
        try {
            axios.get(baseUrl.base+'/favorite-user/?client='+client.id).then((res)=>{
                setFavorites(res.data);
            })
        } catch (error) {
            console.log(error);
        
        }
       }
            


        


    },[client,slug]);

    useEffect(()=>{
        if(agence && agence?.user?.id){
            loadPostes();
            
        }

        if(agence && agence.id){
            try {
                axios.get(baseUrl.base+'/activite-agence/?agence='+agence.id).then((res)=>{
                    setActiviteAgences(res.data);
                })
            } catch (error) {
                console.log(error);
            
            }
        }

        if(agence && agence.id && client && client.id){
            try {
                axios.get(baseUrl.base+'/mark-like/?client='+client.id+'&agence='+agence.id).then((resp)=>{
                    if(resp.data.bool){
                        setStatusLike('like');
                    }
                })
            } catch (error) {
                console.log(error);
            }
        }

        if(agence && agence.id && client && client.id){
            try {
                axios.get(baseUrl.base+'/signal-page/?client='+client.id+'&agence='+agence.id).then((resp)=>{
                    if(resp.data.bool){
                        setSignal(true);
                    }
                })
            } catch (error) {
                console.log(error);
            }
        }

    },[client,agence])

    const handleChangeSignal=(event)=>{
        setSignalData({
            ...signalData,
            [event.target.name]:event.target.value
        });
    }

    function loadPostes(){
        if(agence && agence?.user && agence.user.id){
            setLoadingItems(true);
            try {
                axios.get(baseUrl.base+'/postes/?agence='+agence?.user?.id+'&page=20').then((resp)=>{
                    setPosteAgences(resp.data);
                    setLoadingItems(false);
                })
            } catch (error) {
                console.log(error);
            }
        }
        

    }

    const funcVoirPLus =(poste)=>{
        const _fromData = new FormData();
        _fromData.append('poste',poste);
        _fromData.append('user',client.id);
        try {
            axios.post(baseUrl.base+'/interet-voir-plus-click/',_fromData).then((resp)=>{
                if(resp.data.bool){}else{}
            })
        } catch (error) {
            
        }

        setVoirPlus(poste)
           
        
    }

    const funcVoirTel = (poste)=>{
        const _fromData = new FormData();
        _fromData.append('poste',poste);
        _fromData.append('user',client.id);
        try {
            axios.post(baseUrl.base+'/interet-voir-tel-click/',_fromData).then((resp)=>{
                if(resp.data.bool){}else{}
            })
        } catch (error) {
            
        }

        setVoirTel(poste)
    }

    const markLike=()=>{
        const _fromData = new FormData();
        _fromData.append('user',client.id);
        _fromData.append('agence',agence.id);

        try {
            axios.post(baseUrl.base+'/mark-likes-user/',_fromData).then((resp)=>{
                if(resp.data.bool){
                    setStatusLike('like');
                }else{}
            })
        } catch (error) {
            
        }


    }

    const UnmarkLike=()=>{
        try {
            axios.get(baseUrl.base+'/mark-like-delete/?client='+client.id+'&agence='+agence.id).then((resp)=>{
                if(resp.data.bool){
                    setStatusLike('unlike');
                }else{
                    setStatusLike('like');
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    const signalPage=()=>{
        const _fromData = new FormData();

        if(signalData.titre !=="" && signalData.motif !==""){
            _fromData.append('user',client.id);
            _fromData.append('agence',agence.id);
            _fromData.append('titre',signalData.titre);
            _fromData.append('motif',signalData.motif);
    
            try {
                axios.post(baseUrl.base+'/signal-page-user/',_fromData).then((resp)=>{
                    if(resp.status == 201){
                        setSignal(true);
                        setSignalData({
                            'titre':'',
                            'motif':''
                        })
                        window.$('#modalSignalPage').modal('hide');
                    }else{}
                })
            } catch (error) {
                
            }
        }

    }

    const markFavorite=(poste)=>{
        const _fromData = new FormData();
        _fromData.append('user',client.id);
        _fromData.append('poste',poste);

        try {
            axios.post(baseUrl.base+'/mark-favorite-user/',_fromData).then((resp)=>{
                if(resp.data.bool){
                    axios.get(baseUrl.base+'/favorite-user/?client='+client.id).then((res)=>{
                        setFavorites(res.data);
                    })
                }else{}
            })
        } catch (error) {
            
        }
    }

    const UnMarkFavorite =(poste)=>{
        try {
            axios.get(baseUrl.base+'/delete-favorite-user/?client='+client.id+'&poste='+poste).then((resp)=>{
                if(resp.data.bool){
                    axios.get(baseUrl.base+'/favorite-user/?client='+client.id).then((res)=>{
                        setFavorites(res.data);
                    })
                }else{}
            })
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <PrincipaleComponent>
               <div class="container">
                        <div class="row g-4">
                       
                               {/* Navbar de l'utilisateurs */}
                               <NavUser/>      
                             {/* end Navbar de l'utilisateur */}
                        <div class="col-lg-6 vstack gap-4">
                        
                       
                            
                            <div class="card">
                        
                            <div class="h-200px rounded-top banner-profil" ></div>
                                
                                <div class="card-body py-0">
                                <div class="d-sm-flex align-items-start text-center text-sm-start">
                                    <div>
                                    
                                    <div class="avatar avatar-xxl mt-n5 mb-3">
                                        {agence?.image !== null &&
                                            <img class="avatar-img rounded-circle border border-white border-3" src={agence?.image} alt="" />
                                        }

                                        {agence?.image == null &&
                                            <img class="avatar-img rounded-circle border border-white border-3" src="/assets/images/avatar/placeholder.jpg" alt="" />
                                        }
                                     
                                    </div>
                                    </div>
                                    <div class="ms-sm-4 mt-sm-3">
                                    
                                    <h1 class="mb-0 h5">{agence?.raison_sociale } <i class="bi bi-patch-check-fill text-success small"></i></h1>
                                {/*  <p>250 connections</p> */}
                                    </div>
                                    
                                    <div class="d-flex mt-3 justify-content-center ms-sm-auto">
                                       
                                     {/* <button class="btn btn-info-soft me-2 btn-sm">
                                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle mx-1" viewBox="0 0 16 16">
                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                            </svg>
                                        Requête
                                    </button>  */}
                                     {statusLike=='like' && <button class="btn btn-sm btn-primary d-blockactive border" for="Interested2" onClick={UnmarkLike}><i class="fa-solid fa-thumbs-up me-1"></i> Merci</button>}
                                     {statusLike =='unlike' && <button class="btn btn-sm btn-default d-blockactive border" for="Interested2" onClick={markLike}><i class="fa-solid fa-thumbs-up me-1"></i> Merci</button>}
                                        {!signal &&

                                        <>
                                        <button class="btn btn-danger  btn-sm mx-1"  id="profileAction2" title="Signaler" data-bs-toggle="modal" data-bs-target="#modalSignalPage"><i class="bi bi-sign-stop-fill" ></i>Signaler</button>
                                           
                                        </>

                                        }
                                    
                                        
   
                             
                                 </div>
                                </div>
                                
                              <ul class="list-inline mb-0 text-center text-sm-start mt-3 mt-sm-0">
                                    <li class="list-inline-item"><i class="bi bi-telephone me-1"></i> {agence?.user?.tel}</li>
                                    <li class="list-inline-item"><i class="bi bi-geo-alt me-1"></i> {agence?.adresse}</li>
                                   
                                </ul> 
                                </div>
                                
                                <div class="card-footer mt-3 pt-2 pb-0">
                                
                                <ul class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
                                    <li class="nav-item cursor-pointer"> <a class={tabName=='publication'?"nav-link active": "nav-link "} onClick={()=>setTabName('publication')}>Publications  </a> </li>
                                    <li class="nav-item cursor-pointer"> <a class={tabName=='about'?"nav-link active": "nav-link "} onClick={()=>setTabName('about')}> A propos de nous</a> </li>
                                {/*   <li class="nav-item"> <a class="nav-link" href="my-profile-videos.html"> Videos</a> </li>
                                    <li class="nav-item"> <a class="nav-link" href="my-profile-events.html"> Events</a> </li>
                                    <li class="nav-item"> <a class="nav-link" href="my-profile-activity.html"> Activity</a> </li> */}
                                </ul>
                                </div>
                            </div>
                            
                    
                            
                            {tabName == 'publication' &&
                                <>

                   
                                
                                    {postes && 
                                        postes?.map((poste,index)=>
                                            <Poste poste={poste} index={index}/>
                                    //     <div className="card">
                                    
                                    //     <div class="card-header border-0 pb-0">
                                    //     <div class="d-flex align-items-center justify-content-between">
                                    //         <div class="d-flex align-items-center">
                                            
                                    //         <div class="avatar avatar-story me-2">
                                    //             {JSON.parse(poste.agence_with_annonce)[0]?.fields?.image == null && <a href="#!"> <img class="avatar-img rounded-circle" src="/assets/images/avatar/placeholder.jpg" alt="" /> </a>}
                                    //             {JSON.parse(poste.agence_with_annonce)[0]?.fields?.image != null && <a href="#!"> <img class="avatar-img rounded-circle" src={`${baseUrl.public}/media/${JSON.parse(poste.agence_with_annonce)[0]?.fields?.image}`} alt="" /> </a>}
                                    //         </div>
                                            
                                    //         <div>
                                    //             <div class="nav nav-divider">
                                    //         {JSON.parse(poste.agence_with_annonce)[0] && <h6 class="nav-item card-title mb-0"> <a href="#!"> {JSON.parse(poste.agence_with_annonce)[0]?.fields?.raison_sociale} </a></h6>} 
                                    //         {!JSON.parse(poste.agence_with_annonce)[0] && <h6 class="nav-item card-title mb-0"> <a href="#!"> {client.nom} {client.prenom}</a></h6>} 
                                    //             <span class="nav-item small text-secondary"> {moment(poste.created_at).fromNow()}</span>
                                    //             </div>
                                    //             {JSON.parse(poste.agence_with_annonce)[0] && <p class="mb-0 small d-none d-sm-inline-block">{JSON.parse(poste.agence_with_annonce)[0]?.fields?.info_complementaire}</p> }
                                    //         </div>
                                    //         </div>

                                    //       {favorites.indexOf(poste.id) === -1 ?

                                    //             <button class="btn btn-default py-1 px-2 border" onClick={()=>markFavorite(poste.id)}>
                                    //             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                                    //                 <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                                    //                 </svg>
                                    //             </button>
                                              
                                    //              : 
                                    //              <button class="btn btn-success py-1 px-2 border" onClick={()=>UnMarkFavorite(poste.id)}>
                                    //              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-heart" viewBox="0 0 16 16">
                                    //                  <path d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01L8 2.748zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143c.06.055.119.112.176.171a3.12 3.12 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15z"/>
                                    //                  </svg>
                                    //              </button> 
                                    //       }

                                         
                                           
                                        
                                            
                                    //     </div>
                                    //     </div>
                                    
                                    //     <div class="card-body">
                                    //     <strong>{poste.libelle} {poste?.adresse_is_coloc}<span class="badge bg-danger bg-opacity-10 text-danger mx-2">{separateNumber(poste.montant)} Fcfa</span> {poste.id != voirPLus && <button className="btn text-primary mx-3" onClick={()=>funcVoirPLus(poste.id)}>Voir plus..</button>}  </strong>
                                    //     {poste.id == voirPLus &&
                                    //         <>
                                    //             {poste.immobilier &&
                                    //                 <ul class="list-unstyled mt-0 mb-0">
                                    //                 <li class="mb-0"> Code: <strong> {poste.code} </strong> </li>
                                    //                 <li class="mb-0">  Adresse: <strong> {poste.immobilier?.commune?.libelle} {poste.immobilier?.quatier} </strong> </li>
                                    //                 <li>  Contact: <button className="btn btn-success btn-sm mx-2 p-0" onClick={()=>funcVoirTel(poste.id)}><i class="bi bi-telephone pe-1"></i>{poste.id != voirTel ? "Afficher le contact" : poste.contact}</button> </li>
                                    //                 <li class="mb-0"> Pièce(s): <strong> {poste.immobilier?.piece?.libelle} </strong> </li>
                                    //                 {poste.service?.id == 1 &&  <li class="mb-0"> Caution: <strong> {poste.immobilier?.caution} </strong> </li>}
                                                
                                    //                 <li class="mb-0"> Service: <strong> {poste.service?.libelle} </strong> </li>
                                    //                 <li class="mb-0"> Catégorie: <strong> {poste.immobilier?.category?.libelle} </strong> </li>
                                    //                 <li class="mb-0"> <p className="mt-1 border rounded p-2"><b>{poste.description}</b></p>  </li>
                                    //                 <li class="mb-0"> <button className="btn text-primary " onClick={()=>setVoirPlus(0)}>moins..</button>  </li>
                                                
                                    //             </ul>
                                    //             }

                                    //             {poste.is_coloc &&
                                    //                 <ul class="list-unstyled mt-0 mb-0">
                                    //                     <li class="mb-0"> Code: <strong> {poste.code} </strong> </li>
                                    //                     <li>  Contact: <button className="btn btn-success btn-sm mx-2 p-0" onClick={()=>funcVoirTel(poste.id)}><i class="bi bi-telephone pe-1"></i>{poste.id != voirTel ? "Afficher le contact" : poste.contact}</button> </li>
                                    //                     <li class="mb-0"> Adresse du domicile: <strong> {poste?.adresse_is_coloc} </strong> </li>
                                    //                     <li class="mb-0"> <p className="mt-1 border rounded p-2"><b>{poste.description}</b></p>  </li>
                                    //                     <li class="mb-0"> <button className="btn text-primary " onClick={()=>setVoirPlus(0)}>moins..</button>  </li>
                                    //                 </ul>
                                    //             }

                                    //             {poste.engins &&
                                    //                 <ul class="list-unstyled mt-0 mb-0">
                                    //                     <li class="mb-0"> Code: <strong> {poste.code} </strong> </li>
                                    //                     <li>  Contact: <button className="btn btn-success btn-sm mx-2 p-0" onClick={()=>funcVoirTel(poste.id)}><i class="bi bi-telephone pe-1"></i>{poste.id != voirTel ? "Afficher le contact" : poste.contact}</button> </li>
                                    //                     <li class="mb-0"> Marque: <strong> {poste.engins?.marque?.libelle} </strong> </li>
                                    //                     <li class="mb-0"> Model et année: <strong> {poste.engins?.model}, {poste.engins?.annee?.libelle} </strong> </li>
                                    //                     <li class="mb-0"> Service: <strong> {poste.service?.libelle} </strong> </li>
                                    //                     {poste.service?.id == 2 && <li class="mb-0"> Etat: <strong> {poste.engins?.etat?.libelle} </strong> </li>}
                                    //                     <li class="mb-0"> Couleur: <strong> {poste.engins?.couleur?.libelle} </strong> </li>
                                    //                     <li class="mb-0"> Boîte: <strong> {poste.engins?.boite?.libelle} </strong> -- Transmission :<strong> {poste.engins?.transmission?.libelle} </strong> -- Carburant : <strong> {poste.engins?.carburant?.libelle} </strong>  </li>
                                    //                     <li class="mb-0"> Chassis: <strong> {poste.engins?.chassis?.libelle} </strong> </li>

                                    //                     <li class="mb-0"> <p className="mt-1 border rounded p-2"><b>{poste.description}</b></p>  </li>
                                    //                     <li class="mb-0"> <button className="btn text-primary " onClick={()=>setVoirPlus(0)}>moins..</button>  </li>
                                    //                 </ul>
                                    //             }

                                    //             {poste.computer &&
                                    //                 <ul class="list-unstyled mt-0 mb-0">
                                    //                     <li class="mb-0"> Code: <strong> {poste.code} </strong> </li>
                                    //                     <li>  Contact: <button className="btn btn-success btn-sm mx-2 p-0" onClick={()=>funcVoirTel(poste.id)}><i class="bi bi-telephone pe-1"></i>{poste.id != voirTel ? "Afficher le contact" : poste.contact}</button> </li>
                                    //                     <li class="mb-0"> Marque: <strong> {poste.computer?.marque?.libelle} </strong> </li>
                                    //                     <li class="mb-0"> Model: <strong> {poste.computer?.model} </strong> </li>
                                    //                     <li class="mb-0"> Etat: <strong> {poste.computer?.etat} </strong> </li>
                                    //                     <li class="mb-0"> Couleur: <strong> {poste.computer?.couleur?.libelle} </strong> </li>
                                    //                     <li class="mb-0"> Capacité Stockage: <strong> {poste.computer?.disque}Go</strong> -- RAM : <strong> {poste.computer?.memoire}Go</strong> </li>
                                    //                     <li class="mb-0"> Processeur: <strong> {poste.computer?.processeur} </strong> -- Carte Graphique: <strong> {poste.computer?.carteGraphique ? poste.computer?.carteGraphique : "N/A"} </strong>  -- Type système : <strong> {poste.computer?.typeOs}</strong> </li>
                                    //                     <li class="mb-0"> Ecran: <strong> {poste.computer?.ecran}" </strong> </li>
                                    //                     <li class="mb-0"> Système d'exploitation: <strong> {poste.computer?.systemeOs} </strong> </li>
                                    //                     <li class="mb-0"> Autonomie: <strong> {poste.computer?.autonomie} Heures </strong> </li>
                                    //                     <li class="mb-0"> Port USB: <strong> {poste.computer?.usb} </strong> -- Clavier : <strong> {poste.computer?.clavier} </strong> -- Clavier Retro-eclairé : <strong> {poste.computer?.clavierEclaire ? "OUI" : "NON"} </strong> -- HDMI: <strong>{poste.computer?.is_hdmi? "OUI" :"NON"}</strong></li>

                                    //                     <li class="mb-0"> <p className="mt-1 border rounded p-2"><b>{poste.description}</b></p>  </li>
                                    //                     <li class="mb-0"> <button className="btn text-primary " onClick={()=>setVoirPlus(0)}>moins..</button>  </li>
                                    //                 </ul>
                                    //             }

                                    //             {poste.phone && 
                                    //                 <ul class="list-unstyled mt-0 mb-0">
                                    //                 <li class="mb-0"> Code: <strong> {poste.code} </strong> </li>
                                    //                 <li>  Contact: <button className="btn btn-success btn-sm mx-2 p-0" onClick={()=>funcVoirTel(poste.id)}><i class="bi bi-telephone pe-1"></i>{poste.id != voirTel ? "Afficher le contact" : poste.contact}</button> </li>
                                    //                 <li class="mb-0"> Marque: <strong> {poste.phone?.marque?.libelle} </strong> </li>
                                    //                 <li class="mb-0"> Model: <strong> {poste.phone?.model} </strong> </li>
                                    //                 <li class="mb-0"> Etat: <strong> {poste.phone?.etat} </strong> </li>
                                    //                 <li class="mb-0"> Couleur: <strong> {poste.phone?.couleur?.libelle} </strong> </li>
                                    //                 <li class="mb-0"> Capacité Stockage: <strong> {poste.phone?.disque}Go</strong> -- RAM : <strong> {poste.phone?.memoire}Go</strong> </li>
                                    //                 <li class="mb-0"> Processeur: <strong> {poste.phone?.processeur} </strong> -- Réseau: <strong> {poste.phone?.reseau ? poste.phone?.reseau : "N/A"} </strong>  -- Caméra : <strong> {poste.phone?.appareilPhoto}</strong> </li>
                                    //                 <li class="mb-0"> Ecran: <strong> {poste.phone?.ecran}" </strong> -- Resolution :<strong>{poste.phone?.resolution}</strong> </li>
                                    //                 <li class="mb-0"> Système d'exploitation: <strong> {poste.phone?.systemeOs} {poste.phone?.version} </strong> </li>
                                    //                 <li class="mb-0"> Batterie: <strong> {poste.phone?.batterie} mAh </strong> </li>
                                    //                 <li class="mb-0"> Emprunte Digitale: <strong>{poste.phone?.is_emprunte? "OUI" :"NON"}</strong></li>

                                    //                 <li class="mb-0"> <p className="mt-1 border rounded p-2"><b>{poste.description}</b></p>  </li>
                                    //                 <li class="mb-0"> <button className="btn text-primary " onClick={()=>setVoirPlus(0)}>moins..</button>  </li>
                                    //             </ul>
                                    //             }
                                    //         </>
                                            
                                    //     }
                                     
                                            
                                    //     {!poste.is_coloc &&
                                    //         <>
                                    //             {JSON.parse(poste.images_postes) &&
                                    //         <section className="section">
                                    //         <div className="row">
                                    //             <div className="col-lg-2"></div>
                                    //             <div className="col-lg-8">
                                    //             <div id={`carouselExample${index}`} class="carousel slide carousel-image">
                                    
                                        
                                    //                             <div class="carousel-inner">
                                    //                             {JSON.parse(poste.images_postes).map((img)=>
                                                        
                                    //                                 <div class="carousel-item active">
                                    //                                 <img src={`${baseUrl.public}/media/${img.fields.libelle}`}   class="d-block w-100" alt={poste.slug} />
                                    //                                 </div>
                                    //                                 )}
                                    //                             </div>
                                    //                             <button class="carousel-control-prev" type="button" data-bs-target={`#carouselExample${index}`} data-bs-slide="prev">
                                    //                                 <span class="carousel-control-prev-icon bg-dark" aria-hidden="true"></span>
                                    //                                 <span class="visually-hidden">Previous</span>
                                    //                             </button>
                                    //                             <button class="carousel-control-next" type="button" data-bs-target={`#carouselExample${index}`} data-bs-slide="next">
                                    //                                 <span class="carousel-control-next-icon bg-dark" aria-hidden="true"></span>
                                    //                                 <span class="visually-hidden">Next</span>
                                    //                             </button>
                                                            
                                                    
                                                    
                                    //                             </div>
                                    //             </div>
                                    //             <div className="col-lg-2"></div>
                                    //         </div>
                                    //     </section>
                                    //     }
                                    //         </>
                                    //     }
                                            
                                        
                                    
                                
                                
                                    // <ul class="nav nav-pills nav-pills-light nav-fill nav-stack small border-top border-bottom py-3 mb-3">
                                    //     <li class="nav-item">
                                    //         <a class="nav-link mb-0 active" href="#!"> <i class="bi bi-telephone pe-1"></i> ({poste.interet_click_voir_tel})</a>
                                    //     </li>
                                    
                                        
                                    //     <li class="nav-item">
                                    //         <a class="nav-link mb-0" href="#!"> <i class="bi bi-eye-fill pe-1 text-danger"></i>Vues({poste.interet_click_voir_plus})</a>
                                    //     </li>
                                    // </ul>
                                        
                                
                                        
                                    //     </div>
                                        
                                    //     </div>
                                        )
                                    }

                                    {loadingItems && <div className="text-center">Chargement...</div>}

                                    {postes?.length == 0 && !loadingItems &&
                                        <div class="tab-pane fade show" id="group-tab-6">
                                        <div class="card card-body">
                                        <div class="my-sm-5 py-sm-5 text-center">
                                        
                                            <i class="display-1 text-muted bi bi-calendar-plus"> </i>
                                        
                                            <h4 class="mt-2 mb-3 text-body">Aucune Annonces</h4>
                                            <button class="btn btn-primary-soft btn-sm" onClick={()=>navigate('/cl/')}> Page d'accueil !</button>
                                        </div>
                                        </div>
                                    </div> 
                                    }
                                </>
                            }

                            {tabName=='about' &&
                               <>
                                        <div class="card">
            
                                            <div class="card-header border-0 pb-0">
                                            <h5 class="card-title"> En savoir plus</h5> 
                                            </div>
                                        
                                            <div class="card-body">
                                            <div class="rounded border px-3 py-2 mb-3"> 
                                                <div class="d-flex align-items-center justify-content-between">
                                                <h6>Infomations complementaire</h6>
                                                
                                                </div>
                                                <p>{agence.info_complementaire}</p>
                                            </div>
                                            <div class="row g-4">
                                                <div class="col-sm-6">
                                            
                                                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                                                
                                                    <p class="mb-0">
                                                    <i class="bi bi-briefcase fa-fw me-2"></i> <strong>{agence.raison_sociale}</strong>
                                                    </p>
                                                 
                                                </div>
                                                
                                                </div>
                                                <div class="col-sm-6">
                                                
                                                <div class="d-flex align-items-center rounded border px-3 py-2"> 

                                                    <p class="mb-0">
                                                    <i class="bi bi-envelope fa-fw me-2"></i>Email: <strong> {agence.email}</strong>
                                                    </p>

                                                </div>
                                                
                                                </div>
                                                <div class="col-sm-6">
                                                
                                                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                                                
                                                    <p class="mb-0">
                                                    <i class="fa-solid fa-thumbs-up me-2 text-primary"></i> <strong className="text-primary"> {agence.likeCount} </strong>
                                                    </p>
                                                </div>
                                            
                                                </div>
                                                <div class="col-sm-6">
                                                
                                                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                                                
                                                    <p class="mb-0">
                                                        <i class="bi bi-geo-alt fa-fw me-2"></i> Lieu: <strong> {agence?.adresse} </strong>
                                                    </p>
                                                    
                                                </div>
                                            
                                                </div>
                                                <div class="col-sm-6">
                                                
                                                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                                                
                                                    <p class="mb-0">
                                                    <i class="bi bi-telephone fa-fw me-2"></i> <strong> {agence?.user?.tel} / {agence?.contact != "null" ? agence?.contact : ""} </strong>
                                                    </p>
                                                    
                                                </div>
                                                
                                                </div>
                                                <div class="col-sm-6">
                                            
                                                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                                                    
                                                    <p class="mb-0">
                                                         Pays: <strong> {agence.countrie?.libelle} </strong>
                                                    </p>
                                                   
                                                </div>
                                            
                                                </div>
                                                {agence?.reseauxSociaux &&
                                                <div class="col-sm-6">
                                                
                                                    <div class="d-flex align-items-center rounded border px-3 py-2"> 
                                                    
                                                        <p class="mb-0">
                                                        <i class="bi bi-facebook fa-fw me-2 text-primary"></i> <strong> <Link to={`${agence?.reseauxSociaux}`} target="_blank">{agence?.reseauxSociaux} </Link></strong>
                                                        </p>
                                                        
                                                    </div>
                                                
                                                </div>
                                                }
                                                {agence?.siteInternet &&
                                                <div class="col-sm-6">
                                                    <div class="d-flex align-items-center rounded border px-3 py-2"> 
                                                    
                                                        <p class="mb-0">
                                                        <i class="bi bi-globe2 fa-fw me-2"></i> <strong> <Link to={`${agence?.siteInternet}`} target="_blank">{agence?.siteInternet}</Link> </strong>
                                                        </p>
                                                        
                                                    </div>
                                                </div> 
                                                }
                                                
                                            </div>
                                            </div>
                                        
                                        </div>
                                        
                                        <div class="card">
                                        
                                            <div class="card-header d-sm-flex justify-content-between border-0 pb-0">
                                            <h5 class="card-title"><i>Secteurs d'activité</i>  </h5>
                                         {/*    <a class="btn btn-primary-soft btn-sm" href="#!"> See all</a> */}
                                            </div>
                                            
                                            <div class="card-body">
                                            <div class="row g-4">
                                                {activiteAgences?.map((activite,index)=>
                                                    <div class="col-sm-6 col-lg-4">
                                            
                                                    <div class="d-flex align-items-center position-relative">
                                                   
                                                        <div class="ms-2">
                                                            <h6 class="mb-0"> <a class="stretched-link  text-primary" href="#"> {activite.activite.libelle} </a></h6>
                                                        </div>
                                                    </div>
                                                    
                                                    </div>
                                                )}
                                                
                                            </div>
                                            </div>
                                        
                                        </div>
                               </>
                            }


                            
                           
                        
                        
                        </div>
                        
                        <div class="col-lg-3"></div>

                        {!signal &&
                        <div class="modal fade" id="modalSignalPage" tabindex="-1" aria-labelledby="modalLabelCreateAlbum" aria-hidden="true">
                             <div class="modal-dialog modal-dialog-centered">
                                 <div class="modal-content">
                                
                                     <div class="modal-header">
                                         <h5 class="modal-title" id="modalLabelCreateAlbum">Signaler cette page</h5>
                                         <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                     </div>
                                  
                                     <div class="modal-body">
                                      
                                         <div class="row g-4">
                                             
                                             <div class="col-12">
                                                 <label class="form-label">Titre</label>
                                                 <input type="text" class="form-control" name="titre" onChange={handleChangeSignal} value={signalData.titre} />
                                             </div>
                                             
                                             <div class="col-12">
                                                 <label class="form-label">Motif</label>
                                                 <textarea class="form-control" rows="2" name="motif" onChange={handleChangeSignal} value={signalData.motif}></textarea>
                                             </div>
                                         </div>
                                      
                                     </div>
                                     <div class="modal-footer">
                                         <button type="button" class="btn btn-danger-soft me-2" data-bs-dismiss="modal"> Fermer</button>
                                         <button type="button" class="btn btn-success-soft" onClick={signalPage}>Valider</button>
                                     </div>
                                 </div>
                             </div>
                         </div>
                        }
                        

                        </div> 
                    </div>
        </PrincipaleComponent>
    )
}

export default PageProDetail;