import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import moment from "moment";
import Select from "react-select";
import Validate from "../../Validation";
import Swal from "sweetalert2";
import BaseUrl from "../../BaseUrl";

// const siteUrl = 'http://127.0.0.1:8000';
const baseUrl = BaseUrl();

function MesRequetesPage({agence}){

    function separateNumber(number) { 
        // Vérifier si le nombre est un entier positif
        if (Number.isInteger(number) && number >= 0) {
          // Convertir le nombre en chaîne de caractères
          var numberString = number.toString();
            
          // Séparer les chiffres en centaines
          var separatedNumber = '';
          for (var i = numberString.length - 1; i >= 0; i--) {
            separatedNumber = numberString.charAt(i) + separatedNumber;
            if (i > 0 && (numberString.length - i) % 3 === 0) {
              separatedNumber = ',' + separatedNumber;
            }
          }
      
          return separatedNumber;
        } else {
          return 'Veuillez fournir un entier positif.';
        }
    }

      const navigate = useNavigate();
      const [mesRequetes,setMesRequetes]= useState([]);
      const [loadingRequete,setLoadingRequete] = useState(false);
      const [mesPostes,setMesPostes] = useState([]);
      const [selectedOptionPoste,setSelectedOptionPoste] = useState([]);
      const [notes,setNotes] = useState('');

      const [loadingRepondre,setLoadingRepondre] = useState(false);
      const [errorRecommandation,setErrorRecommandation] = useState('');
      const [errors,setErrorM] = useState({});
      const [chooseAbonne,setChooseAbonne] = useState('');
      const [myAbonne,setMyAbonne] = useState([]);
      const [listMaterielTab,setListMaterielTab] = useState([]);
      const [indexModal,setIndexModal] = useState();
      const [query,setQuery] = useState([]);

      useEffect(()=>{
        mesQuery();
        if (agence && agence.user && agence.user.id) {
            try {
              axios.get(baseUrl.base+'/postes/?pro=' + agence.user.id).then((resp)=>{
                setMesPostes(resp.data);
              });
              
            } catch (error) {
              console.log(error);
            }

            try {
              axios.get(baseUrl.base+'/my-abonnes/?agence='+agence.id).then((resp)=>{
                if(resp.data.bool == false){
                  setMyAbonne([]);
              }else{
                  setMyAbonne(resp.data[0]);
              }
              })
            } catch (error) {
              console.log(error);
            }
        }

      },[agence]);

      const mesQuery =()=>{
        if(agence && agence.id){
          setLoadingRequete(true);
            try {
                axios.get(baseUrl.base+'/mes-requetes-agence/?agence='+agence.id).then((resp)=>{
                    setMesRequetes(resp.data);
                    setLoadingRequete(false);
                })
            } catch (error) {
                console.log(error);
            }
        }
      }

      const DeleteQueryUser =(id)=>{
         try {
          axios.get(baseUrl.base+'/ignore-query-pro/'+id+'/').then((resp)=>{
            mesQuery();
          })
        } catch (error) {
          console.log(error);
        } 
      }

      function isNewProduct(createdDate) {
        const currentDate = new Date();
        const differenceInTime = currentDate.getTime() - Date.parse(createdDate);
        const differenceInDays = differenceInTime / (1000 * 3600 * 24);
        return differenceInDays <= 3;
      }

      // const handleChangeMinchou=(event)=>{
      //   setMinchouData({
      //     ...minchouData,
      //     [event.target.name]:event.target.value
      //   })
      // }

      const optionPostes = mesPostes?.map((option)=>(({
        value:`${option.id}`,
        label:`${option.libelle}`
      })));

      const handleChangeSeletedOptionPoste=(selectedOption)=>{
        setSelectedOptionPoste(selectedOption);
        setErrorRecommandation('');
      }

      const submitMinchou=(minyai,requete)=>{
        setErrorRecommandation('');
        // setErrorM(Validate(minchouData));
        const _fromData = new FormData();

        if(listMaterielTab.length > 0){
          const recommandation = listMaterielTab.map((option)=>option.poste_id);
          // if(minchouData.notes !=''){
            // _fromData.append('notes',minchouData.notes);
            _fromData.append('agence',agence.id);
            _fromData.append('requete',requete);
            _fromData.append('minyai',minyai);
            _fromData.append('postes',recommandation);
           
            _fromData.append("abonnement",chooseAbonne);
            

             setLoadingRepondre(true);
            try {
              axios.post(baseUrl.base+'/reponse-requetes/',_fromData).then((resp)=>{
                setLoadingRepondre(false);
                if(resp.data.bool){
                  Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: resp.data.msg,
                    showConfirmButton: false,
                    timer: 3000,
                    toast:true,
                    position:'top-right',
                    timerProgressBar:true
                  });

                  mesQuery();
                  // setMinchouData({
                  //   'notes':''
                  // });
                  
                  window.$(`#modalReponseRequete`).modal("hide");

                  Swal.fire({
                    title: 'FELICITATION',
                    text: 'Votre reponse a été envoyée avec succès !',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                  }); 
                
                }else{
                  Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: resp.data.msg,
                    showConfirmButton: false,
                    timer: 3000,
                    toast:true,
                    position:'top-right',
                    timerProgressBar:true
                  });
                }
              })
            } catch (error) {
              console.log(error);
            } 


            //console.log(_fromData);
          
        }else{
          setErrorRecommandation('Vous devez faire au moins une(1) recommandation ');
        }


      }

      const RepondrerequeteFunctionBesoin=(data)=>{
        console.log(data.requete?.emailProspect);
        if(data.requete?.emailProspect == "" || data.requete?.emailProspect==null)
        {
          Swal.fire({
            icon: 'info',
            title: "Le client dispose pas de mail. Veuillez le contacter au "+data.requete?.phoneProspect,
          });
          return;
        }
        setQuery(data)
        window.$(`#modalReponseRequete`).modal('show');
      }

      const RepondrerequeteFunction=(data)=>{
        setQuery(data)
        window.$(`#modalReponseRequete`).modal('show');
      }


      const chooseAbonneBtn=(abonne)=>{
        if(abonne == chooseAbonne){
            setChooseAbonne('');
        }else{
          setChooseAbonne(abonne);
        }
      }

      const handleChangePreviewPost=(option)=>{
        let choose = mesPostes.find((post)=> post.id == option.value);
        setErrorRecommandation('');
        if(listMaterielTab.find((list)=> list.poste_id === choose.id)){
          console.log("oops")
        }else{
          const itemTable = {
            "image":`${baseUrl.public}/media/${JSON.parse(choose?.images_postes)[0]?.fields.libelle}`,
            "libelle":`${choose.libelle}-${choose?.immobilier?.piece?.libelle}-${choose?.immobilier?.quatier}`,
            "montant":`${choose.montant}`,
            "poste_id":choose.id,  
          }
  
          setListMaterielTab([...listMaterielTab,itemTable]);
        }
      }
      const removeItem = (i) => {
        const updatedMat = [...listMaterielTab];
        updatedMat.splice(i, 1);
        setListMaterielTab(updatedMat);
      };
    return (
      <>
           <div class="card">
              
              <div class="card-header border-0 pb-0">
               <h6 class="card-title text-dark"> <b className="text-success">{mesRequetes.length}</b> préoccupations clients <button className="btn btn-primary btn-sm p-1 float-end" onClick={()=>mesQuery()}><i class="fa-solid fa-arrows-rotate"></i></button></h6>
             </div> 
             <div class="card-body">
               
               <div class="accordion rounded " id={`communications`}>
               {loadingRequete && <h4 class="mt-2 mb-3 text-body">Chargement...</h4>}
               {mesRequetes.length == 0 && <h3>Aucune requête pour le moment.</h3>}
               {mesRequetes.map((query,index)=>
                     <div class="accordion-item bg-light border my-3">
                     <h2 class="accordion-header" id={`communicationOne${index}`}>
                       <button class="accordion-button mb-0 collapsed h6 " type="button" data-bs-toggle="collapse" data-bs-target={`#communicationcollapseOne${index}`} aria-expanded="true" aria-controls={`communicationcollapseOne${index}`}>
                        <h6 className="text-dark">{query.requete?.objet} {isNewProduct(query.requete?.created_at) && <i className="text-danger float-end" style={{fontSize:"10px"}}>(Nouveau)</i>} </h6>  
                       </button>
                        
                     </h2>
               
                     <div id={`communicationcollapseOne${index}`} class="accordion-collapse collapse " aria-labelledby={`communicationOne${index}`} data-bs-parent={`#communicationOne${index}`}>
                       <div class="accordion-body ">
                           <div className="row">
                               <div className="col-md-12 px-3 py-2 mb-3 rounded border card">
                               {query.requete?.prospect ?
                                   <ul class="list-unstyled mt-0 mb-0">
                                       <li class="mb-0">Ref : <strong>#{query.requete?.reference}</strong></li>
                                       <li class="mb-0">Durée :<strong className="text-success"> {moment(query.requete?.created_at).fromNow()}</strong></li>
                                       <li class="mb-0">Client :<strong> {query.requete?.prospect}</strong></li>
                                       <li class="mb-0">Besoin du client :<strong> {query.requete?.objet}</strong></li>
                                       <li class="mb-0">Budget :<strong> {separateNumber(query.requete?.bg_max)} Fcfa</strong></li>
                                       <li class="mb-0">Service :<strong> {query.requete?.service}</strong></li>
     
                                   </ul>
                                  : 
                                  <ul class="list-unstyled mt-0 mb-0">
                                  <li class="mb-0">Ref : <strong>#{query.requete?.reference}</strong></li>
                                  <li class="mb-0">Durée :<strong className="text-success"> {moment(query.requete?.created_at).fromNow()}</strong></li>
                                  <li class="mb-0">Titre :<strong> {query.requete?.objet}</strong></li>
                                  <li class="mb-0">Budget Min :<strong> {separateNumber(query.requete?.bg_min)} Fcfa</strong></li>
                                  <li class="mb-0">Budget Max :<strong> {separateNumber(query.requete?.bg_max)} Fcfa</strong></li>
                                  <li class="mb-0">Service :<strong> {query.requete?.service}</strong></li>
                                  <li class="mb-0">Catégorie :<strong> {query.requete?.categorie?.libelle}</strong></li>

                              </ul>
                                  }
                               </div>
     
                       
                           </div>
                           <div className="row">
                                 <div className="col-md-12 px-3 py-2 mb-3 rounded border card">
                                     <ul class="list-unstyled mt-0 mb-0">
                                         <li class="mb-0"><strong className="text-primary"><u>#Plus de detail</u></strong></li>
                             
                                     {query.requete?.categorie && query.requete?.categorie?.id == 6 | query.requete?.categorie?.id == 2 | query.requete?.categorie?.id == 1 | query.requete?.categorie?.id == 5 | query.requete?.categorie?.id == 9 | query.requete?.categorie?.id == 10 ?
                                         <>
                                            {query.requete?.categorie?.id !== 10 && <li class="mb-0">Nombre de pièce:<strong> {query.requete?.piece?.libelle}</strong></li>} 
                                             <li class="mb-0">Commune :<strong> {query.requete?.commune?.libelle}</strong></li>
                                             <li class="mb-0">Zone visée:<strong> {query.requete?.zoneVise}</strong></li>
                                             <li class="mb-0">Contact du client:<strong> {query.requete?.user?.tel}</strong></li>
                                         </>
                                         :""
                                     }

                                    {query.requete?.prospect &&
                                      <>
                                        <li class="mb-0">Nombre de pièce:<strong> {query.requete?.piece?.libelle}</strong></li>
                                        <li class="mb-0">Commune:<strong> {query.requete?.commune?.libelle}</strong></li>
                                        <b class="mb-0">Contact du client: <strong className="text-dark">{query.requete.phoneProspect}</strong></b>
                                      </>
                                    }
     
                                    
     
     
     
                                    
     
                                     </ul>
                                 </div>
                           </div>
                           {/* <div className="row">
                             <div className="col-md-12 px-3 py-2 mb-3 rounded border card">
                                     <strong className="text-primary"><u>#Description </u></strong>
                                 <p className="">{query.requete?.description}</p> 
                             </div>
                           </div> */}

                        {!query.requete?.prospect ?
                           <button class="btn btn-info me-2 btn-sm col-sm-6 col-lg-6 col-md-6" id="profileAction2" onClick={()=>RepondrerequeteFunction(query,index)}  ><i className=""></i>Repondre</button>
                        :
                        <button class="btn btn-info me-2 btn-sm col-sm-6 col-lg-6 col-md-6" id="profileAction2" onClick={()=>RepondrerequeteFunctionBesoin(query,index)}  ><i className=""></i>Repondre</button>
                        }      
                           <button class="btn btn-danger btn-sm col-sm-4 col-lg-4 col-md-4 col-" onClick={()=>DeleteQueryUser(query.id)}>Ignorer</button>
     
                       
                          
                       </div>
                     </div>
                   </div>
                 
                 )}
             
                
               
                
               </div>
               
           
             </div>
           
                 </div>
                       {/* Modal reponse */}
                       <div class="modal fade" id={`modalReponseRequete`} tabindex="-1" aria-labelledby="modalLabelCreateAlbum" aria-hidden="true">
                                  <div class="modal-dialog modal-lg">
                                      <div class="modal-content">
                                      
                                          <div class="modal-header">
                                              <span class="modal-title" id="modalLabelCreateAlbum"><strong>{query.requete?.objet}</strong></span>
                                              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                          </div>
                                        
                                          <div class="modal-body">
                                            
                                              <div class="row g-4">
                                                  
                                                  {/* <div class="col-12">
                                                      <label class="form-label">Votre Commentaire</label>
                                                      <textarea class="form-control" rows="1" name="notes" onChange={handleChangeMinchou} value={minchouData.notes}></textarea>
                                                      {errors.notes && <span className="text-danger">{errors.notes}</span>}
                                                  </div> */}

                                                  <div class="col-12 mb-3">
                                                      <label class="form-label">Recommandations d'annonces</label>
                                                      <Select options={optionPostes}  onChange={handleChangePreviewPost} isOptionDisabled={()=>listMaterielTab.length >= 2}/>
                                                      {errorRecommandation && <span className="text-danger">{errorRecommandation}</span>}
                                                  </div>

                                


                                              </div>
{/* 
                                            {!myAbonne?.etat  && 
                                            <>
                                               <hr/>
                                                <div class="col-lg-12 mx-auto mt-3">
                                                  <div class="vstack gap-4">
                                                  
                                                    <div class="card card-body bg-warning">
                                                      <div class="d-md-flex justify-content-between align-items-start">
                                                      
                                                        <div class="mb-2 mb-md-0">
                                                          <h5 class="mb-1"> <a href="#!">Abonnement Ekeu (5.000 Fcfa)</a></h5>
                                                          <p class="small mb-0"><strong>Profiter de 300 réponses pendant 6 mois </strong></p>
                                                        </div>
                                                      
                                                        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                        <button class={chooseAbonne == 'FORFAIT EKEU' ? "btn btn-sm btn-danger border" : "btn btn-sm btn-white border" }  for="Interested2" onClick={()=>chooseAbonneBtn('FORFAIT EKEU')}><i className="fa-solid fa-thumbs-up me-1"></i> Abonner</button>
                                                        </div>
                                                      </div>
                                                    </div>  
                                                  
                                                    <div class="card card-body bg-warning">
                                                      <div class="d-md-flex justify-content-between align-items-start">
                                                      
                                                        <div class="mb-2 mb-md-0">
                                                          <h5 class="mb-1"> <a href="#!">Abonnement Lite (10.000 Fcfa)</a></h5>
                                                          <p class="small mb-0"> <strong> Profiter de 800 réponses pendant 6 mois</strong> </p>
                                                        </div>
                                                      
                                                        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                        
                                                        <button class={chooseAbonne == 'FORFAIT LITE' ? "btn btn-sm btn-danger border" : "btn btn-sm btn-white border" }   for="Interested2" onClick={()=>chooseAbonneBtn('FORFAIT LITE')}><i className="fa-solid fa-thumbs-up me-1"></i> Abonner</button>
                                                        </div>
                                                      </div>
                                                    </div>  
                                                  
                                                    <div class="card card-body bg-warning">
                                                      <div class="d-md-flex justify-content-between align-items-start">
                                                    
                                                        <div class="mb-3 mb-md-0">
                                                          <h5 class="mb-1"> <a href="#!">Abonnement Pro (30.000 Fcfa)</a></h5>
                                                          <p class="small mb-0"><strong>Profiter des réponses illimitées et alerte SMS pendant 1 ans </strong></p>
                                                        </div>
                                                    
                                                        <div class="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                        
                                                          <button class={chooseAbonne == 'FORFAIT PRO' ? "btn btn-sm btn-danger border" : "btn btn-sm btn-white border" }   for="Interested2" onClick={()=>chooseAbonneBtn('FORFAIT PRO')}><i className="fa-solid fa-thumbs-up me-1"></i> Abonner</button>
                                                        </div>
                                                      </div>
                                                    </div>  
                                                  
                                                    
                                                  </div>
                                                </div>
                                            </>
                                             
                                            } */}
                                               <div class="table-responsive ">
                                                <table class="table table-centered text-nowrap mb-0">
                                                  <thead class="table-success">
                                                    <tr>

                                                      {/* <th className="text-center">N°</th> */}
                                                      <th >ANNONCES</th>
                                                      <th className="w-50 text-center">Montant</th>
                                                          {/*<th className="w-50">Dépot de destination</th> */}
                                                      <th className="text-center">Action</th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    {listMaterielTab && 
                                                      listMaterielTab.map((item,index)=>
                                                        <tr key={index}>
                                                            {/* <td className="text-center">{index+1}</td> */}
                                                            <td>
                                                              <div class="d-flex align-items-center">
                                                                <a href="#!"><img src={`${item.image}`} alt="Image" class="img-4by3-md rounded-3" width="50" height="50"/></a>
                                                                <div class="ms-3">
                                                                  <span class="mb-0">{item.libelle}</span>                                      
                                                                </div>
                                                              </div>
                                                            </td>
                                                            <td className="text-center">{item.montant}</td>

                                                            <td className="text-center">
                                                              <button type="button" className="btn btn-danger btn-sm p-0" onClick={()=>removeItem(index)}> <i className="fa fa-trash"></i> </button>
                                                            </td>
                                                        </tr>
                                                      )
                                                    }
                                                  
                                                  


                                                  </tbody>
                                                </table>
                                              </div>
                                             
                                            
                                          </div>
                                          <div class="modal-footer">
                                              
                                              
                                              {!loadingRepondre && 
                                              <>
                                                <button type="button" class="btn btn-danger-soft me-2" data-bs-dismiss="modal"> Fermer</button>
                                                 <button type="button" class="btn btn-success" onClick={()=>submitMinchou(query.id,query.requete?.id)}>Valider</button>
                                              </>
                                             
                                              }
                                              {/* {!loadingRepondre && !myAbonne?.etat && <button type="button" class={chooseAbonne !="" ? "btn btn-success":"btn btn-success disabled"} onClick={()=>submitMinchou(query.id,query.requete?.id,index)}>Valider</button>} */}
                                              {loadingRepondre && <button type="button" class="btn btn-success form-control" disabled>Reponse en cours d'envoie ...</button>}
                                          </div>
                                      </div>
                                  </div>
                              </div>
      </>
   
    )
}

export default MesRequetesPage;