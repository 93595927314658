import { useEffect, useState } from "react";
import PrincipaleComponent from "./PrincipalComponent";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "./BaseU";
import axios from "axios";
import moment from "moment";
import NavUser from "./compo/navUser";
import BaseUrl from "../../BaseUrl";

// const siteUrl = 'http://127.0.0.1:8000';
const baseUrl = BaseUrl();

function MesRequetes(){
    function separateNumber(number) {
        // Vérifier si le nombre est un entier positif
        if (Number.isInteger(number) && number >= 0) {
          // Convertir le nombre en chaîne de caractères
          var numberString = number.toString();
            
          // Séparer les chiffres en centaines
          var separatedNumber = '';
          for (var i = numberString.length - 1; i >= 0; i--) {
            separatedNumber = numberString.charAt(i) + separatedNumber;
            if (i > 0 && (numberString.length - i) % 3 === 0) {
              separatedNumber = ',' + separatedNumber;
            }
          }
      
          return separatedNumber;
        } else {
          return 'Veuillez fournir un entier positif.';
        }
      }

      const client = AuthContext();
      const navigate = useNavigate();
      const [mesRequetes,setMesRequetes]= useState([]);
      const [loadingRequete,setLoadingRequete] = useState(false);

      useEffect(()=>{
        mesQuery();
      },[client]);

      const mesQuery =()=>{
        if(client && client.id){
          setLoadingRequete(true);
            try {
                axios.get(baseUrl.base+'/all-requetes/?user='+client.id).then((resp)=>{
                    setMesRequetes(resp.data);
                    setLoadingRequete(false);
                })
            } catch (error) {
                console.log(error);
            }
        }
      }


      const DeleteQueryUser =(id)=>{
        try {
          axios.get(baseUrl.base+'/delete-query-user/'+id+'/').then((resp)=>{
            try {
              axios.get(baseUrl.base+'/all-requetes/?user='+client.id).then((resp)=>{
                  setMesRequetes(resp.data);
                })
            } catch (error) {
                console.log(error);
            }
          })
        } catch (error) {
          console.log(error);
        }
      }



    return (
        <PrincipaleComponent>
        <div class="container">
                 <div class="row g-4">
                
                 {/* Navbar de l'utilisateurs */}
                 <NavUser/>
                {/* end Navbar de l'utilisateur */}
                 
                 <div class="col-md-6 col-lg-6 vstack gap-4">
            
                     
                     <div class="card">
                 
                      {/* <div class="h-200px rounded-top banner-profil" ></div>  */}
                         
                         <div class="card-body py-0">
                         <div class="d-sm-flex align-items-start text-center text-sm-start">
                           
                             <div class="ms-sm-4 mt-sm-3">
                             
                             <h1 class="mb-0 h5 mt-2">Requêtes éffectuée </h1>
                         {/*  <p>250 connections</p> */}
                             </div>
                             
                      
                         </div>
                         
                         </div>

                         <div class="card-footer mt-3 pt-2 pb-0">
                                
                                <ul class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
                                    <li class="nav-item"> <a class="nav-link active" >Mes requêtes ({mesRequetes.length}) </a> </li>
                              
                                    {/* <li class="nav-item"> <a class="nav-link" href="my-profile-events.html"> Events</a> </li>
                                    <li class="nav-item"> <a class="nav-link" href="my-profile-activity.html"> Activity</a> </li> */}
                                </ul>
                            </div>
                         
                       
                     </div>
                                
                                
                    {/*  {isLoading && <h4 class="mt-2 mb-3 text-body">Chargement...</h4>} */}

                    <div class="card">
              
                
                <div class="card-body border">
                
                  <div class="accordion  rounded" id={`communications`}>
                  {loadingRequete && <h4 class="mt-2 mb-3 text-body">Chargement...</h4>}
                  {mesRequetes.map((query,index)=>
                        <div class={query.status ? "accordion-item bg-light border my-3" : "accordion-item bg-light border my-3 disable"}>
                        <h2 class="accordion-header" id={`communicationOne${index}`}>
                          <button class="accordion-button mb-0 collapsed h6 text-dark" type="button" data-bs-toggle="collapse" data-bs-target={`#communicationcollapseOne${index}`} aria-expanded="true" aria-controls={`communicationcollapseOne${index}`}>
                           {query.objet}
                          </button>
                          
                        </h2>
                  
                        <div id={`communicationcollapseOne${index}`} class="accordion-collapse collapse " aria-labelledby={`communicationOne${index}`} data-bs-parent={`#communicationOne${index}`}>
                          <div class="accordion-body ">
                              <div className="row">
                                  <div className="col-md-12 px-3 py-2 mb-3 rounded border card">
                                      <ul class="list-unstyled mt-0 mb-0">
                                          <li class="mb-0">Ref : <strong>#{query.reference}</strong></li>
                                          <li class="mb-0">Durée :<strong className="text-success"> {moment(query.created_at).fromNow()}</strong></li>
                                          <li class="mb-0">Budget Min :<strong> {separateNumber(query.bg_min)} Fcfa</strong></li>
                                          <li class="mb-0">Budget Max :<strong> {separateNumber(query.bg_max)} Fcfa</strong></li>
                                          <li class="mb-0">Service :<strong> {query.service}</strong></li>
                                          <li class="mb-0">Catégorie :<strong> {query.categorie?.libelle}</strong></li>
  
                                      </ul>
                                  </div>
  
                                 
                              </div>
                              <div className="row">
                                    <div className="col-md-12 px-3 py-2 mb-3 rounded border card">
                                        <ul class="list-unstyled mt-0 mb-0">
                                            <li class="mb-0"><strong className="text-primary"><u>#Plus de detail</u></strong></li>
                                            {query.categorie.id == 3  &&
                                            <>
                                                <li class="mb-0">Etat:<strong> {query.etatEngins?.libelle}</strong></li>
                                                <li class="mb-0">Marque :<strong> {query.marque?.libelle}</strong></li>
                                                <li class="mb-0">Type de boîte:<strong> {query.boite?.libelle}</strong></li>
                                                <li class="mb-0">Carburant:<strong>{query.carburant?.libelle}</strong></li>
                                                <li class="mb-0">Chassis:<strong>{query.chassis?.libelle}</strong></li>
                                            </>
                                        }
                                        {query.categorie && query.categorie.id == 6 | query.categorie.id == 2 | query.categorie.id == 1 | query.categorie.id == 5 | query.categorie.id == 9 | query.categorie.id == 10 ?
                                            <>
                                                {query.categorie.id !== 10 && <li class="mb-0">Nombre de pièce:<strong> {query.piece?.libelle}</strong></li> }
                                                <li class="mb-0">Commune :<strong> {query.commune?.libelle}</strong></li>
                                                <li class="mb-0">Zone visée:<strong> {query.zoneVise}</strong></li>
                                            </>
                                            :""
                                        }

                                        {query.categorie.id ==7 | query.categorie.id == 8 ?
                                            <>
                                                 <li class="mb-0">Marque:<strong> {query.marque?.libelle}</strong></li>
                                                <li class="mb-0">Etat :<strong> {query.etatOthers}</strong> | Quantité :<strong> {query.quantite}</strong></li>
                                                <li class="mb-0">Stockage Min:<strong> {query.rom_min} Go</strong> | Stockage Max:<strong> {query.rom_max} Go</strong></li>
                                                <li class="mb-0">Ram Min:<strong> {query.ram_min} Go</strong> | Ram Max:<strong> {query.ram_max} Go</strong></li>
                                            </>
                                            :""
                                        }



                                       

                                        </ul>
                                    </div>
                              </div> 
                              {/* <div className="row">
                                  <div className="col-md-12 px-3 py-2 mb-3 rounded border card">
                                      <strong className="text-primary"><u>#Description </u></strong>
                                      <p className="">{query.description}</p> 
                                  </div>
                                </div> */}

                              <button class="btn btn-info me-2 btn-sm form-control" onClick={()=>navigate(`/mes-reponses-obtenues-client-aux-requetes/postes?rep=${query.id}`,{state:{requete: query?.objet}})}>Reponse Obtenue <b className="text-dark">({query.total_reponses_by_query})</b></button>
                              {/* <button class="btn btn-danger me-2 btn-sm" onClick={()=>DeleteQueryUser(query.id)}>Supprimer</button> */}

                          
                             
                          </div>
                        </div>
                      </div>
                    
                    )}
                
                   
                  
                   
                  </div>
                  
              
                </div>
             
                    </div>
             

                    
                    
                  
                 
                 </div>
                 
                 <div class="col-lg-3"></div>
                 

                 </div> 
             </div>
        </PrincipaleComponent>
    )
}

export default MesRequetes;