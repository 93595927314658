import logo from './logo.svg';
import Main from './components/Main';
/* import '../public/assets/js/main.js'; */
import moment from 'moment';
import 'moment/locale/fr';

moment.locale('fr')

function App() {
  return (
    
    <div className="App">
     <Main />
    </div>

  );
}

export default App;
