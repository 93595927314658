import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import PrincipaleComponent from "./PrincipalComponent";
import BaseUrl from "../../BaseUrl";

const baseUrl = BaseUrl();
function NotificationClientList(){
    const params = new URLSearchParams(window.location.search);
    const valeur = params.get('client');
    const location = useLocation();
    const [notifications,setNotifications] = useState([]);
    const [loadingNotif,setLoadingNotif] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
        notifAll();
        
    },[valeur]);

    const notifAll=()=>{
        setLoadingNotif(true);
        try {
             axios.get(baseUrl.base+'/notif-list/?clientId='+valeur+'&tous').then((resp)=>{
                    setNotifications(resp.data);
                    setLoadingNotif(false);
                });

                axios.get(baseUrl.base+'/unread-notif-users/'+valeur+'/').then((resp)=>{
                    if(resp.data.bool){}
                })
        } catch (error) {
            console.log(error);
        }
    }

    const ReadingNotification=(subject,notifId)=>{
    
        try {

            
            axios.get(baseUrl.base+'/notif-list/?user='+valeur+'&notifId='+notifId).then((resp)=>{
                setNotifications(resp.data);
                //setLoadingNotif(false);
                
            });
            navigate('/cl/');   
            
                    
               
        } catch (error) {
            console.log(error);
        } 
       
        
        
    }


    const ToutMarkasLu=()=>{
        setLoadingNotif(true);
        try {
            axios.get(baseUrl.base+'/see-all-notif-users/'+valeur+'/').then((resp)=>{
                if(resp.data.bool){
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Bien merci !',
                        showConfirmButton: false,
                        timer: 3000,
                        toast:true,
                        position:'top-right',
                        timerProgressBar:true
                      });
                    axios.get(baseUrl.base+'/notif-list/?client='+valeur).then((resp)=>{
                        setNotifications(resp.data);
                        setLoadingNotif(false);
                    });
                }else{
                    Swal.fire({
                        position: 'top-end',
                        icon: 'warning',
                        title: 'Tout est deja lu merci !',
                        showConfirmButton: false,
                        timer: 3000,
                        toast:true,
                        position:'top-right',
                        timerProgressBar:true
                      });
                      setLoadingNotif(false);
                }
            })
        } catch (error) {
            console.log(error);
        }
    }
    
    const NotReadNotification=()=>{
        setLoadingNotif(true);
        try {
            axios.get(baseUrl.base+'/notif-list/?userId='+valeur+'&nonlu').then((resp)=>{
                setNotifications(resp.data);
                setLoadingNotif(false);
            })
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <PrincipaleComponent>
                    <div class="container">
                <div class="row g-4">
                
                <div class="col-lg-8 mx-auto">
                
                    <div class="card">
                    <div class="card-header py-3 border-0 d-flex align-items-center justify-content-between bg-warning">
                        <h1 class="h5 mb-0">Notifications</h1>
                    
                        <div class="dropdown">
                        <a href="#" class="text-secondary btn btn-secondary-soft-hover py-1 px-2" id="cardNotiAction" data-bs-toggle="dropdown" aria-expanded="false">
                            <i class="bi bi-three-dots"></i>
                        </a>
                        
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="cardNotiAction">
                            <li><a class="dropdown-item" href="#" onClick={()=>ToutMarkasLu()}> <i class="bi bi-check-lg fa-fw pe-2"></i>Tout marquer comme lu</a></li>
                            <li><a class="dropdown-item" href="#" onClick={()=>NotReadNotification()}> <i class="bi bi-bell fa-fw pe-2"></i>Non lu</a></li>
                            <li><a class="dropdown-item" href="#" onClick={()=>notifAll()}> <i class="bi bi-check-all fa-fw pe-2"></i>Tout</a></li>
                        </ul>
                        </div>
                    
                    </div>
                    <div class="card-body p-2">
                        <ul class="list-group list-group-flush list-unstyled">
                        
                        {loadingNotif && <span className="text-success">En cours de chargement...</span>}
                        {notifications.length == 0 && !loadingNotif && <h3 className="text-center">Aucun résultat...</h3>}
                                {notifications &&
                                    notifications.map((notif,index)=>
                                    <li>
                                       
                                             <button onClick={()=>ReadingNotification(notif.notif_subject,notif.id)} class={notif.read_status==false ? "list-group-item list-group-item-action badge-unread rounded d-flex border-0 p-3 mb-1" : "list-group-item list-group-item-action rounded d-flex border-0 p-3 mb-1 " }>

                                             <div class="ms-sm-3 d-flex">
                                                 <p class="small mb-2"><b>{notif.titre}</b> {notif.body}.<br/><span className="text-info">{moment(notif.created_at).fromNow()}</span></p>
                                                 {/* <p class="small ms-3"></p> */}
                                             </div>
                                             </button>
                                        
                                   
                                    </li>
                                    )
                                }
                        
                     
                        </ul>
                    </div>
                    {/* <div class="card-footer border-0 py-3 text-center position-relative d-grid pt-0">
                    
                        <a href="#!" role="button" class="btn btn-loader btn-primary-soft" data-bs-toggle="button" aria-pressed="true">
                        <span class="load-text"> Load more notifications </span>
                        <div class="load-icon">
                            <div class="spinner-grow spinner-grow-sm" role="status">
                            <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                        </a>
                    
                    </div> */}
                    </div>
                
                </div>
                </div> 
            </div>
        </PrincipaleComponent>
    )
    


}






export default NotificationClientList;