import Header from "./Header";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import AuthContextAgence from "./Base";
import BaseUrl from "../../BaseUrl";

const baseUrl = BaseUrl();


function PrincipaleComponentAgence({children}){
    const [isAuthToken, setIsAuthToken] = useState(localStorage.getItem('_token'));
    const [agence,setAgence] = useState([]);
    const navigate = useNavigate();

    useEffect(()=>{
        if(isAuthToken == null){
            localStorage.removeItem('_token');
            navigate('/auth-pro-account/');
        }
        if(isAuthToken !== null){
            try {
                axios.get(baseUrl.base+'/agence/?token='+isAuthToken,{
                    headers:{
                        'Content-Type':'application/json' 
                    },
                    credentials:'include'
                }).then((resp)=>{
                    if(resp.data.failed == true){
                        setIsAuthToken(null);
                        setAgence(null);
                        localStorage.removeItem('_token');
                        navigate('/auth-pro-account/');
                    }
                    setAgence(resp.data[0]);
                })
            } catch (error) {
                console.log(error);
            }
        }

    },[]);

    const logout = ()=>{
        setIsAuthToken(null);
        setAgence(null);
        localStorage.removeItem('_token');
        navigate('/auth-pro-account/');
    }

   return ( 
            <>
                <Header agence={agence} logout={logout}/>
                <main>
                 
                    {children}
                    
                </main>
            </>
    )
}

export default PrincipaleComponentAgence;