import { Link, useNavigate } from "react-router-dom";

function HeaderOff()
{
    const navigate = useNavigate();
    return (
        <>
                    <header class="navbar-light fixed-top header-static bg-mode">


<nav class="navbar navbar-expand-lg">
    <div class="container">
    
        <Link class="navbar-brand" to="/">
            <img class="light-mode-item navbar-brand-item" src="/assets/logo-winin.png" alt="logo" />
            {/* <img class="dark-mode-item navbar-brand-item" src="/assets/images/logo.svg" alt="logo" /> */}
        </Link>
    


        <button class="ms-auto btn btn-primary fw-bold" onClick={()=>navigate("/login")}>
            Se connecter
        </button>

    
        <ul class="nav flex-nowrap align-items-center ms-sm-3 list-unstyled">
            

    


    
    
        </ul>
    
    </div>
</nav>

</header>
        </>
    )
}

export default HeaderOff;