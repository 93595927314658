import { useEffect, useState } from "react";
import PrincipaleComponentAgence from "./PrincipalComponentAgence";
import AuthContextAgence from "./Base";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PostMaison from "./createAn/PostMaison";
import PostColoc from "./createAn/PostColoc";
import PostEngin from "./createAn/PostEngin";
import axios from "axios";
import DetailComponentPoste from "./createAn/DetailPoste";
import UpdateComponentPost from "./createAn/UpdateMaison";
import Swal from "sweetalert2";
import PostOrdinateur from "./createAn/PostOrdinateur";
import PostPhone from "./createAn/PostPhone";
import BaseUrl from "../../BaseUrl";
import moment from "moment";

const baseUrl = BaseUrl();
function CreatePosts(){
  function separateNumber(number) {
    // Vérifier si le nombre est un entier positif
    if (Number.isInteger(number) && number >= 0) {
      // Convertir le nombre en chaîne de caractères
      var numberString = number.toString();
  
      // Séparer les chiffres en centaines
      var separatedNumber = '';
      for (var i = numberString.length - 1; i >= 0; i--) {
        separatedNumber = numberString.charAt(i) + separatedNumber;
        if (i > 0 && (numberString.length - i) % 3 === 0) {
          separatedNumber = ',' + separatedNumber;
        }
      }
  
      return separatedNumber;
    } else {
      return 'Veuillez fournir un entier positif.';
    }
}
  const navigate = useNavigate();
    const agence = AuthContextAgence();
    const location = useLocation();
    const [nameComponent,setNameComponent] = useState('liste');
    const [postes,setPostes] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [detailPoste,setDetailPoste] = useState([]);
    const [visibleItems, setVisibleItems] = useState(5);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [totalPostes,setTotalPostes] = useState(0);
    const [loadingItems,setLoadingItems] = useState(false);
    const [secteurActivites,setSecteurActivites] = useState([]);
    //console.log(agence);
    useEffect(() => {
      loadItems();
      try {
        if (agence && agence.user && agence.user.id) {
          axios.get(baseUrl.base+'/postes/?pro=' + agence.user.id).then((resp) => {
            setTotalPostes(resp.data.length);
          });

          axios.get(baseUrl.base+'/activite-agenceId/?agence=' + agence.id).then((resp) => {
            setSecteurActivites(resp.data);
          });
        }
      } catch (error) {
        console.log(error);
      }
      
    }, [agence,visibleItems]);

    const loadItems = () => {
      // Appel à l'API Django pour récupérer les éléments supplémentaires
      // Utilisez l'URL appropriée pour votre configuration Django
      setLoadingItems(true)
      try {
        
        if(agence && agence.user && agence.user.id){
          axios.get(baseUrl.base+'/postes/?agence='+agence.user?.id+'&page='+visibleItems).then((resp) => {
           
            setLoadingItems(false);
             if(resp.data.length > 0){
              setPostes(resp.data);
             
            }else{
              // setHasMoreItems(false);
            }

           
            
          });
        }
        setLoadingItems(false);
      } catch (error) { 
        setLoadingItems(false);
        console.log(error); 
      }
    };

    const handleShowMore = () => {
      setVisibleItems(prevVisibleItems => prevVisibleItems + 3);  
    };

    const showMoreButton = hasMoreItems && (
      <>
          <button onClick={handleShowMore} role="button" class="btn btn-sm btn-loader btn-primary-soft" >
            <span class="load-text">  Voir plus </span>
            <div class="load-icon">
              <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </button>
      </>
     /*  <button onClick={handleShowMore}>Voir plus</button> */  
    );
 

    const DetailComponent =(poste,sect) =>{ 

      try {
        axios.get(baseUrl.base+'/poste/'+poste+'/').then((resp)=>{
          setDetailPoste(resp.data);
        }) 
      } catch (error) {
        console.log(error);
      }
  
      if(sect == 1){
        setNameComponent('detail');
      }
  
      if(sect == 2){
        setNameComponent('update');
      }
  
      
    }

  const disabledPoste = (id)=>{
    Swal.fire({
      title: 'ATTENTION !',
      icon: "question",
      text: 'Êtes-vous sûrs de vouloir retirer cet élément.',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonText:"NON",
      confirmButtonText: 'OUI',
      cancelButtonColor:"red"
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        try {
            axios.get(baseUrl.base+'/disabled-poste/'+id+'/').then((resp)=>{
              axios.get(baseUrl.base+'/postes/?agence='+agence.user?.id+'&page='+visibleItems).then((resp)=>{
                setPostes(resp.data);
              });
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Poste definitivement désactivé avec succès !',
                showConfirmButton: false,
                timer: 3000,
                toast:true,
                position:'top-right',
                timerProgressBar:true
              });
              setIsLoading(false);
            })
        } catch (error) {
            console.log(error)
        }
      
      }
    }); 
   
    
}




    return (
        <>

  
            <PrincipaleComponentAgence>
                <div className="container">
                    <div className="row g-4">

                    
                    <div className="col-lg-3">

                    
                        <div className="d-flex align-items-center d-lg-none">
                        <button className="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSideNavbar" aria-controls="offcanvasSideNavbar">
                            <span className="btn btn-primary"><i className="fa-solid fa-sliders-h"></i></span>
                            <span className="h6 mb-0 fw-bold d-lg-none ms-2">Menu</span>
                        </button>
                        </div>
                        
                        <nav className="navbar navbar-expand-lg mx-0"> 
                        <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasSideNavbar">
                            
                            <div className="offcanvas-header">
                            <button type="button" className="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>

                            
                            <div className="offcanvas-body d-block px-2 px-lg-0">
                            
                            <div className="card overflow-hidden">
                                
                                <div className="h-50px banner-post-create" ></div>
                                
                                <div className="card-body pt-0">
                                    <div className="text-center">
                                    
                                    <div className="avatar avatar-lg mt-n5 mb-3">
                                      {agence.image == null && <a href="#!"><img className="avatar-img rounded border border-white border-3" src="/assets/images/avatar/placeholder.jpg" alt="" /></a>}
                                      {agence.image != null && <a href="#!"><img className="avatar-img rounded-circle border border-white border-3" src={agence.image} alt="" /></a>}
                                    </div>
                                    
                                    <h5 className="mb-2"> <u>{agence?.raison_sociale} </u> </h5>

                                    <div className="hstack gap-2 gap-xl-3 justify-content-center">
                                    
                                    <div>
                                        <h6 className="mb-0 text-danger">{totalPostes}</h6>
                                        <small>Annonces Totales</small>
                                    </div>
                                    
                                   
                                    </div>
                                    
                                </div>

                                
                                <hr/>

                                
                                <ul className="nav nav-link-secondary flex-column fw-bold gap-2">
                                  <li class="nav-item cursor-pointer">
                                    <a class="nav-link" href="/ag/home/"> <img class="me-2 h-20px fa-fw" src="/assets/images/icon/indicator-icon.svg" alt="" /><span>Accueil </span></a>
                                  </li>
                                    <li className="nav-item cursor-pointer">
                                    <a className={nameComponent == 'liste' ?  "nav-link active" :  "nav-link"}  onClick={()=>setNameComponent('liste')}> <img className="me-2 h-20px fa-fw" src="/assets/images/icon/task-done-outline-filled.svg" alt="" /><span>Mes Annonces </span></a>
                                    </li>
                                    {secteurActivites.includes(2) ?
                                    <li className="nav-item cursor-pointer">
                                    <a className={nameComponent == 'maison' ?  "nav-link active" :  "nav-link"}  onClick={()=>setNameComponent('maison')}> <img className="me-2 h-20px fa-fw" src="/assets/images/icon/home-outline-filled.svg" alt="" /><span>Ajouter une annonces </span></a>
                                    </li>
                                    : "" }
                                  
                                    {secteurActivites.includes(2) ?
                                    <li className="nav-item cursor-pointer">
                                    <a className={nameComponent == 'engin' ?  "nav-link active" :  "nav-link"} onClick={()=>setNameComponent('engin')}> <img className="me-2 h-40px fa-fw" src="/assets/images/icon/1361484_205.jpeg" alt="" /><span>Annonce Vehicules </span></a>
                                    </li>
                                    : "" }
                                     {secteurActivites.includes(3) ?
                                    <li className="nav-item cursor-pointer">
                                      <a className={nameComponent == 'ordinateur' ?  "nav-link active" :  "nav-link"}  onClick={()=>setNameComponent('ordinateur')}> <img className="me-2 h-20px fa-fw" src="/assets/images/icon/computer_icon.svg" alt="" /><span>Annonce Ordinateurs </span></a>
                                    </li>
                                    : "" }
                                  {secteurActivites.includes(4) ?
                                    <li className="nav-item cursor-pointer">
                                      <a className={nameComponent == 'phone' ?  "nav-link active" :  "nav-link"}  onClick={()=>setNameComponent('phone')}> <img className="me-2 h-20px fa-fw" src="/assets/images/icon/phone-icon-filled.svg" alt="" /><span>Annonce Téléphones </span></a>
                                    </li>
                                    : "" }
                         
                                </ul>
                                
                                </div>
                          
                            </div>
                            
                          
                          
                            </div>
                        </div>
                        </nav>
                    
                    </div>
                    
                    <div className="col-md-8 col-lg-6 vstack gap-4">
                     
                        {nameComponent == 'liste' &&
                               <div class="card">
                               
                               <div class="card-header">
                                 <h5 class="card-title">Mes Annonces <button className="btn btn-primary btn-sm border rounded mb-0 float-end"  onClick={()=>setNameComponent('maison')}>Ajouter</button></h5> 
                                 {isLoading &&
                                 <>
                                    <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                    </div>
                                    <div class="spinner-grow spinner-grow-sm" role="status">
                                      <span class="sr-only">Loading...</span>
                                  </div>
                                </>
                                 }
                                 
                               </div>
                         
                               <div class="card-body">
                               {loadingItems && <div className="text-center">Chargement...</div>}
                               {postes.length == 0 && !loadingItems && <div className="text-center">Aucune Annonces</div>}
                                 {postes &&
                                  postes?.map((poste,index)=>
                                      <div className={poste.is_active? "card row d-md-flex align-items-center mb-4 p-3" : " card d-md-flex align-items-center mb-4 disable"}>
                                   
                                      
                                      <div class="w-100">
                                        <div class="d-sm-flex align-items-start">
                                          <h6 class="mb-0"> <Link className="text-primary" onClick={()=>DetailComponent(poste.id,1)}><b className="text-dark">{index+1}</b>-{poste.libelle} <b className="text-danger">({separateNumber(poste?.montant)} Fcfa)</b> </Link></h6>
                                          <p class="small ms-sm-2 mb-0">{poste.immobilier?.quatier} </p>
                                      </div>
                                      <span className="text-secondary">{moment(poste.created_at).format("Do MMMM  YYYY")}</span>
                                      
                                      {/* <ul class="avatar-group mt-1 list-unstyled align-items-sm-center">
                                      
                                        <li class="avatar avatar-xxs">
                                          <div class="avatar-img rounded-circle bg-primary"><span class="smaller text-white position-absolute top-50 start-50 translate-middle">+{poste.interet_click_voir_plus}</span></div>
                                        </li>
                                        <li class="small ms-3">
                                          vues
                                        </li>
                                      </ul> */}
                                      
                                    </div>
                                    
                                    <div class="ms-md-auto mt-3">
                                      <button class="btn btn-danger-soft btn-sm mb-0 me-2" onClick={()=>disabledPoste(poste.id)}> Retirer </button>
                                      <button class="btn btn-primary-soft btn-sm mb-0" onClick={()=>DetailComponent(poste.id,2)}> Modifier </button>
                                    </div>
                                      </div>
                                  )
                                 }
                               
                   
                               
                              
                           <div class="d-grid">
                          
                           {showMoreButton}
                           
                           </div>
                   
                           </div>
                           
                           </div>
                        }
                        {nameComponent == 'maison' &&
                            <PostMaison agence={agence}/>
                        }

                        {nameComponent == 'engin' &&
                            <PostEngin agence={agence} />
                        }

                         {nameComponent == 'ordinateur' &&
                            <PostOrdinateur agence={agence} />
                        } 

                        {nameComponent == 'phone' &&
                            <PostPhone agence={agence} />
                        }

                         {nameComponent == 'detail' &&
                          <DetailComponentPoste poste={detailPoste} />
                        }

                         {nameComponent =='update' &&
                          <UpdateComponentPost poste={detailPoste} agence={agence}/>
                        } 
                         
                        
                    </div>

                    </div> 
                </div>
            </PrincipaleComponentAgence>
          
            

        </>
    )
}

export default CreatePosts;