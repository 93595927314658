import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import moment from "moment";
import Select from "react-select";
import Validate from "../../Validation";
import Swal from "sweetalert2";
import BaseUrl from "../../BaseUrl";

// const siteUrl = 'http://127.0.0.1:8000';
const baseUrl = BaseUrl();
function ReponduRequete({agence}){
    function separateNumber(number) {
        // Vérifier si le nombre est un entier positif
        if (Number.isInteger(number) && number >= 0) {
          // Convertir le nombre en chaîne de caractères
          var numberString = number.toString();
            
          // Séparer les chiffres en centaines
          var separatedNumber = '';
          for (var i = numberString.length - 1; i >= 0; i--) {
            separatedNumber = numberString.charAt(i) + separatedNumber;
            if (i > 0 && (numberString.length - i) % 3 === 0) {
              separatedNumber = ',' + separatedNumber;
            }
          }
      
          return separatedNumber;
        } else {
          return 'Veuillez fournir un entier positif.';
        }
      }

      const navigate = useNavigate();
      const [loadingReponse,setLoadingReponse] = useState(false);
      const [mesReponseRequetes,setMesReponseRequetes] = useState([]);


      useEffect(()=>{
        mesReponses();
      },[agence]);

      const mesReponses=()=>{
        if(agence && agence.id){
            setLoadingReponse(true);
              try {
                  axios.get(baseUrl.base+'/mes-reponses-of-requetes-agence/?agence='+agence.id).then((resp)=>{
                    setMesReponseRequetes(resp.data);
                      setLoadingReponse(false);
                  })
              } catch (error) {
                  console.log(error);
              }
          }
      }

    return (
        <>
            <div className="card">
                <div className="card-body">
                <table class="table table-bordered" style={{"width":"100%"}}>
                    <thead class="thead-dark bg-warning" >
                        <tr>
                        <th style={{"max-width":"40px"}} scope="col">Requêtes</th>
                        <th style={{"max-width":"30px"}} scope="col">Mon commentaire</th>
                        <th style={{"max-width":"10px"}} scope="col">Postes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {mesReponseRequetes &&
                            mesReponseRequetes.map((reponse,index)=>
                            <tr>
                            <td style={{"max-width":"20px"}}><span>Ref: {reponse.requete?.reference}</span><br/><strong>{reponse.requete?.objet}</strong></td>
                            <td style={{"max-width":"30px"}}>{reponse.notes}</td>
                            <td style={{"max-width":"10px"}} className="text-center"><strong className="text-primary cursor-pointer" onClick={()=>navigate(`/mes-recommandation-aux-requetes/postes?rep=${reponse.requete?.id}&others=${reponse.agence?.slug}`,{state:{requete:reponse.requete?.objet}})}>{JSON.parse(reponse.total_recommandations_postes)}</strong></td>
                            </tr>
                            )
                        }
                        
                    </tbody>
                </table>


                </div>
            </div>
        </>
    )
}


export default ReponduRequete;