import axios from "axios";
import { useEffect, useState } from "react";
import Select from "react-select";
import Validate from "../../../Validation";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import BaseUrl from "../../../BaseUrl";

const baseUrl = BaseUrl();
function ComputerPosteUser({client}){

    function generateUniqueID() {
        const timestamp = new Date().getTime().toString(); 
        const randomNum = Math.random().toString(36).substr(2, 9); 
      
        return timestamp + randomNum; 
    }

    const navigate = useNavigate();
    const [errors,setErrorM] = useState({});
    const [image, setImage] = useState([]);
    const [marques,setMarques] = useState([]);
    const [selectedMarque, setSelectedMarque] = useState(null);
    const [couleurs,setCouleurs] = useState([]);
    const [loading,setLoading] = useState(false);
    const [postData,setPostData] = useState({
        'libelle':'',
        'montant':'',
        'description':'',
        'contact':client? client.tel : '',
        'marque':'',
        'model':'',
        'couleur':'',
        'poids':'',
        'ecran':'',
        'processeur':'',
        'systemeOs':'',
        'clavier':'',
        'usb':'',
        'disque':'',
        'memoire':'',
        'autonomie':'',
        'typeOs':'',
        'carteGraphique':'',
        'clavierEclaire':'0',
        'etat':'',
        'is_hdmi':'0'
    });

    useEffect(()=>{
        try {
            axios.get(baseUrl.base+'/marques/?category=03').then((resp)=>{
                setMarques(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/couleurs/').then((resp)=>{
                setCouleurs(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

      
    },[client]);

    const optionsmarques = marques?.map((marque)=>({
        value: marque.id,
        label: `${marque.libelle}`
        
    }));

    const onSelectFile = (event) => {

        setImage((image)=>image.concat(event.target.files[0]));
        
    };

    function deleteHandler(index) {
        const updatedImage = [...image];
        updatedImage.splice(index, 1);
        setImage(updatedImage);
      }

      const handleChange=(event)=>{
        setPostData({
            ...postData,
            [event.target.name]:event.target.value
        });

      }

    const handleChangeMarque = (selectedOption)=>{
        setSelectedMarque(selectedOption.value);
        //console.log(selectedMarque)
    }

    const submitForm=()=>{
        setErrorM(Validate(postData));
        const _fromData = new FormData();
        if(postData.libelle !="" && postData.montant !="" && postData.contact !="" && postData.description !="" && postData.autonomie !="" && postData.clavier !="" && postData.typeOs !="" && postData.processeur !="" && selectedMarque !==null && postData.usb !="" && postData.disque !="" && postData.memoire !=""){
            

            _fromData.append('libelle',postData.libelle);
            _fromData.append('montant',postData.montant);
            _fromData.append('description',postData.description);
            _fromData.append('contact',postData.contact);
            _fromData.append('model',postData.model);
            _fromData.append('marque',selectedMarque);
            _fromData.append('user',client.id);
            _fromData.append('couleur',postData.couleur);
           // _fromData.append('user',postData.model);
            _fromData.append('poids',postData.poids);
            _fromData.append('ecran',postData.ecran);
            _fromData.append('processeur',postData.processeur);
            _fromData.append('systemeOs',postData.systemeOs);
            _fromData.append('clavier',postData.clavier);
            _fromData.append('usb',postData.usb);
            _fromData.append('disque',postData.disque);
            _fromData.append('memoire',postData.memoire);
            _fromData.append('autonomie',postData.autonomie);
            _fromData.append('typeOs',postData.typeOs);
            _fromData.append('carteGraphique',postData.carteGraphique);
            _fromData.append('etat',postData.etat);
            _fromData.append('is_hdmi',postData.is_hdmi);
            _fromData.append('clavierEclaire',postData.clavierEclaire);
            //console.log(_fromData)
            

            Array.from(image).forEach((item, index) => {
                const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
                const originalExtension = item.name.split('.').pop();
                const newFileName = `${currentTimeInSeconds}_${index}_${postData.montant}_${generateUniqueID()}.${originalExtension}`;
                const photo = new File([item], newFileName, { type: item.type });
                _fromData.append('images', photo);
            });

            //console.log(_fromData);
            setLoading(true);
             try {
                axios.post(baseUrl.base+'/post-computers/',_fromData,{
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }).then((resp)=>{
                    if(resp.data.bool){
                        window.location.reload();
                    }else{
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Désolé ! Un problème est subvenu.',
                            showConfirmButton: false,
                            timer: 3000,
                            toast:true,
                            position:'top-right',
                            timerProgressBar:true
                          });
                          setLoading(false);
                    }
                })
            } catch (error) {
                console.log(error);
            } 
        }
    }

    return (
        <>
              <div class="card">
                        
                        <div class="card-header border-0 pb-0">
                            <h1 class="h4 card-title mb-0">Annonce Ordinateur</h1>
                        </div>
                        
                        <div class="card-body">
                            <div class="row g-3">
                            
                            <div class="col-12">
                                <label class="form-label">Titre de l'annonce</label>
                                <input type="text" class="form-control"  name="libelle" onChange={handleChange} value={postData.libelle} />
                                {errors.libelle && <span className="text-danger">{errors.libelle}</span>}
                            </div>
                            
                            <div class="col-sm-6 col-lg-6">
                                <label class="form-label">Montant</label>
                                <input type="text" class="form-control" placeholder="Ex: 60000" name="montant" onChange={handleChange} value={postData.montant} />
                                {errors.montant && <span className="text-danger">{errors.montant}</span>}
                            </div>
                            
                            <div class="col-sm-6 col-lg-6">
                                <label class="form-label">Contact</label>
                                <input type="text" class="form-control" placeholder="Ex: 0171808441" name="contact" onChange={handleChange} value={postData.contact}/>
                                {errors.contact && <span className="text-danger">{errors.contact}</span>}
                            </div>
                            
                            <div class="col-sm-6 col-lg-4">
                                <label class="form-label">Etat</label>
                                <select class="form-select js-choice" name="etat" onChange={handleChange} >
                                    <option value="" selected disabled>Choisir ...</option>
                                    <option value="Neuf" >Nouveau</option>
                                    <option value="Neuf avec sac" >Nouveau avec sac</option>
                                    <option value="Quazi-neuf" >Quasi-neuf</option>
                                    <option value="Quazi-neuf avec sac" >Quasi-neuf avec sac</option>
                                    <option value="Seconde Main" >Seconde Main</option>
                                   
                                
                                </select>
                                {errors.etat && <span className="text-danger">{errors.etat}</span>}
                            </div>


                            <div class="col-sm-6 col-lg-4">
                                <label class="form-label">Marques</label>
                                <Select options={optionsmarques} onChange={handleChangeMarque} />
                                {selectedMarque ==null && <span className="text-danger">ce champs est obligatoire</span> }
                            </div>
                            <div class="col-sm-6 col-lg-4">
                                <label class="form-label">Models</label>
                                <input type="text" class="form-control"  name="model" onChange={handleChange} value={postData.model}/>
                                {errors.model && <span className="text-danger">{errors.model}</span>}
                            </div>

                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Couleur </label>
                                <select class="form-select js-choice" name="couleur" onChange={handleChange} value={postData.couleur}>
                                <option value="" selected disabled>Choisir ...</option>
                                    {couleurs?.map((couleur,index)=>
                                        <option value={couleur.id}>{couleur.libelle}</option>
                                    )}
                                </select>
                                {errors.couleur && <span className="text-danger">{errors.couleur}</span> }
                              
                            </div>

            
                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Mémoire RAM (Go)</label>
                                <input type="number" class="form-control" placeholder="" name="memoire" onChange={handleChange}  />
                                {errors.memoire && <span className="text-danger">{errors.memoire}</span>}
                            </div>
                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Capacité du disque (Go)</label>
                                <input type="number" class="form-control" placeholder="" name="disque" onChange={handleChange} />
                                {errors.disque && <span className="text-danger">{errors.disque}</span>}
                            </div>

                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Système d'exploitation </label>
                                <select class="form-select js-choice" name="systemeOs" onChange={handleChange} >
                                <option value="" selected disabled>Choisir ...</option>
                                <option value="Pas de système" >Aucun</option>
                                <option value="Windows" >Windows</option>
                                <option value="macOS" >macOS</option>
                                <option value="Linux" >Linux</option>
                                <option value="Chrome OS" >Chrome OS </option>
                                <option value="IBM z/OS" >IBM z/OS</option>
                                <option value="Haiku" >Haiku</option>
                                  
                                </select>
                                {errors.systemeOs && <span className="text-danger">{errors.systemeOs}</span>}
                                
                            </div> 

                            

                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Carte graphique</label>
                                <input type="text" class="form-control" placeholder="" name="carteGraphique" onChange={handleChange}  />
                                
                            </div>

                             <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Clavier </label>
                                <select class="form-select js-choice" name="clavier" onChange={handleChange} >
                                <option value="" selected disabled>Choisir ...</option>
                                <option value="Clavier QWERTY" >Clavier QWERTY</option>
                                <option value="Clavier AZERTY" >Clavier AZERTY</option>
                                <option value="Clavier QWERTZ" >Clavier QWERTZ</option>
                                <option value="Clavier Dvorak" >Clavier Dvorak</option>
                                <option value="Clavier mécanique" >Clavier mécanique</option>
                                <option value="Clavier à membrane" >Clavier à membrane</option>
                                    
                                </select>
                                {errors.clavier && <span className="text-danger">{errors.clavier}</span>}
                              
                            </div> 

                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Ecran (Pouces)</label>
                                <input type="number" class="form-control" placeholder="" name="ecran" onChange={handleChange}  />
                                {errors.ecran && <span className="text-danger">{errors.ecran}</span>}
                            </div>

                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Nombre port USB</label>
                                <input type="number" class="form-control" placeholder="" name="usb" onChange={handleChange}  />
                                {errors.usb && <span className="text-danger">{errors.usb}</span>}
                            </div>

                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Autonomie (Heure)</label>
                                <input type="number" class="form-control" placeholder="" name="autonomie" onChange={handleChange} />
                                {errors.autonomie && <span className="text-danger">{errors.autonomie}</span>}
                            </div>

                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Type de système Os </label>
                                <select class="form-select js-choice" name="typeOs" onChange={handleChange} >
                                <option value="" selected disabled>Choisir ...</option>
                                <option value="64 bits" >64 bits</option>
                                <option value="32 bits" >32 bits</option>
                                    
                                </select>
                                {errors.typeOs && <span className="text-danger">{errors.typeOs}</span>}
                            </div> 

                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Clavier Retro-eclairé</label>
                                <select class="form-select js-choice" name="clavierEclaire" onChange={handleChange} >
                                <option value="" selected disabled>Choisir ...</option>
                                <option value="1" >OUI</option>
                                <option value="0" >NON</option>
                                    
                                </select>
                              
                            </div> 

                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">HDMI</label>
                                <select class="form-select js-choice" name="is_hdmi" onChange={handleChange} >
                                <option value="" selected disabled>Choisir ...</option>
                                <option value="1" >OUI</option>
                                <option value="0" >NON</option>
                                    
                                </select>
                              
                            </div> 
                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Poids (kg)</label>
                                <input type="number" class="form-control" placeholder="" name="poids" onChange={handleChange} />
                                
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <label class="form-label">Processeur </label>
                                <input type="text" class="form-control" placeholder="" name="processeur" onChange={handleChange} />
                                {errors.processeur && <span className="text-danger">{errors.processeur}</span> }
                            </div>
                                 

                            
               
                            
                            <div class="col-12">
                                <label class="form-label">Description</label>
                                <textarea class="form-control" rows="3" name="description" onChange={handleChange} value={postData.description}></textarea>
                                {errors.description && <span className="text-danger">{errors.description}</span> }
                               
                            </div>
                            
                            
                            <hr/>

                            
                            <div class="col-12">
                                <h5 class="card-title mb-0">Images Associées</h5>
                            </div>

                            <div class="container">
                               
                               <div class="card mt-3">
                                   <div class="card-body">

                                       <div class="row mb-3">
                                           <div class="col-sm-10">
                                           <div class="tab-pane fade show active" id="tab-1">
                                               <div class="row g-3">

                                               
                                                   <div class="col-6 col-lg-3">
                                                       <div class=" h-100 pb-3 rounded text-center d-flex align-items-center justify-content-center position-relative">
                                                           <label class="btn stretched-link label-img" >
                                                           <input className="input-none"
                                                               type="file"
                                                               onChange={onSelectFile}
                                                               multiple
                                                               accept="image/png , image/jpeg, image/webp"
                                                               />
                                                               <i class="fa-solid fa-camera-retro fs-1"></i>
                                                               <h6>Ajouter Photo</h6>
                                                           </label>
                                                       </div>
                                                   </div>
                                                   
                       
                                               {image &&
                                                   image.map((img, index) => {
                                                       return (
                                                    <div class="col-6 col-lg-3 position-relative" key={index}>
                                                       <div class="position-absolute bottom-0 end-0">
                                                       
                                                           <div class="dropdown mb-2 me-3">
                                                               <button class="btn icon-sm bg-danger text-white rounded-circle" id="photoActionEdit3" onClick={() => deleteHandler(index)}>
                                                                   <i class="bi bi-trash-fill"></i>
                                                               </button>
                                                           </div>
                                                       </div>
                                                      
                                                           <img class="rounded img-fluid" src={img ? URL.createObjectURL(img): null}  alt="" />
                                                       
                                                   </div> 

                                                   );
                                                    })}
                                                   
                                               </div>
                                           </div>
                                           </div>
                                       </div>

                                   </div>
                               </div>

                               </div>
                            
              
                            
                            <div class="col-12 text-end">
                                {image.length > 0 && !loading &&  <button type="button" class="btn btn-primary mb-0" onClick={submitForm} >Enregistrer</button>}
                                {image.length == 0 && !loading &&  <button type="button" class="btn btn-primary mb-0"  disabled>Enregistrer</button>}
                                {image.length > 0 && loading &&  <button type="button" class="btn btn-primary mb-0"  disabled>Enregistrer</button>}
                            </div>
                            </div>
                        </div>
                        
            </div>
        </>
    )
}

export default ComputerPosteUser;