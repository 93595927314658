import { useEffect, useState } from "react";
import AuthContext from "./BaseU";
import PrincipaleComponent from "./PrincipalComponent";
import axios from "axios";
import { Link } from "react-router-dom";
import NavUser from "./compo/navUser";
import BaseUrl from "../../BaseUrl";


const baseUrl = BaseUrl();
function ProfessionnellePage(){
    const client = AuthContext();
    const [agences,setAgences] = useState([]);
    const [secteurs,setSecteurs] = useState([]);
    const [visibleItems, setVisibleItems] = useState(20);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [secteurValue,setSecteurValue]=useState('');
    const [filterStatus,setFilterStatus] = useState(false);
    const [loading,setLoading] = useState(false);

    useEffect(()=>{

        loadPagePro();

        try {
            axios.get(baseUrl.base+'/activites/?all').then((resp)=>{
                setSecteurs(resp.data);
            })
        } catch (error) {
            console.log(error);
        }
    },[client,visibleItems]);


    const loadPagePro=()=>{
        try {
            setLoading(true);
            if(client && client.id){
              axios.get(baseUrl.base+'/pro-agences/?page='+visibleItems).then((resp) => {
                if(resp.data.length > 0){
                    setAgences(resp.data);
                }else{
                  setHasMoreItems(false);
                }
                setLoading(false);
              });
            }
            
          } catch (error) { 
            console.log(error); 
          }


    }


    const filterAgenceProBysecteursActivite=(event)=>{
        setFilterStatus(true);
        setLoading(true);
        const value = event.target.value
        try {
            axios.get(baseUrl.base+'/pro-agences/?secteur='+value).then((resp)=>{
                setAgences(resp.data);
                setLoading(false);
            })
        } catch (error) {
            console.log(error);
        }

    }

    const handleShowMore = () => {
        setVisibleItems(prevVisibleItems => prevVisibleItems + 8);  
      };

    const showMoreButton = hasMoreItems && (
        <>
            <button onClick={handleShowMore} role="button" class="btn btn-sm btn-loader btn-primary-soft form-control" >
              <span class="load-text">  Voir plus </span>
              <div class="load-icon">
                <div class="spinner-grow spinner-grow-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div> 
              </div>
            </button>
        </>
       /*  <button onClick={handleShowMore}>Voir plus</button> */  
      );


    return (
       <PrincipaleComponent>
              <div class="container">
                    <div class="row g-4">
                    <NavUser />
                    <div class="col-md-8 col-lg-8">
                        <div class="bg-mode p-4">
                            <div className="row">
                            <div className="col-8">  
                                <h1 class="h4 mb-4">Agences</h1>
                            </div>
                          
                            <div className="col-4">
                                <select className="form-select float-end p-0 m-0" onChange={filterAgenceProBysecteursActivite}>
                                    <option value="" selected disabled>Secteurs...</option>
                                    {secteurs?.map((secteur,index)=>
                                         <option value={secteur.id}>{secteur.libelle}</option>
                                    )}
                                   
                                </select>
                            </div>
                            
                            </div>
                       
                            {loading && <h4 class="mt-2 mb-3 text-body">Chargement...</h4>}
                       {agences?.map((agence,index)=>
                            <>
                                   <div class="card bg-transparent border-0">
                                        <div class="row g-3">
                                            <div class="col-2">
                                                {agence.image && <img class="rounded" width={100} height={100} src={agence.image} alt="" />}
                                                {agence.image == null  && <img class="rounded" src='/assets/images/no-logo120.jpg' alt="" />}
                                            </div>
                                            <div class="col-10"> 
                                            
                                    {/*             <span class="badge bg-danger bg-opacity-10 text-danger mb-2 fw-bold mx-1">Lifestyle</span> */}
                                                <h5><Link to={`/pro/${agence.slug}/`} class="btn-link stretched-link text-reset fw-bold">{agence.raison_sociale}</Link><span className="text-primary float-end"> <i class="fa-solid fa-thumbs-up"></i> {agence.likeCount} </span></h5>
                                                <div class="d-none d-sm-inline-block ">
                                                <p class="mb-2 d-inline-block text-truncate" style={{maxWidth:"500px"}}>{agence.info_complementaire}</p><br />
                                                
                                                <a class="small text-secondary" href="#!"> <i class="bi bi-geo-alt pe-1"></i> {agence.adresse} | <i class="bi bi-telephone-fill pe-1"></i> {agence.user?.tel}/{agence.contact == "null" ? "":agence.contact} </a><br/>
                                                <a className="small text-secondary"> <i class="bi bi-envelope-at"></i>  {agence.email=="null"?"":agence.email}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                    <hr class="my-4" />
                            </>
                       )}
                        
                     
                        {!filterStatus && showMoreButton}
                        {filterStatus &&
                            <button onClick={()=>window.location.reload()} role="button" class="btn btn-sm btn-loader btn-secondary-soft form-control" >
                                <span class="load-text"> Toutes les pages</span>
                                <div class="load-icon">
                                <div class="spinner-grow spinner-grow-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div> 
                                </div>
                        </button>
                        }
                      
                        </div>
                    </div>
                    <div className="col-lg-3"></div>
                    
                    </div>
                </div>
       </PrincipaleComponent>
    )
}

export default ProfessionnellePage;