import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import Validate from '../../Validation';
import axios from "axios";
import * as $ from 'jquery';
import Swal from "sweetalert2";
import BaseUrl from "../../BaseUrl";

const baseUrl = BaseUrl();

function LoginAgence(){
    const navigate = useNavigate();
    const [errors,setErrorM] = useState({});
    const [msgAlert,setMsgAlert] = useState('');
    const [msgerrorAlert,setMsgerrorAlert] = useState('');
    const [isLoading,setIsLoading] = useState(false);
    const [UniqError,setUniqError] = useState('');
    const [emailUniqError,setEmailUniqError] = useState('');
    const [countries,setCountries] = useState([]);
    const [activites,setActivites] = useState([]);
    const [isAuthToken, setIsAuthToken] = useState(localStorage.getItem('_token'));
    const [selectedOptionActivite, setSelectedOptionActivite] = useState([]);
    const [selectedPays, setSelectedPays] = useState(null);
    const [errorCountrie, setErrorCountrie] = useState('');
    const [errorActivity, setErrorActivity] = useState('');
    const [passwordVisible, setPasswordVisible] = useState(false);

    const [loginAgence,setLoginAgence] = useState({
        'identifiant':'',
        'password':''
      });
  
      const [registerAgence,setRegisterAgence] = useState({
        'raison_sociale':'',
        'info_complementaire':'',
        'num_registre':'',
        'sexe':'',
        'nom':'',
        'prenom':'',
        'adresse':'',
        'email':'',
        'tel':'',
        'siteInternet':'',
        'reseauxSociaux':'',
        'password':'',
        'cfm_password':''
      });

      useEffect(()=>{
        if(isAuthToken){
          navigate('/ag/home/');
        }
        try {
          axios.get(baseUrl.base+'/countrie/').then((resp)=>{
            setCountries(resp.data);
          })
        } catch (error) {
          console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/activites/?all').then((resp)=>{
                setActivites(resp.data);
            })
        } catch (error) {
            console.log(error);
        }
      },[]);

      const handleChangeRegisterAgence=(event)=>{
        setRegisterAgence({
          ...registerAgence,
          [event.target.name]:event.target.value
        })
      }

      const handleChangeLoginAgence=(event)=>{ 
        setLoginAgence({
          ...loginAgence,
          [event.target.name]:event.target.value
        })
      }

      const optionsactivite = activites?.map((activite)=>({
        value: activite.id,
        label:`${activite.libelle}`
      }));

      const optionspays = countries?.map((countrie) => ({
        value: countrie.id,
        label: `${countrie.libelle}`
      }));



      const handleChangePays = (selectedOption)=>{
        setSelectedPays(selectedOption.value);
      }

      const handleChangeActivite=(selectedOption)=>{
        setSelectedOptionActivite(selectedOption);
      }

      const submitRegisterAgence = ()=>{
        setEmailUniqError('');
        setUniqError('');
        setMsgAlert('');
        setMsgerrorAlert('');
            setErrorM(Validate(registerAgence));
            const activiteValue = selectedOptionActivite.map((option)=>option.value);
            if(selectedPays==null){setErrorCountrie("ce champs est obligatoire");}
            if(selectedOptionActivite.length==0){setErrorActivity("ce champs est obligatoire");}
            if(registerAgence.raison_sociale != '' &&registerAgence.info_complementaire !='' && registerAgence.adresse !='' && registerAgence.sexe !='' && registerAgence.nom !='' && registerAgence.prenom !='' && registerAgence.email != '' && (/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/).test(registerAgence.email) && registerAgence.tel !='' && registerAgence.tel.length == 10 && !isNaN(registerAgence.tel) &&  registerAgence.password !='' && registerAgence.cfm_password !='' && registerAgence.cfm_password == registerAgence.password){
                
                
                const _fromData = new FormData();
                _fromData.append('nom',registerAgence.nom);
                _fromData.append('prenom',registerAgence.prenom);
                _fromData.append('tel',registerAgence.tel);
                _fromData.append('countrie',selectedPays);
                _fromData.append('email',registerAgence.email);
                _fromData.append('sexe',registerAgence.sexe);
                _fromData.append('raison_sociale',registerAgence.raison_sociale);
                _fromData.append('info_complementaire',registerAgence.info_complementaire);
                _fromData.append('num_registre',registerAgence.num_registre);
                _fromData.append('adresse',registerAgence.adresse);
                _fromData.append('activiteLists',activiteValue);
                _fromData.append('password',registerAgence.password);
                _fromData.append('siteInternet',registerAgence.siteInternet);
                _fromData.append('reseauxSociaux',registerAgence.reseauxSociaux);

                setIsLoading(true);
                try {
                  axios.post(baseUrl.base+'/register-agences/',_fromData).then((resp)=>{
                    if(resp.data.bool == true){
                      
                      setTimeout(() => {
                        setMsgAlert(resp.data.msg);
                        setIsLoading(false);
                        window.$('#createAgenceModal').modal('hide');
                      }, 2000);
                    }else{
                      if(!resp.data.bool && resp.data.error){
                        setUniqError(resp.data.error );
                        setIsLoading(false);
                      }

                      if(!resp.data.bool && resp.data.errorMail){
                        setEmailUniqError(resp.data.errorMail);
                        setIsLoading(false);
                      }
        
                      if(!resp.data.bool && resp.data.msg){
                       
                        setTimeout(() => {
                          setMsgerrorAlert(resp.data.msg);
                          setIsLoading(false);
                        }, 2000);
                      }
                    }
        
                  })
                } catch (error) {
                  console.log(error);
                }
            }else{
              console.log(errors.cfm_password)
              console.log(errors.tel)
              if(registerAgence.password !== registerAgence.cfm_password){errors.cfm_password = "Le mot de passe ne correspond pas.";}
              // if(registerAgence.tel.length != 10){setUniqError('Le numéro doit contenir 10 chiffres')}
            }
      }

      const submitLogin = () =>{
        setErrorM(Validate(loginAgence));

        setMsgAlert('');
        setMsgerrorAlert('');
        if(loginAgence.identifiant !== "" && loginAgence.identifiant.length == 10 && !isNaN(loginAgence.identifiant) && loginAgence.password !== ""){
            const _formData = new FormData();

            _formData.append('identifiant',loginAgence.identifiant);
            _formData.append('password',loginAgence.password);
            setIsLoading(true);
            try {
                axios.post(baseUrl.base+'/login-agences/',_formData,{
                    headers:{
                        'Content-Type':'application/json'
                    },
                    credentials:'include',
                }).then((resp)=>{
                  setIsLoading(false);
                    if(resp.data.bool == true){
                        localStorage.setItem('_token',resp.data.token);
                        navigate('/ag/home/');
                    }else{
                      setMsgerrorAlert(resp.data.msg);
                    }
  
                 
                
                })
            } catch (error) {
              setIsLoading(false);
                console.log(error)
            }
        }else{
          setMsgerrorAlert("Numéro ou mot de passe invalide.")
        }


    }


    return ( 
        <main>
  
        
        <div className="container">
          <div className="row justify-content-center align-items-center vh-100 py-5">
            
            <div className="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
              
              <div className="card card-body text-center p-4 p-sm-5">
                      {msgAlert !=="" || msgerrorAlert !=="" ?
                        <div className={msgAlert !== "" ? "alert alert-success" : "alert alert-danger"} role="alert">
                          <h4 className="alert-heading">{msgAlert !== "" ? "Félicitation !" : "Attention !"}</h4>
                          <p>{msgAlert != "" ? msgAlert : msgerrorAlert}</p>
                        </div>
                        :""}
                      {/* {msgAlert !=="" &&
                        <div className="alert alert-success" role="alert">
                        <h4 className="alert-heading">Félicitation !</h4>
                        <p>{msgAlert}</p>
                        </div>
                        }

                        {msgerrorAlert !=="" &&
                            <div className="alert alert-danger" role="alert">
                            <h4 className="alert-heading">Attention !</h4>
                            <p>{msgerrorAlert}</p>
                            </div>
                        } */}
                <h4 className="mb-2">Mon compte professionnel</h4>
                <p className="mb-0">Créer un compte professionnel?<a href="#" data-bs-toggle="modal" data-bs-target="#createAgenceModal"> Cliquez ici</a></p>
                 
                
                
                  
                  <div className="mb-3 input-group-lg">
                    <input type="text" className="form-control" placeholder="Identifiant ou numéro de telephone" name="identifiant" onChange={handleChangeLoginAgence} value={loginAgence.identifiant} />
                    {/* {errors.identifiant && <span className="text-danger">{errors.identifiant}</span>} */}
                  </div>
                  
                  
                  <div className="mb-3 position-relative">
                    
                    <div className="input-group input-group-lg">
                      {/* <input className="form-control fakepassword" type="password" id="psw-input" placeholder="Mot de passe" name="password" onChange={handleChangeLoginAgence} value={loginAgence.password}/> */}
                      <input
                          type={passwordVisible ? "text" : "password"}
                          className="form-control"
                          name="password"
                          onChange={handleChangeLoginAgence}
                          value={loginAgence.password}
                          placeholder="Mot de passe"
                        />
                        <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                          >
                            <i className={passwordVisible ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                        </button>
                    </div>
                    {/* {errors.password && <p className="text-danger">{errors.password}</p>} */}
                  </div>
                  
                  <div className="mb-3 d-sm-flex justify-content-between">
                    <div>
                    {/*   <input type="checkbox" className="form-check-input" id="rememberCheck" />
                      <label className="form-check-label" htmlFor="rememberCheck">Remember me?</label> */}
                    </div>
                    <Link to="/password-user-forgot/" >Mot de passe oublié?</Link>
                  </div>
                  
                  <div className="d-grid"><button type="button" className={!isLoading ? "btn btn-lg btn-primary" : "btn btn-lg btn-primary disabled"} onClick={submitLogin}>{!isLoading ? "Se connecter" : "En chargement..."}</button></div>
                  
                  <p className="mb-0 mt-3"><Link target="_blank" to="/login">Retour </Link> </p> 
                
                
              </div>
              
            </div>
          </div> 

            {/* Modal de creation d'une agence professionnelle */}
                <div className="modal fade" id="createAgenceModal" tabindex="-1" aria-labelledby="createModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div>
                                    <h2 className="modal-title" id="exampleModalLabel">Créer un compte professionnel</h2>
                                    <span className="fs-7">(<span className="text-danger">*</span>) champs obligatoire</span>
                                </div>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                            
                                    <div className="row my-2">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group nk-datapk-ctm form-elet-mg" id="data_1">
                                                <label>Raison sociale ou nom de l'entreprise <span className="text-danger">*</span></label>
                                                <div className="input-group date nk-int-st">
                                                    <input type="text" className="form-control"  name="raison_sociale" onChange={handleChangeRegisterAgence} value={registerAgence.raison_sociale}/>
                                                  
                                                </div>
                                                {errors.raison_sociale && <p className="text-danger">{errors.raison_sociale}</p>} 
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group nk-datapk-ctm form-elet-mg" id="data_1">
                                                <label>Information complémentaire <span className="text-danger">*</span></label>
                                                <div className="input-group date nk-int-st">
                                                    <textarea className="form-control" onChange={handleChangeRegisterAgence} name="info_complementaire">{registerAgence.info_complementaire}</textarea>
                                                    
                                                </div>
                                                {errors.info_complementaire && <p className="text-danger">{errors.info_complementaire}</p>} 
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-">
                                            <div className="form-group nk-datapk-ctm form-elet-mg" id="data_1">
                                                <label>N° Registre de commerce {/* <span className="text-danger">*</span> */}</label>
                                                <div className="input-group date nk-int-st">
                                                    <input type="text" className="form-control"  name="num_registre" onChange={handleChangeRegisterAgence} value={registerAgence.num_registre}/>
                                                  
                                                </div>
                                             {/*    {errors.num_registre && <p className="text-danger">{errors.num_registre}</p>}  */}
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <div className="form-group nk-datapk-ctm form-elet-mg" id="data_1">
                                                <label>Lien Site Internet{/* <span className="text-danger">*</span> */}</label>
                                                <div className="input-group date nk-int-st">
                                                    <input type="text" className="form-control"  name="siteInternet" onChange={handleChangeRegisterAgence} value={registerAgence.siteInternet}/>
                                                  
                                                </div>
                                             {/*    {errors.num_registre && <p className="text-danger">{errors.num_registre}</p>}  */}
                                            </div>
                                        </div>

                                        <div className="col-lg-4 col-md-4 col-sm-4 col-xs-4">
                                            <div className="form-group nk-datapk-ctm form-elet-mg" id="data_1">
                                                <label>Lien Facebook{/* <span className="text-danger">*</span> */}</label>
                                                <div className="input-group date nk-int-st">
                                                    <input type="text" className="form-control"  name="reseauxSociaux" onChange={handleChangeRegisterAgence} value={registerAgence.reseauxSociaux}/>
                                                  
                                                </div>
                                             {/*    {errors.num_registre && <p className="text-danger">{errors.num_registre}</p>}  */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <div className="form-group nk-datapk-ctm form-elet-mg" id="data_1">
                                                <label>Siège<span className="text-danger">*</span></label>
                                                <div className="input-group date nk-int-st">
                                                <div className="col">
                                                    <Select options={optionspays} onChange={handleChangePays} />
                                                    {errorCountrie != "" && <span className="text-danger">{errorCountrie}</span>}
                                                </div>
                                                
                                                    
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <div className="form-group nk-datapk-ctm form-elet-mg" id="data_1">
                                                <label>Adresse <span className="text-danger">*</span></label>
                                                <div className="input-group date nk-int-st">
                                                    <input type="text" className="form-control" placeholder="Ex: Abidjan,Yopougon Maroc"  name="adresse" onChange={handleChangeRegisterAgence} value={registerAgence.adresse}/>
                                                  
                                                </div>
                                                {errors.adresse && <p className="text-danger">{errors.adresse}</p>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group nk-datapk-ctm form-elet-mg" id="data_1">
                                                <label>Activité principale <span className="text-danger">*</span></label>
                                                <div className="input-group date nk-int-st">
                                                <div className="col">
                                                    <Select value={selectedOptionActivite} options={optionsactivite} onChange={handleChangeActivite} isMulti />
                                                    {errorActivity !== ""  && <span className="text-danger">{errorActivity}</span>}
                                                </div>
                                                
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row my-3">
                                        <strong className="fs-7">Personne de contact <i className="fas fa-question-circle" ></i></strong>
                                        <hr />
                                        <span className="fs-7">Civilité<span className="text-danger">*</span> {errors.sexe && <span className="text-danger">{errors.sexe}</span>}</span>
                                        <div className="col">
                                            <div className="border rounded p-2">
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">M. </label>
                                            <input className="form-check-input" type="radio" name="sexe"  value="H" id="flexRadioDefault1" onChange={handleChangeRegisterAgence} />
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="border rounded p-2">
                                            <label className="form-check-label" htmlFor="flexRadioDefault1">Mme. </label>
                                            <input className="form-check-input" type="radio" name="sexe" value="F" id="flexRadioDefault2" onChange={handleChangeRegisterAgence} />
                                            </div>
                                        </div>
                                        
                                    </div>

                                    <div className="row my-2">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <div className="form-group nk-datapk-ctm form-elet-mg" id="data_1">
                                                <label>Nom <span className="text-danger">*</span></label>
                                                <div className="input-group date nk-int-st">
                                                    <input type="text" className="form-control"  name="nom" onChange={handleChangeRegisterAgence} value={registerAgence.nom} />
                                                   
                                                </div>
                                                {errors.nom && <p className="text-danger">{errors.nom}</p>}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <div className="form-group nk-datapk-ctm form-elet-mg" id="data_1">
                                                <label>Prénoms <span className="text-danger">*</span></label>
                                                <div className="input-group date nk-int-st">
                                                    <input type="text" className="form-control"  name="prenom" onChange={handleChangeRegisterAgence} value={registerAgence.prenom}/>
                                                   
                                                </div>
                                                {errors.prenom && <p className="text-danger">{errors.prenom}</p>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div className="form-group nk-datapk-ctm form-elet-mg" id="data_1">
                                                <label>Adresse e-mail <span className="text-danger">*</span></label>
                                                <div className="input-group date nk-int-st">
                                                    <input type="text" className="form-control"  name="email" onChange={handleChangeRegisterAgence} value={registerAgence.email}/>
                                                    
                                                    
                                                </div>
                                                {errors.email && <p className="text-danger">{errors.email}</p>}
                                                {emailUniqError !=="" && <p className="text-danger">{emailUniqError}</p>}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row my-2">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <div className="form-group nk-datapk-ctm form-elet-mg" id="data_1">
                                                <label>Téléphone <span className="text-danger">*</span></label>
                                                <div className="input-group date nk-int-st">
                                                    <input type="text" className="form-control"  name="tel" onChange={handleChangeRegisterAgence} value={registerAgence.tel}/>
                                                    
                                                    
                                                </div>
                                                {errors.tel && <p className="text-danger">{errors.tel}</p>}
                                                {UniqError !=="" && <p className="text-danger">{UniqError}</p>}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <div className="form-group nk-datapk-ctm form-elet-mg" id="data_1">
                                                <label>Mot de passe <span className="text-danger">*</span></label>
                                                <div className="input-group date nk-int-st">
                                                    <input type="password" className="form-control"  name="password" onChange={handleChangeRegisterAgence} value={registerAgence.password}/>
                                                    
                                                </div>
                                                {errors.password && <p className="text-danger">{errors.password}</p>}
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                            <div className="form-group nk-datapk-ctm form-elet-mg" id="data_1">
                                                <label>Confirmer le mot de passe <span className="text-danger">*</span></label>
                                                <div className="input-group date nk-int-st">
                                                    <input type="password" className="form-control"  name="cfm_password" onChange={handleChangeRegisterAgence} value={registerAgence.cfm_password}/>
                                                    
                                                </div>
                                                {errors.cfm_password && <p className="text-danger">{errors.cfm_password}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    {!isLoading && 
                                          <button type="button" className="form-control btn btn-success btn-md my-3" onClick={submitRegisterAgence}>Je crée mon compte</button>
                                    }
                                    {isLoading && 
                                            <button className="form-control btn btn-success btn-md my-3" type="button" disabled>
                                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                                            enregistrement...
                                        </button>
                                    }

        
                            </div>
                        </div>

                    </div>
                </div> 
            {/* end Modal */}
        </div>
      
      </main>
    )
}

export default LoginAgence;