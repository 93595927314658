import { useEffect, useState } from "react";
import PrincipaleComponent from "./PrincipalComponent";
import { Link, useNavigate, useParams } from "react-router-dom";
import AuthContext from "./BaseU";
import axios from "axios";
import moment from "moment";
import NavUser from "./compo/navUser";
import BaseUrl from "../../BaseUrl";
import Poste from "./compo/poste";

// const siteUrl = 'http://127.0.0.1:8000';
const baseUrl = BaseUrl();
function FavoriteUserPage(){
    function separateNumber(number) {
        // Vérifier si le nombre est un entier positif
        if (Number.isInteger(number) && number >= 0) {
          // Convertir le nombre en chaîne de caractères
          var numberString = number.toString();
            
          // Séparer les chiffres en centaines
          var separatedNumber = '';
          for (var i = numberString.length - 1; i >= 0; i--) {
            separatedNumber = numberString.charAt(i) + separatedNumber;
            if (i > 0 && (numberString.length - i) % 3 === 0) {
              separatedNumber = ',' + separatedNumber;
            }
          }
      
          return separatedNumber;
        } else {
          return 'Veuillez fournir un entier positif.';
        }
      }

      const client = AuthContext();
      const [postes,setPostes] = useState([]);
      const [voirPLus,setVoirPlus] = useState(0);
      const [voirTel,setVoirTel] = useState(0);
      const [isLoading,setIsLoading] = useState(false);
      const navigate = useNavigate();

      useEffect(()=>{
            
        loadFavoritesItems()
           
      },[client]);


      const loadFavoritesItems=()=>{
        setIsLoading(true);
        if(client && client.id){
            
            try {
                axios.get(baseUrl.base+'/postes/?user-favorite='+client.id).then((resp)=>{
                    setPostes(resp.data);
                    setIsLoading(false);
                });
                
            } catch (error) {
                console.log(error);
            }
        }
      }



      const funcVoirPLus =(poste)=>{
        const _fromData = new FormData();
        _fromData.append('poste',poste);
        _fromData.append('user',client.id);
        try {
                axios.post(baseUrl.base+'/interet-voir-plus-click/',_fromData).then((resp)=>{
                if(resp.data.bool){}else{}
            })
        } catch (error) {
            
        }

        setVoirPlus(poste)
           
        
    }

    const funcVoirTel = (poste)=>{
        const _fromData = new FormData();
        _fromData.append('poste',poste);
        _fromData.append('user',client.id);
        try {
            axios.post(baseUrl.base+'/interet-voir-tel-click/',_fromData).then((resp)=>{
                if(resp.data.bool){}else{}
            })
        } catch (error) {
            
        }

        setVoirTel(poste)
    }

    const UnMarkSave =(poste)=>{
        try {
            axios.get(baseUrl.base+'/delete-favorite-user/?client='+client.id+'&poste='+poste).then((resp)=>{
                if(resp.data.bool){
                    axios.get(baseUrl.base+'/postes/?user-favorite='+client.id).then((resp)=>{
                        setPostes(resp.data);
                    })
                }else{}
            })
        } catch (error) {
            console.log(error);
        }
    }

    


    return (
        <PrincipaleComponent>
        <div class="container">
                 <div class="row g-4">
                
                 {/* Navbar de l'utilisateurs */}
                    <NavUser/>
                {/* end Navbar de l'utilisateur */}
                 
                 <div class="col-md-8 col-lg-6 vstack gap-4">
           
                     
                     <div class="card">
                 
                    {/*  <div class="h-200px rounded-top banner-profil" ></div> */}
                         
                         <div class="card-body py-0">
                         <div class="d-sm-flex align-items-start text-center text-sm-start">
                             {/* <div>
                             
                             <div class="avatar avatar-xxl mt-n5 mb-3">
                                 <img class="avatar-img rounded-circle border border-white border-3" src="/assets/images/avatar/placeholder.jpg" alt="" />
                             </div>
                             </div> */}
                             <div class="ms-sm-4 mt-sm-3">
                             
                             <h1 class="mb-0 h5 mt-2">Mes Favoris ({postes.length})</h1>
                         {/*  <p>250 connections</p> */}
                             </div>
                             
                            {/*  <div class="d-flex mt-3 mb-2 justify-content-center ms-sm-auto">
                             <button class="btn btn-info-soft me-2 btn-sm">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-info-circle mx-1" viewBox="0 0 16 16">
                                     <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                     <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
                                     </svg>
                                 Requête
                             </button> 
                             </div>  */}
                         </div>
                         
                         </div>

                         <div class="card-footer mt-3 pt-2 pb-0">
                                
                                <ul class="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
                                    <li class="nav-item"> <a class="nav-link active" >Mes Favorites  </a> </li>
                                {/*   <li class="nav-item"> <a class="nav-link" href="my-profile-videos.html"> Videos</a> </li>
                                    <li class="nav-item"> <a class="nav-link" href="my-profile-events.html"> Events</a> </li>
                                    <li class="nav-item"> <a class="nav-link" href="my-profile-activity.html"> Activity</a> </li> */}
                                </ul>
                            </div>
                         
                       
                     </div>
                                
                                
                     {postes.length == 0 && isLoading && <h4 class="mt-2 mb-3 text-body">Chargement...</h4>}

                     {postes.length == 0 && !isLoading &&
                        <div class="tab-pane fade show" id="group-tab-6">
                        <div class="card card-body">
                            <div class="my-sm-5 py-sm-5 text-center">
                            
                            <i class="display-1 text-muted bi bi-calendar-plus"> </i>
                            
                            <h4 class="mt-2 mb-3 text-body">Aucune Favoris</h4>
                            <button class="btn btn-primary-soft btn-sm" onClick={()=>navigate('/cl/')}> Retour à la page d'accueil </button>

                            
                            </div>
                        </div>
                        </div>
                    } 
             
                            {postes && 
                                postes?.map((poste,index)=>
                                    <Poste poste={poste} is_favoris={true} UnMarkSave={UnMarkSave}/>
                                )
                            }
                           {/*  {isLoading && <div className="text-center">Chargement...</div>} */}

                   

                    
                    
                  
                 
                 </div>
                 
                 <div class="col-lg-3"></div>
                 

                 </div> 
        </div>
        </PrincipaleComponent>
    )
}

export default FavoriteUserPage;