import { Link, useNavigate } from "react-router-dom";
import HeaderOff from "./HeaderOff";
import { useEffect, useState } from "react";
import BaseUrl from "../../BaseUrl";
import axios from "axios";
import PostePro from "../pro/compo/poste";
import PosteOff from "./Posteoff";
import Select from "react-select";
import Validate from "../../Validation";
import Swal from "sweetalert2";

const baseUrl = BaseUrl();
function DashOffPage()
{
    const navigate = useNavigate();
    const [errors,setErrorM] = useState({});
    const [mesPostes,setMesPostes] = useState([]);
    const [page, setPage] = useState(5);
    const [loading, setLoading] = useState(false);
    const [filterValue,setFiltreValue] = useState(''); 
    const [loadFilter,setLoadFilter] = useState(false);
    const [communes,setCommunes] = useState([]);
    const [isAuthTokenpro, setIsAuthTokenpro] = useState(localStorage.getItem('_token'));
    const [isAuthToken, setIsAuthToken] = useState(localStorage.getItem('_token_ucl'));
		const [pieceRqs,setPieceRqs] = useState([]);
		const [selectOptionCommune,setSelectOptionCommune] = useState(null);
		const [selectOptionPiece,setSelectOptionPiece] = useState(null);
    const [errorCommune,setErrorCommune] = useState("");
    const [errorPiece,setErrorPiece] = useState("");

    const [besoinData,setBesoinData] = useState({
      "prospect":"",
      "phone":"",
      "objet":"",
      "bg_max":"",
      "service":"",
      "email":""
    })

    function separateNumber(number) {
        // Vérifier si le nombre est un entier positif
        if (Number.isInteger(number) && number >= 0) {
          // Convertir le nombre en chaîne de caractères
          var numberString = number.toString();
      
          // Séparer les chiffres en centaines
          var separatedNumber = '';
          for (var i = numberString.length - 1; i >= 0; i--) {
            separatedNumber = numberString.charAt(i) + separatedNumber;
            if (i > 0 && (numberString.length - i) % 3 === 0) {
              separatedNumber = ',' + separatedNumber;
            }
          }
      
          return separatedNumber;
        } else {
          return 'Veuillez fournir un entier positif.';
        }
    }

    useEffect(()=>{
        if(isAuthToken !== null)
        {
            navigate('/cl/');
        }
        if(isAuthTokenpro !== null)
            {
                navigate('/ag/home/');
            }
        loadItemsrecent();  
        communeList();
        pieceList();


    },[]);

    function communeList()
    {
        try {
          axios.get(baseUrl.base+'/communes/?countrie='+1).then((resp)=>{
              setCommunes(resp.data);
          })
      } catch (error) {
          console.log(error);
      }


    }

    function pieceList()
    {
      try {
        axios.get(baseUrl.base+'/pieces/').then((resp)=>{
            setPieceRqs(resp.data);
        })
    } catch (error) {
        console.log(error);
    }
    }



const loadItems = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    if (filterValue ==""){
        const url = `${baseUrl.base}/postes/?result=${page}`; // Ajustez l'URL pour correspondre à votre point d'extrémité d'API Django
        axios.get(url)
        .then(response => {
            const newItems = response.data;
            setMesPostes(prevItems => [...prevItems, ...newItems]);
            setPage(prevPage => prevPage + 1);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
      });
    }else{
        const url = `${baseUrl.base}/postes/?next=${page}&filter=${filterValue}`; // Ajustez l'URL pour correspondre à votre point d'extrémité d'API Django
        axios.get(url)
        .then(response => {
            const newItems = response.data;
            setMesPostes(prevItems => [...prevItems, ...newItems]);
            setPage(prevPage => prevPage + 1);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
      });
    }
    
  };

  const loadItemsrecent = () => {
    if (loading) {
      return;
    }

    setLoading(true);
    if (filterValue ==""){
        const url = `${baseUrl.base}/postes/?recent`; // Ajustez l'URL pour correspondre à votre point d'extrémité d'API Django
        axios.get(url)
        .then(response => {
            const newItems = response.data;
            setMesPostes(newItems);
            // setPage(prevPage => prevPage + 1);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
      });
    }else{
        const url = `${baseUrl.base}/postes/?next=${page}&filter=${filterValue}`; // Ajustez l'URL pour correspondre à votre point d'extrémité d'API Django
        axios.get(url)
        .then(response => {
            const newItems = response.data;
            setMesPostes(prevItems => [...prevItems, ...newItems]);
            setPage(prevPage => prevPage + 1);
            setLoading(false);
        })
        .catch(error => {
            console.log(error);
            setLoading(false);
      });
    }
    
  };

  const handleScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight) {
      loadItems();
    }
  };

   useEffect(() => {
    window.addEventListener('scroll', handleScroll());
    return () => {
      window.removeEventListener('scroll', handleScroll());
    };
  }, [handleScroll()]);




  const request_ask=()=>{
    window.$("#modalCreateRequetes").modal('show');
  }
    


  const handleShowMore = () => {
    loadItems();
  };

  const showMoreButton =  (
    <>
        <button  class="btn btn-sm btn-loader btn-primary" onClick={handleShowMore}>
          <span class="load-text">Voir plus</span>
          <div class="load-icon">
            <div class="spinner-grow spinner-grow-sm" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </button>
    </>
   /*  <button onClick={handleShowMore}>Voir plus</button> */  
  );

  const optionCommunes = communes?.map((option)=>({
      value:`${option.id}`,
      label:`${option.libelle}`
  }));

  const optionPieces = pieceRqs?.map((option)=>({
      value:`${option.id}`,
      label:`${option.libelle}`
  }));

  const handleChange=(event)=>{
    setBesoinData({
      ...besoinData,
      [event.target.name]:event.target.value
    })
  }

  const handleChangeOptionCommune=(selectOption)=>{
      setSelectOptionCommune(selectOption.value);
  }

  const handleChangeOptionPiece=(selectOption)=>{
      setSelectOptionPiece(selectOption.value);
  }

  const submitForm=(e)=>{
    e.preventDefault();
    setErrorCommune("");
    setErrorPiece("");
    setErrorM(Validate(besoinData));

    const _formData = new FormData();

    if(besoinData.prospect !=="" && besoinData.objet !== "" && besoinData.phone !=="" && besoinData.email !=="" && (/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/).test(besoinData.email) && besoinData.phone.length == 10 && !isNaN(besoinData.phone)  &&  besoinData.bg_max !=="" && !isNaN(besoinData.bg_max) && besoinData.service !=="" && selectOptionCommune !== null && selectOptionPiece !==null ) 
    {
      _formData.append('commune',selectOptionCommune);
      _formData.append('piece',selectOptionPiece);
      _formData.append('prospect',besoinData.prospect);
      _formData.append('email',besoinData.email);
      _formData.append('objet',besoinData.objet);
      _formData.append('phone',besoinData.phone);
      _formData.append('bg_max',besoinData.bg_max.toString().replace(/\./g, ''));
      _formData.append('service',besoinData.service);

      setLoading(true)
      try {
        axios.post(baseUrl.base+'/save-requetes-besoin/',_formData).then((resp)=>{
            setLoading(false);

            if(resp.data.bool){
                
                window.$("#modalCreateRequetes").modal('hide');
                 Swal.fire({
                    title: 'FELICITATION',
                    text: "Requête envoyée a "+resp.data.all_agence+" promoteurs",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                  }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                  }); 
            }else{
                Swal.fire({
                    title: 'Erreur',
                    text: 'Un problème est subvenu !',
                    icon: 'error',
                    showCancelButton: false,
                    confirmButtonColor: 'red',
                    confirmButtonText: 'OK'
                  })
            }
        })
    } catch (error) {
        console.log(error);
    }
    }
    else
    {
      if(selectOptionPiece == null)
      {
        setErrorPiece("Ce champs est obligatoire");
      }
      if(selectOptionCommune == null)
      {      setErrorCommune("Ce champs est obligatoire");
       
      }
    }

  }

    return (
        <>
           <HeaderOff />
          <main>
             

                <div class="container">
                        <div class="row g-4">
                       
                        <div class="col-lg-2 "></div>
                        
                        <div class="col-lg-8 vstack gap-4 ">
                            {/* <div class="alert alert-danger alert-dismissible fade show mb-0" role="alert">
                                <strong>Vous êtes une agence professionnelle ou un annonceur.</strong>
                                <Link to="/auth-pro-account/" class="btn btn-xs btn-primary mx-2 mt-md-0 ms-md-4">Créer votre compte</Link>
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div> */}

                            <div class="row">
                        
                                <div class="d-flex gap-2 col">
                                    <div class="position-relative">
                                      <div class="card bg-info border border-2 border-dashed h-150px px-4 px-sm-5 shadow-none d-flex align-items-center justify-content-center text-center">
                                        <div>
                                          <a class="stretched-link btn btn-light rounded-circle icon-md" onClick={()=>request_ask()}><i class="fa-solid fa-plus"></i></a>
                                          <h6 class="mt-2 mb-0 small text-white">Quel est votre besoin ?</h6>
                                        </div>
                                      </div>
                                    </div>
                                </div>
{/* 
                                <div class="d-flex gap-2 col">
                                    <div class="position-relative">
                                      <div class="card border border-2 border-dashed h-150px px-4 px-sm-5 shadow-none d-flex align-items-center justify-content-center text-center">
                                        <div>
                                          <a class="stretched-link btn btn-light rounded-circle icon-md" href="#!"><i class="fa-solid fa-plus"></i></a>
                                          <h6 class="mt-2 mb-0 small">Agence ou Promoteur immobilier</h6>
                                        </div>
                                      </div>
                                    </div>

                                    <div id="stories" class="storiesWrapper stories-square stories user-icon carousel scroll-enable "></div>
                                </div> */}
                            </div>


                                <>
                                    {/* {loading && <div className="text-center">Chargement...</div>} */}
                                    {mesPostes.length == 0 && !loading && <div className="text-center">Aucune Annonces</div>}
                           

                                    {mesPostes && 
                                        mesPostes?.map((poste,index)=>
                                       <PosteOff poste={poste}/>
                                        )
                                    }
                                {loading && <div className="text-center">Chargement...</div>}
                                {showMoreButton}
                                {mesPostes.length == 0 && !loading &&
                                <div class="tab-pane fade show" id="group-tab-6">
                                    <div class="card card-body">
                                        <div class="my-sm-5 py-sm-5 text-center">
                                            <i class="display-1 text-muted bi bi-calendar-plus"> </i>
                                            <h4 class="mt-2 mb-3 text-body">Aucune Annonces</h4>
                                            <button class="btn btn-primary-soft btn-sm" onClick={()=>window.location.reload()}> Actualiser</button>

                                        </div>
                                    </div>
                                </div> 
                                }
                                </>

                     
                            
                          
                        </div>
                        
                        <div class="col-lg-2">


                        </div>
                        

                        </div> 
                    </div>
          </main>

          <div className="modal fade" id="modalCreateRequetes" data-bs-backdrop="static" data-bs-keyboard="true" tabindex="-1" role="dialog" aria-labelledby="modalLabelCreateRequetes" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <form className="modal-content" onSubmit={submitForm}>
                    <div className="modal-header">
                      <h5 className="modal-title" id="modalLabelCreateAlbum">Recevoir des recommandations par mail</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="row g-2">
                            <div className="col-sm-4">
                                <label className="form-label">Nom<span className="text-danger">*</span></label>
                                <input type="text" className="form-control"  name="prospect" onChange={handleChange}/>
                                {errors.prospect && <span className="text-danger">{errors.prospect}</span>}
                            </div>
                            <div className="col-sm-4">
                                <label className="form-label">Email <span className="text-danger">*</span></label>
                                <input type="text" className="form-control"  name="email" onChange={handleChange} placeholder="Saisissez votre adresse email"/>
                                {errors.email && <span className="text-danger">{errors.email}</span>}
                            </div>
                            <div className="col-sm-4">
                                <label className="form-label">Téléphone<span className="text-danger">*</span></label>
                                <input type="text" className="form-control"  name="phone" onChange={handleChange} placeholder="Ex: 0141079236"/>
                                {errors.phone && <span className="text-danger">{errors.phone}</span>}
                            </div>
                            <div className="col-sm-12">
                                <label className="form-label">Commune<span className="text-danger">*</span></label>
                                <Select options={optionCommunes} onChange={handleChangeOptionCommune}/>
                                {errorCommune !== "" && <span className="text-danger">{errorCommune}</span>}
                            </div>
                            <div className="col-sm-12">
                                <label className="form-label">Votre besoin (preciser la zone visée) <span className="text-danger">*</span></label>
                                <input type="text" className="form-control"  name="objet" onChange={handleChange}/>
                                {errors.objet && <span className="text-danger">{errors.objet}</span>}
                            </div>
                            <div className="col-sm-6">
                                <label className="form-label">Service<span className="text-danger">*</span></label>
                                <select className="form-select" name="service" onChange={handleChange}>
                                    <option value="" disabled selected>Achat ou location ...</option>
                                    <option value="ACHAT">Achat</option>
                                    <option value="LOCATION">Location</option>
                                </select>
                                {errors.service && <span className="text-danger">{errors.service}</span>}
                            </div>                            
                            <div className="col-sm-6">
                                <label className="form-label">Budget<span className="text-danger">*</span></label>
                                <input type="text" className="form-control" name="bg_max" onChange={handleChange} placeholder="Ex: 100000"/>
                                {errors.bg_max && <span className="text-danger">{errors.bg_max}</span>}
                            </div>

                            <div className="col-sm-12">
                                <label className="form-label">Nombre de pièce <span className="text-danger">*</span></label>
                                <Select options={optionPieces} onChange={handleChangeOptionPiece} />
                                {errorPiece !== "" && <span className="text-danger">{errorPiece}</span>}
                            </div>
                         
                          
    
                    </div>

                    </div>
                    <div className="modal-footer">
                        
                        
                        {!loading &&
                          <>
                          <button type="button" className="btn btn-danger-soft me-2" data-bs-dismiss="modal"> Fermer</button>
                            <button type="submit" className="btn btn-success">Enregistrer votre requête</button>
                          </>
                        }
                        {loading && <button  className="btn btn-success form-control" type="button" disabled>
                          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span class="visually-hidden">Loading...</span>
                          Recherche d'agences en cours...
                        </button>}
                    </div>
                </form>
            </div>
        </div>
        </>
    )
}

export default DashOffPage;