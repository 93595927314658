import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import AuthContext from "./BaseU";
import PrincipaleComponent from "./PrincipalComponent";
import BaseUrl from "../../BaseUrl";
import NavUser from "./compo/navUser";
import Validate from "../../Validation";

const baseUrl = BaseUrl();
function SettingsClient(){
    function generateUniqueID() {
        const timestamp = new Date().getTime().toString(); 
        const randomNum = Math.random().toString(36).substr(2, 9); 
      
        return timestamp + randomNum; 
    }

    const client = AuthContext();
    const location = useLocation();
    const [countries,setCountries] = useState([]);
    //const [image, setImage] = useState([]);
    const [errorPass,setErrorPass] = useState('');
    const [errorPassEg,setErrorPassEg] = useState('');
    const [loadingInfo,setLoadingInfo] = useState(false);
    const [loadingPwd,setLoadingPwd] = useState(false);
    const [errors,setErrorM] = useState({});

    const [clientData,setClientData] = useState({
        'email':'',
        'nom':'',
        'prenom':'',
        'identifiant':'',
        'tel':'',
        'age':'',
        'sexe':'',
        'countrie':'',
    });

    const [changePass,setChangePass] = useState({
        'cfmPass':'',
        'newPass':'',
        'oldPass':''
    })

    useEffect(()=>{
        setClientData({
            'email':client.email !=="null" ? client.email :'',
            'nom':client.nom,
            'prenom':client.prenom,
            'identifiant':client.identifiant,
            'tel':client.tel,
            'age':client.age,
            'sexe':client.sexe,
            'countrie':client.countrie?.id
        });

        try {
            axios.get(baseUrl.base+'/countrie/').then((resp)=>{
                setCountries(resp.data)
            })
        } catch (error) {
            console.log(error);
        }
    },[client]);

    const handleChange=(event)=>{
        setClientData({
            ...clientData,
            [event.target.name]:event.target.value
        })
    }

   /*  const onSelectFile = (event) => {
        setImage([]);
        setImage((image)=>image.concat(event.target.files[0]));
    };

    function deleteHandler(index) {
        const updatedImage = [...image];
        updatedImage.splice(index, 1);
        setImage(updatedImage);
      } */

      const handleChangePass = (event)=>{
        setChangePass({
          ...changePass,
          [event.target.name]:event.target.value
        })
      }

    const deleteLogoFunct=()=>{
      /*   Swal.fire({
            title: 'Attention !',
            text: 'Voulez-vous vraiment supprimer le logo ?',
            icon: 'info',
            showCancelButton: true,
            cancelButtonText:'NON',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OUI'
          }).then((result) => {
            if (result.isConfirmed) {
                const _fromData = new FormData();
                _fromData.append('agence_id',agence.id);
                try {
                    axios.post(baseUrl.base+'/delete-logo-agence/',_fromData).then((resp)=>{
                        if(resp.data.bool){window.location.reload();}
                    })
                } catch (error) {
                    console.log(error)
                }
                
            }
          }); */
        
    }

    const submitForm=()=>{
        setErrorM(Validate(clientData));

        if(clientData.email !=="" && (/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/).test(clientData.email) && clientData.tel.length == 10 && clientData.tel !=="" && !isNaN(clientData.tel)  && clientData.nom !=="" && clientData.prenom !=="" &&  clientData.countrie !=="" && clientData.age !==""){
            const _fromData = new FormData();
            _fromData.append('email',clientData.email);
            _fromData.append('nom',clientData.nom);
            _fromData.append('prenom',clientData.prenom);
            _fromData.append('countrie',clientData.countrie);
            _fromData.append('tel',clientData.tel);
            _fromData.append('sexe',clientData.sexe);
            _fromData.append('age',clientData.age);
            // console.log(_fromData);

            setLoadingInfo(true);
            try {
                axios.post(baseUrl.base+'/update-clients/'+client.id+'/',_fromData,{
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }).then((resp)=>{
                    if(resp.data.bool){
                        window.location.reload();
                    }else{
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Désolé ! Un problème est subvenu.',
                            showConfirmButton: false,
                            timer: 3000,
                            toast:true,
                            position:'top-right',
                            timerProgressBar:true
                        });
                        setLoadingInfo(false);
                    }
                })
            } catch (error) {
                console.log(error)
            } 
        }
    }

    const submitChangePass=()=>{
        setErrorPassEg('');
        setErrorPass('');
        if(changePass.oldPass !== '' && changePass.newPass !== '' && changePass.cfmPass !== ''){
          
          const _formData = new FormData();
          _formData.append('oldPass',changePass.oldPass);
          _formData.append('newPass',changePass.newPass);
          _formData.append('user_id',client?.id);
  
          if(changePass.newPass == changePass.cfmPass){
            setLoadingPwd(true);
            try {
              axios.post(baseUrl.base+'/change-pass-users/',_formData).then((resp)=>{
                 if(resp.data.bool == true){
                    Swal.fire({
                      position: 'top-end',
                      icon: 'success',
                      title: resp.data.msg,
                      showConfirmButton: false,
                      timer: 3000,
                      toast:true,
                      position:'top-right',
                      timerProgressBar:true
                    });
  
                    setChangePass({
                        'cfmPass':'',
                        'newPass':'',
                        'oldPass':''
                    })
                    setLoadingPwd(false)
                 }else{
                  setErrorPass(resp.data.msg);
                  setLoadingPwd(false)
                 }
              })
            } catch (error) {
              console.log(error);
            }
          }else{
            setErrorPassEg('Le nouveau mot de passe ne correspond pas');
          }
          
        }
  
      }


    return (
        <>
            <PrincipaleComponent>
            <div className="container">
                    <div className="row g-4">
                               {/* Navbar de l'utilisateurs */}
                               <NavUser/>      
                  {/* end Navbar de l'utilisateur */}

                        <div className="col-lg-6 vstack gap-4">
   
                        <div className="tab-content py-0 mb-0">

                        
                            <div className="tab-pane show active fade" id="nav-setting-tab-1">
                            
                            <div className="card mb-4">
                                
                                
                                <div className="card-header border-0 pb-0">
                                    <h1 className="h5 card-title">Mes informations personnelle</h1>
                                   {/*  <p className="mb-0">Ces informations concernent votre entreprise ou compte professionnel.</p> */}
                                </div>
                            
                                <form className="card-body" >
                        
                                    <div className="row g-3" >
                                    
                                        <div className="col-sm-6 col-lg-6">
                                            <label className="form-label">Nom</label>
                                            <input type="text" className="form-control" placeholder="" name="nom" onChange={handleChange} value={clientData.nom}/>
                                            {errors.nom && <span className="text-danger">{errors.nom}</span> }
                                        </div>
                                        <div className="col-sm-6 col-lg-6">
                                            <label className="form-label">Prénoms</label>
                                            <input type="text" className="form-control" placeholder="" name="prenom" onChange={handleChange} value={clientData.prenom}/>
                                            {errors.prenom && <span className="text-danger">{errors.prenom}</span> }
                                        </div>
                                    
                                        <div className="col-sm-6 col-lg-6">
                                        <label className="form-label">Tranche d'âge</label>
                                        <select className="form-select" name="age" value={clientData.age} onChange={handleChange}>
                                                <option value="" selected disabled>---</option>
                                                <option value="+23">+23</option>
                                                <option value="-23">-23</option>
                                        </select>
                                        {errors.age && <span className="text-danger">{errors.age}</span> }
                                        </div>

                                        <div className="col-sm-6 col-lg-6">
                                        <label className="form-label">Sexe {clientData.sexe == "" && <span className="text-info">Veillez complèter le champs sexe svp !</span>}</label>
                                        <select className="form-select" name="sexe" value={clientData.sexe} onChange={handleChange}>
                                                <option value="" selected disabled>---</option>
                                                <option value="HOMME">HOMME</option>
                                                <option value="FEMME">FEMME</option>
                                        </select>
                                        {errors.sexe && <span className="text-danger">{errors.sexe}</span> }
                                        </div>
                                    
                                    
                                        <div className="col-sm-6">
                                        <label className="form-label">Téléphone</label>
                                        <input type="text" className="form-control" placeholder="" name="tel" onChange={handleChange}  value={clientData.tel}/>
                                        {errors.tel && <span className="text-danger">{errors.tel}</span> }
                                        {/* 
                                        <a className="btn btn-sm btn-dashed rounded mt-2" href="#!"> <i className="bi bi-plus-circle-dotted me-1"></i>Add new phone number</a> */}
                                        </div>

                                        <div className="col-sm-6">
                                        <label className="form-label">Adresse e-mail </label>
                                        <input type="email" className="form-control" placeholder="" name="email" onChange={handleChange}  value={clientData.email}/>
                                        {errors.email && <span className="text-danger">{errors.email}</span> }
                                        </div>
                                        {/* <div className="col-12">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="allowChecked" checked />
                                            <label className="form-check-label" for="allowChecked">
                                            Allow anyone to add you to their team
                                            </label>
                                        </div>
                                        </div> */}
                                    
                                    
                                    
                                        
                                        
                                        <div className="col-12">
                                        <label className="form-label">Pays</label>
                                            <select className="form-select" name="countrie" onChange={handleChange} value={clientData.countrie}>
                                                    <option selected disabled>Select un pays ..</option>
                                                {/* {countries?.map((countrie,index)=>
                                                    <option value={countrie.id}>{countrie.libelle}</option>
                                                )} */}

                                        {countries && 
                                        countries.map((countrie,index)=>(
                                            <option value={countrie.id}>{countrie.libelle}</option>
                                        ))
                                        }
                                                
                                            </select>
                                            {errors.countrie && <span className="text-danger">{errors.countrie}</span> }
                                        </div>

                                    {/*  <div className="mb-3">
                                                <label className="form-label">Votre Logo</label>
                                            
                                                <div className="d-flex align-items-center">
                                                <div className="avatar-uploader me-3">
                                                
                                            
                                                    <div className="avatar-edit">
                                                    <input type='file' id="avatarUpload" onChange={onSelectFile} accept="image/png , image/jpeg, image/webp" />
                                                    <label for="avatarUpload"></label>
                                                    </div>
                                                
                                                    
                                                {agence.image == null && image.length ==0 &&
                                                    <div className="avatar avatar-xl position-relative">
                                                    <img id="avatar-preview" className="avatar-img rounded-circle border border-white border-3 shadow" src="/assets/images/avatar/placeholder.jpg" alt="" />
                                                    </div>
                                                }
                                                {image &&
                                                        image.map((img,index)=>
                                                        <div className="avatar avatar-xl position-relative">
                                                            <img id="avatar-preview" className="avatar-img rounded-circle border border-white border-3 shadow" src={img ? URL.createObjectURL(img): null} alt="" />
                                                        </div>
                                                        )
                                                }

                                                {agence.image != null && image.length == 0 &&
                                                    <div className="avatar avatar-xl position-relative">
                                                    <img id="avatar-preview" className="avatar-img rounded-circle border border-white border-3 shadow" src={agence.image} alt="" />
                                                    </div>
                                                }
                                                
                                                </div>
                                                
                                                {agence.image != null && image.length == 0 &&
                                                    <div className="avatar-remove">
                                                        <button type="button" id="avatar-reset-img" className="btn btn-danger" onClick={deleteLogoFunct}>Supprimer</button>
                                                    </div>
                                                }

                                                {image.length > 0 &&
                                                    <div className="avatar-remove">
                                                        <button type="button" id="avatar-reset-img" className="btn btn-light" onClick={deleteHandler}>Supprimer</button>
                                                    </div>
                                                }
                                            

                                                </div>
                                            
                                        </div> */}

                                        <div className="col-12 text-end">
                                        {!loadingInfo && <button type="button" className="btn btn-sm btn-primary mb-0" onClick={submitForm}>Modifier</button>}
                                        {loadingInfo && <button type="submit" className="btn btn-sm btn-primary mb-0" disabled>En chargement...</button>}
                                        </div>
                                    </div>
                                    
                                </form>
                        
                            </div>
                            
                            <div className="card">
                            
                                <div className="card-header border-0 pb-0">
                                <h5 className="card-title">Modifier votre mot de passe</h5>
                                <p className="mb-0">Vous pouvez changer votre mot de passe et le personnaliser.</p>
                                </div>
                            
                                <div className="card-body">
                                
                                <div className="row g-3">
                                
                                    <div className="col-12">
                                    <label className="form-label">Ancien mot de passe</label>
                                    <input type="password" className="form-control" name="oldPass" onChange={handleChangePass} value={changePass.oldPass}/>
                                        {errorPass !=='' && <span className="text-danger">{errorPass}</span>}
                                    </div>
                                
                                    <div className="col-12">
                                    <label className="form-label">Nouveau mot de passe</label>
                                    
                                    <div className="input-group">
                                        <input className="form-control fakepassword" type="password" id="psw-input" name="newPass"  onChange={handleChangePass} value={changePass.newPass} />
                                      
                                    </div>
                            
                                  
                                    </div>
                                
                                    <div className="col-12">
                                    <label className="form-label">Confirmer votre mot de passe</label>
                                    <input type="password" className="form-control" placeholder="" name="cfmPass" onChange={handleChangePass} value={changePass.cfmPass}/>
                                    {errorPassEg !=='' &&  <span className="text-danger">{errorPassEg}</span>}
                                    </div>
                                
                                    <div className="col-12 text-end">
                                    {!loadingPwd && <button type="submit" className="btn btn-primary mb-0" onClick={submitChangePass}>Modifier</button>}
                                    {loadingPwd && <button type="submit" className="btn btn-primary mb-0" disabled>Modifier</button>}
                                    </div>
                                </div>
                                
                                </div>
                            </div>
                            
                            </div>
                            
                            <div className="tab-pane fade" id="nav-setting-tab-2">
                            
                            <div className="card">
                            
                                <div className="card-header border-0 pb-0">
                                <h5 className="card-title">Notification</h5>
                                <p className="mb-0">Tried law yet style child. The bore of true of no be deal. Frequently sufficient to be unaffected. The furnished she concluded depending procuring concealed. </p>
                                </div>
                                
                                <div className="card-body pb-0">
                                
                                <ul className="list-group list-group-flush">
                                
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                                    <div className="me-2">
                                        <h6 className="mb-0">Likes and Comments</h6>
                                        <p className="small mb-0">Joy say painful removed reached end.</p>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked" checked />
                                    </div>
                                    </li>
                                
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                                    <div className="me-2">
                                        <h6 className="mb-0">Reply to My comments</h6>
                                        <p className="small mb-0">Ask a quick six seven offer see among.</p>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked2" checked />
                                    </div>
                                    </li>
                                
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                                    <div className="me-2">
                                        <h6 className="mb-0">Subscriptions</h6>
                                        <p className="small mb-0">Preference any astonished unreserved Mrs.</p>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked3" />
                                    </div>
                                    </li>
                                
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                                    <div className="me-2">
                                        <h6 className="mb-0">Birthdays</h6>
                                        <p className="small mb-0">Contented he gentleman agreeable do be</p>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked4" />
                                    </div>
                                    </li>
                                
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                                    <div className="me-2">
                                        <h6 className="mb-0">Events</h6>
                                        <p className="small mb-0">Fulfilled direction use continually.</p>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked5" checked />
                                    </div>
                                    </li>
                                    
                                    <li className="list-group-item px-0 py-3">
                                    
                                    <div className="accordion accordion-flush border-0" id="emailNotifications">
                                        
                                        <div className="accordion-item bg-transparent">
                                        <h2 className="accordion-header" id="flush-headingOne">
                                            <a href="#!" className="accordion-button mb-0 p-0 collapsed bg-transparent shadow-none" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                            <span>
                                                <span className="mb-0 h6 d-block">Email notifications</span>
                                                <small className="small mb-0 text-secondary">As hastened oh produced prospect. </small>
                                            </span>
                                            </a>
                                        </h2>
                                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#emailNotifications">
                                            <div className="accordion-body p-0 pt-3">
                                            
                                                <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="NotiSwitchCheckChecked6" checked/>
                                                <label className="form-check-label" for="NotiSwitchCheckChecked6">
                                                    Product emails
                                                </label>
                                                </div>
                                            
                                                <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value="" id="NotiSwitchCheckChecke7"/>
                                                <label className="form-check-label" for="NotiSwitchCheckChecke7">
                                                    Feedback emails
                                                </label>
                                                </div>
                                                <hr/>
                                            <div className="mt-3">
                                                <h6>Email frequency</h6>
                                                
                                                <div className="form-check">
                                                <input className="form-check-input" type="radio" name="NotiRadio" id="NotiRadio1"/>
                                                <label className="form-check-label" for="NotiRadio1">
                                                    Daily
                                                </label>
                                                </div>
                                        
                                                <div className="form-check">
                                                <input className="form-check-input" type="radio" name="NotiRadio" id="NotiRadio2" checked />
                                                <label className="form-check-label" for="NotiRadio2">
                                                    Weekly
                                                </label>
                                                
                                                <div className="form-check">
                                                <input className="form-check-input" type="radio" name="NotiRadio" id="NotiRadio3" />
                                                <label className="form-check-label" for="NotiRadio3">
                                                    Periodically
                                                </label>
                                                </div>
                                                
                                                <div className="form-check">
                                                <input className="form-check-input" type="radio" name="NotiRadio" id="NotiRadio4" checked/>
                                                <label className="form-check-label" for="NotiRadio4">
                                                    Off
                                                </label>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                    </li>
                                
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                                    <div className="me-2">
                                        <h6 className="mb-0">Push notifications</h6>
                                        <p className="small mb-0">Rendered six say his striking confined. </p>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked8" checked />
                                    </div>
                                    </li>
                                
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                                    <div className="me-2">
                                        <h6 className="mb-0">Weekly account summary <span className="badge bg-primary smaller"> Pro only</span> </h6>
                                        <p className="small mb-0">Rendered six say his striking confined. </p>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="NotiSwitchCheckChecked9" disabled />
                                    </div>
                                    </li>
                                </ul>
                                
                                
                                </div>
                            
                            <div className="card-footer pt-0 text-end border-0">
                                <button type="submit" className="btn btn-sm btn-primary mb-0">Save changes</button>
                            </div>
                            </div>
                            
                            </div>
                            
                            <div className="tab-pane fade" id="nav-setting-tab-3">
                            
                            <div className="card">
                                
                                <div className="card-header border-0 pb-0">
                                <h5 className="card-title">Privacy and safety</h5>
                                <p className="mb-0">See information about your account, download an archive of your data, or learn about your account deactivation options</p>
                                </div>
                                
                                <div className="card-body">
                                
                                <ul className="list-group">
                                    
                                    
                                    <li className="list-group-item d-md-flex justify-content-between align-items-start">
                                    <div className="me-md-3">
                                        <h6 className="mb-0">	Use two-factor authentication</h6>  
                                        <p className="small mb-0">Unaffected occasional thoroughly. Adieus it no wonders spirit houses. </p>
                                    </div>
                                    <button className="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i className="bi bi-pencil-square"></i> Change</button>
                                    </li>

                                    
                                    <li className="list-group-item d-md-flex justify-content-between align-items-start">
                                    <div className="me-md-3">
                                        <h6 className="mb-0">Login activity</h6>  
                                        <p className="small mb-0">Select the language you use on social</p>
                                    </div>
                                    <button className="btn btn-primary-soft btn-sm mt-1 mt-md-0" data-bs-toggle="modal" data-bs-target="#modalLoginActivity"> <i className="bi bi-eye"></i> View</button>
                                    </li>

                                    
                                    <li className="list-group-item d-md-flex justify-content-between align-items-start">
                                    <div className="me-md-3">
                                        <h6 className="mb-0">Manage your data and activity</h6>  
                                        <p className="small mb-0">Select a language for translation</p>
                                    </div>
                                    <button className="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i className="bi bi-pencil-square"></i> Change</button>
                                    </li>

                                    
                                    <li className="list-group-item d-md-flex justify-content-between align-items-start">
                                    <div className="me-md-3">
                                        <h6 className="mb-0">Search history</h6>  
                                        <p className="small mb-0">Choose to autoplay videos on social</p>
                                    </div>
                                    <button className="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i className="bi bi-pencil-square"></i> Change</button>
                                    </li>

                                    
                                    <li className="list-group-item d-md-flex justify-content-between align-items-start">
                                    <div className="me-md-3">
                                        <h6 className="mb-0">Permitted services</h6>  
                                        <p className="small mb-0">Choose if this feature appears on your profile</p>
                                    </div>
                                    <button className="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i className="bi bi-pencil-square"></i> Change</button>
                                    </li>
                                </ul>
                                
                                </div>
                                
                                <div className="card-footer pt-0 text-end border-0">
                                <button type="submit" className="btn btn-sm btn-primary mb-0">Save changes</button>
                                </div>
                            </div>
                            
                            </div>
                        
                            <div className="tab-pane fade" id="nav-setting-tab-4">
                            
                            <div className="card">
                            
                                <div className="card-header border-0 pb-0">
                                <h5 className="card-title">Who can connect with you?</h5>
                                <p className="mb-0">He moonlights difficult engrossed it, sportsmen. Interested has all Devonshire difficulty gay assistance joy. Unaffected at ye of compliment alteration to.</p>
                                </div>
                            
                                <div className="card-body">
                                
                                <div className="accordion" id="communications">
                                
                                    <div className="accordion-item bg-transparent">
                                    <h2 className="accordion-header" id="communicationOne">
                                        <button className="accordion-button mb-0 h6" type="button" data-bs-toggle="collapse" data-bs-target="#communicationcollapseOne" aria-expanded="true" aria-controls="communicationcollapseOne">
                                        Connection request
                                        </button>
                                    </h2>
                                
                                    <div id="communicationcollapseOne" className="accordion-collapse collapse show" aria-labelledby="communicationOne" data-bs-parent="#communications">
                                        <div className="accordion-body">
                                        
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="ComRadio" id="ComRadio5" />
                                            <label className="form-check-label" for="ComRadio5">
                                            Everyone on social (recommended)
                                            </label>
                                        </div>
                                        
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="ComRadio" id="ComRadio2" checked/>
                                            <label className="form-check-label" for="ComRadio2">
                                            Only people who know your email address
                                            </label>
                                        </div>
                                    
                                        <div className="form-check">
                                            <input className="form-check-input" type="radio" name="ComRadio" id="ComRadio3" />
                                            <label className="form-check-label" for="ComRadio3">
                                            Only people who appear in your mutual connection list
                                            </label>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                
                                    <div className="accordion-item bg-transparent">
                                    <h2 className="accordion-header" id="communicationTwo">
                                        <button className="accordion-button mb-0 h6 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#communicationcollapseTwo" aria-expanded="false" aria-controls="communicationcollapseTwo">
                                        Who can message you
                                        </button>
                                    </h2>
                                    
                                    <div id="communicationcollapseTwo" className="accordion-collapse collapse" aria-labelledby="communicationTwo" data-bs-parent="#communications">
                                        <div className="accordion-body">
                                        <ul className="list-group list-group-flush">
                                        
                                            <li className="list-group-item d-sm-flex justify-content-between align-items-center px-0 py-1 border-0">
                                            <div className="me-2">
                                                <p className="mb-0">Enable message request notifications</p>
                                            </div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="comSwitchCheckChecked" />
                                            </div>
                                            </li>
                                        
                                            <li className="list-group-item d-sm-flex justify-content-between align-items-center px-0 py-1 border-0">
                                            <div className="me-2">
                                                <p className="mb-0">Allow connections to add you on group </p>
                                            </div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="comSwitchCheckChecked2" checked />
                                            </div>
                                            </li>
                                            
                                            <li className="list-group-item d-sm-flex justify-content-between align-items-center px-0 py-1 border-0">
                                            <div className="me-2">
                                                <p className="mb-0">Allow Sponsored Messages </p>
                                                <p className="small">Your personal information is safe with our marketing partners unless you respond to their Sponsored Messages </p>
                                            </div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="comSwitchCheckChecked3" checked />
                                            </div>
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                    
                                    <div className="accordion-item bg-transparent">
                                    <h2 className="accordion-header" id="communicationThree">
                                        <button className="accordion-button mb-0 h6 collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#communicationcollapseThree" aria-expanded="false" aria-controls="communicationcollapseThree">
                                        How people can find you
                                        </button>
                                    </h2>
                                    
                                    <div id="communicationcollapseThree" className="accordion-collapse collapse" aria-labelledby="communicationThree" data-bs-parent="#communications">
                                        <div className="accordion-body">
                                        <ul className="list-group list-group-flush">
                                        
                                            <li className="list-group-item d-sm-flex justify-content-between align-items-center px-0 py-1 border-0">
                                            <div className="me-2">
                                                <p className="mb-0">Allow search engines to show your profile?</p>
                                            </div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="comSwitchCheckChecked4" checked />
                                            </div>
                                            </li>
                                        
                                            <li className="list-group-item d-sm-flex justify-content-between align-items-center px-0 py-1 border-0">
                                            <div className="me-2">
                                                <p className="mb-0">Allow people to search by your email address? </p>
                                            </div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="comSwitchCheckChecked5" />
                                            </div>
                                            </li>
                                        
                                            <li className="list-group-item d-sm-flex justify-content-between align-items-center px-0 py-1 border-0">
                                            <div className="me-2">
                                                <p className="mb-0">Allow Sponsored Messages </p>
                                                <p className="small">Your personal information is safe with our marketing partners unless you respond to their Sponsored Messages </p>
                                            </div>
                                            <div className="form-check form-switch">
                                                <input className="form-check-input" type="checkbox" role="switch" id="comSwitchCheckChecked6" checked />
                                            </div>
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                                
                                </div>
                            
                            <div className="card-footer pt-0 text-end border-0">
                                <button type="submit" className="btn btn-sm btn-primary mb-0">Save changes</button>
                            </div>
                            </div>
                            
                            </div>
                        
                            <div className="tab-pane fade" id="nav-setting-tab-5">
                            
                            <div className="card mb-4">
                            
                                <div className="card-header border-0 pb-0">
                                <h5 className="card-title">Messaging privacy settings</h5>
                                <p className="mb-0">As young ye hopes no he place means. Partiality diminution gay yet entreaties admiration. In mention perhaps attempt pointed suppose. Unknown ye chamber of warrant of Norland arrived. </p>
                                </div>
                        
                                <div className="card-body">
                                
                                <ul className="list-group list-group-flush">
                                    
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                                    <div className="me-2">
                                        <h6 className="mb-0">Enable message request notifications</h6>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="msgSwitchCheckChecked" checked />
                                    </div>
                                    </li>
                                    
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                                    <div className="me-2">
                                        <h6 className="mb-0">Invitations from your network</h6>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="msgSwitchCheckChecked2" checked/>
                                    </div>
                                    </li>
                                    
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                                    <div className="me-2">
                                        <h6 className="mb-0">Allow connections to add you on group</h6>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="msgSwitchCheckChecked3"/>
                                    </div>
                                    </li>
                                    
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                                    <div className="me-2">
                                        <h6 className="mb-0">Reply to comments</h6>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="msgSwitchCheckChecked4"/>
                                    </div>
                                    </li>
                                    
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                                    <div className="me-2">
                                        <h6 className="mb-0">Messages from activity on my page or channel</h6>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="msgSwitchCheckChecked5" checked/>
                                    </div>
                                    </li>
                                    
                                    <li className="list-group-item d-flex justify-content-between align-items-center px-0">
                                    <div className="me-2">
                                        <h6 className="mb-0">Personalise tips for my page</h6>
                                    </div>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" role="switch" id="msgSwitchCheckChecked6" checked/>
                                    </div>
                                    </li>
                                </ul>
                                
                            </div>
                            
                            <div className="card-footer pt-0 text-end border-0">
                                <button type="submit" className="btn btn-sm btn-primary mb-0">Save changes</button>
                            </div>
                            </div>
                            
                            <div className="card">
                            
                                <div className="card-header border-0 pb-0">
                                <h5 className="card-title">Messaging experience</h5>
                                <p className="mb-0">Arrived off she elderly beloved him affixed noisier yet. </p>
                                </div>
                            
                                <div className="card-body">
                                
                                <ul className="list-group list-group-flush">
                                    
                                    <li className="list-group-item d-sm-flex justify-content-between align-items-center px-0">
                                    <div className="me-2">
                                        <h6 className="mb-0">Read receipts and typing indicators</h6>
                                    </div>
                                    <button className="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i className="bi bi-pencil-square"></i> Change</button>
                                    </li>
                                    
                                    <li className="list-group-item d-sm-flex justify-content-between align-items-center px-0">
                                    <div className="me-2">
                                        <h6 className="mb-0">Message suggestions</h6>
                                    </div>
                                    <button className="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i className="bi bi-pencil-square"></i> Change</button>
                                    </li>
                                    
                                    <li className="list-group-item d-sm-flex justify-content-between align-items-center px-0">
                                    <div className="me-2">
                                        <h6 className="mb-0">Message nudges</h6>
                                    </div>
                                    <button className="btn btn-primary-soft btn-sm mt-1 mt-md-0"> <i className="bi bi-pencil-square"></i> Change</button>
                                    </li>
                                </ul>
                                
                                </div>
                            
                            <div className="card-footer pt-0 text-end border-0">
                                <button type="submit" className="btn btn-sm btn-primary mb-0">Save changes</button>
                            </div>
                            </div>
                            
                            </div>
                            
                            <div className="tab-pane fade" id="nav-setting-tab-6">
                            
                            <div className="card">
                            
                                <div className="card-header border-0 pb-0">
                                <h5 className="card-title">Delete account</h5>
                                <p className="mb-0">He moonlights difficult engrossed it, sportsmen. Interested has all Devonshire difficulty gay assistance joy. Unaffected at ye of compliment alteration to.</p>
                                </div>
                        
                                <div className="card-body">
                                
                                <h6>Before you go...</h6>
                                <ul>
                                    <li>Take a backup of your data <a href="#">Here</a> </li>
                                    <li>If you delete your account, you will lose your all data.</li>
                                </ul>
                                <div className="form-check form-check-md my-4">
                                    <input className="form-check-input" type="checkbox" value="" id="deleteaccountCheck" />
                                    <label className="form-check-label" for="deleteaccountCheck">Yes, I'd like to delete my account</label>
                                </div>
                                <a href="#" className="btn btn-success-soft btn-sm mb-2 mb-sm-0">Keep my account</a>
                                <a href="#" className="btn btn-danger btn-sm mb-0">Delete my account</a>
                                
                                </div>
                            
                            </div>
                            
                            </div>
                        

                        </div>
                        
                        </div>
                    </div>
            </div>
            </PrincipaleComponent>

            <div className="modal fade" id="modalLoginActivity" tabindex="-1" aria-labelledby="modalLabelLoginActivity" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                
                    <div className="modal-header">
                        <h5 className="modal-title" id="modalLabelLoginActivity">Where You're Logged in </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <ul className="list-group list-group-flush">
                        
                        <li className="list-group-item d-flex justify-content-between align-items-center px-0 pb-3">
                            <div className="me-2">
                            <h6 className="mb-0">London, UK</h6>
                            <ul className="nav nav-divider small">
                                <li className="nav-item">Active now </li>
                                <li className="nav-item">This Apple iMac </li>
                            </ul>
                            </div>
                            <button className="btn btn-sm btn-primary-soft"> Logout </button>
                        </li>
                        
                        <li className="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                            <div className="me-2">
                            <h6 className="mb-0">California, USA</h6>
                            <ul className="nav nav-divider small">
                                <li className="nav-item">Active now </li>
                                <li className="nav-item">This Apple iMac </li>
                            </ul>
                            </div>
                            <button className="btn btn-sm btn-primary-soft"> Logout </button>
                        </li>
                        
                        <li className="list-group-item d-flex justify-content-between align-items-center px-0 py-3">
                            <div className="me-2">
                            <h6 className="mb-0">New york, USA</h6>
                            <ul className="nav nav-divider small">
                                <li className="nav-item">Active now </li>
                                <li className="nav-item">This Windows </li>
                            </ul>
                            </div>
                            <button className="btn btn-sm btn-primary-soft"> Logout </button>
                        </li>
                        
                        <li className="list-group-item d-flex justify-content-between align-items-center px-0 pt-3">
                            <div className="me-2">
                            <h6 className="mb-0">Mumbai, India</h6>
                            <ul className="nav nav-divider small">
                                <li className="nav-item">Active now </li>
                                <li className="nav-item">This Windows </li>
                            </ul>
                            </div>
                            <button className="btn btn-sm btn-primary-soft"> Logout </button>
                        </li>
                        </ul>
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SettingsClient;