export default function Validate(values){
    const errors = {} 
    

    const pattern_email = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/;
    
    if(values.email === ""){
        errors.email = "Champs email est obligatoire";
    }else if(!pattern_email.test(values.email)){
        errors.email = "Email saisir est incorrect";
    }

    if(values.emailUser === ""){
        errors.emailUser = "Champs email est obligatoire";
    }else if(!pattern_email.test(values.emailUser)){
        errors.emailUser = "Email saisir est incorrect";
    }

    if(values.nom === ""){
        errors.nom = "Le nom est obligatoire";
    }

    if(values.prenom === ""){
        errors.prenom = "Le prenom est obligatoire";
    }

    if(values.prospect === ""){
        errors.prospect = "Ce champs est obligatoire";
    }

    if(values.objet === ""){
        errors.objet = "Ce champs est obligatoire";
    }

    if(values.phone === ""){
        errors.phone = "Le téléphone est obligatoire";
    }

    if(values.phone?.length !== 10){
        errors.phone = "Le numéro de téléphone doit contenir 10 chiffres.";
    }
    if(isNaN(values.phone) ){
        errors.phone = "Le numéro est invalide";
    }

    if(values.bg_max === ""){
        errors.bg_max = "Le budget est obligatoire";
    }

    if(isNaN(values.bg_max) ){
        errors.bg_max = "Le montant saisi est invalide";
    }

    if(values.tel === ""){
        errors.tel = "Le téléphone est obligatoire";
    }
    if(values.tel?.length !== 10){
        errors.tel = "Le numéro de téléphone doit contenir 10 chiffres.";
    }
    if(isNaN(values.tel) ){
        errors.tel = "Le numéro est invalide";
    }

    if(values.password === ""){
        errors.password = "Mot de passe est obligatoire";
    }

    if(values.cfm_password === ""){
        errors.cfm_password = "Veuillez confirmer le mot de passe";
    }

    if(values.cfm_password !== values.password){
        errors.cfm_password = "Le mot de passe ne correspond pas.";
    }

    if(values.countrie === ""){
        errors.countrie = "champs obligatoire";
    }

    if(values.age === ""){
        errors.age = "Age est obligatoire";
    }

    if(values.identifiant === ""){
        errors.identifiant = "cet champs est obligatoire";
    }

    if(values.raison_sociale === ""){
        errors.raison_sociale = "La raison sociale est obligatoire";
    }

    if(values.info_complementaire === ""){
        errors.info_complementaire = "champs est obligatoire";
    }

    if(values.num_registre === ""){
        errors.num_registre = "Registre de commerce obligatoire";
    }

    if(values.sexe === ""){
        errors.sexe = "civilité est obligatoire";
    }

    if(values.libelle === ""){
        errors.libelle = "ce champs est obligatoire";
    }

    if(values.montant === ""){
        errors.montant = "ce champs est obligatoire";
    }

    if(isNaN(values.montant)){
        errors.montant = "Le montant est invalide";
    }


    if(values.description === ""){
        errors.description = "ce champs est obligatoire";
    }

    if(values.piece === ""){
        errors.piece = "ce champs est obligatoire";
    }

    if(values.category === ""){
        errors.category = "ce champs est obligatoire";
    }

    if(values.service === ""){
        errors.service = "ce champs est obligatoire";
    }

    if(values.caution === ""){
        errors.caution = "ce champs est obligatoire";
    }

    if(values.quatier === ""){
        errors.quatier = "ce champs est obligatoire";
    }

    if(values.contact === ""){
        errors.contact = "ce champs est obligatoire";
    }

    if(values.contact?.length !== 10){
        errors.contact = "Le numéro de téléphone doit contenir 10 chiffres.";
    }
    if(isNaN(values.contact) ){
        errors.contact = "Le numéro est invalide";
    }

    if(values.adresse_is_coloc === ""){
        errors.adresse_is_coloc = "ce champs est obligatoire";
    }

    if(values.etat === ""){
        errors.etat = "ce champs est obligatoire";
    }

    if(values.couleur === ""){
        errors.couleur = "ce champs est obligatoire";
    }
    if(values.chassis === ""){
        errors.chassis = "ce champs est obligatoire";
    }
    if(values.boite === ""){
        errors.boite = "ce champs est obligatoire";
    }

    if(values.transmission === ""){
        errors.transmission = "ce champs est obligatoire";
    }

    if(values.carburant === ""){
        errors.carburant = "ce champs est obligatoire";
    }

    if(values.model === ""){
        errors.model = "ce champs est obligatoire";
    }

    if(values.ecran === ""){
        errors.ecran = "ce champs est obligatoire";
    }

    if(values.processeur === ""){
        errors.processeur = "ce champs est obligatoire";
    }

    if(values.systemeOs === ""){
        errors.systemeOs = "ce champs est obligatoire";
    }

    if(values.clavier === ""){
        errors.clavier = "ce champs est obligatoire";
    }

    if(values.usb === ""){
        errors.usb = "ce champs est obligatoire";
    }

    if(values.disque === ""){
        errors.disque = "ce champs est obligatoire";
    }

    if(values.memoire === ""){
        errors.memoire = "ce champs est obligatoire";
    }

    if(values.autonomie === ""){
        errors.autonomie = "ce champs est obligatoire";
    }

    if(values.typeOs === ""){
        errors.typeOs = "ce champs est obligatoire";
    }

    if(values.carteGraphique === ""){
        errors.carteGraphique = "ce champs est obligatoire";
    }

    if(values.clavierEclaire === ""){
        errors.clavierEclaire = "ce champs est obligatoire";
    }

    if(values.notes === ""){
        errors.notes = "ce champs est obligatoire";
    }




    return errors;

    

}