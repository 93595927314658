import axios from "axios";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import BaseUrl from "../../BaseUrl";
import Validate from "../../Validation";


const baseUrl = BaseUrl();
function ForgotPassword(){
    const navigate = useNavigate();
    const [errors,setErrorM] = useState({});
    const [msgAlert,setMsgAlert] = useState('');
    const [change,setChangePass]= useState({
        'email':''
    });
    const [msgerrorAlert,setMsgerrorAlert] = useState('');
    const [loading,setLoading] = useState(false);

    const handleChange=(event)=>{
        setChangePass({
            ...change,
            [event.target.name]:event.target.value
        });
    }

    const submitForm=()=>{
        setErrorM(Validate(change))
        const _formData = new FormData();
        _formData.append('email',change.email);
        setMsgAlert('');

        if(change.email !='' && (/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/).test(change.email)){
          setLoading(true);
            try {
                axios.post(baseUrl.base+'/pass-oublie/',_formData).then((resp)=>{
                    if(resp.data.bool){
                      setMsgAlert('Votre nouveau mot de passe à été envoyé a votre adresse e-mail. Merci !');
                        // Swal.fire({
                        //     title: 'FELICITATION',
                        //     text: 'Votre nouveau mot de passe à été envoyé sur votre numéro.Merci !',
                        //     icon: 'success',
                        //     showCancelButton: false,
                        //     confirmButtonColor: '#3085d6',
                        //     confirmButtonText: 'OK'
                        //   }).then((result) => {
                        //     if (result.isConfirmed) {
                        //         navigate('/');
                        //     }
                        //   }); 
                    }else{
                        setMsgerrorAlert(resp.data.msg);
                    }
                    setLoading(false);
                })
            } catch (error) {
              setLoading(false);
                console.log(error);
            }
        }

    }
    return (
        <main>
  
       
        <div class="container">
          <div class="row justify-content-center align-items-center vh-100 py-5">
           
            <div class="col-sm-10 col-md-8 col-lg-7 col-xl-6 col-xxl-5">
              
              <div class="card card-body rounded-3 text-center p-4 p-sm-5">

              {msgAlert !=="" || msgerrorAlert !=="" ?
            <div className={msgAlert !== "" ? "alert alert-success" : "alert alert-danger"} role="alert">
              <h4 className="alert-heading">{msgAlert !== "" ? "Félicitation !" : "Attention !"}</h4>
              <p>{msgAlert != "" ? msgAlert : msgerrorAlert}</p>
            </div>
            :""}
               
                <h1 class="mb-2">Mon mot de passe</h1>
                <p>Avez vous oublié votre mot de passe ?</p>
                
                <div class="mt-3">
                  
                  <div class="mb-3">
                    
                    <div class="input-group input-group-lg">
                      <input class="form-control fakepassword" type="email" id="psw-input" name="email" placeholder="Saisissez votre adresse email" onChange={handleChange} />
                    
                    </div>
                    {errors.email && <p className="text-danger">{errors.email}</p>} 
                    
                  </div>
                 
                 
                  
                  <div class="d-grid"><button type="submit" class={!loading ? "btn btn-lg btn-primary" : "btn btn-lg btn-primary disabled"} onClick={submitForm}>Valider</button></div>
                  <p className="mb-0 mt-3"><Link  to="/login">Retour </Link> </p> 
                </div>
        
              </div>
              
            </div>
          </div> 
        </div>
        
      
      </main>
    )
}

export default ForgotPassword;