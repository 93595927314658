import { useEffect, useState } from "react";
import AuthContext from "./BaseU";
import PrincipaleComponent from "./PrincipalComponent";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import PosteHomeUser from "./createAn/PosteHomeUser";
import UpdatePosteHomeUser from "./createAn/UpdatePosteHomeUser";
import DetailPosteUser from "./createAn/DetailPosteUser";
import PosteColocUser from "./createAn/PosteColocUser";
import EnginPosteUser from "./createAn/EnginPosteUser";
import ComputerPosteUser from "./createAn/ComputerPosteUser";
import PhonePosteUser from "./createAn/PhonePosteUser";
import BaseUrl from "../../BaseUrl";

const baseUrl = BaseUrl();
function PagePoste(){
    const client = AuthContext();
    const location = useLocation();
    const [nameComponent,setNameComponent] = useState('liste');
    const [postes,setPostes] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [detailPoste,setDetailPoste] = useState([]);
    const [visibleItems, setVisibleItems] = useState(5);
    const [hasMoreItems, setHasMoreItems] = useState(true);
    const [totalPostes,setTotalPostes] = useState(0);
    const [loadingItems,setLoadingItems] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
      loadItems();

      try {
        if(client && client.id){
          
          axios.get(baseUrl.base+'/postes/?client='+client.id).then((resp) => {
              setTotalPostes(resp.data.length);
          });
        }
        
      } catch (error) { 
        console.log(error); 
      }
    }, [client,visibleItems]);

    const loadItems = () => {
      // Appel à l'API Django pour récupérer les éléments supplémentaires
      // Utilisez l'URL appropriée pour votre configuration Django
      setLoadingItems(true)
      try {
        
        if(client && client.id){
          axios.get(baseUrl.base+'/postes/?agence='+client.id+'&page='+visibleItems).then((resp) => {
            if(resp.data.length > 0){
              setPostes(resp.data);
            }else{
              setHasMoreItems(false);
            }

            setLoadingItems(false);
            
          });
        }
        
      } catch (error) { 
        console.log(error); 
      }
    };

    const handleShowMore = () => {
      setVisibleItems(prevVisibleItems => prevVisibleItems + 3);  
    };

    const showMoreButton = hasMoreItems && (
      <>
          <button onClick={handleShowMore} role="button" class="btn btn-sm btn-loader btn-primary-soft" >
            <span class="load-text">  Voir plus </span>
            <div class="load-icon">
              <div class="spinner-grow spinner-grow-sm" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </button>
      </>
     /*  <button onClick={handleShowMore}>Voir plus</button> */  
    );

    const DetailComponent =(poste,sect) =>{ 

      try {
        axios.get(baseUrl.base+'/poste/'+poste+'/').then((resp)=>{
          setDetailPoste(resp.data);
        }) 
      } catch (error) {
        console.log(error);
      }
  
      if(sect == 1){
        setNameComponent('detail');
      }
  
      if(sect == 2){
        setNameComponent('update');
      }
  
      
    }

    const disabledPoste = (id)=>{
      Swal.fire({
        title: 'ATTENTION !',
        icon: "question",
        text: 'Êtes-vous sûrs de vouloir retirer cet élément.',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonText:"NON",
        confirmButtonText: 'OUI',
        cancelButtonColor:"red"
      }).then((result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          try {
              axios.get(baseUrl.base+'/disabled-poste/'+id+'/').then((resp)=>{
                axios.get(baseUrl.base+'/postes/?agence='+client.id+'&page='+visibleItems).then((resp)=>{
                  setPostes(resp.data);
                });
                Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Poste definitivement désactivé avec succès !',
                  showConfirmButton: false,
                  timer: 3000,
                  toast:true,
                  position:'top-right',
                  timerProgressBar:true
                });
                setIsLoading(false);
              });
          } catch (error) {
              console.log(error)
          }
        
        }
      }); 

      
  }


    return (
        <>
            <PrincipaleComponent>
                <div className="container">
                    <div className="row g-4">

                    
                    <div className="col-lg-3">

                    
                        <div className="d-flex align-items-center d-lg-none">
                        <button className="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSideNavbar" aria-controls="offcanvasSideNavbar">
                            <span className="btn btn-primary"><i className="fa-solid fa-sliders-h"></i></span>
                            <span className="h6 mb-0 fw-bold d-lg-none ms-2">Menu</span>
                        </button>
                        </div>
                        
                        <nav className="navbar navbar-expand-lg mx-0"> 
                        <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasSideNavbar">
                            
                            <div className="offcanvas-header">
                            <button type="button" className="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>

                            
                            <div className="offcanvas-body d-block px-2 px-lg-0">
                            
                            <div className="card overflow-hidden">
                                
                                <div className="h-50px banner-post-create" ></div>
                                
                                <div className="card-body pt-0">
                                    <div className="text-center">
                                    
                                    <div className="avatar avatar-lg mt-n5 mb-3">
                                     <a href="#!"><img className="avatar-img rounded border border-white border-3" src="/assets/images/avatar/placeholder.jpg" alt="" /></a>
                                    </div>
                                    
                                    <h6 className="mb-3"> <a href="#!">{client?.nom} {client?.prenom} </a> </h6>
                                    {/* <small>Web Developer at Webestica</small> 
                                    <p className="mt-3">I'd love to change the world, but they won’t give me the source code.</p>*/}

                                    
                                    <div className="hstack gap-2 gap-xl-3 justify-content-center">
                                    
                                    <div>
                                        <h6 className="mb-0 text-danger">{totalPostes}</h6>
                                        <small>Annonces</small>
                                    </div>
                                    
                                     {/* <div className="vr"></div> */}
                                    
                    
                                    </div>
                                    
                                </div>

                                
                                <hr/>

                                
                                <ul className="nav nav-link-secondary flex-column fw-bold gap-2">
                                  <li class="nav-item cursor-pointer">
                                  <a class="nav-link" onClick={()=>{navigate('/cl/');window.location.reload()}} > <img class="me-2 h-20px fa-fw" src="/assets/images/icon/indicator-icon.svg" alt="" /><span>Accueil </span></a>
                                  </li>
                                    <li className="nav-item cursor-pointer">
                                      <a className={nameComponent == 'liste' ?  "nav-link active" :  "nav-link"}  onClick={()=>setNameComponent('liste')}> <img className="me-2 h-20px fa-fw" src="/assets/images/icon/task-done-outline-filled.svg" alt="" /><span>Mes Annonces </span></a>
                                    </li>
                                   {/*  <li className="nav-item cursor-pointer">
                                      <a className={nameComponent == 'maison' ?  "nav-link active" :  "nav-link"}  onClick={()=>setNameComponent('maison')}> <img className="me-2 h-20px fa-fw" src="/assets/images/icon/home-outline-filled.svg" alt="" /><span>Poster une Maison </span></a>
                                    </li> */}
                                    {/* <li className="nav-item cursor-pointer">
                                      <a className={nameComponent == 'coloc' ?  "nav-link active" :  "nav-link"} onClick={()=>setNameComponent('coloc')}> <img className="me-2 h-20px fa-fw" src="/assets/images/icon/handshake-outline-filled.svg" alt="" /><span>Rechercher Colocataire </span></a>
                                    </li> */}
                           {/*          <li className="nav-item cursor-pointer">
                                      <a className={nameComponent == 'engin' ?  "nav-link active" :  "nav-link"} onClick={()=>setNameComponent('engin')}> <img className="me-2 h-30px fa-fw" src="/assets/images/icon/1361484_205.jpeg" alt="" /><span>Poster Vehicules </span></a>
                                    </li>
                                    <li className="nav-item cursor-pointer">
                                      <a className={nameComponent == 'ordinateur' ?  "nav-link active" :  "nav-link"}  onClick={()=>setNameComponent('ordinateur')}> <img className="me-2 h-20px fa-fw" src="/assets/images/icon/computer_icon.svg" alt="" /><span>Ordinateurs </span></a>
                                    </li>
                                    <li className="nav-item cursor-pointer">
                                      <a className={nameComponent == 'phone' ?  "nav-link active" :  "nav-link"}  onClick={()=>setNameComponent('phone')}> <img className="me-2 h-20px fa-fw" src="/assets/images/icon/phone-icon-filled.svg" alt="" /><span>Téléphones </span></a>
                                    </li> */}
                           {/*  <li className="nav-item">
                                    <a className="nav-link" href="events.html"> <img className="me-2 h-20px fa-fw" src="/assets/images/icon/calendar-outline-filled.svg" alt="" /><span>Events </span></a>
                                    </li>
                                    <li className="nav-item">
                                    <a className="nav-link" href="groups.html"> <img className="me-2 h-20px fa-fw" src="/assets/images/icon/chat-outline-filled.svg" alt="" /><span>Groups </span></a>
                                    </li>
                                    <li className="nav-item">
                                    <a className="nav-link" href="notifications.html"> <img className="me-2 h-20px fa-fw" src="/assets/images/icon/notification-outlined-filled.svg" alt="" /><span>Notifications </span></a>
                                    </li>
                                    <li className="nav-item">
                                    <a className="nav-link" href="settings.html"> <img className="me-2 h-20px fa-fw" src="/assets/images/icon/cog-outline-filled.svg" alt="" /><span>Settings </span></a>
                                    </li> */}
                                </ul>
                                
                                </div>
                            
                              {/*   <div className="card-footer text-center py-2">
                                <Link className="btn btn-link btn-sm" to='/cl/'>Retour </Link>
                                </div> */}
                            </div>
                            

                            </div>
                        </div>
                        </nav>
                    
                    </div>
                    
                    <div className="col-md-8 col-lg-6 vstack gap-4">
                     
                     
                      
                       
                        {nameComponent == 'liste' &&
                               <div class="card">
                               
                               <div class="card-header  pb-1">
                                 <h5 class="card-title "> Listes des annonces  <button className="btn btn-primary btn-sm border rounded mb-1 float-end"  onClick={()=>setNameComponent('coloc')}>Ajouter</button> </h5> 
                                 {isLoading &&
                                 <>
                                    <div class="spinner-border spinner-border-sm" role="status">
                                        <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow spinner-grow-sm" role="status">
                                      <span class="sr-only">Loading...</span>
                                  </div>
                                </>
                                 }
                                 
                               </div>
                         
                               <div class="card-body">
                               {loadingItems && <div className="text-center">Chargement...</div>}
                               {postes.length == 0 && !loadingItems && <div className="text-center">Aucune Annonces</div>}
                               {postes &&
                                  postes?.map((poste,index)=>
                                  <div className={poste.is_active? "d-md-flex align-items-center mb-4" : "d-md-flex align-items-center mb-4 disable"} key={index}>
                                      
                                  {/*  <div class="avatar me-3 mb-3 mb-md-0">
                                     <a href="#!"> <img class="avatar-img rounded-circle" src="/assets/images/avatar/01.jpg" alt=""/> </a>
                                   </div> */}
                                   
                                   <div class="w-100">
                                     <div class="d-sm-flex align-items-start">
                                       <h6 class="mb-0"><Link  onClick={()=>DetailComponent(poste.id,1)}>{poste.libelle} </Link></h6>
                                       <p class="small ms-sm-2 mb-0">{poste.immobilier?.commune?.libelle} {poste.immobilier?.quatier} {poste?.adresse_is_coloc} </p> 
                                   </div>
                                   
                                   <ul class="avatar-group mt-1 list-unstyled align-items-sm-center">
                                      
                                      <li class="avatar avatar-xxs">
                                        <div class="avatar-img rounded-circle bg-primary"><span class="smaller text-white position-absolute top-50 start-50 translate-middle">+{poste.interet_click_voir_plus}</span></div>
                                      </li>
                                      <li class="small ms-3">
                                        vues
                                      </li>
                                    </ul>
                                   
                                 </div>
                                 
                                 <div class="ms-md-auto d-flex">
                                   <button class="btn btn-danger-soft btn-sm mb-0 me-2" onClick={()=>disabledPoste(poste.id)}> Retirer </button>
                                   <button class="btn btn-primary-soft btn-sm mb-0" onClick={()=>DetailComponent(poste.id,2)}> Modifier </button>
                                 </div>
                                   </div>
                                 )
                                }
                               
                   
                               
                              
                           <div class="d-grid">
                          
                           {showMoreButton}
                           
                           </div>
                   
                           </div>
                           
                           </div>
                        }
                        {/*  {nameComponent == 'maison' &&
                            <PosteHomeUser client={client}/>
                        } */}
                        
                        {nameComponent == 'engin' &&
                            <EnginPosteUser client={client} />
                        }

                        {nameComponent == 'coloc' &&
                            <PosteColocUser client={client}/>
                        }

                        {nameComponent == 'ordinateur' &&
                            <ComputerPosteUser client={client} />
                        }

                        {nameComponent == 'phone' &&
                            <PhonePosteUser client={client} />
                        }

                        {nameComponent == 'detail' &&
                          <DetailPosteUser poste={detailPoste}  />
                        } 

                        {nameComponent =='update' &&
                          <UpdatePosteHomeUser poste={detailPoste} client={client}/>
                        }


                        
                        
                    </div>

                    </div> 
                </div>
            </PrincipaleComponent>

           
             {/* <div class="preloader">
              <div class="preloader-item">
                <div class="spinner-grow text-primary"></div>
              </div>
            </div>  */}

        </>
    )
}

export default PagePoste;