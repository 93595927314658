import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import BaseUrl from "../../BaseUrl";
import PosteOff from "./Posteoff";
import HeaderOff from "./HeaderOff";

const baseUrl = BaseUrl();
function MesReponsesNotAuth(){

    const [isAuthTokenpro, setIsAuthTokenpro] = useState(localStorage.getItem('_token'));
    const [isAuthToken, setIsAuthToken] = useState(localStorage.getItem('_token_ucl'));
    const navigate = useNavigate();

    function separateNumber(number) {
        // Vérifier si le nombre est un entier positif
        if (Number.isInteger(number) && number >= 0) {
          // Convertir le nombre en chaîne de caractères
          var numberString = number.toString();
      
          // Séparer les chiffres en centaines
          var separatedNumber = '';
          for (var i = numberString.length - 1; i >= 0; i--) {
            separatedNumber = numberString.charAt(i) + separatedNumber;
            if (i > 0 && (numberString.length - i) % 3 === 0) {
              separatedNumber = ',' + separatedNumber;
            }
          }
      
          return separatedNumber;
        } else {
          return 'Veuillez fournir un entier positif.';
        }
      }

      const params = new URLSearchParams(window.location.search);
      const repId = params.get('rep');
      const slug = params.get('others');
      const location = useLocation();
      const [voirPLus,setVoirPlus] = useState(0);
      const [voirTel,setVoirTel] = useState(0);
      const [loading, setLoading] = useState(false);
      const [postes,setPostes] = useState([]);
      const [requeteSelect,setRequeteSelect] = useState([]);

      useEffect(()=>{
        if(isAuthToken !== null)
            {
                navigate('/cl/');
            }
        if(isAuthTokenpro !== null)
            {
                navigate('/ag/home/');
            }
        mesReponseProduits();
        myQuery();

      },[repId,slug]);


      const mesReponseProduits=()=>{
        if (repId){
            setLoading(true);
            try {
                axios.get(baseUrl.base+'/postes/?reponse='+repId).then((resp)=>{
                    setPostes(resp.data);
                    setLoading(false);
                })
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
      }

      const myQuery=()=>{
        if (repId){
            setLoading(true);
            try {
                axios.get(baseUrl.base+'/all-requetes/?queryId='+repId).then((resp)=>{
                    setRequeteSelect(resp.data[0]);
                    setLoading(false);
                })
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
      }



    return (
        <>
        <HeaderOff />
        <main>
            <div class="container">
                 <div class="row g-4">
                
                 <div class="col-lg-2 "></div>
                 
                 <div class="col-md-8 col-lg-6 vstack gap-4">
                     
                     <div class="card">
                 
                    {/*  <div class="h-200px rounded-top banner-profil" ></div> */}
                         
                         <div class="card-body py-0">
                         <div class="d-sm-flex align-items-start text-center text-sm-start">
                          
                             <div class="ms-sm-4 mt-sm-3">
                             
                             <h6 class="mb-3 ">Vous avez reçu <span className="text-danger">{postes.length}</span> recommandations pour la requête  <span className="bg-success">{requeteSelect?.objet}</span></h6>
                         {/*  <p>250 connections</p> */}
                             </div>
                             
                            
                         </div>
                         
                         </div>

                       
                         
                       
                     </div>

              
                     {postes && 
                        postes?.map((poste,index)=>
                            <PosteOff poste={poste} index={index} />
                        )
                    }
                    
                    
           
                 
                 </div>
                 
                 <div class="col-lg-2"></div>
                 

                 </div> 
             </div>
        </main>
        </>

    )
}

export default MesReponsesNotAuth;