import { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import BaseUrl from "../../../BaseUrl";


const baseUrl = BaseUrl();
function BestEntreprise(){
	const [agences,setAgences] = useState([]);
	useEffect(()=>{
		try {
            axios.get(baseUrl.base+'/pro-agences/?page='+5).then((resp)=>{
                setAgences(resp.data);
            })
        } catch (error) {
            console.log(error);
        }
	},[]);

    return (
        <div class="col-sm-6 col-lg-12">
						<div class="card">
							
							<div class="card-header pb-0 border-0">
								<h5 class="card-title mb-0 ">Meilleurs Entreprises</h5>
							</div>
							
							
							<div class="card-body">

								{agences?.map((agence,index)=>
									<div class="hstack gap-2 mb-3">
									
									<div class="avatar avatar-story">
										<Link to={`/pro/${agence.slug}/`}> <img class="avatar-img rounded-circle" src={agence.image} alt="" /> </Link>
									</div>
									
									<div class="overflow-hidden">
										<Link class="h6 mb-0" to={`/pro/${agence.slug}/`}>{agence.raison_sociale} </Link>
									
									</div>
								</div>
								)}
								
								
								<div class="d-grid mt-3">
									<Link class="btn btn-sm btn-primary-soft" to="/agences-professionnelle/">Voir plus</Link>
								</div>
							</div>
							
						</div>
        </div>
    )
}

export default BestEntreprise;