import axios from "axios";
import { useEffect, useState } from "react";
import Select from "react-select";
import Validate from "../../../Validation";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import BaseUrl from "../../../BaseUrl";

const baseUrl = BaseUrl();
function PostMaison({agence}){

    function generateUniqueID() {
        const timestamp = new Date().getTime().toString(); 
        const randomNum = Math.random().toString(36).substr(2, 9); 
      
        return timestamp + randomNum; 
    }

    const navigate = useNavigate();
    const [errors,setErrorM] = useState({});
    const [selectedImages, setSelectedImages] = useState([]);
    const [communes,setCommunes] = useState([]);
    const [pieces,setPieces] = useState([]);
    const [categories,setCategories]= useState([]);
    const [services,setServices] = useState([]);
    const [selectedCommune, setSelectedCommune] = useState(null);
    const [image, setImage] = useState([]);
    const [loading,setLoading] = useState(false);
    const [valueDescription,setValueDescription] = useState('');
    const [errorDescription,setErrorDescription] = useState('');
    const [errorCommune,setErrorCommune] = useState('');
    
    const [postData,setPostData] = useState({
        'libelle':'',
        'montant':'',
        'description':'',
        'contact':agence.user? agence.user?.tel : '',
        'piece':'',
        'category':'',
        'service':'',
        'caution':'0',
        'quatier':''
    });

    useEffect(()=>{

        try {
            axios.get(baseUrl.base+'/communes/?countrie='+agence.countrie?.id+'').then((resp)=>{
                setCommunes(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/pieces/').then((resp)=>{
                setPieces(resp.data);
            })
        } catch (error) {
            console.log(error)
        }

        try {
            axios.get(baseUrl.base+'/category/?tag=01').then((resp)=>{
                setCategories(resp.data);
            })
        } catch (error) {
            console.log(error)
        }

        try {
            axios.get(baseUrl.base+'/services/').then((resp)=>{
                setServices(resp.data);
            })
        } catch (error) {
            console.log(error)
        }
    },[agence]);

    const optionscommunes = communes?.map((commune)=>({
        value: commune.id,
        label: `${commune.libelle}`
        
    }))

    const onSelectFile = (event) => {

        setImage((image)=>image.concat(event.target.files[0]));
        
    };

      function deleteHandler(index) {
        const updatedImage = [...image];
        updatedImage.splice(index, 1);
        setImage(updatedImage);
      }

      const handleChange=(event)=>{
        setPostData({
            ...postData,
            [event.target.name]:event.target.value
        });

      }




      const handleChangeCommune = (selectedOption)=>{
        setSelectedCommune(selectedOption.value);
      }


    const submitForm=()=>{

        setErrorM(Validate(postData));

        console.log(valueDescription);
        console.log(errorCommune);

        setErrorCommune("");
        if(postData.libelle !== '' && postData.montant !==''  && !isNaN(postData.montant)  && postData.service !=='' && postData.category !=='' && postData.quatier !=='' && selectedCommune !== null && postData.caution !==''  && postData.contact !="" && postData.contact?.length == 10 && !isNaN(postData.contact) && valueDescription !=="" && !isNaN(postData.contact) && postData.contact?.length == 10 && !isNaN(postData.montant)){
            
            if(["1","2","5","6","9"].includes(postData.category) && postData.piece == "")
            {
                return;
            }
            const _fromData = new FormData();

            _fromData.append('libelle',postData.libelle);
            _fromData.append('montant',postData.montant.toString().replace(/\./g, ''));
            _fromData.append('description',valueDescription);
            _fromData.append('contact',postData.contact);
            _fromData.append('commune',selectedCommune);
            _fromData.append('quatier',postData.quatier);
            _fromData.append('category',postData.category);
            _fromData.append('service',postData.service);
            _fromData.append('caution',postData.caution);
            _fromData.append('user',agence.user?.id);
            _fromData.append('piece',postData.piece);
            
            
            Array.from(image).forEach((item, index) => {
                const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
                const originalExtension = item.name.split('.').pop();
                const newFileName = `${currentTimeInSeconds}_${index}_${postData.montant}_${generateUniqueID()}.${originalExtension}`;
                const photo = new File([item], newFileName, { type: item.type });
                _fromData.append('images', photo);
            });
          
            setLoading(true);
            try {
                axios.post(baseUrl.base+'/post-home/',_fromData,{
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }).then((resp)=>{
                    if(resp.data.bool == true){
                       window.location.reload();
                    }else{
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Désolé ! Un problème est subvenu.',
                            showConfirmButton: false,
                            timer: 3000,
                            toast:true,
                            position:'top-right',
                            timerProgressBar:true
                          });
                          setLoading(true);
                    }
                    
                })
            } catch (error) {
                console.log(error);
            }
        }else{
            if(valueDescription == "")
            {
                setErrorDescription('La description est obligatoire');
            }
            if(selectedCommune == null)
            {
                setErrorCommune("La commune est obligatoire");

            }
        }
        
       

    }

 
    const modules = {
        toolbar: [
          [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
          [{size: ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{'list': 'ordered'}, {'list': 'bullet'}, 
           {'indent': '-1'}, {'indent': '+1'}],
          ['link'],
          ['clean']
        ],
      };
      
      const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link'
      ];
    return (
        <>
            <div class="card">
                        
                        <div class="card-header border-0 pb-0">
                            <h1 class="h4 card-title mb-0">Faire une annonce </h1>
                        </div>
                        
                        <div class="card-body">
                            <div class="row g-3">
                            
                            <div class="col-12">
                                <label class="form-label">Titre de l'annonce <span className="text-danger">*</span></label>
                                <input type="text" class="form-control" placeholder="Ex: Studio en location Abobo Marché" name="libelle" onChange={handleChange} value={postData.libelle} />
                                {errors.libelle && <span className="text-danger">{errors.libelle}</span> }
                            </div>
                            
                            <div class="col-sm-6 col-lg-4">
                                <label class="form-label">Montant <span className="text-danger">*</span></label>
                                <input type="text" class="form-control" placeholder="Ex: 60000" name="montant" onChange={handleChange} value={postData.montant} />
                                {errors.montant && <span className="text-danger">{errors.montant}</span> }
                            </div>
                            
                            <div class="col-sm-6 col-lg-4">
                                <label class="form-label">Contact </label>
                                <input type="text" class="form-control" placeholder="Ex: 0171808441" name="contact" onChange={handleChange} value={postData.contact} />
                                {errors.contact && <span className="text-danger">{errors.contact}</span> }
                            </div>
                            
                            <div class="col-sm-6 col-lg-4">
                                <label class="form-label">Type de service <span className="text-danger">*</span></label>
                                <select class="form-select js-choice" name="service" onChange={handleChange} >
                                    <option value="" selected disabled>Choisir un service ...</option>
                                    {services?.map((service,index)=>
                                        <option value={service.id}>{service.libelle}</option>
                                    )}
                                
                                </select>
                                {errors.service && <span className="text-danger">{errors.service}</span> }
                            </div>

                            <div class="col-sm-6 col-lg-6">
                                <label class="form-label">Commune <span className="text-danger">*</span></label>
                                <Select options={optionscommunes} onChange={handleChangeCommune} />
                                {errorCommune !=="" && <span className="text-danger">{errorCommune}</span> }
                            </div>
                            
                            <div class="col-sm-6">
                                <label class="form-label">Lieu <span className="text-danger">*</span></label>
                                <input type="text" class="form-control" placeholder="Ex: Abobo marché près de la mairie" name="quatier" onChange={handleChange} value={postData.quatier} />
                                {errors.quatier && <span className="text-danger">{errors.quatier}</span> }
                            </div>

                            <div class="col-sm-4 col-lg-4">
                                <label class="form-label">Catégorie <span className="text-danger">*</span></label>
                                <select class="form-select js-choice" name="category" onChange={handleChange} >
                                <option value="" selected disabled>Choisir ...</option>
                                    {categories?.map((categorie,index)=>
                                        <option value={categorie.id}>{categorie.libelle}</option>
                                    )}
                                </select>
                                {errors.category && <span className="text-danger">{errors.category}</span> }
                            </div>
                            {["1","2","5","6","9"].includes(postData.category) &&
                                <>
                                    <div class="col-sm-4 col-lg-4">
                                        <label class="form-label">Nombre de pièce <span className="text-danger">*</span></label>
                                        <select class="form-select js-choice" name="piece" onChange={handleChange}>
                                            <option value="" selected disabled>Choisir ...</option>
                                            {pieces?.map((piece,index)=>
                                                <option value={piece.id}>{piece.libelle}</option>
                                            )}
                                        </select>
                                        {errors.piece && <span className="text-danger">{errors.piece}</span> }
                                    </div>
                                    
                                    <div class="col-lg-4">
                                        <label class="form-label">Caution</label> 
                                        <input type="number" class="form-control" value={postData.caution} name="caution" onChange={handleChange} />
                                        {/* {errors.caution && <span className="text-danger">{errors.caution}</span> } */}
                                    </div>
                                </>
                            }

                            
                            <div class="col-12">
                                <label class="form-label">Description <span className="text-danger">*</span></label>
                                <ReactQuill theme="snow"  value={valueDescription} onChange={setValueDescription}  modules={modules} formats={formats}/>
                                {/* <textarea class="form-control" rows="3" value={postData.description} name="description" onChange={handleChange} ></textarea> */}
                                {valueDescription == "" && <span className="text-danger">{errorDescription}</span> }
                            </div>
                            
                            
                            <hr/>

                            
                            <div class="col-12 ">
                                <h5 class="card-title mb-0 col-6">Images Associées ({image? image.length : 0}) </h5>{/*{image?.length > 6 ?<span className="text-danger col-6">Vous pouvez ajouter que 6 images</span> : ''}*/}
                            </div>
                                <div class="container">
                               
                                <div class="card mt-3">
                                    <div class="card-body">

                                        <div class="row mb-3">
                                            <div class="col-sm-10">
                                            <div class="tab-pane fade show active" id="tab-1">
                                                <div class="row g-3">

                                                
                                                    <div class="col-6 col-lg-3">
                                                        <div class=" h-100 pb-3 rounded text-center d-flex align-items-center justify-content-center position-relative">
                                                            <label class="btn stretched-link label-img" >
                                                            <input className="input-none"
                                                                type="file"
                                                                onChange={onSelectFile}
                                                                multiple
                                                                accept="image/png , image/jpeg, image/webp"
                                                                />
                                                                <i class="fa-solid fa-camera-retro fs-1"></i>
                                                                <h6>Ajouter Photo</h6>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    
                        
                                                {image &&
                                                    image.map((img, index) => {
                                                        return (
                                                     <div class="col-6 col-lg-3 position-relative" key={index}>
                                                        <div class="position-absolute bottom-0 end-0">
                                                        
                                                            <div class="dropdown mb-2 me-3">
                                                                <button class="btn icon-sm bg-danger text-white rounded-circle" id="photoActionEdit3" onClick={() => deleteHandler(index)}>
                                                                    <i class="bi bi-trash-fill"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                       
                                                            <img class="rounded img-fluid" src={img ? URL.createObjectURL(img): null}  alt="" />
                                                        
                                                    </div> 

                                                    );
                                                     })}
                                                    
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                </div>
                            
                            <div class="col-12 text-end">
                                {image.length > 0 && !loading &&  <button type="button" class="btn btn-primary mb-0" onClick={submitForm} >Enregistrer</button>}
                                {image.length == 0 && !loading && <button type="button" class="btn btn-primary mb-0"  disabled>Enregistrer</button>}
                                {image.length > 0 && loading && <button type="button" class="btn btn-primary mb-0"  disabled>Enregistrer</button>}
                            </div>
                            </div>
                        </div>
                        
                    </div>
        </>
    )
}

export default PostMaison;