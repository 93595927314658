import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import BaseUrl from "../../BaseUrl";
import { jwtDecode } from "jwt-decode";

const baseUrl = BaseUrl();
function AuthContextAgence(){
    const [isAuthToken, setIsAuthToken] = useState(localStorage.getItem('_token'));
    const [agence,setAgence] = useState([]);
    const navigate = useNavigate();
    const token = localStorage.getItem('_token') !== null ? localStorage.getItem('_token') : null;
    const exp = token !== null ? jwtDecode(token).exp : null;
    const isExpired =exp !==null ? new Date() > new Date(exp * 1000) : true;
 
    console.log(isExpired);
    useEffect(()=>{
        if(isExpired){
            localStorage.removeItem('_token');
            navigate('/auth-pro-account/');
        }
        if(isAuthToken !== null){
            try {
                axios.get(baseUrl.base+'/agence/?token='+isAuthToken,{
                    headers:{
                        'Content-Type':'application/json'
                    },
                    credentials:'include'
                }).then((resp)=>{
                    if(resp.data.failed == true){
                        setIsAuthToken(null);
                        setAgence(null);
                        localStorage.removeItem('_token');
                        navigate('/auth-pro-account/');
                    }
                    setAgence(resp.data[0]);
                })
            } catch (error) {
                console.log(error);
            }
        }

    },[]);

    return agence;
}

export default AuthContextAgence;