import axios from "axios";
import { useEffect, useState } from "react";
import Select from "react-select";
import Validate from "../../../Validation";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import BaseUrl from "../../../BaseUrl";

const baseUrl = BaseUrl();
function EnginPosteUser({client}){

    function generateUniqueID() {
        const timestamp = new Date().getTime().toString(); 
        const randomNum = Math.random().toString(36).substr(2, 9); 
      
        return timestamp + randomNum; 
    }

    const navigate = useNavigate();
    const [errors,setErrorM] = useState({});
    const [couleurs,setCouleurs] = useState([]);
    const [transmissions,setTransmissions] = useState([]);
    const [categories,setCategories]= useState([]);
    const [services,setServices] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null);
    const [selectedMarque, setSelectedMarque] = useState(null);
    const [selectedAnne, setSelectedAnne] = useState(null);
    const [image, setImage] = useState([]);
    const [marques,setMarques] = useState([]);
    const [models,setModels] = useState([]);
    const [annees,setAnnes] = useState([]);
    const [etats,setEtatEngins] = useState([]);
    const [boites,setBoites] = useState([]);
    const [carburants,setCarburants] = useState([]);
    const [chassis,setChassis] = useState([]);

    const [loading,setLoading] = useState(false);
    
    const [postData,setPostData] = useState({
        'libelle':'',
        'montant':'',
        'description':'',
        'contact':client? client.tel : '',
        'etat':'',
        'category':'',
        'service':'',
        'vitesse':'',
        'cylindre':'',
        'couleur':'',
        'transmission':'',
        'boite':'',
        'model':'',
        'carburant':'',
        'chassis':'',
        'kilometrage':'',
        'poids':''
    });

    useEffect(()=>{
        try {
            axios.get(baseUrl.base+'/marques/?category=01').then((resp)=>{
                setMarques(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/annee-engins/').then((resp)=>{
                setAnnes(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/etat-engins/').then((resp)=>{
                setEtatEngins(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/couleurs/').then((resp)=>{
                setCouleurs(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/transmissions/').then((resp)=>{
                setTransmissions(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/type-de-boites/').then((resp)=>{
                setBoites(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/carburants/').then((resp)=>{
                setCarburants(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/chassis/').then((resp)=>{
                setChassis(resp.data);
            })
        } catch (error) {
            console.log(error);
        }

        try {
            axios.get(baseUrl.base+'/category/?tag=02').then((resp)=>{
                setCategories(resp.data);
            })
        } catch (error) {
            console.log(error)
        }

        try {
            axios.get(baseUrl.base+'/services/').then((resp)=>{
                setServices(resp.data);
            })
        } catch (error) {
            console.log(error)
        }

        

    },[client]);

    const optionsmarques = marques?.map((marque)=>({
        value: marque.id,
        label: `${marque.libelle}`
        
    }));

    /* const optionsmodels = models.map((model)=>({
        value: model.id,
        label: `${model.libelle}`
        
    })); */

    const optionsannes = annees?.map((annee)=>({
        value: annee.id,
        label: `${annee.libelle}`
        
    }));

    const onSelectFile = (event) => {

        setImage((image)=>image.concat(event.target.files[0]));
        
    };

    function deleteHandler(index) {
        const updatedImage = [...image];
        updatedImage.splice(index, 1);
        setImage(updatedImage);
      }

      const handleChange=(event)=>{
        setPostData({
            ...postData,
            [event.target.name]:event.target.value
        });

      }

      const handleChangeMarque = (selectedOption)=>{
        setSelectedMarque(selectedOption.value);
        /* try {
            axios.get(baseUrl.base+'/model-engins/?marque='+selectedOption.value).then((resp)=>{
                setModels(resp.data);
            })
        } catch (error) {
            console.log(error);
        } */
      }

      /* const handleChangeModels=(selectedOption)=>{
        setSelectedModel(selectedOption.value);
      } */

      const handleChangeAnnee=(selectedOption)=>{
        setSelectedAnne(selectedOption.value);
      }

      const submitForm=()=>{
        setErrorM(Validate(postData));
        if(postData.libelle !="" && postData.montant !="" && postData.boite !="" && postData.transmission !="" && postData.couleur !="" && postData.contact !="" && postData.category !="" && postData.description !="" && postData.service !="" && postData.etat !="" && postData.carburant !="" && postData.chassis !="" && selectedAnne != null && selectedMarque !=null && postData.model !=""){
            const _fromData = new FormData();

            _fromData.append('libelle',postData.libelle);
            _fromData.append('montant',postData.montant);
            _fromData.append('description',postData.description);
            _fromData.append('model',postData.model);
            _fromData.append('contact',postData.contact);
            _fromData.append('marque',selectedMarque);
            _fromData.append('annee',selectedAnne);
            _fromData.append('etat',postData.etat);
            _fromData.append('category',postData.category);
            _fromData.append('service',postData.service);
            _fromData.append('vitesse',postData.vitesse);
            _fromData.append('cylindre',postData.cylindre);
            _fromData.append('couleur',postData.couleur);
            _fromData.append('transmission',postData.transmission);
            _fromData.append('boite',postData.boite);
            _fromData.append('carburant',postData.carburant);
            _fromData.append('chassis',postData.chassis);
            _fromData.append('kilometrage',postData.kilometrage);
            _fromData.append('poids',postData.poids)
            _fromData.append('user',client.id);

            Array.from(image).forEach((item, index) => {
                const currentTimeInSeconds = Math.floor(new Date().getTime() / 1000);
                const originalExtension = item.name.split('.').pop();
                const newFileName = `${currentTimeInSeconds}_${index}_${postData.montant}_${generateUniqueID()}.${originalExtension}`;
                const photo = new File([item], newFileName, { type: item.type });
                _fromData.append('images', photo);
            });

            //console.log(_fromData);
            setLoading(true);
             try {
                axios.post(baseUrl.base+'/post-engins/',_fromData,{
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }).then((resp)=>{
                    if(resp.data.bool){
                        window.location.reload();
                    }else{
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Désolé ! Un problème est subvenu.',
                            showConfirmButton: false,
                            timer: 3000,
                            toast:true,
                            position:'top-right',
                            timerProgressBar:true
                          });
                          setLoading(false);
                    }
                })
            } catch (error) {
                console.log(error);
            } 

        }
      }

      

    return (
        <>
           <div class="card">
                        
                        <div class="card-header border-0 pb-0">
                            <h1 class="h4 card-title mb-0">Annonce Vehicule</h1>
                        </div>
                        
                        <div class="card-body">
                            <div class="row g-3">
                            
                            <div class="col-12">
                                <label class="form-label">Titre de l'annonce</label>
                                <input type="text" class="form-control" placeholder="Ex: Toyota Corolla année 2017 essence boîte auto " name="libelle" onChange={handleChange} value={postData.libelle} />
                                {errors.libelle && <span className="text-danger">{errors.libelle}</span>}
                            </div>
                            
                            <div class="col-sm-6 col-lg-6">
                                <label class="form-label">Montant</label>
                                <input type="text" class="form-control" placeholder="Ex: 60000" name="montant" onChange={handleChange} value={postData.montant} />
                                {errors.montant && <span className="text-danger">{errors.montant}</span>}
                            </div>
                            
                            <div class="col-sm-6 col-lg-6">
                                <label class="form-label">Contact</label>
                                <input type="text" class="form-control" placeholder="Ex: 0171808441" name="contact" onChange={handleChange} value={postData.contact}/>
                                {errors.contact && <span className="text-danger">{errors.contact}</span>}
                            </div>
                            
                            <div class="col-sm-6 col-lg-4">
                                <label class="form-label">Type de service</label>
                                <select class="form-select js-choice" name="service" onChange={handleChange} >
                                    <option value="" selected disabled>Choisir un service ...</option>
                                    {services?.map((service,index)=>
                                        <option value={service.id}>{service.libelle}</option>
                                    )}
                                
                                </select>
                                {errors.service && <span className="text-danger">{errors.service}</span> }
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <label class="form-label">Catégorie</label>
                                <select class="form-select js-choice" name="category" onChange={handleChange} >
                                <option value="" selected disabled>Choisir ...</option>
                                    {categories?.map((categorie,index)=>
                                        <option value={categorie.id}>{categorie.libelle}</option>
                                    )}
                                </select>
                                {errors.category && <span className="text-danger">{errors.category}</span> }
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <label class="form-label">Etat </label>
                                <select class="form-select js-choice" name="etat" onChange={handleChange} >
                                <option value="" selected disabled>Choisir ...</option>
                                    {etats?.map((etat,index)=>
                                        <option value={etat.id}>{etat.libelle}</option>
                                    )}
                                </select>
                                {errors.etat && <span className="text-danger">{errors.etat}</span> }
                            </div>

                            <div class="col-sm-6 col-lg-4">
                                <label class="form-label">Marques</label>
                                <Select options={optionsmarques} onChange={handleChangeMarque} />
                                {selectedMarque ==null && <span className="text-danger">ce champs est obligatoire</span> }
                            </div>
                            <div class="col-sm-6 col-lg-4">
                                <label class="form-label">Models</label>
                                <input type="text" class="form-control"  name="model" onChange={handleChange} value={postData.model}/>
                                {errors.model && <span className="text-danger">{errors.model}</span>}
                            </div>
                            <div class="col-sm-6 col-lg-4">
                                <label class="form-label">Années</label>
                                <Select options={optionsannes} onChange={handleChangeAnnee} />
                                {selectedAnne ==null && <span className="text-danger">ce champs est obligatoire</span> }
                            </div>

                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Couleur </label>
                                <select class="form-select js-choice" name="couleur" onChange={handleChange} value={postData.couleur}>
                                <option value="" selected disabled>Choisir ...</option>
                                    {couleurs?.map((couleur,index)=>
                                        <option value={couleur.id}>{couleur.libelle}</option>
                                    )}
                                </select>
                                {errors.couleur && <span className="text-danger">{errors.couleur}</span> }
                              
                            </div>

                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Boîtes </label>
                                <select class="form-select js-choice" name="boite" onChange={handleChange} value={postData.boite}>
                                <option value="" selected disabled>Choisir ...</option>
                                    {boites?.map((boite,index)=>
                                        <option value={boite.id}>{boite.libelle}</option>
                                    )}
                                </select>
                                {errors.boite && <span className="text-danger">{errors.boite}</span> }
                              
                            </div>
                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Transmission </label>
                                <select class="form-select js-choice" name="transmission" onChange={handleChange} value={postData.transmission}>
                                <option value="" selected disabled>Choisir ...</option>
                                    {transmissions?.map((transmission,index)=>
                                        <option value={transmission.id}>{transmission.libelle}</option>
                                    )}
                                </select>
                                {errors.transmission && <span className="text-danger">{errors.transmission}</span> }
                            </div>

                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Chassis </label>
                                <select class="form-select js-choice" name="chassis" onChange={handleChange} value={postData.chassis}>
                                <option value="" selected disabled>Choisir ...</option>
                                    {chassis?.map((chassi,index)=>
                                        <option value={chassi.id}>{chassi.libelle}</option>
                                    )}
                                </select>
                                {errors.chassis && <span className="text-danger">{errors.chassis}</span> }
                              
                            </div>

                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Nombre de Vitesses</label>
                                <input type="number" class="form-control" placeholder="" name="vitesse" onChange={handleChange} value={postData.vitesse} />
                                
                            </div>
                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Nombre de Cylindres</label>
                                <input type="number" class="form-control" placeholder="" name="cylindre" onChange={handleChange} value={postData.cylindre} />
                                
                            </div>

                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">kilométrage(km)</label>
                                <input type="number" class="form-control" placeholder="" name="kilometrage" onChange={handleChange} value={postData.kilometrage} />
                                
                            </div>

                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Carburant </label>
                                <select class="form-select js-choice" name="carburant" onChange={handleChange} value={postData.carburant}>
                                <option value="" selected disabled>Choisir ...</option>
                                    {carburants?.map((carburant,index)=>
                                        <option value={carburant.id}>{carburant.libelle}</option>
                                    )}
                                </select>
                                {errors.carburant && <span className="text-danger">{errors.carburant}</span> }
                              
                            </div>

                            <div class="col-sm-6 col-lg-3">
                                <label class="form-label">Poids(kg)</label>
                                <input type="number" class="form-control" placeholder="" name="poids" onChange={handleChange} value={postData.poids} />
                                
                            </div>
                                 

                            
               
                            
                            <div class="col-12">
                                <label class="form-label">Description</label>
                                <textarea class="form-control" rows="3" name="description" onChange={handleChange} value={postData.description}></textarea>
                                {errors.description && <span className="text-danger">{errors.description}</span> }
                               
                            </div>
                            
                            
                            <hr/>

                            
                            <div class="col-12">
                                <h5 class="card-title mb-0">Images Associées</h5>
                            </div>

                            <div class="container">
                               
                               <div class="card mt-3">
                                   <div class="card-body">

                                       <div class="row mb-3">
                                           <div class="col-sm-10">
                                           <div class="tab-pane fade show active" id="tab-1">
                                               <div class="row g-3">

                                               
                                                   <div class="col-6 col-lg-3">
                                                       <div class=" h-100 pb-3 rounded text-center d-flex align-items-center justify-content-center position-relative">
                                                           <label class="btn stretched-link label-img" >
                                                           <input className="input-none"
                                                               type="file"
                                                               onChange={onSelectFile}
                                                               multiple
                                                               accept="image/png , image/jpeg, image/webp"
                                                               />
                                                               <i class="fa-solid fa-camera-retro fs-1"></i>
                                                               <h6>Ajouter Photo</h6>
                                                           </label>
                                                       </div>
                                                   </div>
                                                   
                       
                                               {image &&
                                                   image.map((img, index) => {
                                                       return (
                                                    <div class="col-6 col-lg-3 position-relative" key={index}>
                                                       <div class="position-absolute bottom-0 end-0">
                                                       
                                                           <div class="dropdown mb-2 me-3">
                                                               <button class="btn icon-sm bg-danger text-white rounded-circle" id="photoActionEdit3" onClick={() => deleteHandler(index)}>
                                                                   <i class="bi bi-trash-fill"></i>
                                                               </button>
                                                           </div>
                                                       </div>
                                                      
                                                           <img class="rounded img-fluid" src={img ? URL.createObjectURL(img): null}  alt="" />
                                                       
                                                   </div> 

                                                   );
                                                    })}
                                                   
                                               </div>
                                           </div>
                                           </div>
                                       </div>

                                   </div>
                               </div>

                               </div>
                            
              
                            
                            <div class="col-12 text-end">
                                {image.length > 0 && !loading &&  <button type="button" class="btn btn-primary mb-0" onClick={submitForm} >Enregistrer</button>}
                                {image.length == 0 && !loading &&  <button type="button" class="btn btn-primary mb-0"  disabled>Enregistrer</button>}
                                {image.length > 0 && loading &&  <button type="button" class="btn btn-primary mb-0"  disabled>Enregistrer</button>}
                            </div>
                            </div>
                        </div>
                        
                    </div>
        </>
    )
}

export default EnginPosteUser;