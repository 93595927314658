import axios from "axios";
import { useEffect, useState } from "react";
import Select from "react-select";
import Validate from "../../../Validation";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import BaseUrl from "../../../BaseUrl";

const baseUrl = BaseUrl();

function PosteColocUser({client}){
    const navigate = useNavigate();
    const [errors,setErrorM] = useState({});
    const [communes,setCommunes] = useState([]);
    const [pieces,setPieces] = useState([]);
    const [categories,setCategories]= useState([]);
    const [services,setServices] = useState([]);
    const [selectedCommune, setSelectedCommune] = useState(null);
    const [loading,setLoading] = useState(false);

    const [postData,setPostData] = useState({
        'libelle':'Recherche colocataire',
        'montant':'',
        'description':'',
        'contact':client? client.tel : '',
        'adresse_is_coloc':''
    });

    useEffect(()=>{

    },[client]);

    const handleChange=(event)=>{
        setPostData({
            ...postData,
            [event.target.name]:event.target.value
        });

      }

    const submitForm=()=>{
        setErrorM(Validate(postData));

        if(postData.libelle !== '' && postData.montant !=='' && !isNaN(postData.montant) && postData.adresse_is_coloc !==''&& postData.description && postData.contact !="" && postData.contact?.length == 10 && !isNaN(postData.contact)){
            const _fromData = new FormData();

            _fromData.append('libelle',postData.libelle);
            _fromData.append('montant',postData.montant.toString().replace(/\./g, ''));
            _fromData.append('description',postData.description);
            _fromData.append('contact',postData.contact);
            _fromData.append('adresse_is_coloc',postData.adresse_is_coloc);
            _fromData.append('user',client.id);
            

            setLoading(true)
            try {
                axios.post(baseUrl.base+'/post-coloc/',_fromData,{
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }).then((resp)=>{
                    if(resp.data.bool == true){
                       window.location.reload();
                    }else{
                        Swal.fire({
                            position: 'top-end',
                            icon: 'error',
                            title: 'Désolé ! Un problème est subvenu.',
                            showConfirmButton: false,
                            timer: 3000,
                            toast:true,
                            position:'top-right',
                            timerProgressBar:true
                          });
                          setLoading(false);
                    }
                    
                })
            } catch (error) {
                console.log(error);
            }
        }
        
       

    }

    return (
        <>
            <div class="card">
                        
                        <div class="card-header border-0 pb-0">
                            <h1 class="h4 card-title mb-0">Faire une annonce <button className="mt-1 btn btn-primary btn-sm border rounded mb-0 d-lg-none float-end" onClick={()=>window.location.reload()}><i class="fa-solid fa-arrow-left"></i></button></h1>
                        </div>
                        
                        <div class="card-body">
                            <div class="row g-3">
                            
                            <div class="col-12">
                                <label class="form-label">Titre de l'annonce</label>
                                <input type="text" class="form-control"  placeholder="Je recherche un colocataire." name="libelle" onChange={handleChange} value={postData.libelle}  disabled readonly/>
                                {errors.libelle && <span className="text-danger">{errors.libelle}</span> }
                                
                            </div>
                            
                            <div class="col-sm-6 col-lg-6">
                                <label class="form-label">Montant du loyer</label>
                                <input type="text" class="form-control" placeholder="Ex: 60000" name="montant" onChange={handleChange} value={postData.montant} />
                                {errors.montant && <span className="text-danger">{errors.montant}</span> }
                            </div>
                            
                            <div class="col-sm-6 col-lg-6">
                                <label class="form-label">Contact</label>
                                <input type="text" class="form-control" placeholder="Ex: 0171808441" name="contact" onChange={handleChange} value={postData.contact} />
                                {errors.contact && <span className="text-danger">{errors.contact}</span> }
                            </div>
                            
                            <div class="col-sm-12">
                                <label class="form-label">Préciser la commune, le quatier...</label>
                                <input type="text" class="form-control" placeholder="Ex: Abobo marché près de la mairie" name="adresse_is_coloc" onChange={handleChange} value={postData.adresse_is_coloc} />
                                {errors.adresse_is_coloc && <span className="text-danger">{errors.adresse_is_coloc}</span> }
                            </div>

                            <div class="col-12">
                                <label class="form-label">Description</label>
                                <textarea class="form-control" rows="3" name="description" onChange={handleChange} value={postData.description}></textarea>
                                {errors.description && <span className="text-danger">{errors.description}</span> }
                            </div>
                            
                            
                            <div class="col-12 text-end">
                               {!loading &&  <button type="submit" class="btn btn-primary mb-0" onClick={submitForm}>Créer votre annonce</button>}
                               {loading &&  <button type="submit" class="btn btn-primary mb-0"  disabled>Créer votre annonce</button>}
                            </div>
                            </div>
                        </div>
                        
                    </div>
        </>
    )
}

export default PosteColocUser;