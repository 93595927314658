export default function BaseUrl(){
    const url = {};
    // url.base = "http://192.168.1.3:8000/api";
    // url.public = "http://192.168.1.3:8000";

    // url.base = "http://127.0.0.1:8000/api";
    // url.public = "http://127.0.0.1:8000";

    url.base = "https://backend-api.winin.ci/api";
    url.public = "https://backend-api.winin.ci";
    return url;
    
}