import AuthContext from "../BaseU";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Select from "react-select";
import Swal from "sweetalert2";
import BaseUrl from "../../../BaseUrl";
import MyRequestComponent from "./MyRequestComponent";

// const siteUrl = 'http://127.0.0.1:8000';
const baseUrl = BaseUrl(); 
function NavUser(){
	const user = AuthContext();
    const navigate = useNavigate();

    useEffect(()=>{

    },[user]);


	const openModalRequete=()=>{
       window.$("#modalCreateRequetes").modal('show');
    }



    const logout = ()=>{
        localStorage.removeItem('_token_ucl');
        navigate('/login');
    }



    return (
		<>

        <div class="col-lg-3">

                        
        <div class="d-flex align-items-center d-lg-none">
            <button class="border-0 bg-transparent" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasSideNavbar" aria-controls="offcanvasSideNavbar">
                <span class="btn btn-primary"><i class="fa-solid fa-sliders-h"></i></span>
                <span class="h6 mb-0 fw-bold d-lg-none ms-2">{user.nom}  </span>
                
            </button>
            <button className=" btn btn-success btn-sm border rounded mb-0 ms-auto"  onClick={openModalRequete} ><i class="fa-brands fa-searchengin"></i>Recherche avancée</button>
        </div>



        <nav class="navbar navbar-expand-lg mx-0"> 
            <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasSideNavbar">
                
                <div class="offcanvas-header">
                    <button type="button" class="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>

                
                <div class="offcanvas-body d-block px-2 px-lg-0">
                    
                    <div class="card overflow-hidden">
                        
                        <div class="h-50px" style={{"backgroundImage":"url(/assets/images/bg/01.jpg)", "backgroundPosition": "center", "backgroundSize": "cover", "backgroundRepeat": "no-repeat"}}></div>
                            
                            <div class="card-body pt-0">
                                <div class="text-center">
                                
                                <div class="avatar avatar-lg mt-n5 mb-3">
                                    <a href="#!"><img className="avatar-img rounded border border-white border-3" src="/assets/images/avatar/placeholder.jpg" alt="" /></a>
                                </div>
                                
                                <h5 class="mb-0"> <a href="#!">{user.nom} {user.prenom}</a> </h5>
                                <small>{user.tel}</small>
                                {/*<p class="mt-3">I'd love to change the world, but they won’t give me the source code.</p>*/}
                                <div class="hstack gap-2 gap-xl-3 justify-content-center">
                                    
                                    {/* <div>
                                        <h6 class="mb-0">256</h6>
                                        <small>Post</small>
                                    </div>
                                    
                                    <div class="vr"></div>
                                    
                                    <div>
                                        <h6 class="mb-0">2.5K</h6>
                                        <small>Followers</small>
                                    </div>
                                    
                                    <div class="vr"></div>
                                
                                    <div>
                                        <h6 class="mb-0">365</h6>
                                        <small>Following</small>
                                    </div> */}

                                    <button className="mt-1 btn btn-success btn-sm border rounded form-control mb-0 d-none d-sm-inline-block" onClick={openModalRequete} ><img class="me-2 h-20px fa-fw" src="/assets/images/icon/sun-demi-icon.svg" alt="" />Recheche avancée</button>

                                </div>
                                
                            </div>

                            
                            <hr />

                            
                            <ul class="nav nav-link-secondary flex-column fw-bold gap-2">
                                <li class="nav-item  cursor-pointer">
                                    <a class="nav-link " onClick={()=>{navigate('/cl/');window.location.reload()}} > <img class="me-2 h-20px fa-fw" src="/assets/images/icon/indicator-icon.svg" alt="" /><span>Accueil </span></a>
                                </li>
                                <li class="nav-item cursor-pointer">
                                    <a class="nav-link"  onClick={()=>{navigate('/cl/creer-nouveau-posts/');window.location.reload()}}  > <img class="me-2 h-20px fa-fw" src="/assets/images/icon/home-outline-filled.svg" alt="" /><span>Mes Annonces </span></a>
                                </li>
                                <li class="nav-item cursor-pointer">
                                    <a class="nav-link" onClick={()=>{navigate('/users-favorites-postes/');window.location.reload()}} > <img class="me-2 h-20px fa-fw" src="/assets/images/icon/heath-favorit.svg" alt="" /><span>Mes Favoris </span></a>
                                </li>
                                <li class="nav-item cursor-pointer">
                                    <a class="nav-link" onClick={()=>{navigate('/mes-requetes/');window.location.reload()}} > <img class="me-2 h-20px fa-fw" src="/assets/images/icon/earth-outline-filled.svg" alt="" /><span>Mes Requêtes </span></a>
                                </li>
                                {/* <li class="nav-item">
                                    <a class="nav-link" href="events.html"> <img class="me-2 h-20px fa-fw" src="/assets/images/icon/calendar-outline-filled.svg" alt="" /><span>J'aide. <span className="badge rounded-pill text-bg-danger p-1">+99</span> </span></a>
                                </li> */}
                                {/* <li class="nav-item">
                                    <a class="nav-link" href="groups.html"> <img class="me-2 h-20px fa-fw" src="/assets/images/icon/chat-outline-filled.svg" alt="" /><span>Requêts Commenter </span></a>
                                </li> */}
                                <li class="nav-item cursor-pointer">
                                    <a class="nav-link"  onClick={()=>{navigate('/agences-professionnelle/');window.location.reload()}} > <img class="me-2 h-20px fa-fw" src="/assets/images/icon/building-icon-online.svg" alt="" /><span>Entreprises </span></a>
                                </li>
                                <li class="nav-item cursor-pointer">
                                    <a class="nav-link" onClick={()=>{navigate('/cl/settings/');window.location.reload()}} > <img class="me-2 h-20px fa-fw" src="/assets/images/icon/cog-outline-filled.svg" alt="" /><span>Mon compte </span></a>
                                </li>
                            </ul>
                            
                        </div>
                        
                        
                        <div class="card-footer text-center py-2">
                            <a class="btn btn-link btn-sm cursor-pointer" onClick={logout}>Se Deconnecter </a>
                        </div>
                    </div>
                    

                    
                    {/* <ul class="nav small mt-4 justify-content-center lh-1">
                        <li class="nav-item">
                            <a class="nav-link" href="my-profile-about.html">About</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="settings.html">Settings</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" target="_blank" href="https://support.webestica.com/login">Support </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" target="_blank" href="docs/index.html">Docs </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="help.html">Help</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="privacy-and-terms.html">Privacy & terms</a>
                        </li>
                    </ul> */}
                    
                    {/* <p class="small text-center mt-1"><Link class="text-body"  to="/cl/" > Page d'accueil </Link></p> */}
                </div>
            </div>
        </nav>

        </div>
		
            <MyRequestComponent user={user} />

		</>
       
    )
}

export default NavUser;