import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BaseUrl from "../../BaseUrl";

const baseUrl = BaseUrl();
function Header({agence,logout}){

    const [notifications,setNotifications] = useState([]);
    const [notifReadLength,setNotifReadLength] = useState(0);
    const [loadingNotif,setLoadingNotif] = useState(false);
    const [NotReadLength,setNotReadLength] = useState(0);
    const navigate = useNavigate();

    useEffect(()=>{
        if(agence && agence.id){ 
            setLoadingNotif(true);
            try {
                axios.get(baseUrl.base+'/notif-list/?agence='+agence.id).then((resp)=>{
                    setNotifications(resp.data);
                    setLoadingNotif(false);
                });

                axios.get(baseUrl.base+'/read-notif/'+agence.id+'/').then((res)=>{
                    if(res.data.bool){
                        setNotifReadLength(res.data.nbres);
                    }
                })

                axios.get(baseUrl.base+'/unread-status-notif/'+agence.id+'/').then((res)=>{
                    if(res.data.bool){
                        setNotReadLength(res.data.nbres);
                    }
                })
            } catch (error) {
                console.log(error);
            }
            //verifier chaque 2 min si il n'y a pas une nouvelle notification.
            const intervalId = setInterval(() => {
                axios.get(baseUrl.base+'/notif-list/?agence='+agence.id).then((resp)=>{
                    setNotifications(resp.data);
                });

                axios.get(baseUrl.base+'/unread-status-notif/'+agence.id+'/').then((res)=>{
                    if(res.data.bool){
                        setNotReadLength(res.data.nbres);
                    }
                });
              }, 2 * 60 * 1000); 
          
              return () => {
                clearInterval(intervalId);
              };
        }
    },[agence]);

    const NotifCallandRead=()=>{
        try {
            axios.get(baseUrl.base+'/unread-notif/'+agence.id+'/').then((resp)=>{
                if(resp.data.bool){
                    setNotifReadLength(resp.data.nbres);
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    const ReadingNotification=(subject,notifId)=>{

        try {
            axios.get(baseUrl.base+'/update-notif/'+notifId+'/').then((resp)=>{
                if(resp.data.bool){
                    
                        
                        axios.get(baseUrl.base+'/notif-list/?agence='+agence.id).then((resp)=>{
                            setNotifications(resp.data);
                            setLoadingNotif(false);
                        });
                        axios.get(baseUrl.base+'/unread-status-notif/'+agence.id+'/').then((res)=>{
                            if(res.data.bool){
                                setNotReadLength(res.data.nbres);
                            }
                        });
                        navigate('/all-notifications/?agence='+agence.id);
                    
                }
            })
        } catch (error) {
            console.log(error);
        }
       
        
        
    }
   
    return (
        <header class="navbar-light fixed-top header-static bg-mode">


        <nav class="navbar navbar-expand-lg">
            <div class="container">
            
                <Link class="navbar-brand" to="/auth-pro-account/">
                    <img class="light-mode-item navbar-brand-item" src="/assets/logo-winin.png" alt="logo" />
                    {/* <img class="dark-mode-item navbar-brand-item" src="/assets/images/logo.svg" alt="logo" /> */}
                </Link>
            


                <button class="navbar-toggler ms-auto icon-md btn btn-light p-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-animation">
                    <span></span>
                    <span></span>
                    <span></span>
                    </span>
                </button>

            
                <ul class="nav flex-nowrap align-items-center ms-sm-3 list-unstyled">
                    
                    <li class="nav-item dropdown ms-2">
                                <a class="nav-link icon-md btn btn-light p-0" onClick={()=>NotifCallandRead()} id="notifDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" data-bs-auto-close="outside">
                       {/* {notifReadLength > 0 && <span class="badge-notif animation-blink position-absolute"></span>}  */}
                       <span class="badge-notif animation-blink position-absolute " style={{"width":"11px","height":"11px","background":"none","marginRight":"10px"}}><p className="badge bg-danger  rounded " style={{padding:"1px"}}><b style={{fontSize:"10px"}}>{NotReadLength>99 ? "+99" : NotReadLength}</b></p></span>
                            <i class="bi bi-bell-fill fs-6"> </i>
                        </a>
                        <div class="dropdown-menu dropdown-animation dropdown-menu-end dropdown-menu-size-md p-0 shadow-lg border-0" aria-labelledby="notifDropdown" style={{right:"-56px"}}>
                            <div class="card">
                            <div class="card-header d-flex justify-content-between align-items-center">
                                <h6 class="m-0">Notifications <span class="badge bg-danger bg-opacity-10 text-danger ms-2">{NotReadLength} Nouveaux</span></h6>
                                <Link class="small" to={`/all-notifications/?agence=${agence.id}`}>Voir tout</Link>
                            </div>
                            <div class="card-body p-0">
                                <ul class="list-group list-group-flush list-unstyled p-2" style={{"width": "23em", "max-height": "600px", "overflow-y": "auto"}}>
                                {loadingNotif && <span className="text-success">En cours de chargement...</span>}
                                {notifications &&
                                    notifications.map((notif,index)=>
                                    <li>
                                       
                                             <Link onClick={()=>ReadingNotification(notif.notif_subject,notif.id)} class={notif.read_status==false ? "list-group-item list-group-item-action badge-unread rounded d-flex border-0 p-3 mb-1" : "list-group-item list-group-item-action rounded d-flex border-0 p-3 mb-1" }>

                                             <div class="ms-sm-3 d-flex">
                                                 <p class="small mb-2"><b>{notif.titre}</b> {notif.body}.<br/><span className="text-info">{moment(notif.created_at).fromNow()}</span></p>
                                                 {/* <p class="small ms-3"></p> */}
                                             </div>
                                             </Link>
                                        
                                   
                                    </li>
                                    )
                                }
                               
    
                                </ul>
                            </div>
                           {/*  <div class="card-footer text-center">
                                <a href="#" class="btn btn-sm btn-primary-soft">See all incoming activity</a>
                            </div> */}
                            </div>
                        </div>
                    </li>
            

            <li class="nav-item ms-2 dropdown">
                        <a class="nav-link btn icon-md p-0" href="#" id="profileDropdown" role="button" data-bs-auto-close="outside" data-bs-display="static" data-bs-toggle="dropdown" aria-expanded="false">
                            {agence.image ==null && <img class="avatar-img rounded-circle" src="/assets/images/avatar/placeholder.jpg" alt="" />}
                            {agence.image !=null && <img class="avatar-img rounded-circle" src={agence.image} alt="" />}
                        </a>
            <ul class="dropdown-menu dropdown-animation dropdown-menu-end pt-3 small me-md-n3" aria-labelledby="profileDropdown">
                
                <li class="px-3">
                <div class="d-flex align-items-center position-relative">
                    
                    <div class="avatar me-3">
                            {agence.image ==null && <img class="avatar-img rounded-circle" src="/assets/images/avatar/placeholder.jpg" alt="" />}
                            {agence.image !=null && <img class="avatar-img rounded-circle" src={agence.image} alt="" />}
                    </div>
                    <div>
                    <a class="h6 stretched-link" href="#">{agence?.raison_sociale}</a>
                   {/*  <p class="small m-0">Web Developer</p> */}
                    </div>
                </div>
                <button class="dropdown-item btn btn-primary-soft btn-sm my-2 text-center" onClick={()=>navigate('/ag/home/')} >Mon Profil</button>
                </li>
                
                <li><Link class="dropdown-item" to="/ag/settings/"><i class="bi bi-gear fa-fw me-2"></i>Paramètres & Profil</Link></li>
               {/*  <li> 
                <a class="dropdown-item" href="https://support.webestica.com/" target="_blank">
                    <i class="fa-fw bi bi-life-preserver me-2"></i>Support
                </a> 
                </li> 
                <li> 
                <a class="dropdown-item" href="docs/index.html" target="_blank">
                    <i class="fa-fw bi bi-card-text me-2"></i>Aides
                </a> 
                </li>*/}
                <li class="dropdown-divider"></li>
                <li><button class="dropdown-item bg-danger-soft-hover" onClick={logout}><i class="bi bi-power fa-fw me-2"></i>Se Deconnecter</button></li>
            
                        
            </ul>
                    </li>
            
            
                </ul>
            
            </div>
        </nav>

    </header>
    )
}

export default Header;