import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import Validate from '../../Validation';
import axios from "axios";
import * as $ from 'jquery';
import BaseUrl from "../../BaseUrl";

const baseUrl = BaseUrl();

function Login(){
    const navigate = useNavigate();
    const [isAuthToken, setIsAuthToken] = useState(localStorage.getItem('_token_ucl'));
    const [errors,setErrorM] = useState({});
    const [msgAlert,setMsgAlert] = useState('');
    const [msgerrorAlert,setMsgerrorAlert] = useState('');
    const [isLoading,setIsLoading] = useState(false);
    const [telUniqError,setTelUniqError] = useState('');
    const [emailUniqError,setEmailUniqError] = useState('');
    const [countries,setCountries] = useState([]);
    const [loading,setLoading] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);

    const [registerLocataire,setRegisterLocataire] = useState({
      'nom':'',
      'prenom':'',
      'tel':'',
      'password':'',
      'countrie':'',
      'age':'',
      'email':''
    });

    const [loginLocataire,setloginLocataire] = useState({
      'tel':'',
      'password':''
    });



    useEffect(()=>{
      if(isAuthToken != null && isAuthToken){
        navigate('/cl/');
      }
      try {
        axios.get(baseUrl.base+'/countrie/').then((resp)=>{
          setCountries(resp.data);
        })
      } catch (error) {
        console.log(error);
      }
    },[isAuthToken]);

    const handleChangeRegisterLocataire=(event)=>{
      setRegisterLocataire({
        ...registerLocataire,
        [event.target.name]:event.target.value
      })
    }

    const handleChangeLoginLocataire=(event)=>{
      setloginLocataire({
        ...loginLocataire,
        [event.target.name]:event.target.value
      })
    }

    const submitLogin = () =>{
      setMsgAlert("");
      setMsgerrorAlert("");

      if(loginLocataire.tel !== "" && loginLocataire.tel.length == 10 && !isNaN(loginLocataire.tel) && loginLocataire.password !== ""){
          const _formData = new FormData();

          _formData.append('tel',loginLocataire.tel);
          _formData.append('password',loginLocataire.password);
          setLoading(true);
          try {
              axios.post(baseUrl.base+'/login-users-client/',_formData,{
                  headers:{
                      'Content-Type':'application/json'
                  },
                  credentials:'include',
              }).then((resp)=>{
                setLoading(false);
                  if(resp.data.bool == true){
                      localStorage.setItem('_token_ucl',resp.data.token);
                      navigate('/cl/');
                  }else{
                    setMsgerrorAlert(resp.data.msg); 
                  }

               
              
              })
          } catch (error) {
            setLoading(false);
              console.log(error)
          }
      } else{
        setMsgerrorAlert("Numero de téléphone ou mot de passe invalide."); 
      }


  }





    const submitRegisterLocataire = (e)=>{
      e.preventDefault();
      setErrorM(Validate(registerLocataire));
      setTelUniqError('');
      setTelUniqError('');
      setMsgAlert("");
      setMsgerrorAlert("");
      if(registerLocataire.email !=="" && (/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/).test(registerLocataire.email) && registerLocataire.tel.length == 10 && !isNaN(registerLocataire.tel) && registerLocataire.tel !=="" && registerLocataire.nom !=="" && registerLocataire.prenom !==""  && registerLocataire.password !=="" && registerLocataire.countrie !=="" && registerLocataire.age !==""){
        const _fromData = new FormData();
        
        _fromData.append('nom',registerLocataire.nom);
        _fromData.append('prenom',registerLocataire.prenom);
        _fromData.append('tel',registerLocataire.tel);
        _fromData.append('password',registerLocataire.password);
        _fromData.append('countrie',registerLocataire.countrie);
        _fromData.append('age',registerLocataire.age);
        _fromData.append('email',registerLocataire.email);
        setIsLoading(true);
        try {
          axios.post(baseUrl.base+'/register-users/',_fromData).then((resp)=>{
            setIsLoading(false);
            if(resp.data.bool == true){
              
            
                setMsgAlert(resp.data.msg);
                setIsLoading(false);
                setRegisterLocataire({
                  'nom':'',
                  'prenom':'',
                  'tel':'',
                  'password':'',
                  'countrie':'',
                  'age':'',
                  'email':''
                })
                window.$('#createModal').modal('hide');

           
            }else{
              if(!resp.data.bool && resp.data.error){
                setTelUniqError(resp.data.error);
                setEmailUniqError(resp.data.erroremail);
                setIsLoading(false);
              }

              if(!resp.data.bool && resp.data.erroremail){
                setEmailUniqError(resp.data.erroremail);
                setIsLoading(false);
              }

              if(!resp.data.bool && resp.data.msg){
                  setMsgerrorAlert(resp.data.msg);
                  setIsLoading(false);
              
              }
            }

          })
        } catch (error) {
          setIsLoading(false);
          console.log(error);
        }

    

        
      }

    }



    return (
       <>
        
      <div className="container mt-5 pt-5 d-flex flex-column flex-lg-row justify-content-evenly">
        
        <div className="text-center text-lg-start mt-0 pt-0 mt-lg-5 pt-lg-5 ">
          <h1 className="text-primary fw-bold fs-0"><img src="https://winin.ci/assets/logo-winin.png" width="50%" height="20%"/></h1>
          <p className="w-75 mx-auto fs-4 mx-lg-0 d-none d-sm-inline-block">Nous vous aidons a trouver le logement qui vous convient de façon simple et sécurisé.</p>
        </div>
      
        <div className="login-style container  align-items-center justify-content-center" >
          
          <div className="bg-white shadow rounded p-3 input-group-lg ">

          {msgAlert !=="" || msgerrorAlert !=="" ?
            <div className={msgAlert !== "" ? "alert alert-success" : "alert alert-danger"} role="alert">
              <h4 className="alert-heading">{msgAlert !== "" ? "Félicitation !" : "Attention !"}</h4>
              <p>{msgAlert != "" ? msgAlert : msgerrorAlert}</p>
            </div>
            :""}

            {/* form login locataire */}
            <div className="input-group input-group-lg my-3 pt-3">
            <input type="text" className="form-control" name="tel" onChange={handleChangeLoginLocataire} value={loginLocataire.tel} placeholder="Numéro de Téléphone" />
            </div>
            {/* <input type="password" className="form-control my-3" name="password" onChange={handleChangeLoginLocataire} value={loginLocataire.password} placeholder="Mot de passe" /> */}
            <div className="input-group input-group-lg my-3">
              <input
                type={passwordVisible ? "text" : "password"}
                className="form-control"
                name="password"
                onChange={handleChangeLoginLocataire}
                value={loginLocataire.password}
                placeholder="Mot de passe"
              />
              <button
                type="button"
                className="btn btn-outline-primary"
                onClick={() => setPasswordVisible(!passwordVisible)}
              >
                <i className={passwordVisible ? "fa fa-eye-slash" : "fa fa-eye"}></i>
              </button>
            </div>
            <button className={!loading ? "btn btn-primary w-100 my-3" : "btn btn-primary w-100 my-3 disabled"} onClick={submitLogin}>{!loading ? "Se connecter" : "En cours..."}</button>
            <Link to="/password-user-forgot/" className="text-decoration-none text-center"><p>Mot de passe oublié ?</p></Link>

            <hr />
            <div className="text-center my-4">
              <button className="btn btn-success btn-lg" type="button" data-bs-toggle="modal" data-bs-target="#createModal">Créer votre compte</button>
            </div>
          {/* from register locataire */}
            <div className="modal fade" id="createModal" tabindex="-1" aria-labelledby="createModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
              
                  <div className="modal-header">
                    <div>
                      <h2 className="modal-title" id="exampleModalLabel">Je Crée mon compte</h2>
                      <span className="fs-7 ">Facile et rapide</span>
                    </div>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
          
                  <form className="modal-body" onSubmit={submitRegisterLocataire}>
                    
                      
                      <div className="row">
                        <div className="col">
                          <input type="text" className="form-control" placeholder="votre nom" name="nom" onChange={handleChangeRegisterLocataire}  value={registerLocataire.nom}/>
                        {errors.nom && <p className="text-danger">{errors.nom}</p>} 
                        </div>
                        <div className="col">
                          <input type="text" className="form-control" placeholder="votre prénom" name="prenom" onChange={handleChangeRegisterLocataire} value={registerLocataire.prenom}/>
                          {errors.prenom && <p className="text-danger">{errors.prenom}</p>} 
                        </div>
                      </div>
                      <div className="row">
                          <div className="col">
                              <input type="text" className="form-control mt-3" placeholder="Numéro de Téléphone" name="tel" onChange={handleChangeRegisterLocataire} value={registerLocataire.tel} />
                              {telUniqError !=="" && <p className="text-danger">{telUniqError}</p>}
                              {errors.tel && <p className="text-danger">{errors.tel}</p>} 
                          </div>
                          <div className="col">
                              <input type="email" className="form-control mt-3" placeholder="Adresse e-mail" name="email" onChange={handleChangeRegisterLocataire} value={registerLocataire.email} />
                              {emailUniqError !=="" && <p className="text-danger">{emailUniqError}</p>}
                              {errors.email && <p className="text-danger">{errors.email}</p>} 
                          </div>
                        </div>
                        <div className="input-group mt-3">
                          <input
                            type={passwordVisible ? "text" : "password"}
                            className="form-control"
                            name="password"
                            onChange={handleChangeRegisterLocataire}
                            value={registerLocataire.password}
                            placeholder="Mot de passe"
                          />
                          <button
                            type="button"
                            className="btn btn-outline-primary"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                          >
                            <i className={passwordVisible ? "fa fa-eye-slash" : "fa fa-eye"}></i>
                          </button>
                         
                        </div>
                        {errors.password && <p className="text-danger">{errors.password}</p>} 
                      {/* <input type="password" className="form-control mt-3" placeholder="Nouveau mot de passe" name="password" onChange={handleChangeRegisterLocataire} value={registerLocataire.password} />
                      {errors.password && <p className="text-danger">{errors.password}</p>}  */}
                    
                        <span className="fs-7">Information <i className="fas fa-question-circle"  type="button" ></i></span>
                        <div className="col mb-2">
                          <select className="form-select" name="countrie" onChange={handleChangeRegisterLocataire} value={registerLocataire.countrie}>
                            <option value="" disabled>Votre Pays </option>
                            {/* {countries?.map((countrie,index)=>{
                              <option value={countrie.id}>{countrie.libelle}</option>
                            })} */}
                            {countries && 
                              countries.map((countrie,index)=>(
                                <option value={countrie.id}>{countrie.libelle}</option>
                              ))
                            }
                          </select>
                        </div>
                        {errors.countrie && <p className="text-danger">{errors.countrie}</p>} 
                        <div className="col">
                          <select className="form-select" name="age" onChange={handleChangeRegisterLocataire} value={registerLocataire.age}>
                            <option value="" disabled>Tranche d'âge</option>
                            <option value="+23">+23</option>
                            <option value="-23">-23</option>
                          </select>
                        </div>
                        {errors.age && <p className="text-danger">{errors.age}</p>} 
                        
                    
                        {!isLoading && 
                          <button type="submit" className="btn btn-success btn-lg form-control my-3">Je crée mon compte</button>
                        }
                        {isLoading && 
                          <button class="btn btn-success btn-lg form-control my-3" type="button" disabled>
                          <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                          enregistrement...
                        </button>
                        }
                      
                      
                      <div>
                        {/* <span className="fs-7">En vous inscrivant, vous accepter nos <a href="#">Conditions générales</a></span> */}
                      </div>
                    
                  </form>
                </div>
              </div>
            </div>
          
          </div>
          
          <div className="my-5 pb-5 text-center">
            <p className="fw-bold card pt-2"><span className="fw-bold">Je suis une agence professionnelle</span><Link to="/auth-pro-account/" className="text-decoration-none" > cliquez ici </Link> </p>
          </div>
        </div>
      </div>

       </>
    )
}

export default Login;