import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import moment from "moment";
import PrincipaleComponent from "./PrincipalComponent";
import AuthContext from "./BaseU";
import Poste from "./compo/poste";
import NavUser from "./compo/navUser";
import BaseUrl from "../../BaseUrl";

const baseUrl = BaseUrl();
function MesReponsesAuxRequetes(){

    function separateNumber(number) {
        // Vérifier si le nombre est un entier positif
        if (Number.isInteger(number) && number >= 0) {
          // Convertir le nombre en chaîne de caractères
          var numberString = number.toString();
      
          // Séparer les chiffres en centaines
          var separatedNumber = '';
          for (var i = numberString.length - 1; i >= 0; i--) {
            separatedNumber = numberString.charAt(i) + separatedNumber;
            if (i > 0 && (numberString.length - i) % 3 === 0) {
              separatedNumber = ',' + separatedNumber;
            }
          }
      
          return separatedNumber;
        } else {
          return 'Veuillez fournir un entier positif.';
        }
      }

      const params = new URLSearchParams(window.location.search);
      const repId = params.get('rep');
      const slug = params.get('others');
      const location = useLocation();
      const [voirPLus,setVoirPlus] = useState(0);
      const [voirTel,setVoirTel] = useState(0);
      const [loading, setLoading] = useState(false);
      const [postes,setPostes] = useState([]);
      const requeteSelect = location.state ? location.state.requete : "";
      const client = AuthContext();

      useEffect(()=>{
        mesReponseProduits();

      },[client,repId,slug]);


      const mesReponseProduits=()=>{
        if (repId){
            setLoading(true);
            try {
                axios.get(baseUrl.base+'/postes/?reponse='+repId).then((resp)=>{
                    setPostes(resp.data);
                    setLoading(false);
                })
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
      }

      const funcVoirPLus =(poste)=>{
        const _fromData = new FormData();
        _fromData.append('poste',poste);
        _fromData.append('user',client.id);
        try {
            axios.post(baseUrl.base+'/interet-voir-plus-click/',_fromData).then((resp)=>{
                if(resp.data.bool){}else{}
            })
        } catch (error) {
            
        }

        setVoirPlus(poste)
           
        
    }

    const funcVoirTel = (poste)=>{
        const _fromData = new FormData();
        _fromData.append('poste',poste);
        _fromData.append('user',client.id);
        try {
            axios.post(baseUrl.base+'/interet-voir-tel-click/',_fromData).then((resp)=>{
                if(resp.data.bool){}else{}
            });
        } catch (error) {
            
        }

        setVoirTel(poste)
    }


    return (
        <PrincipaleComponent>
                           <div class="container">
                 <div class="row g-4">
                
                 <NavUser />
                 
                 <div class="col-md-8 col-lg-6 vstack gap-4">
                     
                     <div class="card">
                 
                    {/*  <div class="h-200px rounded-top banner-profil" ></div> */}
                         
                         <div class="card-body py-0">
                         <div class="d-sm-flex align-items-start text-center text-sm-start">
                          
                             <div class="ms-sm-4 mt-sm-3">
                             
                             <h6 class="mb-3 ">Vous avez reçu <span className="text-danger">{postes.length}</span> recommandations pour la requête  <span className="bg-success">{requeteSelect}</span></h6>
                         {/*  <p>250 connections</p> */}
                             </div>
                             
                             <div class="d-flex mx-2 mt-2 mb-2 justify-content-center ms-sm-auto">
                             <Link class="btn btn-info-soft me-2 btn-sm" to={'/mes-requetes/'}>
                             
                                 Retour
                             </Link> 
                             </div> 
                         </div>
                         
                         </div>

                       
                         
                       
                     </div>

              
                     {postes && 
                        postes?.map((poste,index)=>
                            <Poste poste={poste} index={index} />
                        )
                    }
                    
                    
           
                 
                 </div>
                 
                 <div class="col-lg-3"></div>
                 

                 </div> 
             </div>
        </PrincipaleComponent>
    )
}

export default MesReponsesAuxRequetes;