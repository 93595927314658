import {Routes as Switch, Route} from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/auth/Login';
import ProfilDashboard from './pages/pro/ProfilDash';
import LoginAgence from './pages/auth/LoginAgence';
import CreatePosts from './pages/pro/createPosts';
import SettingsPro from './pages/pro/Settings';
import ProfilUsers from './pages/clients/ProfilUsers';
import PagePoste from './pages/clients/PagePoste';
import SettingsClient from './pages/clients/parametres';
import ProfessionnellePage from './pages/clients/ProfessionnellePage';
import PageProDetail from './pages/clients/PageProDetail';
import FavoriteUserPage from './pages/clients/FavoriteUserPage';
import SearchPage from './pages/clients/searchPage';
import MesRequetes from './pages/clients/MesRequetes';
import ForgotPassword from './pages/auth/forgotPass';
import MesRecommandations from './pages/pro/MesRecommandations';
import NotificationAgencePage from './pages/pro/NotificationPage';
import NotificationClientList from './pages/clients/NotificationClientList';
import MesReponsesAuxRequetes from './pages/clients/mesReponsesAuxRequetes';
import PageNotFound from './pages/PageNotFound';
import DashOffPage from './pages/off/dashOff';
import MesReponsesNotAuth from './pages/off/MesReponsesNotAuth';
function Main(){
    return (
        <div>
            <Switch>
                <Route path="*" element={<PageNotFound />} /> 
                <Route path="/login" element={<Login />} />
                <Route path="/auth-pro-account/" element={<LoginAgence />} />
                <Route path="/ag/home/" element={<ProfilDashboard />} />
                <Route path="/ag/creer-nouveau-posts/" element={<CreatePosts />} />
                <Route path="/ag/settings/" element={<SettingsPro />} />

                <Route path="/cl/" element={<ProfilUsers />} />
                <Route path="/cl/creer-nouveau-posts/" element={<PagePoste />} />
                <Route path="/cl/settings/" element={<SettingsClient />} />
                <Route path='/agences-professionnelle/' element={<ProfessionnellePage />} />
                <Route path='/pro/:slug/' element={< PageProDetail />}/>
                <Route path='/users-favorites-postes/' element={<FavoriteUserPage />} />

                <Route path='/search/top' element={<SearchPage />} />

                <Route path='/mes-requetes/' element={<MesRequetes />} />
                <Route path='/password-user-forgot/' element={<ForgotPassword />} />
                <Route path='/mes-recommandation-aux-requetes/postes'  element={<MesRecommandations />}  />
                <Route path='/mes-reponses-obtenues-client-aux-requetes/postes'  element={<MesReponsesAuxRequetes />}  />
                <Route path='/mes-reponses-obtenues/postes'  element={<MesReponsesNotAuth />}  />

                <Route path='/all-notifications/' element={<NotificationAgencePage />} />
                <Route path='/all-notifications-client/' element={<NotificationClientList />} />


                <Route path='/' element={<DashOffPage />} />
            </Switch>

        </div>
    )
}

export default Main;