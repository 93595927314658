import axios from "axios";
import { useEffect, useState } from "react";
import moment from "moment";
import BaseUrl from "../../../BaseUrl";

const baseUrl = BaseUrl();
function DetailComponentPoste({poste}){

  function separateNumber(number) {
    // Vérifier si le nombre est un entier positif
    if (Number.isInteger(number) && number >= 0) {
      // Convertir le nombre en chaîne de caractères
      var numberString = number.toString();
  
      // Séparer les chiffres en centaines
      var separatedNumber = '';
      for (var i = numberString.length - 1; i >= 0; i--) {
        separatedNumber = numberString.charAt(i) + separatedNumber;
        if (i > 0 && (numberString.length - i) % 3 === 0) {
          separatedNumber = ',' + separatedNumber;
        }
      }
  
      return separatedNumber;
    } else {
      return 'Veuillez fournir un entier positif.';
    }
}

  const [images,setImageDetail] = useState([]);

  useEffect(()=>{
      try {
          axios.get(baseUrl.base+'/images-poste/?poste='+poste.id).then((resp)=>{
            setImageDetail(resp.data);
          })
      } catch (error) {
          console.log(error);
      }
  },[poste]);

    return (
      <>
      <div class="card">
     
       <div class="card-header border-0 pb-0">
         <h5 class="card-title"> Information complète</h5> 
       </div>
    
       <div class="card-body">
         <div class="rounded border px-3 py-2 mb-3"> 
           <div class="d-flex align-items-center justify-content-between">
             <h6>Description</h6>
           </div>
           <div dangerouslySetInnerHTML={{ __html: poste.description }} />
           </div>
           {poste.immobilier && 
              <div class="row g-4">
                 <div class="col-sm-6">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> code: <strong> {poste?.code} </strong>
                     </p>
                   </div>
             
                 </div>
                   <div class="col-sm-6">
                  
                     <div class="d-flex align-items-center rounded border px-3 py-2"> 
                  
                       <p class="mb-0">
                         <i class="bi bi-calendar-date fa-fw me-2"></i> Publication: <strong> {moment(poste.created_at).format("Do MMMM  YYYY")} </strong>
                       </p>
                     </div>
               
                   </div>
                   <div class="col-sm-6">
                   
                     <div class="d-flex align-items-center rounded border px-3 py-2"> 
                    
                       <p class="mb-0">
                        <strong> {poste?.libelle} </strong>
                       </p>
                     </div>
             
                   </div>
                   <div class="col-sm-6">
                    
                     <div class="d-flex align-items-center rounded border px-3 py-2"> 
               
                       <p class="mb-0">
                         <i class="bi bi-money fa-fw me-2"></i>Montant: <strong> {separateNumber(poste?.montant)} Fcfa</strong>
                       </p>
                     </div>
               
                   </div>
                   <div class="col-sm-6">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> contact: <strong> {poste?.contact} </strong>
                     </p>
                   </div>
             
                 </div>
                   <div class="col-sm-6">
                    
                     <div class="d-flex align-items-center rounded border px-3 py-2"> 
                     
                       <p class="mb-0">
                         <i class="bi bi-geo-alt fa-fw me-2"></i> Commune: <strong> {poste.immobilier?.commune?.libelle}</strong>
                       </p>
                     </div>
                   
                   </div>
                   <div class="col-sm-6">
                   
                     <div class="d-flex align-items-center rounded border px-3 py-2"> 
                    
                       <p class="mb-0">
                         <i class="bi bi-geo-alt fa-fw me-2"></i> lieu: <strong> {poste.immobilier?.quatier} </strong>
                       </p>
                     </div>
                 
                   </div>
                 {poste.immobilier?.piece &&
                  <div class="col-sm-6">
                   
                  <div class="d-flex align-items-center rounded border px-3 py-2"> 
                  
                    <p class="mb-0">
                      <i class="bi bi-envelope fa-fw me-2"></i> pièce: <strong> {poste.immobilier?.piece?.libelle} </strong>
                    </p>
                  </div>
            
                </div>
                }
                   
   
                 <div class="col-sm-6">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> catégorie: <strong> {poste.immobilier?.category?.libelle} </strong>
                     </p>
                   </div>
             
                 </div>
   
                 <div class="col-sm-6">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> service: <strong> {poste.service?.libelle} </strong>
                     </p>
                   </div>
             
                 </div>
                {poste.immobilier?.caution != 0 &&
                  <div class="col-sm-6">
                   
                  <div class="d-flex align-items-center rounded border px-3 py-2"> 
                  
                    <p class="mb-0">
                      <i class="bi bi-envelope fa-fw me-2"></i> caution: <strong> {poste.immobilier?.caution} </strong>
                    </p>
                  </div>
            
                </div>
                }
                 
                  {/*  <div class="col-sm-6 position-relative">
                   
                     <a class="btn btn-dashed rounded w-100" href="#!"> <i class="bi bi-plus-circle-dotted me-1"></i>Add a workplace</a>
                   
                   </div>
                   <div class="col-sm-6 position-relative">
                     
                     <a class="btn btn-dashed rounded w-100" href="#!"> <i class="bi bi-plus-circle-dotted me-1"></i>Add a education</a>
                     
                   </div> */}
                 </div>
           }

           {poste.is_coloc &&
                 <div class="row g-4">
                 <div class="col-sm-6">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> code: <strong> {poste?.code} </strong>
                     </p>
                   </div>
             
                 </div>
                   <div class="col-sm-6">
                  
                     <div class="d-flex align-items-center rounded border px-3 py-2"> 
                  
                       <p class="mb-0">
                         <i class="bi bi-calendar-date fa-fw me-2"></i> Publication: <strong> {moment(poste.created_at).format("Do MMMM  YYYY")}</strong>
                       </p>
                     </div>
               
                   </div>
                   <div class="col-sm-6">
                   
                     <div class="d-flex align-items-center rounded border px-3 py-2"> 
                    
                       <p class="mb-0">
                        <strong> {poste?.libelle} </strong>
                       </p>
                     </div>
             
                   </div>
                   <div class="col-sm-6">
                    
                     <div class="d-flex align-items-center rounded border px-3 py-2"> 
               
                       <p class="mb-0">
                         <i class="bi bi-money fa-fw me-2"></i>Montant: <strong> { separateNumber(poste?.montant)} Fcfa</strong>
                       </p>
                     </div>
               
                   </div>
                   <div class="col-sm-12">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> contact: <strong> {poste?.contact} </strong>
                     </p>
                   </div>
             
                 </div>
              
                   <div class="col-sm-12">
                   
                     <div class="d-flex align-items-center rounded border px-3 py-2"> 
                    
                       <p class="mb-0">
                         <i class="bi bi-geo-alt fa-fw me-2"></i> lieu: <strong> {poste.adresse_is_coloc} </strong>
                       </p>
                     </div>
                 
                   </div>
                
   
                
   
                
   
                
                  {/*  <div class="col-sm-6 position-relative">
                   
                     <a class="btn btn-dashed rounded w-100" href="#!"> <i class="bi bi-plus-circle-dotted me-1"></i>Add a workplace</a>
                   
                   </div>
                   <div class="col-sm-6 position-relative">
                     
                     <a class="btn btn-dashed rounded w-100" href="#!"> <i class="bi bi-plus-circle-dotted me-1"></i>Add a education</a>
                     
                   </div> */}
                 </div>
           }

           {poste.engins && 
                 <div class="row g-4">
                 <div class="col-sm-6">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> code: <strong> {poste?.code} </strong>
                     </p>
                   </div>
             
                 </div>
                   <div class="col-sm-6">
                  
                     <div class="d-flex align-items-center rounded border px-3 py-2"> 
                  
                       <p class="mb-0">
                         <i class="bi bi-calendar-date fa-fw me-2"></i> Publication: <strong> {moment(poste.created_at).format("Do MMMM  YYYY")} </strong>
                       </p>
                     </div>
               
                   </div>
                   <div class="col-sm-6">
                   
                     <div class="d-flex align-items-center rounded border px-3 py-2"> 
                    
                       <p class="mb-0">
                        <strong> {poste?.libelle} </strong>
                       </p>
                     </div>
             
                   </div>
                   <div class="col-sm-6">
                    
                     <div class="d-flex align-items-center rounded border px-3 py-2"> 
               
                       <p class="mb-0">
                         <i class="bi bi-money fa-fw me-2"></i>Montant: <strong> {separateNumber(poste?.montant)} Fcfa</strong>
                       </p>
                     </div>
               
                   </div>
                   <div class="col-sm-6">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> contact: <strong> {poste?.contact} </strong>
                     </p>
                   </div>
             
                 </div>
                   <div class="col-sm-6">
                    
                     <div class="d-flex align-items-center rounded border px-3 py-2"> 
                     
                       <p class="mb-0">
                         <i class="bi bi-geo-alt fa-fw me-2"></i> Marque: <strong> {poste.engins?.marque?.libelle}</strong>
                       </p>
                     </div>
                   
                   </div>
                   <div class="col-sm-6">
                   
                     <div class="d-flex align-items-center rounded border px-3 py-2"> 
                    
                       <p class="mb-0">
                         <i class="bi bi-geo-alt fa-fw me-2"></i> Model: <strong> {poste.engins?.model} </strong>
                       </p>
                     </div>
                 
                   </div>
                 
                   <div class="col-sm-6">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> Année: <strong> {poste.engins?.annee?.libelle} </strong>
                     </p>
                   </div>
             
                 </div>
   
                 <div class="col-sm-6">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> catégorie: <strong> {poste.engins?.category?.libelle} </strong>
                     </p>
                   </div>
             
                 </div>
   
                 <div class="col-sm-6">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> service: <strong> {poste.service?.libelle} </strong>
                     </p>
                   </div>
             
                 </div>
   
                 <div class="col-sm-6">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> Etat: <strong> {poste.engins?.etat?.libelle} </strong>
                     </p>
                   </div>
             
                 </div>

                 <div class="col-sm-6">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> Couleur: <strong> {poste.engins?.couleur?.libelle} </strong>
                     </p>
                   </div>
             
                 </div>

                 <div class="col-sm-6">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> Boîte: <strong> {poste.engins?.boite?.libelle} </strong>
                     </p>
                   </div>
             
                 </div>

                 <div class="col-sm-6">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> Transmission: <strong> {poste.engins?.transmission?.libelle} </strong>
                     </p>
                   </div>
             
                 </div>

                 <div class="col-sm-6">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> Chassis: <strong> {poste.engins?.chassis?.libelle} </strong>
                     </p>
                   </div>
             
                 </div>
                 <div class="col-sm-6">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> Carburant: <strong> {poste.engins?.carburant?.libelle} </strong>
                     </p>
                   </div>
             
                 </div>
                 <div class="col-sm-4">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> Vitesse: <strong> {poste.engins?.vitesse} </strong>
                     </p>
                   </div>
             
                 </div>
                 <div class="col-sm-4">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> Cylindre: <strong> {poste.engins?.cylindre} </strong>
                     </p>
                   </div>
             
                 </div>
                 <div class="col-sm-4">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> Kilometrage: <strong> {poste.engins?.kilometrage} km</strong>
                     </p>
                   </div>
             
                 </div>
                 <div class="col-sm-4">
                   
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       <i class="bi bi-envelope fa-fw me-2"></i> Poids à vide: <strong> {poste.engins?.poids} kg</strong>
                     </p>
                   </div>
             
                 </div>
                  {/*  <div class="col-sm-6 position-relative">
                   
                     <a class="btn btn-dashed rounded w-100" href="#!"> <i class="bi bi-plus-circle-dotted me-1"></i>Add a workplace</a>
                   
                   </div>
                   <div class="col-sm-6 position-relative">
                     
                     <a class="btn btn-dashed rounded w-100" href="#!"> <i class="bi bi-plus-circle-dotted me-1"></i>Add a education</a>
                     
                   </div> */}
                 </div>
           }

           {poste.phone &&
               <div class="row g-4">
               <div class="col-sm-6">
                 
                 <div class="d-flex align-items-center rounded border px-3 py-2"> 
                 
                   <p class="mb-0">
                     <i class="bi bi-envelope fa-fw me-2"></i> code: <strong> {poste?.code} </strong>
                   </p>
                 </div>
           
               </div>
                 <div class="col-sm-6">
                
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                
                     <p class="mb-0">
                       <i class="bi bi-calendar-date fa-fw me-2"></i> Publication: <strong> {moment(poste.created_at).format("Do MMMM  YYYY")} </strong>
                     </p>
                   </div>
             
                 </div>
                 <div class="col-sm-6">
                 
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                  
                     <p class="mb-0">
                      <strong> {poste?.libelle} </strong>
                     </p>
                   </div>
           
                 </div>
                 <div class="col-sm-6">
                  
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
             
                     <p class="mb-0">
                       <i class="bi bi-money fa-fw me-2"></i>Montant: <strong> {separateNumber(poste?.montant)} Fcfa</strong>
                     </p>
                   </div>
             
                 </div>
                 <div class="col-sm-6">
                 
                 <div class="d-flex align-items-center rounded border px-3 py-2"> 
                 
                   <p class="mb-0">
                      contact: <strong> {poste?.contact} </strong>
                   </p>
                 </div>
           
               </div>
                 <div class="col-sm-6">
                  
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                   
                     <p class="mb-0">
                       Marque: <strong> {poste.phone?.marque?.libelle}</strong>
                     </p>
                   </div>
                 
                 </div>
                 <div class="col-sm-6">
                 
                   <div class="d-flex align-items-center rounded border px-3 py-2"> 
                  
                     <p class="mb-0">
                      Model: <strong> {poste.phone?.model} </strong>
                     </p>
                   </div>
               
                 </div>
               
                 <div class="col-sm-6">
                 
                 <div class="d-flex align-items-center rounded border px-3 py-2"> 
                 
                   <p class="mb-0">
                    Couleur: <strong> {poste.phone?.couleur?.libelle} </strong>
                   </p>
                 </div>
           
               </div>
 
               <div class="col-sm-4">
                 
                 <div class="d-flex align-items-center rounded border px-3 py-2"> 
                 
                   <p class="mb-0">
                      Ecran: <strong> {poste.phone?.ecran}"</strong>
                   </p>
                 </div>
           
               </div>
 
               <div class="col-sm-4">
                 
                 <div class="d-flex align-items-center rounded border px-3 py-2"> 
                 
                   <p class="mb-0">
                      Résolution: <strong> {poste.phone?.resolution} </strong>
                   </p>
                 </div>
           
               </div>

               <div class="col-sm-4">
                 
                 <div class="d-flex align-items-center rounded border px-3 py-2"> 
                 
                   <p class="mb-0">
                     Caméra: <strong> {poste.phone?.appareilPhoto} </strong>
                   </p>
                 </div>
           
               </div>
 
               <div class="col-sm-4">
                 
                 <div class="d-flex align-items-center rounded border px-3 py-2"> 
                 
                   <p class="mb-0">
                     Etat: <strong> {poste.phone?.etat} </strong>
                   </p>
                 </div>
           
               </div>

               <div class="col-sm-4">
                 
                 <div class="d-flex align-items-center rounded border px-3 py-2"> 
                 
                   <p class="mb-0">
                      Système: <strong> {poste.phone?.systemeOs} {poste.phone?.version}</strong>
                   </p>
                 </div>
           
               </div>

               <div class="col-sm-4">
                 
                 <div class="d-flex align-items-center rounded border px-3 py-2"> 
                 
                   <p class="mb-0">
                      Réseau: <strong> {poste.phone?.reseau} </strong>
                   </p>
                 </div>
           
               </div>

               <div class="col-sm-4">
                 
                 <div class="d-flex align-items-center rounded border px-3 py-2"> 
                 
                   <p class="mb-0">
                     Stockage: <strong> {poste.phone?.disque}Go </strong>
                   </p>
                 </div>
           
               </div>

               <div class="col-sm-4">
                 
                 <div class="d-flex align-items-center rounded border px-3 py-2"> 
                 
                   <p class="mb-0">
                      RAM: <strong> {poste.phone?.memoire}Go </strong>
                   </p>
                 </div>
           
               </div>

               <div class="col-sm-4">
                 
                 <div class="d-flex align-items-center rounded border px-3 py-2"> 
                 
                   <p class="mb-0">
                     Processeur: <strong> {poste.phone?.processeur} </strong>
                   </p>
                 </div>
           
               </div>

               <div class="col-sm-4">
                 
                 <div class="d-flex align-items-center rounded border px-3 py-2"> 
                 
                   <p class="mb-0">
                     Autonomie: <strong> {poste.phone?.batterie} mAh </strong>
                   </p>
                 </div>
           
               </div>
               <div class="col-sm-4">
                 
                 <div class="d-flex align-items-center rounded border px-3 py-2"> 
                 
                   <p class="mb-0">
                     Poids: <strong> {poste.phone?.poids} kg </strong>
                   </p>
                 </div>
           
               </div>
               <div class="col-sm-4">
                 
                 <div class="d-flex align-items-center rounded border px-3 py-2"> 
                 
                   <p class="mb-0">
                      Emprunte digitale: <strong> {poste.phone?.is_emprunte ? "OUI" : "NON"}</strong>
                   </p>
                 </div>
           
               </div>
           
               
               </div>
           }

           {poste.computer &&
              <div class="row g-4">
              <div class="col-sm-6">
                
                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                
                  <p class="mb-0">
                    <i class="bi bi-envelope fa-fw me-2"></i> code: <strong> {poste?.code} </strong>
                  </p>
                </div>
          
              </div>
                <div class="col-sm-6">
               
                  <div class="d-flex align-items-center rounded border px-3 py-2"> 
               
                    <p class="mb-0">
                      <i class="bi bi-calendar-date fa-fw me-2"></i> Publication: <strong> {moment(poste.created_at).format("Do MMMM  YYYY")} </strong>
                    </p>
                  </div>
            
                </div>
                <div class="col-sm-6">
                
                  <div class="d-flex align-items-center rounded border px-3 py-2"> 
                 
                    <p class="mb-0">
                     <strong> {poste?.libelle} </strong>
                    </p>
                  </div>
          
                </div>
                <div class="col-sm-6">
                 
                  <div class="d-flex align-items-center rounded border px-3 py-2"> 
            
                    <p class="mb-0">
                      <i class="bi bi-money fa-fw me-2"></i>Montant: <strong> {separateNumber(poste?.montant)} Fcfa</strong>
                    </p>
                  </div>
            
                </div>
                <div class="col-sm-6">
                
                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                
                  <p class="mb-0">
                     contact: <strong> {poste?.contact} </strong>
                  </p>
                </div>
          
              </div>
                <div class="col-sm-6">
                 
                  <div class="d-flex align-items-center rounded border px-3 py-2"> 
                  
                    <p class="mb-0">
                      Marque: <strong> {poste.computer?.marque?.libelle}</strong>
                    </p>
                  </div>
                
                </div>
                <div class="col-sm-6">
                
                  <div class="d-flex align-items-center rounded border px-3 py-2"> 
                 
                    <p class="mb-0">
                     Model: <strong> {poste.computer?.model} </strong>
                    </p>
                  </div>
              
                </div>
              
                <div class="col-sm-6">
                
                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                
                  <p class="mb-0">
                   Couleur: <strong> {poste.computer?.couleur?.libelle} </strong>
                  </p>
                </div>
          
              </div>

              <div class="col-sm-4">
                
                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                
                  <p class="mb-0">
                     Ecran: <strong> {poste.computer?.ecran}"</strong>
                  </p>
                </div>
          
              </div>

              <div class="col-sm-4">
                
                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                
                  <p class="mb-0">
                     Carte graphique: <strong> {poste.computer?.carteGraphique} </strong>
                  </p>
                </div>
          
              </div>

              <div class="col-sm-4">
                
                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                
                  <p class="mb-0">
                    HDMI: <strong> {poste.computer?.is_hdmi ? "OUI" : "NON"} </strong>
                  </p>
                </div>
          
              </div>

              <div class="col-sm-4">
                
                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                
                  <p class="mb-0">
                    Etat: <strong> {poste.computer?.etat} </strong>
                  </p>
                </div>
          
              </div>

              <div class="col-sm-4">
                
                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                
                  <p class="mb-0">
                     Système: <strong> {poste.computer?.systemeOs} </strong>
                  </p>
                </div>
          
              </div>

              <div class="col-sm-4">
                
                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                
                  <p class="mb-0">
                     Type de système: <strong> {poste.computer?.typeOs} </strong>
                  </p>
                </div>
          
              </div>

              <div class="col-sm-4">
                
                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                
                  <p class="mb-0">
                    Stockage: <strong> {poste.computer?.disque}Go </strong>
                  </p>
                </div>
          
              </div>

              <div class="col-sm-4">
                
                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                
                  <p class="mb-0">
                     RAM: <strong> {poste.computer?.memoire}Go </strong>
                  </p>
                </div>
          
              </div>

              <div class="col-sm-4">
                
                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                
                  <p class="mb-0">
                    Processeur: <strong> {poste.computer?.processeur} Hz</strong>
                  </p>
                </div>
          
              </div>

              <div class="col-sm-4">
                
                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                
                  <p class="mb-0">
                    Autonomie: <strong> {poste.computer?.autonomie} Heures </strong>
                  </p>
                </div>
          
              </div>
              <div class="col-sm-4">
                
                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                
                  <p class="mb-0">
                    Poids: <strong> {poste.computer?.poids} kg </strong>
                  </p>
                </div>
          
              </div>
              <div class="col-sm-4">
                
                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                
                  <p class="mb-0">
                     Clavier retro-eclairé: <strong> {poste.computer?.clavierEclaire ? "OUI" : "NON"}</strong>
                  </p>
                </div>
          
              </div>

              <div class="col-sm-4">
                
                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                
                  <p class="mb-0">
                    Usb: <strong> {poste.computer?.usb} </strong>
                  </p>
                </div>
          
              </div>
              <div class="col-sm-4">
                
                <div class="d-flex align-items-center rounded border px-3 py-2"> 
                
                  <p class="mb-0">
                    Clavier: <strong> {poste.computer?.clavier} </strong>
                  </p>
                </div>
          
              </div>
          
              
              </div>
           }
       
       </div>
      
     </div>

           {images.length > 0 &&
                 <div class="card">
                 <div class="card-header d-sm-flex align-items-center justify-content-between border-0 pb-0">
                     <h5 class="card-title">Photos</h5>
                 </div>
 
                 <div class="card-body">
                     <div class="row g-3">
                         {images &&
                             images.map((image,index)=>
                             <div class="col-sm-6 col-md-4 col-lg-3">
                             <img class="rounded img-fluid" src={image.libelle} alt={image.poste?.libelle} />
                             </div>
                            
                             )
                         }
                     </div>
                 </div>
           </div>
           }
   

   </>
    )
}

export default DetailComponentPoste;